import { Box, HStack, Link as ChakraLink, Separator, Stack } from '@chakra-ui/react'
import { memo } from 'react'
import { BiExtension, BiBookOpen, BiHelpCircle, BiCalendarAlt, BiTestTube } from 'react-icons/bi'
import { FaSlack } from 'react-icons/fa'
import { NavLink, useParams } from 'react-router-dom'

import NavButton from './navButton'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Can from '@app/shared/authorization/can'
import FavoritesSidebarList from '@app/shared/layout/shells/components/favoritesSidebarList'
import GoogleSheetSidebarMenu from '@app/shared/layout/shells/components/googleSheetSidebarMenu'
import SidebarMenu from '@app/shared/layout/shells/components/sidebarMenu'
import ICON_MAP from '@app/utils/iconMap'
import { Routes } from '@app/utils/routeHelpers'

const Sidebar = () => {
  const { strategyId } = useParams()
  const { user } = useStoreCurrentUser()

  let intercomProps = {}
  if (strategyId) {
    intercomProps = {
      onClick: () => {
        if (window.Intercom) {
          window.Intercom('show')
        }
      }
    }
  }

  return (
    <Stack as="section" justifyContent="space-between" direction="column" h="100%">
      <Box overflowY="auto" px={{ base: '4', sm: '6' }} py={{ base: '6', sm: '8' }}>
        <Stack gap={{ base: '4', sm: '6' }}>
          <NavButton as={NavLink} label="Maps" icon={ICON_MAP.StrategyMap} to="/strategy" />
          <>
            <Separator />
            <Stack gap="1">
              <NavButton as={NavLink} label="Cards" icon={ICON_MAP.BasicCard} to="/cards" />
              <HStack>
                <NavButton flex="1" as={NavLink} label="Metrics" icon={ICON_MAP.Metric} to="/metrics" />
                <GoogleSheetSidebarMenu />
              </HStack>
              <NavButton as={NavLink} label="Work" icon={ICON_MAP.Work} to="/work" />
            </Stack>
          </>
          <Separator />
          <FavoritesSidebarList />
          <Stack gap={1}>
            <Can I="update" an="organization">
              <NavButton as={NavLink} label="Playbooks" icon={ICON_MAP.Playbook} to="/playbooks" />
            </Can>
            <Can I="view" an="integration">
              <NavButton as={NavLink} label="Integrations" to="/settings/integrations" icon={BiExtension} />
            </Can>
            <Can I="view" a="metricSource">
              <NavButton as={NavLink} label="Import status" to="/tasks" icon={ICON_MAP.Task} />
            </Can>

            <Can I="view" an="admin">
              <NavButton as={NavLink} label="Evals" to="/evals" icon={BiTestTube} />
            </Can>

            <NavButton
              label="Schedule a chat"
              href={Routes.chat}
              icon={BiCalendarAlt}
              as={ChakraLink}
              rel="onoopener noreferrer"
              target="_blank"
            />
            <NavButton
              label="Slack community"
              href={Routes.slackCommunity}
              icon={FaSlack}
              as={ChakraLink}
              rel="onoopener noreferrer"
              target="_blank"
            />

            {/* <NavButton */}
            {/*  as={ChakraLink} */}
            {/*  label="Our changelog" */}
            {/*  href={Routes.doubleloopProductUpdates} */}
            {/*  icon={BiListCheck} */}
            {/*  rel="onoopener noreferrer" */}
            {/* target="_blank" */}
            {/* /> */}
            <NavButton
              as={ChakraLink}
              label="Documentation"
              href={Routes.docs}
              icon={BiBookOpen}
              rel="onoopener noreferrer"
              target="_blank"
            />
            <NavButton id="intercom-launcher" label="Need help?" icon={BiHelpCircle} {...intercomProps} />
          </Stack>
        </Stack>
      </Box>

      <Stack>{user && <SidebarMenu user={user} />}</Stack>
    </Stack>
  )
}

export default memo(Sidebar)
