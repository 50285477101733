import type { FC } from 'react'
import { memo } from 'react'

// import AssistantForm from './components/assistantForm'
import PerformanceMetrics from './components/performanceMetrics'
// import Pipelines from './components/pipelines'

import { PageHeader, PageStack } from '@app/shared/layout'

const Evals: FC = () => (
  <PageStack gap={6}>
    <PageHeader title="Evals" subtitle="AI Assistant performance dashboard" />
    <PerformanceMetrics />
    {/* <AssistantForm /> */}
    {/* <Pipelines /> */}
  </PageStack>
)

export default memo(Evals)
