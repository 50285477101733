import { Box, Group, IconButton } from '@chakra-ui/react'
import { NodeToolbar } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'

import { Tooltip } from '@app/components/ui/tooltip'
import useGetEdges from '@app/hooks/useGetEdges'
import useGetNodes from '@app/hooks/useGetNodes'
import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import BulkDuplicateButton from '@app/pages/maps/components/multiSelectToolbar/bulkDuplicateButton'
import BulkLabelsButton from '@app/pages/maps/components/multiSelectToolbar/bulkLabelsButton'
import BulkColorButton from '@app/pages/maps/components/nodes/components/toolbar/components/bulkColorButton'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'

interface MultiSelectToolbarProps {
  strategyId: string
  isSelecting: boolean
}

const MultiSelectToolbar: FC<MultiSelectToolbarProps> = ({ strategyId, isSelecting }) => {
  const isAnonymousUser = useIsAnonymousUser(strategyId)
  const nodes = useGetNodes(strategyId)
  const selectedNodes = nodes.filter((node) => node.selected)

  const edges = useGetEdges(strategyId)
  const selectedEdges = edges.filter((edge) => edge.selected)

  const onNodesDelete = useStore.use.onNodesDelete()
  const moveNodesToNewMap = useStore.use.moveNodesToNewMap()
  const collapseNodes = useStore.use.collapseNodes()

  const isVisible = selectedNodes.length > 1

  const handleNodesMoveToNewMap = () => {
    const positionSum = selectedNodes.reduce(
      (acc, node) => ({
        x: acc.x + node.position.x,
        y: acc.y + node.position.y
      }),
      { x: 0, y: 0 }
    )

    const position = {
      x: Math.floor(positionSum.x / selectedNodes.length),
      y: Math.floor(positionSum.y / selectedNodes.length)
    }

    moveNodesToNewMap(strategyId, selectedNodes, selectedEdges, position)
  }

  const handleCollapseNodes = () => {
    collapseNodes(strategyId, selectedNodes)
  }

  if (isSelecting || isAnonymousUser) {
    return null
  }

  return (
    <NodeToolbar nodeId={selectedNodes.map((selectedNode) => selectedNode.id)} isVisible={isVisible}>
      <Group bg="bg" attached colorPalette="gray">
        <Tooltip content="Copy">
          <Box>
            <BulkDuplicateButton nodes={selectedNodes} />
          </Box>
        </Tooltip>
        <Tooltip content="Move to submap">
          <IconButton
            borderRadius="none"
            aria-label="submap-button"
            onClick={handleNodesMoveToNewMap}
            size="sm"
            variant="outline"
          >
            <ICON_MAP.StrategyMap />
          </IconButton>
        </Tooltip>
        <Tooltip content="Card color">
          <Box>
            <BulkColorButton strategyId={strategyId} />
          </Box>
        </Tooltip>
        <BulkLabelsButton strategyId={strategyId} />
        <Tooltip content="Collapse cards downstream">
          <IconButton
            borderRadius="none"
            aria-label="collapse-button"
            onClick={handleCollapseNodes}
            size="sm"
            variant="outline"
          >
            <ICON_MAP.CollapseAction />
          </IconButton>
        </Tooltip>
        <Tooltip content="Delete">
          <IconButton
            borderRadius="none"
            aria-label="delete"
            data-testid="delete-button"
            onClick={() => onNodesDelete(strategyId, selectedNodes)}
            size="sm"
            variant="outline"
          >
            <ICON_MAP.Delete />
          </IconButton>
        </Tooltip>
      </Group>
    </NodeToolbar>
  )
}

MultiSelectToolbar.displayName = 'MultiSelectToolbar'
export default memo(MultiSelectToolbar)
