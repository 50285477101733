import type { NativeSelect } from '@chakra-ui/react'
import type { FC } from 'react'

import { NativeSelectRoot, NativeSelectField } from '@app/components/ui/native-select'
import { useLookerApiLookFieldsQuery } from '@graphql/queries'

type Props = Omit<NativeSelect.FieldProps, 'children'> & {
  credentialId: string
  lookId: string
}

const LookFieldSelectInput: FC<Props> = ({ credentialId, lookId, ...rest }) => {
  const [{ data }] = useLookerApiLookFieldsQuery({
    variables: {
      credentialId,
      lookId
    },
    pause: !lookId
  })

  const options = data?.lookerApiLookFields || []

  return (
    <NativeSelectRoot>
      <NativeSelectField {...rest}>
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </NativeSelectField>
    </NativeSelectRoot>
  )
}

export default LookFieldSelectInput
