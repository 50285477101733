import { Box, Flex, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import type { OperationResult } from 'urql'

import EmptyStatePrompt from './components/emptyStatePrompt'

import ChatDrawerHeader from '@app/pages/maps/components/aiChat/components/chatDrawerHeader'
import PromptForm from '@app/pages/maps/components/aiChat/components/promptForm'
import PipelineInstructions from '@app/pages/maps/components/aiChat/pipelineInstructions'
import type { PipelineRunMutation } from '@graphql/queries'
import type { PipelineEnum } from '@graphql/types'

type Props = {
  pipelineId: PipelineEnum
  strategyId?: string | null
}

const New: FC<Props> = ({ pipelineId, strategyId: propStrategyId }) => {
  const { strategyId: paramStrategyId } = useParams()
  const strategyId = propStrategyId || paramStrategyId
  const navigate = useNavigate()
  const onSuccess = (result: OperationResult<PipelineRunMutation>) => {
    const chatId = result?.data?.pipelineRun?.chat?.id

    if (chatId) {
      navigate(`../${chatId}`)
    }
  }

  return (
    <>
      <ChatDrawerHeader />
      <Flex pos="relative" direction="column" overflow="hidden" h="100vh" bg="bg.canvas">
        <Box overflowY="auto" h="full" pt="20" pb="40">
          <EmptyStatePrompt>
            <PipelineInstructions pipelineId={pipelineId} />
          </EmptyStatePrompt>
        </Box>

        <Box
          pos="absolute"
          bottom="0"
          mx="4"
          py="4"
          bgGradient="to-t"
          gradientFrom="bg.canvas 80%"
          gradientTo="rgba(0,0,0,0)"
          insetX="0"
        >
          <Stack mx="auto">
            <PromptForm pipelineId={pipelineId} strategyId={strategyId} onSuccess={onSuccess} />
          </Stack>
        </Box>
      </Flex>
    </>
  )
}

export default New
