import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import useCookie from '@app/hooks/useCookie'
import { BASIC_CARD_TYPE_COOKIE } from '@app/lib/globals'
import AddCardDrawer from '@app/pages/maps/components/addCards/addCardDrawer'

const AddBasicCardDrawer = () => {
  const { cardTypeId: paramCardTypeId } = useParams()
  const [cookieCardTypeId] = useCookie(BASIC_CARD_TYPE_COOKIE)

  const mutationCardId = paramCardTypeId || cookieCardTypeId

  const mutationVariables = useMemo(
    () => ({
      cardTypeId: mutationCardId
    }),
    [mutationCardId]
  )

  return (
    <AddCardDrawer
      cardType="basicCard"
      headerText="Cards"
      buttonText="New card"
      mutationVariables={mutationVariables}
    />
  )
}

export default AddBasicCardDrawer
