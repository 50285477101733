import { Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import { PasswordInput } from '@app/components/ui/password-input'
import type { Credential } from '@graphql/types'

interface Props {
  credential?: Pick<Credential, 'host' | 'port' | 'warehouse' | 'database' | 'username' | 'schema' | 'role'>
}

const SnowflakeAuth: FC<Props> = ({ credential }) => (
  <Stack>
    <Field
      label="Host"
      required
      helperText="Snowflake hosts should not include http(s)://, and end in `.snowflakecomputing.com`"
    >
      <Input
        autoComplete="off"
        data-1p-ignore
        defaultValue={credential?.host}
        name="host"
        pattern="[a-zA-Z\d][\.a-zA-Z\d_\-]*(\.[a-zA-Z\d_\-])*\.snowflakecomputing\.com"
        placeholder="<org>-<account>.snowflakecomputing.com"
      />
    </Field>
    <Field label="Database port" required>
      <Input
        autoComplete="off"
        data-1p-ignore
        defaultValue={credential?.port || '443'}
        name="port"
        placeholder="Snowflake port number"
      />
    </Field>
    <Field label="Warehouse name" required>
      <Input
        autoComplete="off"
        data-1p-ignore
        defaultValue={credential?.warehouse}
        name="warehouse"
        placeholder="Warehouse name"
      />
    </Field>
    <Field label="Database name" required>
      <Input
        autoComplete="off"
        data-1p-ignore
        defaultValue={credential?.database}
        name="database"
        placeholder="Database name"
      />
    </Field>
    <Field label="Schema name" required>
      <Input
        autoComplete="off"
        data-1p-ignore
        defaultValue={credential?.schema || 'public'}
        name="schema"
        placeholder="Schema name"
      />
    </Field>
    <Field label="Username" required>
      <Input
        autoComplete="off"
        data-1p-ignore
        defaultValue={credential?.username}
        name="username"
        placeholder="Snowflake user name"
      />
    </Field>
    <Field
      label="Password"
      required={isEmpty(credential)}
      helperText={
        !isEmpty(credential) ? 'Leave blank to keep the current password or fill in to set a new password' : null
      }
    >
      <PasswordInput name="password" placeholder="Snowflake user password" autoComplete="off" data-1p-ignore />
    </Field>
    <Field label="Role">
      <Input
        autoComplete="off"
        data-1p-ignore
        defaultValue={credential?.role}
        name="role"
        placeholder="(Optional) Snowflake role to use for the query"
      />
    </Field>
    {!!Object.keys(credential || {}).length && (
      <VisuallyHidden asChild>
        <Input defaultValue="password" name="secretFields" />
      </VisuallyHidden>
    )}
  </Stack>
)

export default SnowflakeAuth
