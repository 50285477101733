import { Editable, HStack, Icon, IconButton, Spinner, Table, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { FiChevronDown, FiTrash2 } from 'react-icons/fi'

import { iconMap } from '../customIcons'

import CardTypeDeleteButton from './cardTypeDeleteButton'
import ColorPickerPopover from './colorPickerPopover'
import ColorSquare from './colorSquare'
import IconPickerPopover from './iconPickerPopover'

import { Checkbox } from '@app/components/ui/checkbox'
import { useStore } from '@app/store'
import type { CardType } from '@graphql/types'

interface Props {
  cardType: CardType
}

const CardTypeTableRow: FC<Props> = ({ cardType }) => {
  const [name, setName] = useState(cardType.name || '')
  const [color, setColor] = useState(cardType.color || '')
  const [icon, setIcon] = useState(cardType.icon || '')
  const [showInMenu, setShowInMenu] = useState<boolean>(cardType.showInMenu || false)
  const [deleting, setDeleting] = useState(false)
  const updateObject = useStore.use.updateObject()

  const IconComponent = iconMap[icon]
  const iconColor = color ? `${color}.500` : 'gray.500'
  const renderedIcon = icon ? (
    <Icon boxSize={5} color={iconColor}>
      <IconComponent />
    </Icon>
  ) : (
    <Text>None</Text>
  )

  const handleColorChange = (e: string) => {
    setColor(e)

    updateObject({ cardType: { id: cardType.id, color: e } })
  }

  const handleIconChange = (e: string) => {
    setIcon(e)

    updateObject({ cardType: { id: cardType.id, icon: e } })
  }

  const handleNameChange = ({ value: newName }) => {
    updateObject({ cardType: { id: cardType.id, name: newName } })
  }

  const handleShowInMenuChange = (e: boolean) => {
    setShowInMenu(e)

    updateObject({ cardType: { id: cardType.id, showInMenu: e } })
  }

  return (
    <Table.Row>
      <Table.Cell w="55px">
        <ColorPickerPopover onClick={handleColorChange} value={color}>
          <HStack w="50px" cursor="pointer">
            <ColorSquare color={color} />
            <Icon>
              <FiChevronDown />
            </Icon>
          </HStack>
        </ColorPickerPopover>
      </Table.Cell>
      <Table.Cell w="55px" px={1}>
        <IconPickerPopover onClick={handleIconChange} value={icon} color={color}>
          <HStack cursor="pointer">
            {renderedIcon}
            <Icon>
              <FiChevronDown />
            </Icon>
          </HStack>
        </IconPickerPopover>
      </Table.Cell>
      <Table.Cell>
        <Editable.Root
          fontSize="md"
          defaultValue={name}
          onValueChange={({ value }) => {
            setName(value)
          }}
          onValueCommit={handleNameChange}
          placeholder="Add a name"
        >
          <Editable.Preview textWrap="wrap" w="100%" color={`${color}.500`} lineHeight="1.2" />
          <Editable.Input />
        </Editable.Root>
      </Table.Cell>
      <Table.Cell w="60px" pr={0} textAlign="end">
        <HStack>
          <Checkbox checked={showInMenu} onCheckedChange={(e) => handleShowInMenuChange(!!e.checked)} />
          <CardTypeDeleteButton cardTypeId={cardType.id} onDelete={() => setDeleting(true)}>
            <IconButton color="red.500" fontSize="lg" aria-label="Delete type" variant="ghost">
              {deleting ? <Spinner size="xs" /> : <FiTrash2 />}
            </IconButton>
          </CardTypeDeleteButton>
        </HStack>
      </Table.Cell>
    </Table.Row>
  )
}

export default CardTypeTableRow
