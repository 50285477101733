import { Icon } from '@chakra-ui/react'
import type { FC } from 'react'
import type { IconType } from 'react-icons'

import type { ButtonProps } from '@app/components/ui/button'
import { Button } from '@app/components/ui/button'

interface Props extends ButtonProps {
  icon: IconType
  label: string
  to?: string
  href?: string
  target?: string
}

const NavButton: FC<Props> = ({ icon, label, ...rest }) => {
  const iconSize = rest?.size === 'sm' ? '4' : '5'
  const IconComponent = icon

  return (
    <Button
      justifyContent="start"
      color="fg.muted"
      whiteSpace="normal"
      wordBreak="break-word"
      _currentPage={{ bg: 'bg.muted' }}
      title={label}
      variant="ghost"
      {...rest}
    >
      <Icon boxSize={iconSize} color="fg.muted">
        <IconComponent />
      </Icon>{' '}
      {label}
    </Button>
  )
}

export default NavButton
