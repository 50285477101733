import { Box, Group, Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import type { FormEventHandler } from 'react'
import { useState } from 'react'
import { useNavigation } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import { toaster } from '@app/components/ui/toaster'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import FormAlert from '@app/next/forms/formAlert'
import ToggleInput from '@app/next/forms/toggleInput'
import timezones from '@app/utils/timezones'
import type { GqlError } from '@app/utils/unpackErrors'
import unpackErrors from '@app/utils/unpackErrors'
import { actionMutation } from '@graphql/client'
import { UserUpdate } from '@graphql/documents/user.graphql'
import type { UserUpdateMutation } from '@graphql/queries'

const humanizeTimezone = (tz) => tz.replaceAll('_', ' ').replaceAll('/', ' - ')

const UserForm = () => {
  const { user } = useStoreCurrentUser()
  const navigation = useNavigation()

  const [errors, setErrors] = useState<Record<string, GqlError>>({})
  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const input = Object.fromEntries(formData.entries())

    try {
      const response = await actionMutation<UserUpdateMutation>(UserUpdate, input)

      ahoy.track('user:edited', input)

      if (response.error) {
        throw response
      }

      const { errors: updateErrors } = response.data.userUpdate

      if (updateErrors.length) {
        const unpacked = unpackErrors(updateErrors)
        setErrors(unpacked)
      }

      toaster.create({
        title: 'Updating your profile',
        type: 'success'
      })
    } catch (error) {
      setErrors(error)
    }
  }

  return (
    <form onSubmit={onSubmit} id="user-form">
      <VisuallyHidden asChild>
        <Input defaultValue={user.id} name="userId" />
      </VisuallyHidden>
      <FormAlert description={errors?.global?.message} title="Failed to update your profile!" />
      <Stack gap="5">
        <Field label="Name" required invalid={!!errors?.name} errorText={errors?.name?.message}>
          <Input bg="bg" defaultValue={user.name} name="name" placeholder="Whats your name?" />
        </Field>
        <Field label="Email" id="email" invalid={!!errors?.email} required errorText={errors?.email?.message}>
          <Input
            bg="bg"
            autoComplete="email"
            defaultValue={user.email}
            name="email"
            placeholder="Email address"
            type="email"
          />
        </Field>
        <Field label="Job title" invalid={!!errors?.jobTitle} errorText={errors?.jobTitle?.message}>
          <Input
            bg="bg"
            defaultValue={(user as unknown as { jobTitle: string }).jobTitle}
            name="jobTitle"
            placeholder="Whats your job title?"
          />
        </Field>
        <Field label="Time zone" invalid={!!errors?.timeZone} errorText={errors?.timeZone?.message}>
          <NativeSelectRoot>
            <NativeSelectField name="timeZone" placeholder={null} defaultValue={user.timeZone} bg="bg">
              {timezones.map((timezone) => (
                <option key={timezone} value={timezone}>
                  {humanizeTimezone(timezone)}
                </option>
              ))}
            </NativeSelectField>
          </NativeSelectRoot>
        </Field>
        <Box css={{ '--field-label-width': '430px' }}>
          <Field label="Opt into platform analytics" orientation="horizontal">
            <ToggleInput name="optedInToAnalytics" defaultValue={user.optedInToAnalytics} />
          </Field>
        </Box>
        <Group justifyContent="end" w="full">
          <Button loading={navigation.state === 'submitting'} type="submit">
            Save changes
          </Button>
        </Group>
      </Stack>
    </form>
  )
}

export default UserForm
