import { Group } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import useGetNodes from '@app/hooks/useGetNodes'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'

const MapExpandAllNodes: FC = () => {
  const { strategyId } = useParams<{ strategyId: string }>()
  const { canEdit } = usePermissionsContext()
  const nodes = useGetNodes(strategyId)
  const expandNodes = useStore.use.expandNodes()
  const collapsedNodes = Object.values(nodes).filter((node) => node.metadata?.collapsed)

  if (!canEdit) {
    return null
  }

  if (!collapsedNodes.length) {
    return null
  }

  const handleUnCollapseAll = () => {
    expandNodes(strategyId, collapsedNodes)
  }

  return (
    <Group colorPalette="gray">
      <Button aria-label="Uncollapse all nodes" onClick={handleUnCollapseAll} size="2xs" variant="ghost">
        <ICON_MAP.ExpandAction /> Uncollapse all
      </Button>
    </Group>
  )
}

export default MapExpandAllNodes
