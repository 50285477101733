import { Group, IconButton } from '@chakra-ui/react'
import type { IconButtonProps } from '@chakra-ui/react'
import { useReactFlow } from '@xyflow/react'

import { Tooltip } from '@app/components/ui/tooltip'
import ICON_MAP from '@app/utils/iconMap'

const ZoomButtons = () => {
  const { zoomIn, zoomOut, fitView } = useReactFlow()

  const commonProps: IconButtonProps = {
    size: '2xs',
    variant: 'ghost',
    colorPalette: 'gray'
  }

  return (
    <Group attached>
      <Tooltip aria-label="Zoom in" content="Zoom in">
        <IconButton aria-label="Zoom in" onClick={() => zoomIn()} title="Zoom in" {...commonProps}>
          <ICON_MAP.ZoomIn />
        </IconButton>
      </Tooltip>
      <Tooltip aria-label="Zoom out" content="Zoom out">
        <IconButton aria-label="Zoom out" onClick={() => zoomOut()} title="Zoom out" {...commonProps}>
          <ICON_MAP.ZoomOut />
        </IconButton>
      </Tooltip>
      <Tooltip aria-label="Fit view" content="Fit view">
        <IconButton aria-label="Fit view" onClick={() => fitView()} title="Fit view" {...commonProps}>
          <ICON_MAP.FitView />
        </IconButton>
      </Tooltip>
    </Group>
  )
}

export default ZoomButtons
