import Uppy from '@uppy/core'
import type { UppyEventMap, UppyFile, UppyOptions } from '@uppy/core'
import XHR from '@uppy/xhr-upload'

import { MAX_DOUBLELOOP_UPLOADER_FILE_SIZE } from '@app/lib/globals'
import GenerateUploaderTicketPlugin from '@app/shared/uploader/generateUploaderTicketPlugin'
import type { FileTicketMeta, RemoteFile } from '@app/shared/uploader/types'
import { useUploadTicketGenerateMutation } from '@graphql/queries'

const MAX_FILES = 20 // OpenAI limit

type Props = {
  onUploadSuccess?: UppyEventMap<FileTicketMeta, RemoteFile>['upload-success']
  onComplete?: UppyEventMap<FileTicketMeta, RemoteFile>['complete']
  dropTarget?: HTMLElement | null
  endpoint?: string | null
}

const useUppyInstance: ({ onUploadSuccess, onComplete, endpoint }: Props) => Uppy<FileTicketMeta, RemoteFile> = ({
  onUploadSuccess,
  onComplete,
  endpoint = null
}) => {
  const ENDPOINT = endpoint || import.meta.env.PUBLIC_UPLOADER_ENDPOINT
  const uppyOptions: UppyOptions<FileTicketMeta, RemoteFile> = {
    autoProceed: true,
    restrictions: {
      maxFileSize: MAX_DOUBLELOOP_UPLOADER_FILE_SIZE,
      maxNumberOfFiles: MAX_FILES,
      minFileSize: 0,
      maxTotalFileSize: 0,
      minNumberOfFiles: 0,
      allowedFileTypes: [
        '.c',
        '.cpp',
        '.cs',
        '.css',
        '.doc',
        '.docx',
        '.html',
        '.java',
        '.js',
        '.json',
        '.md',
        '.pdf',
        '.php',
        '.pptx',
        '.py',
        '.rb',
        '.sh',
        '.tex',
        '.ts',
        '.txt'
      ],
      requiredMetaFields: []
    }
  }

  const uppy = new Uppy<FileTicketMeta, RemoteFile>(uppyOptions)
  const [, getTicket] = useUploadTicketGenerateMutation()

  uppy.use(GenerateUploaderTicketPlugin, { getTicket })

  uppy.use(XHR, {
    endpoint: ENDPOINT,
    formData: false,
    headers: (file: UppyFile<FileTicketMeta, RemoteFile>) => ({
      'Content-Type': file.type,
      'X-Filename': file.name,
      'X-Ticket': file.meta.ticket
    })
  })

  uppy.on('upload-success', onUploadSuccess)
  uppy.on('complete', onComplete)

  return uppy
}

export default useUppyInstance
