import { Input, Stack } from '@chakra-ui/react'
import type { ChangeEvent, FC, FocusEventHandler } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Field } from '@app/components/ui/field'
import useCurrentUser from '@app/hooks/useCurrentUser'
import SlackChannelInput from '@app/next/forms/slackChannelInput'
import ToggleInput from '@app/next/forms/toggleInput'
import type { MapDomainReport } from '@app/types'
import type { SlackChannel } from '@graphql/types'

interface ReportFacade extends Pick<MapDomainReport, 'sendToEmail' | 'sendToSlack' | 'emails'> {
  id: string | null
  sendToEmail: boolean
  sendToSlack: boolean
  emails: string
  slackChannelId?: string
  slackChannel?: Pick<SlackChannel, 'label' | 'value'>
}

interface Props {
  report?: ReportFacade
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const ChannelInput: FC<Props> = ({ report, onChange = () => {} }) => {
  const { user } = useCurrentUser()
  const { state } = useLocation()
  const [showEmail, setShowEmail] = useState<boolean>(report?.sendToEmail || state?.reportType)
  const [showSlack, setShowSlack] = useState<boolean>(report?.sendToSlack)
  let defaultEmails = report?.emails || ''

  if (state && state.reportType) {
    defaultEmails = user?.email || ''
  }

  const blurHandler: FocusEventHandler<HTMLInputElement> = onChange

  return (
    <Stack gap={6}>
      <Stack gap={2}>
        <Field label="Send email" orientation="horizontal">
          <ToggleInput name="sendToEmail" defaultValue={showEmail} onChange={setShowEmail} />
        </Field>
        {showEmail && (
          <Field label="Email">
            <Input
              autoComplete="email"
              defaultValue={defaultEmails}
              multiple
              name="emails"
              onBlur={blurHandler}
              onChange={(e) => {
                e.preventDefault()
                e.stopPropagation()

                return false
              }}
              placeholder="Add an email or multiple emails seperated by a comma"
              type="email"
            />
          </Field>
        )}
      </Stack>
      <Stack gap={2}>
        <Field label="Send slack" orientation="horizontal">
          <ToggleInput name="sendToSlack" defaultValue={showSlack} onChange={setShowSlack} />
        </Field>
        {showSlack && (
          <Field label="Slack channel" required={showSlack} disabled={showSlack}>
            <SlackChannelInput
              name="slackChannelId"
              defaultValue={report?.slackChannelId ? [report.slackChannelId] : []}
              onValueChange={(e) => {
                const changeTarget = {
                  name: 'slackChannelId',
                  value: e.value[0]
                }

                onChange({
                  target: changeTarget
                } as ChangeEvent<HTMLInputElement>)
              }}
            />
          </Field>
        )}
      </Stack>
    </Stack>
  )
}

export default ChannelInput
