import { Stack, useBreakpointValue } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import ImageWithOverlay from './imageWithOverlay'
import PlaybookCardAttribution from './playbookCardAttribution'

import UsePlaybookButton from '@app/pages/playbooks/components/usePlaybookButton'
import type { Playbook } from '@graphql/queries'

interface Props {
  playbook: Playbook
}

const PlaybookCard: FC<Props> = ({ playbook }) => {
  const { hero, name, friendlyId } = playbook

  return (
    <Stack
      gap={useBreakpointValue({
        base: '4',
        md: '5'
      })}
      minW="sm"
    >
      <Link to={friendlyId}>
        <Stack gap={1}>
          <PlaybookCardAttribution playbook={playbook} />
          <ImageWithOverlay
            playbook={playbook}
            title={name}
            src={hero}
            wrapWithLink={false}
            url={friendlyId}
            cta={<UsePlaybookButton playbook={playbook} aria-label="Use this playbook" />}
          />
        </Stack>
      </Link>
    </Stack>
  )
}

export default PlaybookCard
