import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Tooltip } from '@app/components/ui/tooltip'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  path: string
}

const DetailsButton: FC<Props> = ({ path }) => {
  const navigate = useNavigate()

  const openDrawer = () => {
    navigate(path)
  }

  return (
    <Tooltip content="Details">
      <IconButton aria-label="Card Details" onClick={openDrawer} size="sm" variant="outline">
        <ICON_MAP.Details />
      </IconButton>
    </Tooltip>
  )
}

export default DetailsButton
