import { Box } from '@chakra-ui/react'
import type { FC } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Outlet } from 'react-router-dom'

import { useColorModeValue } from '@app/components/ui/color-mode'

interface Props {
  open: boolean
  onOpenChange: (e) => void
  children?: React.ReactNode
}

const Drawer: FC<Props> = ({ open, onOpenChange, children = null, ...rest }) => {
  useHotkeys('esc', () => {
    onOpenChange({ open: false })
  })

  return (
    <Box
      pos="absolute"
      zIndex="docked"
      top={0}
      right={0}
      overflow="hidden"
      w={{ base: '100%', md: '45%', lg: '35%' }}
      h="100%"
      bg="bg"
      shadow={useColorModeValue('md', 'md-dark')}
      _open={{
        animation: 'fade-in 100ms ease-out'
      }}
      data-state={open ? 'open' : 'closed'}
      {...rest}
    >
      {children || <Outlet />}
    </Box>
  )
}

export default Drawer
