import { Icon } from '@chakra-ui/react'
import { FiBold } from 'react-icons/fi'

import inlineButtonMaker from './inlineButtonMaker'

const BoldButton = inlineButtonMaker({
  style: 'BOLD',
  children: (
    <Icon>
      <FiBold />
    </Icon>
  )
})

export default BoldButton
