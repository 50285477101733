import { createContext, useCallback, useContext, useMemo, useReducer } from 'react'

const MatrixScoresContext = createContext({
  scores: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  appendScores: (arg?) => {}
})

const init = (initialState = {}) => initialState

const reducer = (state, action) => {
  if (action.type === 'merge') {
    const newScores = { ...state.scores }

    action.payload.pairs.forEach((pair) => {
      newScores[pair.id] = pair.score
    })

    return { ...state, scores: newScores }
  }

  return state
}

const MatrixScoresContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, { scores: {} }, init)

  const appendScores = useCallback(
    (correlationPairs) => dispatch({ type: 'merge', payload: { pairs: correlationPairs } }),
    []
  )

  const value = useMemo(
    () => ({
      scores: state.scores,
      appendScores
    }),
    [appendScores, state.scores]
  )

  return <MatrixScoresContext.Provider value={value} {...props} />
}

const useMatrixScoresContext = () => {
  const context = useContext(MatrixScoresContext)

  if (context === undefined) {
    throw new Error('useMatrixScoresContext must be used within MatrixScoresContext.Provider')
  }

  return context
}

export { MatrixScoresContextProvider, useMatrixScoresContext }
