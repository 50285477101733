import { Outlet } from 'react-router-dom'

import { MatrixScoresContextProvider } from '@app/pages/correlations/components/matrixScoresContext'
import Show from '@app/pages/correlations/show'

const Layout = () => (
  <MatrixScoresContextProvider>
    <Outlet />
  </MatrixScoresContextProvider>
)

const routes = {
  path: 'correlations',
  element: <Layout />,
  children: [
    {
      path: 'graph/:sourceId/:targetId',
      element: <Show />
    }
  ]
}

export default routes
