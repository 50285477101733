import { HStack, Stack, Text } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

interface Props {
  title: string
  subtitle?: unknown

  icon?: ReactNode
  children?: ReactNode
}

const CardHeader: FC<Props> = ({ title, subtitle = null, children = null, icon = null }) => (
  <Stack justify="space-between" direction={{ base: 'column', md: 'row' }} gap="4">
    <HStack gap={4}>
      {icon}
      <Stack gap="1">
        <Text fontSize="lg" fontWeight="medium">
          {title}
        </Text>
        {subtitle && (
          <Text color="muted" fontSize="sm">
            {subtitle as ReactNode}
          </Text>
        )}
      </Stack>
    </HStack>
    {children}
  </Stack>
)

export default CardHeader
