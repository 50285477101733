import { Box, Link, HStack } from '@chakra-ui/react'
import { useState } from 'react'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import { Switch } from '@app/components/ui/switch'

interface Props {
  name: string
}

const TermsAndConditionsToggle: FC<Props> = ({ name, ...rest }) => {
  const [checked, setChecked] = useState(false)

  return (
    <Box css={{ '--field-label-width': '320px' }}>
      <Field
        label={
          <HStack gap={1}>
            I agree to DoubleLoop&apos;s
            <Link
              color="link"
              href="https://doubleloop.app/utility-pages/terms-of-service"
              rel="onoopener noreferrer"
              target="_blank"
            >
              terms and conditions
            </Link>
          </HStack>
        }
        orientation="horizontal"
        required
      >
        <Switch
          id={name}
          checked={checked}
          name={name}
          onCheckedChange={() => setChecked(!checked)}
          value={checked.toString()}
          {...rest}
        />
      </Field>
    </Box>
  )
}

export default TermsAndConditionsToggle
