import type { NativeSelect } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { ChangeEvent, FC } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'

interface Props extends Partial<NativeSelect.FieldProps> {
  value: string
  sources?: string[]
  show?: boolean
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
}

const ForeignTypeInput: FC<Props> = ({ value, show = false, sources = [], onChange = () => {}, ...rest }) => {
  if (!show) {
    return null
  }

  return (
    <Field>
      <NativeSelectRoot>
        <NativeSelectField
          value={value}
          name="scope.foreign_type"
          placeholder=""
          onChange={onChange}
          data-testid="show-sources-input"
          {...rest}
        >
          <option value="">All types</option>
          {sources.map((source) => (
            <option key={source} value={source}>
              {capitalize(source)}
            </option>
          ))}
        </NativeSelectField>
      </NativeSelectRoot>
    </Field>
  )
}

export default ForeignTypeInput
