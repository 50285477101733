import { HStack, Text, Spacer } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import FieldSearchInput from '@app/next/forms/fieldSearchInput'
import { paginationSummary } from '@app/shared/pagination'

interface Props {
  page?: number
  limitValue?: number
  totalCount?: number
  leftChild?: ReactNode
  leftSummarySibling?: ReactNode
  searchField?: string
}

const SearchRow: FC<Props> = ({
  page,
  limitValue,
  totalCount,
  leftChild,
  leftSummarySibling,
  searchField = 'filter'
}) => (
  <HStack gap={4} px={0} py={4}>
    {leftChild}
    <Spacer />
    {leftSummarySibling}
    <Text color="muted" fontSize="xs" whiteSpace="nowrap">
      {paginationSummary(page, limitValue, totalCount)}
    </Text>
    <FieldSearchInput field={searchField} placeholder="Search" w="xs" bg="bg" />
  </HStack>
)

export default SearchRow
