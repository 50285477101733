import { Stack, StackSeparator } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useNavigate } from 'react-router-dom'
import type { AnyVariables } from 'urql'

import type { Nodes } from '@app/types'

type Props = {
  cardType?: Nodes
  headerText?: string
  buttonText?: string
  mutationVariables?: AnyVariables
}

const MapEducationDrawer: FC<Props> = () => {
  const navigate = useNavigate()
  useHotkeys('esc', () => navigate('..'))

  return (
    <Stack
      pos="absolute"
      top="24px"
      right="74px"
      bottom={20}
      w="385px"
      py={6}
      borderRadius="sm"
      shadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
      bgColor="bg"
      data-testid="map-education-drawer"
      separator={<StackSeparator />}
    />
  )
}

export default memo(MapEducationDrawer)
