import { BsGraphUp, BsMicrosoft } from 'react-icons/bs'
import { HiOutlineCalculator } from 'react-icons/hi'
import {
  SiAmazon,
  SiGoogleanalytics,
  SiGooglesheets,
  SiLooker,
  SiMysql,
  SiPostgresql,
  SiSnowflake,
  SiStripe
} from 'react-icons/si'

import Amplitude from '@app/images/amplitude'
import GoogleBigQuery from '@app/images/googleBigQuery'
import Mixpanel from '@app/images/mixpanel'
import type { MetricSourceDisplayProps } from '@app/types'
import { IntervalEnum } from '@graphql/types'

// Development Constants
export const DEVELOPMENT_TUNNEL_HOST = 'https://dev.doubleloop.app'

// Time Constants
const metricSource = (display: string, id: string, opts = {}): MetricSourceDisplayProps => ({
  id: `metric_source_${id}`,
  display,
  runnable: false,
  testable: false,
  color: 'blue.500',
  icon: null,
  ...opts
})

export const METRIC_SOURCES: Record<string, MetricSourceDisplayProps> = {
  'MetricSources::Amplitude': metricSource('Amplitude', 'amplitude', {
    color: '#1396c4',
    testable: true,
    runnable: true,
    icon: Amplitude,
    historical: true
  }),
  'MetricSources::Calculated': metricSource('Calculated', 'calculated', {
    color: '#65789a',
    runnable: true,
    icon: HiOutlineCalculator,
    historical: true
  }),
  'MetricSources::DummyData': metricSource('Random data', 'dummy_data', {
    color: '#65789a',
    testable: true,
    runnable: true,
    icon: BsGraphUp
  }),
  'MetricSources::GoogleAnalyticsV4': metricSource('Google Analytics V4', 'google_analytics_v4', {
    icon: SiGoogleanalytics,
    color: '#e3b52a',
    runnable: true,
    testable: true,
    historical: true
  }),
  'MetricSources::GoogleBigQuery': metricSource('Google BigQuery', 'google_big_query', {
    icon: GoogleBigQuery,
    color: '#4b6cf2',
    runnable: true,
    testable: true
  }),
  'MetricSources::GoogleSheets': metricSource('Google Sheets', 'google_sheets', {
    icon: SiGooglesheets,
    color: '#4b6cf2',
    runnable: true,
    testable: true
  }),
  'MetricSources::Looker': metricSource('Looker (Action Hub)', 'looker', { icon: SiLooker, color: '#3f19af' }),
  'MetricSources::LookerApi': metricSource('Looker API', 'looker_api', {
    icon: SiLooker,
    color: '#3f19af',
    runnable: true,
    testable: true,
    historical: true
  }),
  'MetricSources::Mixpanel': metricSource('Mixpanel', 'mixpanel', {
    icon: Mixpanel,
    color: '#a086d3',
    runnable: true,
    testable: true,
    historical: true
  }),
  'MetricSources::Mysql': metricSource('MySQL', 'mysql', {
    icon: SiMysql,
    color: '#00758f',
    runnable: true,
    testable: true
  }),
  'MetricSources::Mssql': metricSource('SQL Server', 'mssql', {
    icon: BsMicrosoft,
    color: '#00a2ed',
    runnable: true,
    testable: true
  }),
  'MetricSources::Postgres': metricSource('Postgres', 'postgres', {
    icon: SiPostgresql,
    color: '#1e3e67',
    runnable: true,
    testable: true
  }),
  'MetricSources::Redshift': metricSource('Amazon Redshift', 'redshift', {
    icon: SiAmazon,
    color: '#1c4391',
    runnable: true,
    testable: true
  }),
  'MetricSources::Snowflake': metricSource('Snowflake', 'snowflake', {
    icon: SiSnowflake,
    color: '#2393db',
    runnable: true,
    testable: true
  }),
  'MetricSources::Stripe': metricSource('Stripe', 'stripe', {
    icon: SiStripe,
    color: '#6772e5',
    runnable: true,
    testable: true,
    historical: true
  })
}

// Slack Constants
export const SLACK_BOT_SCOPE = [
  'channels:read',
  'channels:join',
  'chat:write',
  'chat:write.customize',
  'chat:write.public',
  'groups:read',
  'im:read',
  'im:write',
  'mpim:read',
  'mpim:write',
  'team:read',
  'users:read',
  'users:read.email'
].join(',')

export const SLACK_USER_SCOPE = ['identity.avatar', 'identity.basic', 'identity.email', 'identity.team'].join(',')

export const GOOGLE_SCOPE = ['https://www.googleapis.com/auth/analytics.readonly'].join(',')

// Upload Constants
export const IMAGE_FILE_TYPES = ['image/*', 'application/pdf']
export const MAX_AWS_FILE_SIZE = 250 * 1024 * 1024
export const MAX_DOUBLELOOP_UPLOADER_FILE_SIZE = 500 * 1024 * 1024

// Entities / Graph constants
export const DEFAULT_INTERVAL = IntervalEnum.Week

// Cookie names
export const SELECTED_SLACK_CHANNEL_COOKIE = 'selected-slack-channel'

export const BASIC_CARD_TYPE_COOKIE = 'basic-card-type'
export const METRIC_CARD_TYPE_COOKIE = 'metric-card-type'

export const AI_CHAT_DIMENSIONS_COOKIE = 'ai-chat-dimensions'

export const CARD_FILTER_SIGIL = 'f.'

export const aiInsightsShadow = '0 0 3px 2px rgba(128, 0, 128, 0.7)'

// Random/Dummy data constants
export const RANDOM_DATA_STD_DEV = 3.0
export const RANDOM_DATA_UPWARD_MOVE_PERCENTAGE = 55.0

export const NON_SELECTED_DIMMING_OPACITY = 0.5
