import { Box, Group } from '@chakra-ui/react'
import Editor from '@draft-js-plugins/editor'
import createToolbarPlugin from '@draft-js-plugins/static-toolbar'
import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'

import {
  BlockquoteButton,
  BoldButton,
  CodeBlockButton,
  ItalicButton,
  OrderedListButton,
  UnorderedListButton
} from './buttons'
import buttonStyles from './buttonStyles.module.css'
import toolbarStyles from './toolbarStyles.module.css'

interface Props {
  initialValue?: string
  onValueChange: (value: string) => void
  placeholder?: string
}

type SetValue = (value: string) => void
export interface MarkdownEditorRef {
  setValue: SetValue
}

const MarkdownEditor = forwardRef<MarkdownEditorRef, Props>(
  ({ initialValue = '', onValueChange = () => {}, placeholder = '', ...rest }, forwardedRef) => {
    const [plugins, Toolbar] = useMemo(() => {
      const toolbarPlugin = createToolbarPlugin({
        theme: {
          buttonStyles,
          toolbarStyles
        }
      })
      return [[toolbarPlugin], toolbarPlugin.Toolbar]
    }, [])

    const rawData = markdownToDraft(initialValue || '', {
      preserveNewlines: true
    })
    const contentState = convertFromRaw(rawData)

    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))

    const editor = useRef(null)

    const updateInternalState = useCallback(
      (value: EditorState) => {
        setEditorState(value)

        const content = value.getCurrentContent()
        const rawObject = convertToRaw(content)
        const markdownString = draftToMarkdown(rawObject, { preserveNewlines: false })

        onValueChange(markdownString)
      },
      [onValueChange]
    )

    const setValue = useCallback(
      (value: string) => {
        const newContentState = ContentState.createFromText(value)
        const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters')

        updateInternalState(newEditorState)
      },
      [editorState, updateInternalState]
    )

    useImperativeHandle(
      forwardedRef,
      function cannotBeAnArrowFunction() {
        return { setValue }
      },
      [setValue]
    )

    const focus = () => {
      editor.current?.focus()
    }

    return (
      <Box h="auto" minH="6.5rem" px={0} pt="12px" {...rest}>
        <Box className="markdown-body" px={4} pb={6} id="markdown-body" onClick={focus}>
          <Editor
            placeholder={placeholder}
            editorKey="CustomInlineToolbarEditor"
            editorState={editorState}
            onChange={updateInternalState}
            plugins={plugins}
            ref={(element) => {
              editor.current = element
            }}
          />
        </Box>
        <Toolbar>
          {(externalProps) => (
            <Group>
              <ItalicButton {...externalProps} />
              <BoldButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <BlockquoteButton {...externalProps} />
              <CodeBlockButton {...externalProps} />
            </Group>
          )}
        </Toolbar>
      </Box>
    )
  }
)

export default MarkdownEditor
