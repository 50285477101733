export type GqlError = {
  path?: string[]
  message: string
}

type UnpackErrors = (errors: GqlError[]) => Record<string, GqlError>

const unpackErrors: UnpackErrors = (errors) =>
  errors.reduce<Record<string, GqlError>>((acc, error) => {
    const { path, message } = error

    // validation errors on forms
    if (path[0] === 'attributes') {
      const key = path[1]
      acc[key] = error

      return acc
    }

    if (!acc.global) {
      acc.global ||= new Error()
      acc.global.message = ''
    }

    if (acc.global.message) {
      acc.global.message += ', '
    }

    acc.global.message += message

    return acc
  }, {})

export default unpackErrors
