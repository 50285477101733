import type { RouteObject } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

import List from '@app/pages/credentials/list'
import loadCredentials from '@app/routes/lib/loadCredentials'

const routes: RouteObject = {
  path: 'credentials',
  id: 'credentials',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <List />,
      loader: loadCredentials
    }
  ]
}

export default routes
