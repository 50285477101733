import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useRef, useEffect } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import useGetObjects from '@app/hooks/useGetObjects'

type Props = {
  cardTypeId: string
}

const QueryExistingBasicCardFields: FC<Props> = ({ cardTypeId }) => {
  const ref = useRef<HTMLSelectElement>(null)

  const cardTypes = sortBy(useGetObjects('cardType'), 'name')

  // Handle the user clicking between different card types in the map menu
  useEffect(() => {
    if (cardTypeId && ref.current) {
      ref.current.value = cardTypeId
    }
  }, [cardTypeId])

  return (
    <Field>
      <NativeSelectRoot>
        <NativeSelectField defaultValue={cardTypeId || ''} placeholder={null} name="scope.cardTypeId" ref={ref}>
          <option value="">All types</option>
          {cardTypes.map((cardType) => (
            <option key={cardType.id} value={cardType.id}>
              {cardType.name}
            </option>
          ))}
        </NativeSelectField>
      </NativeSelectRoot>
    </Field>
  )
}

export default QueryExistingBasicCardFields
