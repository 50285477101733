import type { FC } from 'react'

type PipelineInstructionsProps = {
  pipelineId: string
}

const PipelineInstructions: FC<PipelineInstructionsProps> = ({ pipelineId }) => {
  const instructionsHash = {
    map_generator_northstar_playbook: 'Get started by entering a company name'
  }

  const instructions = instructionsHash[pipelineId]

  if (!instructions) {
    return 'How can I help you?'
  }

  return instructions
}

export default PipelineInstructions
