import { Box, HStack, Input, Link as ChakraLink, Stack, Text, VisuallyHidden } from '@chakra-ui/react'
import type { FC, FormEventHandler } from 'react'
import { useState } from 'react'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { toaster } from '@app/components/ui/toaster'
import FormAlert from '@app/next/forms/formAlert'
import type { MapDomainIntegration } from '@app/types'
import { useIntegrationCreateMutation } from '@graphql/queries'

interface Props {
  integration?: Pick<MapDomainIntegration, 'sourceId' | 'sourceName'>
}

const ShortcutTokenForm: FC<Props> = ({ integration = null }) => {
  const [error, setError] = useState<string | null>(null)
  const [, doCreate] = useIntegrationCreateMutation()
  const defaultSourceName = integration?.sourceName || 'shortcut'
  const sourceId = integration?.sourceId

  const createIntegration: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    setError(null)

    const formData = new FormData(e.currentTarget)
    const formValues = Object.fromEntries(formData.entries())

    try {
      await doCreate({
        input: {
          sourceName: formValues.sourceName as string,
          sourceId: formValues.sourceId as string
        }
      })

      toaster.create({
        title: 'Token updated',
        description: 'Your token has been added to the integration!',
        type: 'success'
      })
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <Stack gap="4">
      <Text fontSize="sm">
        Click here to go to your{' '}
        <ChakraLink
          color="link"
          href="https://app.shortcut.com/settings/account/api-tokens"
          rel="onoopener noreferrer"
          target="_blank"
        >
          Shortcut API Tokens
        </ChakraLink>{' '}
        page to create a new one. Copy it and paste it here.
      </Text>
      <form onSubmit={createIntegration}>
        <FormAlert description={error} title="Failed to create the integration!" />
        <HStack align="end" p={4} border="1px" borderColor="bg.muted">
          <Field label="API Token" required>
            <Input
              autoComplete="off"
              defaultValue={sourceId}
              name="sourceId"
              placeholder="The API Token you got from Shortcut"
              variant="outline"
            />
          </Field>
          <Box p={1}>
            <Button type="submit">Save</Button>
          </Box>
        </HStack>
        <VisuallyHidden asChild>
          <Input defaultValue={defaultSourceName} name="sourceName" />
        </VisuallyHidden>
      </form>
    </Stack>
  )
}

export default ShortcutTokenForm
