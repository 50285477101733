import { Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import PlaybookGrid from './playbookGrid'

import { CardHeader } from '@app/shared/layout'
import type { Playbook } from '@graphql/queries'

interface Props {
  groupName?: string
  playbooks: Playbook[]
}

const PlaybookGroup: FC<Props> = ({ groupName = 'General', playbooks }) => {
  const name = groupName === 'null' ? 'General' : groupName

  return (
    <Stack>
      <CardHeader title={name} />
      <PlaybookGrid playbooks={playbooks} />
    </Stack>
  )
}

export default PlaybookGroup
