import last from 'lodash/last'
import { useMatches } from 'react-router-dom'

import HeadMetaData from '@app/shared/headMetaData'

const Head = () => {
  const matches = useMatches() as { handle: { headData: (arg?) => unknown }; data: unknown }[]
  const headData = matches
    .filter((match) => Boolean(match.handle?.headData))
    .map((match) => match.handle.headData(match.data))

  const datum = headData.length ? (last(headData) as object) : {}

  return <HeadMetaData {...datum} />
}

export default Head
