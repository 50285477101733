import { Stack, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props {
  title: string
  subtitle?: string
}

const HeroHeader: FC<Props> = ({ title, subtitle = null }) => {
  const spacing = useBreakpointValue({ base: 4, md: 8, lg: 12 })

  return (
    <Stack align="center" gap={{ base: '8', md: '10' }} my={spacing} py={spacing}>
      <Stack gap={{ base: '4', md: '6' }} textAlign="center">
        <Stack gap="3">
          <Heading fontWeight="semibold" size={useBreakpointValue({ base: 'md', md: 'lg' })}>
            {title}
          </Heading>
        </Stack>
        <Text maxW="3xl" color="muted" fontSize={{ base: 'lg', md: 'xl' }}>
          {subtitle}
        </Text>
      </Stack>
    </Stack>
  )
}

export default HeroHeader
