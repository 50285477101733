import { memo } from 'react'
import { useParams } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { abilitiesFor } from '@app/shared/authorization/caslProvider'
import PageTabs from '@app/shared/pageTabs'
import type { TabsType } from '@app/types'

const Index = () => {
  const { '*': tab, strategyId } = useParams()
  const { user } = useStoreCurrentUser()

  const abilities = abilitiesFor(user)
  const canCreateMetricSource = abilities.can('create', 'metricSource')
  const canUpdateMetric = abilities.can('update', 'metric')

  const tabs: TabsType = {}
  tabs.details = { to: 'details' }
  tabs.data = { to: 'data' }

  if (canCreateMetricSource) {
    tabs.source = { to: 'source' }
  }

  tabs.events = { to: 'events' }
  tabs.key_results = { to: 'key_results', label: 'Key results', collapsed: true }

  // Currently this has a behavior of being able to drag onto a strategy map that causes a failure in the standard metric page.
  if (strategyId) {
    tabs.correlations = { to: 'correlations', collapsed: true }
  }

  tabs.comments = { to: 'comments', collapsed: true }

  if (canUpdateMetric) {
    tabs.settings = { to: 'settings', collapsed: true }
  }

  return <PageTabs tab={tab} tabs={tabs} />
}

export default memo(Index)
