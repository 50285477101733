import type { FC } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import MapDrawer from '@app/pages/maps/components/drawer/mapDrawer'
import EntityForm from '@app/pages/shared/entities/components/entityForm'

const EditWorkItemDrawer: FC = () => {
  const { entityId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const onCancel = () => navigate(`..${location.search}`)
  const entity = useGetObject(entityId, 'entity')

  if (!entity) {
    return null
  }

  return (
    <MapDrawer p={4} top="24px" right="74px" left="auto" bottom="auto">
      <EntityForm entity={entity} onCancel={onCancel} />
    </MapDrawer>
  )
}

export default EditWorkItemDrawer
