import type { IconButtonProps } from '@chakra-ui/react'
import type { FC } from 'react'

import { ClipboardIconButton, ClipboardRoot } from '@app/components/ui/clipboard'

type Props = Partial<IconButtonProps> & {
  content: string
}

const CopyButton: FC<Props> = ({ content, ...rest }) => (
  <ClipboardRoot value={content}>
    <ClipboardIconButton {...rest} />
  </ClipboardRoot>
)

export default CopyButton
