import { Link } from '@chakra-ui/react'
import type { FC } from 'react'

import type { ButtonProps } from '@app/components/ui/button'
import { Button } from '@app/components/ui/button'
import { Paths } from '@app/utils/routeHelpers'
import type { Metric } from '@graphql/queries'

type Props = ButtonProps & {
  metric: Pick<Metric, 'id'>
}
const MetricDownloadCSVButton: FC<Props> = ({ metric, ...props }) => (
  <Button asChild {...props}>
    <Link href={Paths.downloadMetricCSVPath({ metricId: metric.id })}>Download CSV</Link>
  </Button>
)

export default MetricDownloadCSVButton
