import type { NativeSelect } from '@chakra-ui/react'
import type { FC } from 'react'

import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import { useAmplitudeMetricSourceEventNamesQuery } from '@graphql/queries'

type Props = Omit<NativeSelect.FieldProps, 'children' | 'collection'> & {
  disabled?: boolean
  credentialId: string
}

const EventNamesInput: FC<Props> = ({ credentialId, ...selectInputProps }) => {
  const [{ data }] = useAmplitudeMetricSourceEventNamesQuery({
    variables: {
      credentialId
    }
  })

  const options = data?.amplitudeMetricSourceEventNames || []

  return (
    <NativeSelectRoot>
      <NativeSelectField {...selectInputProps}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelectField>
    </NativeSelectRoot>
  )
}

export default EventNamesInput
