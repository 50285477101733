import { Group, HStack, IconButton, Spacer, Spinner } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import MetricDeleteButton from './metricDeleteButton'

import Can from '@app/shared/authorization/can'
import FavoriteToggleButton from '@app/shared/favorites/favoriteToggleButton'
import ICON_MAP from '@app/utils/iconMap'
import type { Metric } from '@graphql/queries'

interface Props {
  data: Metric
}

const MetricListActions: FC<Props> = ({ data }) => {
  const { id, favoriteId } = data

  return (
    <HStack gap={0}>
      <Spacer />
      <Group attached>
        <FavoriteToggleButton favoritableType="Metric" favoritableId={id} favoriteId={favoriteId} />
        <Can I="update" a="metric">
          <IconButton aria-label="Edit metric" variant="ghost">
            <Link to={`${data.id}/settings`}>
              <ICON_MAP.Edit />
            </Link>
          </IconButton>
          <MetricDeleteButton metricId={data.id}>
            {(props) => (
              <IconButton color="error" aria-label="Delete metric" variant="ghost">
                {props?.loading ? <Spinner size="xs" /> : <ICON_MAP.Delete />}
              </IconButton>
            )}
          </MetricDeleteButton>
        </Can>
      </Group>
    </HStack>
  )
}

export default MetricListActions
