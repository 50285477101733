const mergeRelatedData = (combinedArray, relatedData) => {
  if (relatedData) {
    combinedArray.push(...relatedData)
  }

  return combinedArray
}

const unpackStrategy = (strategy) => {
  const {
    basicCards,
    bets,
    commentThreads,
    metrics,
    entities,
    sections,
    nodes,
    edges,
    reports,
    notes,
    mapImages,
    strategies,
    recurringReportConfigurations,
    ...strategyRest
  } = strategy

  let combinedArray = [strategyRest]

  combinedArray = mergeRelatedData(combinedArray, basicCards)
  combinedArray = mergeRelatedData(combinedArray, bets)
  combinedArray = mergeRelatedData(combinedArray, commentThreads)
  combinedArray = mergeRelatedData(combinedArray, metrics)
  combinedArray = mergeRelatedData(combinedArray, entities)
  combinedArray = mergeRelatedData(combinedArray, sections)
  combinedArray = mergeRelatedData(combinedArray, nodes)
  combinedArray = mergeRelatedData(combinedArray, edges)
  combinedArray = mergeRelatedData(combinedArray, reports)
  combinedArray = mergeRelatedData(combinedArray, notes)
  combinedArray = mergeRelatedData(combinedArray, mapImages)
  combinedArray = mergeRelatedData(combinedArray, strategies)
  combinedArray = mergeRelatedData(combinedArray, recurringReportConfigurations)

  return combinedArray
}

const unpackReport = (report) => {
  const { strategy, ...reportRest } = report

  let combinedArray = [reportRest]

  // I don't love that I had to wrap it in an array.
  combinedArray = mergeRelatedData(combinedArray, [strategy])

  return combinedArray
}

export default (object) => {
  switch (object.classType) {
    case 'strategy':
      return unpackStrategy(object)
    case 'report':
      return unpackReport(object)
    default:
      return [object]
  }
}
