import type { Dictionary } from 'lodash' // eslint-disable-line lodash/import-scope
import { useShallow } from 'zustand/react/shallow'

import { useStore, getIncomingCollapsedNodesSelector } from '@app/store'
import type { MapDomainObject, MapDomainNode } from '@app/types'

type GroupObject = { rfId?: string } & MapDomainObject
type UseGetIncomingCollapsedNodes = (node: MapDomainNode) => {
  nodes: MapDomainNode[]
  groups: Dictionary<GroupObject[]>
  confidenceScore?: number | string
}

const useGetIncomingCollapsedNodes: UseGetIncomingCollapsedNodes = (node) =>
  useStore(useShallow((state) => getIncomingCollapsedNodesSelector(state, node)))

export default useGetIncomingCollapsedNodes
