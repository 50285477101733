import type { FC, MutableRefObject } from 'react'

import { contributorToPickerItem, fetchContributors } from './contributorsAutocompleteShared'

import { ChakraAsyncCreatableSelect } from '@app/shared/autocomplete/chakraAutocompletes'
import type { Contributor } from '@graphql/queries'

interface ContributorObject {
  id: string | number
  contributor: Contributor
}

interface Props {
  name?: string
  onChange?: (value?: unknown) => void
  defaultValue?: ContributorObject[]
  id?: string
  inputRef?: MutableRefObject<undefined>
  placeholder?: string
}

const ContributorsAutocomplete: FC<Props> = ({ defaultValue = [], onChange = () => {}, ...rest }) => {
  // Append sourceName: 'email' on to the normal option set for new options
  const getNewOptionData = (inputValue, optionLabel) => ({
    value: inputValue,
    label: optionLabel,
    sourceName: 'email'
  })

  // Generic Email Validation
  const isValidNewOption = (inputValue) => /^[^@\s]+@[^@\s.]+\.[^@.\s]+$/.test(inputValue)

  // Instead of Create "X" it shows Add "X"
  const formatCreateLabel = (inputValue) => `Add ${inputValue}`

  return (
    <ChakraAsyncCreatableSelect
      aria-label="contributors-autocomplete"
      asyncFetch={(inputValue) => fetchContributors(inputValue)}
      placeholder="Add a contributor"
      noOptionsMessage="Start typing to find a contributor or type an email to add one"
      formatCreateLabel={formatCreateLabel}
      getNewOptionData={getNewOptionData}
      onChange={onChange}
      isValidNewOption={isValidNewOption}
      initialValues={defaultValue?.map(contributorToPickerItem) || []}
      {...rest}
    />
  )
}

export default ContributorsAutocomplete
