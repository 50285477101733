import { Text } from '@chakra-ui/react'
import type { FC } from 'react'

import type { Metric } from '@graphql/queries'

interface Props {
  data: Metric
}

const MetricDataPoints: FC<Props> = ({ data: { metricDataPointsCount } }) => (
  <Text data-testid="metric-data-points">
    {metricDataPointsCount > 0 ? `${metricDataPointsCount} data points` : ''}
  </Text>
)

export default MetricDataPoints
