import type { IconButtonProps } from '@chakra-ui/react'
import { IconButton, Spinner } from '@chakra-ui/react'
import type { FC } from 'react'

import ICON_MAP from '@app/utils/iconMap'

type Props = Omit<IconButtonProps, 'aria-label'> & {
  loading?: boolean
  'aria-label'?: string
}

const AiFieldUpdateButton: FC<Props> = ({ onClick, loading = false, ...buttonProps }) => (
  <IconButton aria-label="Populate from an AI suggestion" onClick={onClick} variant="ghost" {...buttonProps}>
    {loading ? <Spinner size="xs" /> : <ICON_MAP.AiPrompt />}
  </IconButton>
)

export default AiFieldUpdateButton
