import { Box } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props {
  color: string
  boxSize?: number
}

const ColorSquare: FC<Props> = ({ color, boxSize = 4 }) => {
  let boxColor = color

  if (!color) {
    boxColor = 'gray.500'
  } else if (['black', 'white'].includes(color)) {
    boxColor = color
  } else {
    boxColor = `${color}.500`
  }

  return <Box w={boxSize} h={boxSize} m={0} p={0} borderRadius="sm" bgColor={boxColor} />
}

export default ColorSquare
