import { HStack, Input, Spacer, Stack } from '@chakra-ui/react'
import type { ChangeEventHandler, FC, MutableRefObject } from 'react'

import AmplitudeAuth from './auth/amplitudeAuth'
import GoogleBigQueryAuth from './auth/googleBigQueryAuth'
import LookerApiAuth from './auth/lookerApiAuth'
import MixpanelAuth from './auth/mixpanelAuth'
import OdbcAuth from './auth/odbcAuth'
import SnowflakeAuth from './auth/snowflakeAuth'
import StripeAuth from './auth/stripeAuth'
import Nameplate from './nameplate'
import TestCredentialsButton from './testCredentialsButton'

import { Field } from '@app/components/ui/field'
import { METRIC_SOURCES } from '@app/lib/globals'
import type { Credential } from '@graphql/types'

interface AuthProps {
  onChange: ChangeEventHandler<HTMLFormElement>
  sourceName: string
  credential?: Credential | null
}

interface Props extends AuthProps {
  formRef: MutableRefObject<HTMLFormElement>
  isValid: boolean
}

const Auth: FC<AuthProps> = ({ credential, onChange, sourceName }) => {
  switch (sourceName) {
    case 'MetricSources::Amplitude':
      return <AmplitudeAuth credential={credential} />
    case 'MetricSources::Mixpanel':
      return <MixpanelAuth credential={credential} />
    case 'MetricSources::Stripe':
      return <StripeAuth credential={credential} />
    case 'MetricSources::GoogleBigQuery':
      return <GoogleBigQueryAuth credential={credential} onChange={onChange} />
    case 'MetricSources::LookerApi':
      return <LookerApiAuth credential={credential} />
    case 'MetricSources::Mysql':
    case 'MetricSources::Mssql':
    case 'MetricSources::Postgres':
    case 'MetricSources::Redshift':
      return <OdbcAuth credential={credential} serviceName={METRIC_SOURCES[sourceName]?.display} />
    case 'MetricSources::Snowflake':
      return <SnowflakeAuth credential={credential} />

    default:
      return null
  }
}

const SourceConfiguration: FC<Props> = ({ credential, isValid, sourceName, formRef, onChange }) => (
  <Stack gap={4}>
    <HStack>
      <Nameplate sourceName={sourceName} />
      <Spacer />
      <TestCredentialsButton formRef={formRef} disabled={!isValid} />
    </HStack>
    <Stack gap={4}>
      <Field
        label="Name (optional)"
        helperText="So you can easily distinguish this from other sources, for the same integration"
      >
        <Input
          autoComplete="off"
          defaultValue={credential?.label}
          name="label"
          placeholder="Name (e.g. Production, Staging, etc.)"
        />
      </Field>
      <Auth sourceName={sourceName} onChange={onChange} credential={credential} />
    </Stack>
  </Stack>
)

export default SourceConfiguration
