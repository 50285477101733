import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props {
  title?: string
  isDisplayed?: boolean
  children?: React.ReactNode
}

const Section: FC<Props> = ({ title = null, isDisplayed = true, children = null }) => {
  if (!isDisplayed) {
    return null
  }

  return (
    <Stack>
      <Text fontSize="sm">{title}</Text>
      {children}
    </Stack>
  )
}

export default Section
