import { Box, Flex, HStack, IconButton, Spacer, Spinner, Stack, Text } from '@chakra-ui/react'
import type { UploadResult, UppyEventMap, UppyOptions } from '@uppy/core'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useMemo, useState, useCallback } from 'react'

import { Button } from '@app/components/ui/button'
import { toaster } from '@app/components/ui/toaster'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import { MAX_DOUBLELOOP_UPLOADER_FILE_SIZE } from '@app/lib/globals'
import DoubleloopUploaderModal from '@app/shared/uploader/doubleloopUploaderModal'
import type { RemoteFile, FileTicketMeta } from '@app/shared/uploader/types'
import type { MapDomainStrategy } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import {
  useAiAssistantVectorStoreFilesAddMutation,
  useAiAssistantVectorStoreFilesRemoveMutation
} from '@graphql/queries'

const uppyOptions: UppyOptions<FileTicketMeta, RemoteFile> = {
  restrictions: {
    maxFileSize: MAX_DOUBLELOOP_UPLOADER_FILE_SIZE,
    // https://platform.openai.com/docs/assistants/tools/file-search/supported-files
    allowedFileTypes: [
      '.c',
      '.cpp',
      '.cs',
      '.css',
      '.doc',
      '.docx',
      '.html',
      '.java',
      '.js',
      '.json',
      '.md',
      '.pdf',
      '.php',
      '.pptx',
      '.py',
      '.rb',
      '.sh',
      '.tex',
      '.ts',
      '.txt'
    ],
    minFileSize: 0,
    maxTotalFileSize: 0,
    maxNumberOfFiles: 0,
    minNumberOfFiles: 0,
    requiredMetaFields: []
  }
}

const FileButton = ({ strategyId, file, ...rest }) => {
  const [, deleteFile] = useAiAssistantVectorStoreFilesRemoveMutation()
  const [loading, setLoading] = useState(false)
  const onClick = useCallback(async () => {
    setLoading(true)
    try {
      await deleteFile({ input: { strategyId, fileIds: [file.id] } })
    } finally {
      setLoading(false)
    }
  }, [file.id])

  return (
    <Flex align="center" display="inline-flex" {...rest}>
      <Text fontSize="sm">{file.name}</Text>
      <IconButton ms={2} aria-label={`Delete ${file.name}`} onClick={onClick} size="sm" variant="outline">
        {loading ? <Spinner size="xs" /> : <ICON_MAP.Delete />}
      </IconButton>
    </Flex>
  )
}

type Props = {
  strategy: MapDomainStrategy
}

const AiAssistantFiles: FC<Props> = ({ strategy }) => {
  const files = useGetObjectsByProperties('aiAssistantFile', {
    strategyId: strategy.id,
    uploadedToStrategyContext: true
  })
  const [, addFiles] = useAiAssistantVectorStoreFilesAddMutation()
  const onComplete: UppyEventMap<FileTicketMeta, RemoteFile>['complete'] = useCallback(
    async (result: UploadResult<FileTicketMeta, RemoteFile>) => {
      try {
        addFiles({
          input: {
            strategyId: strategy.id,
            fileIds: result.successful.map((file) => file.response.body.id)
          }
        })
      } catch (e) {
        toaster.create({
          type: 'error',
          title: 'Failed to upload files',
          description: e.message
        })
      }
    },
    []
  )

  const sortedFiles = useMemo(() => sortBy(files, 'name'), [files])

  return (
    <Stack gap={2}>
      <Box>
        {sortedFiles.map((file) => (
          <FileButton key={file.id} file={file} strategyId={strategy.id} me={2} mb={2} />
        ))}
      </Box>
      <DoubleloopUploaderModal onComplete={onComplete} uppyOptions={uppyOptions}>
        <HStack>
          <Text fontSize="sm" fontWeight="medium">
            AI Files
          </Text>
          <Spacer />
          <Button aria-label="Upload files" variant="subtle" size="sm" type="button">
            <ICON_MAP.Upload /> Upload files
          </Button>
        </HStack>
      </DoubleloopUploaderModal>
    </Stack>
  )
}

export default AiAssistantFiles
