import { Table, TableCaption } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'

import CardTypeTableRow from './cardTypeTableRow'

import type { CardType } from '@graphql/types'

interface Props {
  cardTypes: CardType[]
}

const CardTypeTable: FC<Props> = ({ cardTypes = [] }) => {
  const sortedCardTypes = sortBy(cardTypes, (ct) => Number(ct.id))

  return (
    <Table.Root borderRadius="sm" size="sm">
      {!sortedCardTypes.length && <TableCaption>No card types yet</TableCaption>}
      <Table.Body>
        {sortedCardTypes.map((cardType) => (
          <CardTypeTableRow key={`card-types-${cardType.id}`} cardType={cardType} />
        ))}
      </Table.Body>
    </Table.Root>
  )
}

export default CardTypeTable
