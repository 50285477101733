import ahoy from 'ahoy.js'
import set from 'lodash/set'

import { toaster } from '@app/components/ui/toaster'
import { METRIC_SOURCES } from '@app/lib/globals'
import { useStore } from '@app/store'
import { MetricSourceCreate, MetricSourceUpdate } from '@graphql/documents/metric_source.graphql'
import type { MetricSourceCreatePayload, MetricSourceUpdatePayload } from '@graphql/types'

const parseMetricSourceInput = (input) => {
  const metricSourceInput = { ...input }

  // hydrate dot / array paths
  Object.keys(metricSourceInput).forEach((key) => {
    if (/[[\].]/.test(key)) {
      const val = metricSourceInput[key]

      delete metricSourceInput[key]
      set(metricSourceInput, key, val)
    }
  })

  if (metricSourceInput.calculatedMetricInputs) {
    metricSourceInput.calculatedMetricInputs.forEach((calculatedMetricInput) => {
      // eslint-disable-next-line no-param-reassign
      calculatedMetricInput.position = parseInt(calculatedMetricInput.position, 10)
    })
  }

  if (metricSourceInput.configuration?.segments) {
    metricSourceInput.configuration.segments &&= metricSourceInput.configuration?.segments.filter(
      (segment) => segment.name !== ''
    )
  }

  return metricSourceInput
}

type CreateMetricSource = (args: { metricId: string; formData: FormData }) => Promise<MetricSourceCreatePayload>

export const createMetricSource: CreateMetricSource = ({ metricId, formData }) => {
  const input = parseMetricSourceInput({ ...Object.fromEntries(formData.entries()), metricId })
  const { actionMutation } = useStore.getState()

  return actionMutation(MetricSourceCreate, input).then((resp) => {
    ahoy.track('metricSource:created', input)

    if (resp.error) {
      throw resp
    }

    const data = resp.data.metricSourceCreate
    const { metricSource, errors } = data

    if (errors.length) {
      return data
    }

    toaster.create({
      title: 'Creating your source',
      placement: 'bottom-end',
      type: 'success'
    })

    if (METRIC_SOURCES[metricSource?.sourceName]?.historical) {
      toaster.create({
        title: 'Metric data source',
        description: 'Importing data has started, check back soon.',
        placement: 'bottom-end',
        type: 'success'
      })
    }

    return data
  })
}

type UpdateMetricSource = (args: { formData: FormData }) => Promise<MetricSourceUpdatePayload>
export const updateMetricSource: UpdateMetricSource = ({ formData }) => {
  const input = parseMetricSourceInput(Object.fromEntries(formData.entries()))
  const { actionMutation } = useStore.getState()

  delete input.metricId
  delete input.validIntegration

  // If we're editing and the password is blank, remove it. This will keep the password as is on the backend.
  if (input.password === '') {
    delete input.password
  }

  // If a host is set, use that instead of the creds.
  if (input.host) {
    delete input.credentialId
  } else {
    // Delete all the empty fields so they aren't sent to the backend
    ;['host', 'port', 'database', 'username', 'password', 'warehouse', 'schema', 'role'].forEach((field) => {
      delete input[field]
    })
  }

  return actionMutation(MetricSourceUpdate, input).then((resp) => {
    ahoy.track('metricSource:updated', input)

    if (resp.error) {
      throw resp
    }

    const data = resp.data.metricSourceUpdate
    const { errors } = data

    if (errors.length) {
      return data
    }

    toaster.create({
      title: 'Updating your source',
      placement: 'bottom-end',
      type: 'success'
    })

    return data
  })
}
