import merge from 'lodash/merge'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { type NodeInput } from '@graphql/types'

export const toNode = (nodeData, metadata = {}) => {
  const sanitizedMetadata = omit(metadata, ['style', 'data'])
  let type = 'default'

  if (nodeData.type) {
    type = nodeData.type
  }

  const node = {
    id: nodeData.id,
    classType: nodeData.classType,
    position: { x: 0, y: 0 },
    type,
    strategyId: nodeData.strategyId,
    width: nodeData.width,
    height: nodeData.height
  }

  return merge(node, sanitizedMetadata)
}

// Used when sending node data to a DoubleLoop Node mutation
export const mutationNodes = (nodes): NodeInput[] =>
  nodes.map((node) => {
    const syncableNode: NodeInput = pick(node, 'metadata', 'position')

    syncableNode.id = node.nodeId
    syncableNode.parentNodeId = node.parentNodeId

    if ((node?.measured?.height || node?.measured?.width) && ['section', 'note', 'mapImage'].includes(node.type)) {
      syncableNode.dimensions = { height: node.measured.height, width: node.measured.width }
    }

    return syncableNode
  })
