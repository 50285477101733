import { Box } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { Tooltip } from '@app/components/ui/tooltip'

interface Props {
  color?: string
  size?: string
  top?: number
  right?: number
  bottom?: number
  left?: number
  animationSpeed?: number
  label?: string
  link?: string
  isActive?: boolean
}

const PulsingCircle: FC<Props> = ({
  color = 'blue.500',
  size = '24px',
  top,
  right,
  bottom,
  left,
  animationSpeed = 2.25,
  label = 'Recent events within two weeks',
  link,
  isActive = false
}) => {
  if (!isActive) {
    return null
  }

  const floatSize = parseFloat(size)
  const absoluteOffset = Math.floor(floatSize / 12) * -1

  return (
    <Tooltip content={label} positioning={{ placement: 'top' }}>
      <Link to={link}>
        <Box
          pos="absolute"
          top={top || absoluteOffset}
          right={right || absoluteOffset}
          bottom={bottom}
          left={left}
          w={size}
          h={size}
          _after={{
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            display: 'block',
            width: '100%',
            height: '100%',
            backgroundColor: color,
            borderRadius: '100px',
            boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
            animation: `pulse-dot ${animationSpeed}s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite`
          }}
          cursor="pointer"
        />
      </Link>
    </Tooltip>
  )
}

export default PulsingCircle
