import { HStack, Spacer, Stack } from '@chakra-ui/react'
import { type ElementType, type FC } from 'react'
import { useParams } from 'react-router-dom'

import BasicCardEntitiesFetcher from '@app/fetchers/basicCardEntitiesFetcher'
import EntityEntitiesFetcher from '@app/fetchers/entityEntitiesFetcher'
import MetricEntitiesFetcher from '@app/fetchers/metricEntitiesFetcher'
import useGetObject from '@app/hooks/useGetObject'
import MetricGraph from '@app/pages/metrics/components/metricGraph'
import Can from '@app/shared/authorization/can'
import AggregatedEntitiesList from '@app/shared/entities'
import CreateEntityButton from '@app/shared/entities/createEntityButton'
import type { Nodes } from '@app/types'
import { EntityWorkflowStateEnum } from '@graphql/types'

interface EntitiesListProps {
  Fetcher: ElementType
  fetcherParams: object
  nodeType: Nodes
}

const EntitiesList: FC<EntitiesListProps> = ({ Fetcher, fetcherParams, nodeType }) => (
  <Fetcher {...fetcherParams}>
    {(collection, metadata) => (
      <AggregatedEntitiesList
        collection={collection}
        metadata={metadata}
        nodeType={nodeType}
        layoutRowProps={{ flexDir: 'column' }}
      />
    )}
  </Fetcher>
)

type FetcherParams = {
  id: string
  workflowState?: EntityWorkflowStateEnum
  strategyId: string | null
}

interface Props {
  nodeType: Nodes
}

const List: FC<Props> = ({ nodeType }) => {
  const { nodeId, strategyId } = useParams()

  const domainObject = useGetObject(nodeId, nodeType as Exclude<Nodes, 'existing'>)

  let fetcher = null
  const fetcherParams: FetcherParams = { id: nodeId, strategyId, workflowState: EntityWorkflowStateEnum.Finished }

  switch (nodeType) {
    case 'metric':
      fetcher = MetricEntitiesFetcher
      break
    case 'entity':
      fetcher = EntityEntitiesFetcher
      break
    case 'basicCard':
      fetcher = BasicCardEntitiesFetcher
      break
    default:
      throw new Error(`Unknown entity list for type: ${nodeType}`)
  }

  return (
    <Stack gap={0} px={4}>
      {nodeType === 'metric' && <MetricGraph metric={domainObject} />}
      <HStack p={4}>
        <Spacer />
        <Can I="create" an="event" passThrough>
          {(allowed) => <HStack>{allowed && <CreateEntityButton size="xs" text="Add work item" />}</HStack>}
        </Can>
      </HStack>
      <EntitiesList Fetcher={fetcher} fetcherParams={fetcherParams} nodeType={nodeType} />
    </Stack>
  )
}

export default List
