import { Box, Group, Center, HStack, Input, Stack, Separator, Text, Textarea, VisuallyHidden } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { type FC, useEffect, useState } from 'react'
import { RRule, rrulestr } from 'rrule'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import { NumberInputField, NumberInputRoot } from '@app/components/ui/number-input'
import { Slider } from '@app/components/ui/slider'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

function daysForLocale(localeName = undefined, weekday = 'short') {
  const formatter = new Intl.DateTimeFormat(localeName, {
    weekday: weekday as 'short' | 'long' | 'narrow',
    timeZone: 'UTC'
  })

  const days = [1, 2, 3, 4, 5, 6, 7].map((day) => {
    const dd = day + 1 < 10 ? `0${day + 1}` : day + 1

    return new Date(`2017-01-${dd}T00:00:00+00:00`)
  })

  return days.map((date, index) => ({
    label: formatter.format(date),
    value: index
  }))
}

const AVAILABLE_FREQUENCIES = [
  RRule.SECONDLY,
  RRule.MINUTELY,
  RRule.HOURLY,
  RRule.DAILY,
  RRule.WEEKLY,
  RRule.MONTHLY,
  RRule.YEARLY
]

const FREQUENCY_OPTIONS = [
  {
    label: 'Yearly',
    value: RRule.YEARLY
  },
  {
    label: 'Monthly',
    value: RRule.MONTHLY
  },
  {
    label: 'Weekly',
    value: RRule.WEEKLY
  },
  {
    label: 'Daily',
    value: RRule.DAILY
  },
  {
    label: 'Hourly',
    value: RRule.HOURLY
  },
  {
    label: 'Minutely',
    value: RRule.MINUTELY
  },
  {
    label: 'Secondly',
    value: RRule.SECONDLY
  }
]

interface FrequencySelectProps {
  freq: number
  setFreq: (freq: number) => void
  availableFrequencies?: number[]
}

const FrequencySelect: FC<FrequencySelectProps> = ({ freq, setFreq, availableFrequencies }) => {
  const filteredFrequencies = FREQUENCY_OPTIONS.filter((frequencyOption) =>
    availableFrequencies.includes(frequencyOption.value)
  )

  const updateFrequency = (e) => {
    setFreq(parseInt(e.target.value, 10))
  }

  return (
    <Field label="Repeat">
      <NativeSelectRoot>
        <NativeSelectField value={freq.toString()} onChange={updateFrequency} placeholder="">
          {filteredFrequencies.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </NativeSelectField>
      </NativeSelectRoot>
    </Field>
  )
}

interface IntervalInputProps {
  interval: number
  setInterval: (interval: number) => void
  freq: number
}

const IntervalInput: FC<IntervalInputProps> = ({ interval, setInterval, freq }) => {
  let max
  let description

  const handleChange = (e) => {
    setInterval(e.value)
  }

  useEffect(() => {
    if (interval > 7 && freq === RRule.DAILY) {
      setInterval(7)
    }

    if (interval > 12 && freq === RRule.MONTHLY) {
      setInterval(12)
    }
  }, [freq, setInterval, interval])

  if (freq === RRule.DAILY) {
    description = 'Day'
    max = 7
  } else if (freq === RRule.WEEKLY) {
    description = 'Week'
    max = 52
  } else if (freq === RRule.MONTHLY) {
    description = 'Month'
    max = 12
  }

  return (
    <Field label={`Every ${interval} ${pluralize(description, interval)}`}>
      <Box w="100%" px={2}>
        <Slider flex="1" id="interval" max={max} min={1} onValueChange={handleChange} value={[interval]}>
          {interval}
        </Slider>
      </Box>
    </Field>
  )
}

interface DayButtonsProps {
  byweekday: number[]
  setByweekday: (byweekday: number[]) => void
  freq: number
}

const DayButtons: FC<DayButtonsProps> = ({ byweekday, setByweekday, freq }) => {
  if (freq !== RRule.WEEKLY) {
    return null
  }

  const toggleDay = (dayId) => {
    let newValue

    if (!byweekday) {
      newValue = [dayId]
    } else if (byweekday.includes(dayId)) {
      newValue = byweekday.slice(0).filter((day) => day !== dayId)
    } else {
      newValue = [...byweekday, dayId].sort()
    }

    setByweekday(newValue)
  }

  const localeDays = daysForLocale()

  return (
    <Field label="Days">
      <Stack>
        <Center maxW={{ md: '3xl' }}>
          <Group>
            {localeDays.map((day) => (
              <Button
                key={day.label}
                onClick={() => toggleDay(day.value)}
                variant={byweekday?.includes(day.value) ? 'solid' : 'ghost'}
                size={['2xs', 'xs']}
              >
                {day.label}
              </Button>
            ))}
          </Group>
        </Center>
      </Stack>
    </Field>
  )
}

interface DayInputProps {
  bymonthday: number
  setBymonthday: (bymonthday: string) => void
  freq: number
}

const DayInput: FC<DayInputProps> = ({ bymonthday, setBymonthday, freq }) => {
  if (freq !== RRule.MONTHLY) {
    return null
  }

  return (
    <Field label="Day of the month">
      <NumberInputRoot
        w="full"
        maxW={{ md: '3xl' }}
        allowMouseWheel
        inputMode="numeric"
        max={31}
        min={1}
        onValueChange={(e) => setBymonthday(e.value)}
        value={bymonthday.toString()}
      >
        <NumberInputField />
      </NumberInputRoot>
    </Field>
  )
}

interface TimeInputProps {
  dtstart: Date
  setDtstart: (dtstart: Date) => void
  timeFunc: (date: Date) => Date
}

type TzFunc = (date: Date) => Date

const TimeInput: FC<TimeInputProps> = ({ dtstart, setDtstart, timeFunc }) => {
  const timeZone = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' }).substring(4)

  const handleChange = (e) => {
    const [hour, minutes] = e.target.value.split(':')

    const inTz = timeFunc(new Date())
    inTz.setHours(hour, minutes, 0, 0)
    setDtstart(inTz)
  }

  let value: string | number | readonly string[]

  try {
    value = new Intl.DateTimeFormat(undefined, {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    }).format(dtstart)
  } catch (e) {
    const nowIsh = new Date()
    nowIsh.setSeconds(0, 0)

    value = new Intl.DateTimeFormat(undefined, {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false
    }).format(nowIsh)
  }

  return (
    <Field label="Send at">
      <HStack w="full" maxW="100%">
        <Input w="full" defaultValue={value} onChange={handleChange} type="time" />
        <Text>{timeZone}</Text>
      </HStack>
    </Field>
  )
}

const defaultValues = (tzFunc: TzFunc, value: string) => {
  let inTz: Date
  let parsedSchedule: RRule
  if (value) {
    parsedSchedule = rrulestr(value)
    const dts = parsedSchedule?.origOptions?.dtstart

    inTz = tzFunc(dts)
  } else {
    const date = new Date()
    date.setHours(9, 0, 0, 0)

    parsedSchedule = new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      byhour: 9,
      byminute: 0,
      // tzid: 'Atlantic/Faroe',
      dtstart: date,
      wkst: RRule.MO,
      byweekday: [RRule.FR]
    })

    inTz = tzFunc(date)
  }

  const byminute = inTz.getMinutes() || '0'
  const trueHour = inTz.getHours()
  const byhour = ((trueHour + 11) % 12) + 1
  const ampm = trueHour >= 12 ? 'pm' : 'am'

  return { ...parsedSchedule.options, byminute, byhour, ampm }
}

interface RRuleInputProps {
  name: string
  availableFrequencies: number[]
  disabled?: boolean
  defaultValue?: string
}

const RRuleInput: FC<RRuleInputProps> = ({
  name,
  availableFrequencies = AVAILABLE_FREQUENCIES,
  disabled = false,
  defaultValue = null
}) => {
  const { dateTimeInUserTimeZone } = useStoreCurrentUser()
  const parsedRRule = defaultValues(dateTimeInUserTimeZone, defaultValue)

  // Value holds the actual submitted value of this "field"
  const [value, setValue] = useState(defaultValue)

  // The following is just for internal state management
  const [freq, setFreq] = useState(parsedRRule.freq || 0)
  const [interval, setInterval] = useState(parsedRRule.interval || 1)
  const [byweekday, setByweekday] = useState(parsedRRule.byweekday)
  const [bymonthday, setBymonthday] = useState(parsedRRule.bymonthday || [1])
  const [dtstart, setDtstart] = useState(parsedRRule.dtstart)

  const setByMd = (val) => {
    setBymonthday([val])
  }

  // When one of the rrule fields change, update the hidden field
  useEffect(() => {
    const values = {
      freq,
      interval,
      byweekday,
      bymonthday
    }

    if (freq !== RRule.MONTHLY) {
      delete values.bymonthday
    }

    if (freq !== RRule.WEEKLY) {
      delete values.byweekday
    }

    let newRRule
    try {
      newRRule = new RRule({ ...values, dtstart }).toString()
    } catch (e) {
      newRRule = new RRule({ ...values }).toString()
    }

    setValue(newRRule)
  }, [freq, interval, byweekday, bymonthday, dtstart])

  if (disabled) {
    return null
  }

  return (
    <>
      {/* This needs to stay a Textarea because it supports newlines (required by rrule) and Input does not */}
      <VisuallyHidden>
        <Textarea name={name} readOnly value={value || ''} />
      </VisuallyHidden>
      <Stack gap="5" separator={<Separator />}>
        <FrequencySelect freq={freq} setFreq={setFreq} availableFrequencies={availableFrequencies} />
        <IntervalInput interval={interval} setInterval={setInterval} freq={freq} />
        {freq === RRule.WEEKLY && <DayButtons byweekday={byweekday} setByweekday={setByweekday} freq={freq} />}
        {freq === RRule.MONTHLY && <DayInput bymonthday={bymonthday?.[0] || 1} setBymonthday={setByMd} freq={freq} />}
        <TimeInput dtstart={dtstart} setDtstart={setDtstart} timeFunc={dateTimeInUserTimeZone} />
      </Stack>
    </>
  )
}

export default RRuleInput
