import type { Dialog } from '@chakra-ui/react'
import { HStack, Spacer, Text } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC, MutableRefObject } from 'react'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { FiCheck, FiChevronDown } from 'react-icons/fi'

import type { CredentialSelectApi } from '../metricSourceShow'

import type { ButtonProps } from '@app/components/ui/button'
import { Button } from '@app/components/ui/button'
import { MenuContent, MenuItem, MenuRoot, MenuSeparator, MenuTrigger } from '@app/components/ui/menu'
import useGetObjects from '@app/hooks/useGetObjects'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import { METRIC_SOURCES } from '@app/lib/globals'
import MetricIntegrationModal from '@app/pages/settings/integrations/components/metricIntegrations/metricIntegrationModal'
import Nameplate from '@app/pages/settings/integrations/components/metricIntegrations/nameplate'
import type { Credential as GqlCredential } from '@graphql/types'
import { IntegrationSourceNamesEnum } from '@graphql/types'

type Credential = Pick<GqlCredential, 'id' | 'classType' | 'displayName' | 'sourceName' | 'label'>
type CredId = Credential['id'] | Credential['sourceName'] | ''
type OnSelect = (sourceName: string, credential: Credential) => void

type SourceNameProps = {
  sourceName: string
  label?: string
}

type SourceMenuItemProps = {
  credential: Credential | null
  sourceName: string
  onClick: OnSelect
  isSelected: boolean
}

export type Props = Partial<Omit<ButtonProps, 'onSelect'>> & {
  onSelect?: OnSelect
  credential?: Credential | null
  defaultSourceName?: string
  apiRef?: MutableRefObject<CredentialSelectApi>
}

const SourceName: FC<SourceNameProps> = ({ sourceName, label }) => {
  const source = METRIC_SOURCES[sourceName]

  if (source) {
    return <Nameplate sourceName={sourceName}>{label ? <span> &mdash; {label}</span> : ''}</Nameplate>
  }

  return (
    <HStack>
      <Text>{label || 'None'}</Text>
    </HStack>
  )
}

const SourceMenuItem: FC<SourceMenuItemProps> = ({ credential, sourceName, onClick, isSelected }) => (
  <MenuItem value={credential?.id || sourceName} onClick={() => onClick(sourceName, credential)}>
    <HStack>
      <SourceName sourceName={credential?.sourceName || sourceName} label={credential?.label} />
      <Spacer />
      {isSelected ? <FiCheck /> : null}
    </HStack>
  </MenuItem>
)

const AddSourceModal: FC<Partial<Dialog.RootProps>> = (props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <MenuItem value="add_source" onClick={() => setOpen(true)}>
        Add source...
      </MenuItem>

      <MetricIntegrationModal open={open} onOpenChange={(e) => setOpen(e.open)} {...props} />
    </>
  )
}

const CredentialSelect = forwardRef<HTMLButtonElement, Props>(
  ({ defaultSourceName, credential: credentialProp, onSelect, apiRef, ...buttonProps }, ref) => {
    const credentials = useGetObjects('credential')
    const [credential, setCredential] = useState(credentialProp)
    const [sourceName, setSourceName] = useState(credential?.sourceName || defaultSourceName || '')
    const [credId, setCredId] = useState<CredId>(credential?.id || sourceName)
    const showGoogle =
      useGetObjectsByProperties('integration', { sourceName: IntegrationSourceNamesEnum.Google }).length > 0
    const { disabled } = buttonProps

    useImperativeHandle(
      apiRef,
      () => ({
        reset: () => {}, // Matching the API that is expected via the typescript type.
        clear: () => {
          setCredId('')
          setSourceName('')
          setCredential(null)
        }
      }),
      []
    )

    const dynamicSources = useMemo(() => {
      const mapped = [
        ...(credentials || []).map((c) => ({
          sourceName: c.sourceName,
          credential: c
        })),
        {
          sourceName: 'MetricSources::Looker',
          credential: null
        },
        {
          sourceName: 'MetricSources::GoogleSheets',
          credential: null
        }
      ]

      if (showGoogle) {
        mapped.push({
          sourceName: 'MetricSources::GoogleAnalyticsV4',
          credential: null
        })
      }

      return sortBy(mapped, (c) => {
        const { display = '' } = METRIC_SOURCES[c.sourceName] || {}
        const basic = display || c.sourceName
        return c.credential ? `${basic} ${c.credential.label}` : basic
      })
    }, [credentials, showGoogle])

    const onClick: OnSelect = useCallback(
      (newSourceName, newCredential) => {
        if (!disabled) {
          setCredId(newCredential?.id || newSourceName)
          setSourceName(newSourceName)
          setCredential(newCredential)
          onSelect?.(newSourceName, newCredential)
        }
      },
      [onSelect, disabled]
    )

    return (
      <MenuRoot>
        <MenuTrigger asChild>
          <Button ref={ref} variant="outline" {...buttonProps}>
            <SourceName sourceName={credential?.sourceName || sourceName} label={credential?.label} />
            <FiChevronDown />
          </Button>
        </MenuTrigger>
        <MenuContent overflowY="auto" maxH="450px">
          <AddSourceModal />
          <SourceMenuItem sourceName="" credential={null} onClick={onClick} isSelected={credId === ''} />
          <MenuSeparator />
          <SourceMenuItem
            sourceName="MetricSources::Calculated"
            credential={null}
            onClick={onClick}
            isSelected={credId === 'MetricSources::Calculated'}
          />
          <SourceMenuItem
            sourceName="MetricSources::DummyData"
            credential={null}
            onClick={onClick}
            isSelected={credId === 'MetricSources::DummyData'}
          />
          <MenuSeparator />
          {dynamicSources.map(({ sourceName: name, credential: cred }) => (
            <SourceMenuItem
              key={cred?.id || name}
              sourceName={name}
              credential={cred}
              onClick={onClick}
              isSelected={credId === cred?.id}
            />
          ))}
        </MenuContent>
      </MenuRoot>
    )
  }
)

export default CredentialSelect
