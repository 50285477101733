import type { OnNodeDrag, SelectionDragHandler, XYPosition } from '@xyflow/react'
import { useReactFlow } from '@xyflow/react'
import type { DragEventHandler } from 'react'
import { useMemo, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import { attachNodeToParent, detachNodeFromParent } from '@app/pages/maps/components/nodes/helpers'
import useImages from '@app/pages/maps/useImages'
import { useStore } from '@app/store'
import type { MapDomainEdge, MapDomainNode } from '@app/types'
import type { Playbook } from '@graphql/types'

type DragHandlerEvent = Parameters<DragEventHandler<HTMLDivElement>>[0]

const useInteraction = (strategyId: string) => {
  const { screenToFlowPosition, getIntersectingNodes } = useReactFlow<MapDomainNode, MapDomainEdge>()

  const addNode = useStore.use.addNode()
  const getNodeById = useStore.use.getNodeById()
  const updateNodes = useStore.use.updateNodes()
  const applyPlaybookToStrategy = useStore.use.applyPlaybookToStrategy()

  const strategyNodes = useGetObjectsByProperties('node', { strategyId })

  const addEdge = useStore.use.addEdge()
  const removeEdge = useStore.use.removeEdge()

  const { createImageNodes } = useImages(strategyId)

  const handleSectionIntersections = useCallback(
    (movedNodes: MapDomainNode[]) =>
      movedNodes.flatMap((node) => {
        const intersectingNodes = getIntersectingNodes(node)

        // If the node is not a section, check to see if it needs to be attached or detached
        if (node.type !== 'section') {
          const intersectingSection = intersectingNodes.find((n) => n.type === 'section')

          // handle a direct move from one section to another
          if (intersectingSection && node.parentId && intersectingSection.id !== node.parentId) {
            return attachNodeToParent(node, intersectingSection)
          }

          // It is now in a section and doesn't have a parent, attach
          if (intersectingSection && !node.parentId) {
            return attachNodeToParent(node, intersectingSection)
          }

          // It has a parent and is no longer in a section, detach
          if (node.parentId && !intersectingSection) {
            const parentNode = getNodeById(node.parentId)

            return detachNodeFromParent(node, parentNode)
          }
        } else {
          // If the node is a section, attach any intersecting nodes that aren't a section
          // and aren't already in a section.
          const attachedNodes: Partial<MapDomainNode>[] = intersectingNodes
            .filter((iNode) => iNode.type !== 'section' && !iNode.parentId)
            .map((iNode) => attachNodeToParent(iNode, node))

          const intersections = new Set(intersectingNodes.map((n) => n.id))

          // remove any children who are no longer intersecting
          const detachedNodes = strategyNodes
            .filter((sNode) => sNode.type !== 'section' && sNode.parentId === node.id && !intersections.has(sNode.id))
            .map((sNode) => detachNodeFromParent(sNode, node))

          attachedNodes.push(...detachedNodes)

          // Need to ensure we send the section itself back for finishing onNodeDragStop behaviors.
          attachedNodes.push(node)

          return attachedNodes
        }

        return node
      }),
    [getIntersectingNodes, getNodeById, strategyNodes]
  )

  const onSelectionDragStop: SelectionDragHandler<MapDomainNode> = useCallback(
    (_, nodes) => {
      const updatedNodes = handleSectionIntersections(nodes)

      // "Force dragging to false to make the drag selection not over select."
      // THIS IS STILL NECESSARY AS TESTED ON JUNE 11 2024 -- SNG
      updatedNodes.forEach((updatedNode) => {
        updatedNode.dragging = false
      })

      updateNodes(strategyId, updatedNodes)
    },
    [handleSectionIntersections, strategyId, updateNodes]
  )

  const onNodeDragStop: OnNodeDrag<MapDomainNode> = useCallback(
    (e, node) => {
      onSelectionDragStop(e, [node])
    },
    [onSelectionDragStop]
  )

  // Move into store/maps.ts
  const onEdgeConnect = useCallback(
    async (params) => {
      const { source, sourceHandle, target, targetHandle } = params

      // Currently the edge selector does the smashing together of `data` for db backed object
      // Can't decide if addEdge should have this logic baked into it or just accept an unsaved edge
      const sourceNode = getNodeById(source)
      const targetNode = getNodeById(target)
      const { id: sourceId, classType: sourceType } = sourceNode.data
      const { id: targetId, classType: targetType } = targetNode.data
      const rfId = `${source}-${target}`

      const unsavedEdge = {
        id: rfId,
        type: 'custom',
        classType: 'edge',
        source,
        target,
        sourceHandle,
        targetHandle,
        sourceId,
        sourceType,
        targetId,
        targetType,
        strategyId
      }

      addEdge(strategyId, unsavedEdge)
    },
    [strategyId, addEdge, getNodeById]
  )

  // Move into store/maps.ts
  const onEdgeDelete = useCallback(
    (edgesToDelete: MapDomainEdge[]) => {
      edgesToDelete.forEach((edge) => {
        removeEdge(edge.id)
      })
    },
    [removeEdge]
  )

  const onDragOver: DragEventHandler<HTMLDivElement> = useCallback((event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const dropPlaybook = useCallback(
    (playbook: Pick<Playbook, 'id'>, position: XYPosition) =>
      applyPlaybookToStrategy({
        strategyId,
        playbookId: playbook.id,
        offsetX: position.x,
        offsetY: position.y
      }),
    [applyPlaybookToStrategy, strategyId]
  )

  const onDrop: DragEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault()

      if (event.dataTransfer.getData('application/reactflow')) {
        const object = JSON.parse(event.dataTransfer.getData('application/reactflow'))

        const position = screenToFlowPosition({
          x: event.clientX,
          y: event.clientY
        })

        if (object.classType === 'playbook') {
          dropPlaybook(object, position)
          return
        }

        const objectData = {
          id: object.id,
          type: object.classType
        }

        addNode({
          strategyId,
          nodeData: {
            position
          },
          objectType: 'existing', // Does not create a new object, uses id and type,
          objectData
        })
      }
    },
    [screenToFlowPosition, addNode, strategyId]
  )

  type OnDragStart = (event: DragHandlerEvent, object: { id: string; classType: string }) => void
  const onDragStart: OnDragStart = useCallback((event, object) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(object))
    event.dataTransfer.effectAllowed = 'move'
  }, [])

  const onImageDrop = useCallback(
    (files: File | File[]) => {
      createImageNodes(files)
    },
    [createImageNodes]
  )

  const { getRootProps } = useDropzone({
    onDrop: onImageDrop,
    noClick: true,
    noKeyboard: true,
    onDragEnter: () => {},
    onDragOver: () => {},
    onDragLeave: () => {},
    multiple: false
  })

  return useMemo(
    () => ({
      onNodeDragStop,
      onSelectionDragStop,
      onEdgeConnect,
      onEdgeDelete,
      onDragOver,
      onDrop,
      onDragStart,
      getRootProps,
      handleSectionIntersections
    }),
    [
      onNodeDragStop,
      onSelectionDragStop,
      onEdgeConnect,
      onEdgeDelete,
      onDragOver,
      onDrop,
      onDragStart,
      getRootProps,
      handleSectionIntersections
    ]
  )
}
export default useInteraction
