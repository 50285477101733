import type { FC, ReactNode } from 'react'

import EntityAttachmentUploadButton from './entityAttachmentUploadButton'

import { useEntityCreateAttachmentMutation } from '@graphql/queries'

type Props = {
  entityId: string
  children: ReactNode
}

const ExistingEntityAttachmentUploadButton: FC<Props> = ({ entityId, children }) => {
  const [, createAttachment] = useEntityCreateAttachmentMutation()

  const onUpload = (shrineHash) => createAttachment({ input: { shrineHash, entityId } })

  return <EntityAttachmentUploadButton onUploadSuccess={onUpload}>{children}</EntityAttachmentUploadButton>
}

export default ExistingEntityAttachmentUploadButton
