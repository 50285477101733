import type { FC } from 'react'
import { useEffect, useState } from 'react'

import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useUserAccountsQuery } from '@graphql/queries'

interface Props {}

const UserAccountsFetcher: FC<Props> = () => {
  const [accountData, setAccountData] = useState(null)

  const [{ data }] = useUserAccountsQuery({
    variables: {},
    pause: !!accountData
  })

  useEffect(() => {
    if (!data) {
      return
    }

    setAccountData(data)
  }, [data])

  return null
}

export default withSuspenseWrapper(UserAccountsFetcher)
