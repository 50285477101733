import { useMemo } from 'react'
import { Provider as UrqlProvider } from 'urql'

import { useStore } from '@app/store'
import { makeClient } from '@graphql/client'

const GraphqlProvider = ({ children }) => {
  const addObject = useStore.use.addObject()
  const deleteObject = useStore.use.deleteObject()

  const client = useMemo(
    () =>
      makeClient({
        addObject,
        deleteObject
      }),
    [addObject, deleteObject]
  )

  return <UrqlProvider value={client}>{children}</UrqlProvider>
}

export default GraphqlProvider
