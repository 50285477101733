import isNull from 'lodash/isNull'
import omitBy from 'lodash/omitBy'
import type { RouteObject } from 'react-router-dom'
import { defer, Outlet } from 'react-router-dom'

import { CARD_FILTER_SIGIL } from '@app/lib/globals'
import AddWorkItemDrawer from '@app/next/changelog/components/addWorkItemDrawer'
import EditWorkItemDrawer from '@app/next/changelog/components/editWorkItemDrawer'
import ChangelogEdit from '@app/next/changelog/edit'
import ChangelogList from '@app/next/changelog/list'
import ChangelogShow from '@app/next/changelog/show'
import MapEducationDrawer from '@app/pages/maps/components/learn/mapEducationDrawer'
import { createEvent, updateEvent } from '@app/routes/events'
import { useStore } from '@app/store'
import { StrategyChangelog, StrategyChangelogEntity } from '@graphql/documents/strategy.graphql'
import type { StrategiesQueryVariables } from '@graphql/queries'

const loadStrategyChangelog = async ({ request, params }) => {
  const url = new URL(request.url)
  const page = parseInt(url.searchParams.get('page'), 10) || 1
  const limit = parseInt(url.searchParams.get('limit'), 10) || null
  const containerNodeIds = url.searchParams.get(`${CARD_FILTER_SIGIL}containerNodeIds`)
  const variables: StrategiesQueryVariables = omitBy({ page, limit, containerNodeIds }, isNull)
  const { loaderQuery, addObjectPage } = useStore.getState()

  const { strategyId } = params

  const result = await loaderQuery(StrategyChangelog, { strategyId, ...variables })

  const { collection = [], metadata } = result?.data?.strategyChangelog || {}
  addObjectPage('entity', collection, metadata)

  return defer({ changelog: result.data.strategyChangelog })
}

const loadStrategyChangelogEntity = async ({ params }) => {
  const { strategyId, entityId } = params
  const { loaderQuery } = useStore.getState()

  const result = await loaderQuery(StrategyChangelogEntity, { strategyId, id: entityId })

  return defer({ entity: result.data.strategyChangelogEntity })
}

const strategyChangelogRoutes: RouteObject = {
  path: 'changelog',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <ChangelogList />,
      loader: loadStrategyChangelog
    },
    {
      path: 'learn',
      element: <MapEducationDrawer />
    },
    {
      path: 'add',
      loader: loadStrategyChangelog,
      element: (
        <>
          <ChangelogList />
          <AddWorkItemDrawer />
        </>
      ),
      action: ({ request, params }) => {
        switch (request.method) {
          case 'POST':
            return createEvent({ request, params, redirectTo: (event) => `../${event.id}` })
          default:
            return null
        }
      }
    },
    {
      path: 'edit/:entityId',
      loader: async (args) => {
        const resolved = await Promise.all([loadStrategyChangelog(args), loadStrategyChangelogEntity(args)])

        return resolved[1]
      },
      element: (
        <>
          <ChangelogList />
          <EditWorkItemDrawer />
        </>
      ),
      action: ({ request, params }) => {
        switch (request.method) {
          case 'POST':
            return updateEvent({ request, params })
          default:
            return null
        }
      }
    },
    {
      path: ':entityId',
      element: <Outlet />,
      loader: loadStrategyChangelogEntity,
      id: 'strategyChangelogEntity',
      children: [
        {
          index: true,
          element: <ChangelogShow />
        },
        {
          path: 'edit',
          loader: loadStrategyChangelogEntity,
          element: <ChangelogEdit />,
          action: ({ request, params }) => {
            switch (request.method) {
              case 'POST':
                return updateEvent({ request, params })
              default:
                return null
            }
          }
        }
      ]
    }
  ]
}

// export { strategyChangelogRoutes as MapRoutes }

export default strategyChangelogRoutes
