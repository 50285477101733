import { Icon } from '@chakra-ui/react'
import { FiItalic } from 'react-icons/fi'

import inlineButtonMaker from './inlineButtonMaker'

const ItalicButton = inlineButtonMaker({
  style: 'ITALIC',
  children: (
    <Icon>
      <FiItalic />
    </Icon>
  )
})

export default ItalicButton
