import { Flex, Text } from '@chakra-ui/react'
import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown'
import type { LexicalEditor } from 'lexical'
import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import RichTextInputWithLabel from '@app/next/forms/inline/richTextInputWithLabel'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import PopulateEdgeInsightsButton from '@app/pages/maps/components/populateEdgeInsightsButton'
import { useStore } from '@app/store'
import type { MapDomainEdge } from '@app/types'

interface Props {
  edge: MapDomainEdge
}

const InsightsDetails: FC<Props> = ({ edge }) => {
  const [error, setError] = useState<string>(null)
  const { id, insightsDetails } = edge
  const getNodeById = useStore.use.getNodeById()
  const source = getNodeById(edge?.source)
  const target = getNodeById(edge?.target)

  const detailsRef = useRef<LexicalEditor>(null)
  const showPopulateInsights = source.data.classType === 'metric' && target.data.classType === source.data.classType

  // Update the details when they change in the background, so AI pipelines can update it.
  useEffect(() => {
    if (
      insightsDetails?.length > 0 &&
      detailsRef.current?.isEditable() &&
      // do not update if the editor is focused, as it makes editing it inline nearly impossible.
      detailsRef.current.getRootElement() !== document.activeElement
    ) {
      detailsRef.current.update(() => {
        $convertFromMarkdownString(insightsDetails, TRANSFORMERS)
      })
    }
  }, [insightsDetails])

  const onClick = useCallback(() => {
    setError(null)
  }, [])

  const onError = useCallback((err: Error) => {
    setError(err.message)
  }, [])

  return (
    <ViewerFieldWrapper value={edge.insightsDetails}>
      <Flex justify="space-between">
        <RichTextInputWithLabel
          domainObject={edge}
          name="insightsDetails"
          placeholder="Insights..."
          label="Insights"
          domainObjectUpdateId="edgeId"
          ref={detailsRef}
          stackProps={{ width: '100%' }}
        />
        {showPopulateInsights && (
          <PopulateEdgeInsightsButton w="fit-content" edgeId={id} onClick={onClick} onRequestError={onError} />
        )}
        {error && (
          <Text color="error" fontSize="xs">
            {error}
          </Text>
        )}
      </Flex>
    </ViewerFieldWrapper>
  )
}

export default InsightsDetails
