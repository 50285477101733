import { format, fromUnixTime } from 'date-fns'
import type { FC } from 'react'

import MinorEntitiesList from './minorEntitiesList'
import { groupEntitiesByImportance } from './utils'

import LayoutRow, { type Props as LayoutRowProps } from '@app/pages/changelogs/components/layoutRow'
import Entity from '@app/shared/entity'
import type { Props as EntityProps } from '@app/shared/entity/entity'
import type { MapDomainEntity } from '@app/types'
import { ImportanceEnum } from '@graphql/queries'

export interface Props extends Omit<EntityProps, 'entity'> {
  week: string
  entities?: MapDomainEntity[]
  variant?: string
  layoutRowProps?: Partial<LayoutRowProps>
}

const EntitiesByWeek: FC<Props> = ({ week, entities = [], layoutRowProps = {}, ...rest }) => {
  const { variant } = rest
  const entitiesByImportance = groupEntitiesByImportance(entities)
  const importances = Object.keys(entitiesByImportance).sort()

  return (
    <LayoutRow date={format(fromUnixTime(parseInt(week, 10)), 'MMM d, yyyy')} variant={variant} {...layoutRowProps}>
      {importances.map((importance) => {
        const importanceEntities = entitiesByImportance[importance]

        if (importance === ImportanceEnum.Major || importance === ImportanceEnum.Medium) {
          return importanceEntities.map((entity) => (
            <Entity
              key={`entity-${entity.id}`}
              entity={entity}
              isListView
              scrollContainer="#lazyload-container"
              {...rest}
            />
          ))
        }
        return <MinorEntitiesList key={`minor-entities-${week}`} entities={importanceEntities} />
      })}
    </LayoutRow>
  )
}

export default EntitiesByWeek
