import sortBy from 'lodash/sortBy'
import type { FC } from 'react'

import UserAccountsFetcher from '@app/fetchers/userAccountsFetcher'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import OrganizationCell from '@app/pages/settings/user/components/columns/organizationCell'
import RoleCell from '@app/pages/settings/user/components/columns/roleCell'
import UserActionsCell from '@app/pages/settings/user/components/columns/userActionsCell'
import Table from '@app/shared/table'
import type { Column, ColumnCellProps, Data } from '@app/shared/table/types'

const columns: Column<Data>[] = [
  {
    header: 'Organization',
    cell: OrganizationCell as unknown as FC<ColumnCellProps<Data>>
  },
  {
    header: 'Role',
    cell: RoleCell
  },
  {
    header: '',
    cell: UserActionsCell as unknown as FC<ColumnCellProps<Data>>
  }
]

// const rowFormatter = (data: Data): Partial<TableRowProps> => {
//   const { user } = useStore.use.currentUser()
//   const account: Partial<Account> = data
//   const additionalProps: Partial<TableRowProps> = {}
//
//   if (user?.organization?.id === account.organizationId) {
//     additionalProps.bgColor = 'bg.muted'
//   }
//
//   return additionalProps
// }

interface Props {}

const AccountsList: FC<Props> = () => {
  const { user } = useStoreCurrentUser()
  const userAccounts = useGetObjectsByProperties('account', {
    userId: user.id
  })

  const sortedAccounts = sortBy(userAccounts || [], ['organizationName'])
  // const formatter = useCallback(rowFormatter, [sortedAccounts])

  return (
    <>
      <UserAccountsFetcher />
      <Table columns={columns} data={sortedAccounts as Data[]} />
    </>
  )
}

export default AccountsList
