import type { DefaultEdgeOptions } from '@xyflow/react'
import { MarkerType } from '@xyflow/react'

import type { MapDomainEdge, MapDomainNode } from '@app/types'
import { COLORS, correlationScoreColor } from '@app/utils/metricHelpers'

// eslint-disable-next-line import/prefer-default-export
export const defaultEdgeOptions: DefaultEdgeOptions = {
  animated: true,
  labelStyle: {
    fill: 'white',
    fontWeight: 700,
    fontSize: '18px',
    fontFamily:
      'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
  },
  labelBgPadding: [12, 8],
  labelBgBorderRadius: 6,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 10,
    height: 10
  }
}

export const shouldFlipColors = (source: MapDomainNode, target: MapDomainNode) => {
  let flipColors = false
  if (source?.data?.positiveDirection !== target?.data?.positiveDirection) {
    flipColors = true
  }
  return flipColors
}

export const displayLabel = (
  edge: MapDomainEdge,
  source: MapDomainNode,
  target: MapDomainNode,
  showCorrelations: boolean
) => {
  if (!source || !target) {
    return {}
  }

  if (edge?.insights) {
    const color = edge?.color || 'gray'

    return {
      displayType: 'insight',
      color,
      selectedColor: color,
      label: edge.insights
    }
  }

  const flipColors = shouldFlipColors(source, target)
  const { color, selectedColor } = correlationScoreColor(edge.correlationScore, flipColors)

  if (showCorrelations && edge?.correlationScore) {
    return {
      displayType: 'correlation',
      color,
      selectedColor,
      label: edge.correlationScore
    }
  }

  return {
    displayType: null,
    color: COLORS.neutral.color,
    selectedColor: COLORS.neutral.selectedColor,
    label: null
  }
}
