import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { memo } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import CardAvatar from '@app/shared/cards/components/cardAvatar'

interface Props {
  messageId: string | null
}

const ChatsListMessage: FC<Props> = ({ messageId }) => {
  const message = useGetObject(messageId, 'message')
  const { userId, role, content, updatedAt } = message || {}
  const user = useGetObject(userId, 'user')
  const { formatDateTimeInUserTimeZone } = useStoreCurrentUser()

  if (!message) {
    return null
  }

  const dateTime = updatedAt ? formatDateTimeInUserTimeZone(updatedAt, 'MMMM do, yyy h:mm aaa') : null

  const name = user?.name || capitalize(role)

  return (
    <HStack align="flex-start" gap="3" px="4" py="3" _hover={{ bg: 'bg.muted' }} rounded="md">
      <Box pt="1">
        <CardAvatar user={user} />
      </Box>
      <Stack flex="1" gap="0" fontSize="sm" truncate>
        <HStack gap="1">
          <Text flex="1" fontWeight="medium">
            {name}
          </Text>
          <Text color="fg.subtle" fontSize="xs">
            {dateTime}
          </Text>
        </HStack>
        <Box color="fg.subtle" truncate>
          {content}
        </Box>
      </Stack>
    </HStack>
  )
}

export default memo(ChatsListMessage)
