import type { FC } from 'react'

import { Alert } from '@app/components/ui/alert'
import type { AlertProps } from '@app/components/ui/alert'

interface Props extends AlertProps {
  description?: string
}

const FormAlert: FC<Props> = ({ title = '', description = '', status = 'error' }) => {
  if (!description) {
    return null
  }

  return (
    <Alert title={title} status={status}>
      {description}
    </Alert>
  )
}

export default FormAlert
