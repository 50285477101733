import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import GoalsTable from '../components/goals/goalsTable'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'

const MetricGoalTab: FC = () => {
  const params = useParams()
  const metricId = params.metricId || params.nodeId

  const goals = useGetObjectsByProperties('goal', { metricId })

  return <GoalsTable goals={goals} />
}

export default MetricGoalTab
