import type { NativeSelect } from '@chakra-ui/react'
import type { FC } from 'react'

import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import { RollUpEnum } from '@graphql/queries'

const MetricRollUpSelect: FC<NativeSelect.FieldProps> = (props) => (
  <NativeSelectRoot>
    <NativeSelectField {...props}>
      <option value={RollUpEnum.Sum}>Sum</option>
      <option value={RollUpEnum.Average}>Average</option>
      <option value={RollUpEnum.Increase}>Amount Increased</option>
      <option value={RollUpEnum.LastMonthAvailable}>MoM, month over 3-month average, YoY</option>
    </NativeSelectField>
  </NativeSelectRoot>
)

export default MetricRollUpSelect
