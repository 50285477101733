import { HStack, Spacer } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import type { FC } from 'react'

import { CardIssues, CardMultiStepProgress, CardProgressPercent } from '@app/shared/cards/components'
import { totalEntities } from '@app/shared/cards/entity/helpers'
import type { CardSize } from '@app/types'
import type { Entity } from '@graphql/queries'

interface Props {
  entity: Pick<Entity, 'workflowState' | 'containeeStats'>
  size?: CardSize
}

export const shouldRenderCardEntityStatsAndProgress = (entity) => {
  const { containeeStats } = entity
  const issueCount = totalEntities(containeeStats || {})

  return !isEmpty(containeeStats) && issueCount > 0
}

const CardEntityStatsAndProgress: FC<Props> = ({ entity, size = 'normal' }) => {
  const { workflowState, containeeStats } = entity
  const issueCount = totalEntities(containeeStats || {})

  if (!shouldRenderCardEntityStatsAndProgress(entity)) {
    return null
  }

  return (
    <>
      {containeeStats && (
        <CardMultiStepProgress progresses={containeeStats} workflowState={workflowState} size={size} />
      )}
      <HStack>
        {issueCount > 0 && <CardIssues issueCount={issueCount} size={size} />}
        <Spacer />
        <CardProgressPercent progresses={containeeStats} size={size} />
      </HStack>
    </>
  )
}

export default CardEntityStatsAndProgress
