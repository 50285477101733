import { Box, HStack, Icon, Stack, Text, Textarea } from '@chakra-ui/react'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import ICON_MAP from '@app/utils/iconMap'
import type { MetricSource as BaseMetricSource } from '@graphql/types'

type Metric = BaseMetricSource['metric']
type MetricSource = Pick<BaseMetricSource, 'id' | 'configuration'> & {
  metric: Pick<Metric, 'id'>
}

const HelperText = () => (
  <Stack>
    <HStack>
      <Icon color="gray.500" fontSize="xl">
        <ICON_MAP.Details />
      </Icon>
      <Box>
        <Text>
          Use metric IDs to reference other metrics in the formula. IDs must begin with a non-numeric character, and may
          only contain alphanumeric characters and underscores. You can find a metric&apos;s ID on its
          &quot;Details&quot; page.
        </Text>
        <Text>
          Valid operations are <code>+</code>, <code>-</code>, <code>*</code>, <code>/</code>, and <code>^</code>. You
          can use parentheses to group operations, and formulae can span multiple lines.
        </Text>
      </Box>
    </HStack>
  </Stack>
)

export interface Props {
  metricSource: MetricSource | null
  disabled: boolean
}

const Calculated: FC<Props> = ({ metricSource, disabled }) => (
  <Field label="Formula" required disabled={disabled} helperText={<HelperText />}>
    <Textarea
      w="full"
      maxW="full"
      defaultValue={metricSource?.configuration?.formula}
      name="configuration.formula"
      placeholder="Enter a formula..."
    />
  </Field>
)

export default Calculated
