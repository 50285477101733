import { subject } from '@casl/ability'
import { HStack, IconButton, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { PiUserSwitch } from 'react-icons/pi'

import { Tooltip } from '@app/components/ui/tooltip'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import AccountDeleteButton from '@app/pages/settings/organization/components/accountDeleteButton'
import AccountSwitchButton from '@app/pages/settings/user/components/accountSwitchButton'
import Can from '@app/shared/authorization/can'
import type { Account } from '@graphql/types'

interface Props {
  account: Account
}

const AccountsListActions: FC<Props> = ({ account }) => {
  const { user } = useStoreCurrentUser()
  const { id } = account

  return (
    <Can I="delete" this={subject('account', { id, organizationId: user?.organization?.id })}>
      <HStack gap={0}>
        <Spacer />
        <AccountSwitchButton account={account}>
          <Tooltip content="Switch to this account">
            <IconButton aria-label="Switch to this account" disabled={!!account.disabledAt} variant="ghost">
              <PiUserSwitch />
            </IconButton>
          </Tooltip>
        </AccountSwitchButton>
        <AccountDeleteButton account={account}>
          <Tooltip content="Delete account">
            <IconButton aria-label="Delete accoount" variant="ghost">
              <FiTrash2 />
            </IconButton>
          </Tooltip>
        </AccountDeleteButton>
      </HStack>
    </Can>
  )
}

export default AccountsListActions
