import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Tooltip } from '@app/components/ui/tooltip'
import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  borderRadius: 'none'
}

interface Props {
  path: string
}

const EventsButton: FC<Props> = ({ path }) => {
  const navigate = useNavigate()

  const openDrawer = () => {
    navigate(path)
  }

  return (
    <Tooltip content="Work events">
      <IconButton {...buttonStyles} aria-label="work events" onClick={openDrawer} size="sm" variant="outline">
        <ICON_MAP.Work />
      </IconButton>
    </Tooltip>
  )
}

export default EventsButton
