import { createContext, useContext, useMemo } from 'react'

import type { MetricSourceTestAttributesInput } from '@graphql/types'

const MetricSourceTabContext = createContext(undefined)

type CreateAttributes = (input: MetricSourceTestAttributesInput['input']) => MetricSourceTestAttributesInput['input']
const createAttributes: CreateAttributes = (input) => {
  const output = { ...input }

  // Remove any config object that does not contain at least an id
  Object.keys(input.configuration || []).forEach((key) => {
    if (Array.isArray(input.configuration[key])) {
      output.configuration[key] = input.configuration[key].filter((config) => config.id)
    }
  })

  return output
}

interface Props {
  children: React.ReactNode
}

const MetricSourceTabContextProvider = ({ children }: Props) => {
  const value = useMemo(
    () => ({
      createAttributes
    }),
    []
  )

  return <MetricSourceTabContext.Provider value={value}>{children}</MetricSourceTabContext.Provider>
}

const useMetricSourceTabContext = () => {
  const context = useContext(MetricSourceTabContext)

  if (context === undefined) {
    throw new Error('useMetricSourceTabContext must be used within StrategyDrawerTabContext.Provider')
  }

  return context
}

export { MetricSourceTabContextProvider, useMetricSourceTabContext }
