import { Input, VisuallyHidden } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import { Switch, type SwitchProps } from '@app/components/ui/switch'

export interface Props extends Omit<SwitchProps, 'defaultValue' | 'onChange'> {
  name?: string
  defaultValue?: boolean
  onChange?: (checked: boolean) => void
}

const ToggleInput: FC<Props> = ({ name, defaultValue = false, onChange = (_checked) => null, ...switchProps }) => {
  const [checked, setChecked] = useState(defaultValue)

  const handleChange = () => {
    setChecked(!checked)
    onChange(!checked)
  }

  return (
    <>
      <Switch checked={checked} onCheckedChange={handleChange} value={checked.toString()} {...switchProps} />
      <VisuallyHidden asChild>
        <Input name={name} readOnly value={checked.toString()} />
      </VisuallyHidden>
    </>
  )
}

export default ToggleInput
