import type { BoxProps, FlexProps, IconButtonProps } from '@chakra-ui/react'
import { Box, Flex, IconButton } from '@chakra-ui/react'
import type { KeenSliderOptions } from 'keen-slider/react'
import { useKeenSlider } from 'keen-slider/react'
import type { FC, ReactElement } from 'react'
import { forwardRef } from 'react'

import { useColorModeValue } from '@app/components/ui/color-mode'

export const Carousel = forwardRef<HTMLDivElement, FlexProps>(function Carousel(props, ref) {
  return <Flex className="chakra-carousel" ref={ref} pos="relative" overflow="hidden" userSelect="none" {...props} />
})

export const CarouselSlide = (props: BoxProps) => (
  <Box className="chakra-carousel__slide" pos="relative" overflow="hidden" w="100%" minH="100%" {...props} />
)

interface CarouselIconButtonProps extends IconButtonProps {
  icon: ReactElement
}

export const CarouselIconButton: FC<CarouselIconButtonProps> = (props) => {
  const { icon } = props

  return (
    <IconButton
      display="inline-flex"
      boxSize="auto"
      minW="auto"
      color="muted"
      fontSize="2xl"
      _hover={{
        color: useColorModeValue('blue.500', 'blue.300'),
        _disabled: { color: useColorModeValue('gray.600', 'gray.400') }
      }}
      _active={{ color: useColorModeValue('blue.600', 'blue.400') }}
      _focus={{ boxShadow: 'none' }}
      _focusVisible={{ boxShadow: 'tertiary' }}
      variant="ghost"
      {...props}
    >
      {icon}
    </IconButton>
  )
}

export const useCarousel = (options?: KeenSliderOptions) => {
  const defaultOptions = { selector: '.chakra-carousel__slide' }
  return useKeenSlider<HTMLDivElement>({ ...defaultOptions, ...options })
}
