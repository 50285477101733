import { IconButton } from '@chakra-ui/react'
import { PiHeadCircuit } from 'react-icons/pi'
import { Link } from 'react-router-dom'

import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@app/components/ui/menu'
import Can from '@app/shared/authorization/can'

const AiChatMenu = ({ strategyId }) => (
  <MenuRoot>
    <MenuTrigger asChild>
      <IconButton aria-label="AI Chat" colorPalette="gray" size="2xs" variant="ghost">
        <PiHeadCircuit />
      </IconButton>
    </MenuTrigger>
    <MenuContent>
      <MenuItem value="Data scientist" asChild>
        <Link to={`/strategy/${strategyId}/map/ai/chat`}>Data scientist</Link>
      </MenuItem>
      <MenuItem value="KPI tree generator" asChild>
        <Link to={`/strategy/${strategyId}/map/ai/kpi_tree_generator`}>KPI tree generator</Link>
      </MenuItem>
      <Can I="view" an="admin">
        <MenuItem value="KPI tree generator (DEV)" asChild>
          <Link to={`/strategy/${strategyId}/map/ai/kpi_tree_generator_dev`}>KPI tree generator (DEV)</Link>
        </MenuItem>
      </Can>
      <MenuItem value="Corporate strategy generator" asChild>
        <Link to={`/strategy/${strategyId}/map/ai/map_generator`}>Corporate strategy generator</Link>
      </MenuItem>
      <MenuItem value="Northstar playbook" asChild>
        <Link to={`/strategy/${strategyId}/map/ai/map_generator_northstar_playbook`}>Northstar playbook</Link>
      </MenuItem>
    </MenuContent>
  </MenuRoot>
)

export default AiChatMenu
