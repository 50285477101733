import { Box, Flex, Separator, Stack } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useMemo, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import AssistantButton from '@app/pages/maps/components/aiChat/components/assistantButton'
import ChatDrawerHeader from '@app/pages/maps/components/aiChat/components/chatDrawerHeader'
import MessageContent from '@app/pages/maps/components/aiChat/components/messageContent'
import MessageGroup from '@app/pages/maps/components/aiChat/components/messageGroup'
import PromptForm from '@app/pages/maps/components/aiChat/components/promptForm'
import type { MapDomainMessage } from '@app/types'
import type { ChatNextStepButtonPressedMutation, ChatPreviousStepButtonPressedMutation } from '@graphql/queries'
import {
  useChatNextAssistantQuery,
  useChatNextStepButtonPressedMutation,
  useChatPreviousAssistantQuery,
  useChatPreviousStepButtonPressedMutation
} from '@graphql/queries'
import type { AiAssistantRoleEnum, PipelineEnum } from '@graphql/types'

type Props = {
  pipelineId: PipelineEnum
  chatId?: string
  strategyId?: string
}

type MessageGrouping = [
  string, // groupKey
  AiAssistantRoleEnum, // role
  Pick<MapDomainMessage, 'id' | 'classType' | 'content' | 'role' | 'userId' | 'createdAt'>[]
]

const Show: FC<Props> = ({ pipelineId, chatId: propChatId = null, strategyId: propStrategyId = null }) => {
  const { chatId: paramChatId, strategyId: paramStrategyId } = useParams()
  const chatId = propChatId || paramChatId
  const strategyId = propStrategyId || paramStrategyId
  const storeMessages = useGetObjectsByProperties('message', { chatId })
  const storeChat = useGetObjectsByProperties('chat', { id: chatId })[0]
  const [nextStepLabel, setNextStepLabel] = useState<string | null>(null)
  const [previousStepLabel, setPreviousStepLabel] = useState<string | null>(null)

  // Move mutation hooks outside of conditional rendering
  const nextStepMutation = useChatNextStepButtonPressedMutation()
  const previousStepMutation = useChatPreviousStepButtonPressedMutation()

  const [{ data: nextAssistantData }] = useChatNextAssistantQuery({
    variables: { chatId: chatId! },
    pause: !chatId || !storeChat?.processedState || storeChat?.processedState !== 'ready_for_next'
  })
  const [{ data: previousAssistantData }] = useChatPreviousAssistantQuery({
    variables: { chatId: chatId! },
    pause: !chatId || !storeChat?.processedState || storeChat?.processedState !== 'ready_for_next'
  })

  const messages = useMemo(
    () => sortBy(storeMessages, 'createdAt').filter((message) => message.content),
    [storeMessages]
  )
  const firstMessage = messages[0]
  const scrollRef = useRef(null)

  useEffect(() => {
    if (
      storeChat?.processedState === 'ready_for_next' &&
      nextAssistantData?.chat?.nextAssistantButtonLabel &&
      nextAssistantData.chat.nextAssistantButtonLabel !== nextStepLabel
    ) {
      setNextStepLabel(nextAssistantData.chat.nextAssistantButtonLabel)
    }
  }, [storeChat?.processedState, nextStepLabel, nextAssistantData?.chat?.nextAssistantButtonLabel])

  useEffect(() => {
    if (
      storeChat?.processedState === 'ready_for_next' &&
      previousAssistantData?.chat?.previousAssistantButtonLabel &&
      previousAssistantData.chat.previousAssistantButtonLabel !== previousStepLabel
    ) {
      setPreviousStepLabel(previousAssistantData.chat.previousAssistantButtonLabel)
    }
  }, [storeChat?.processedState, previousStepLabel, previousAssistantData?.chat?.previousAssistantButtonLabel])

  // condense messages from the same user
  const messageGroups = useMemo(
    () =>
      messages.reduce<MessageGrouping[]>((acc, message) => {
        const [lastKey, lastRole, lastMessages] = acc[acc.length - 1] || []

        if (
          lastMessages &&
          ((lastRole === 'user' && message.role === 'user') ||
            (lastRole === 'assistant' && message.role === 'assistant'))
        ) {
          acc[acc.length - 1] = [lastKey, lastRole, [...lastMessages, message]]
        } else {
          acc.push([message.id, message.role, [message]])
        }

        return acc
      }, []),
    [messages]
  )

  // scroll to the bottom of the list when the messages list changes
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight, behavior: 'smooth' })
    }
  }, [messages.length, scrollRef?.current?.scrollHeight])

  const shouldShowButtons = firstMessage && storeChat?.processedState === 'ready_for_next'

  return (
    <Flex pos="relative" direction="column" overflow="hidden" h="100%" bg="bg.canvas">
      <ChatDrawerHeader />
      <Flex ref={scrollRef} overflowY="auto" pt="0" pb="40" grow={1}>
        <Stack
          gap="10"
          w="100%"
          mx="auto"
          px={4}
          separator={
            <Box ml="14!">
              <Separator />
            </Box>
          }
        >
          {messageGroups?.map(([groupKey, role, contents]) => (
            <MessageGroup
              key={groupKey}
              role={role}
              messages={contents.map((message) => [
                message.id,
                message,
                <MessageContent key={message.id} message={message} />
              ])}
            />
          ))}
        </Stack>
      </Flex>

      <Flex mx="4" py="8" bgGradient="linear(to-t, bg.canvas 80%, rgba(0,0,0,0))" grow={0} insetX="0">
        <Stack gap={4} w="100%">
          <Flex justify="space-between" w="100%">
            <Box>
              {shouldShowButtons && previousStepLabel && previousAssistantData?.chat?.previousAssistantButtonLabel && (
                <AssistantButton<ChatPreviousStepButtonPressedMutation, { input: { chatId: string } }>
                  chatId={chatId!}
                  buttonLabel={previousStepLabel}
                  onLabelChange={setPreviousStepLabel}
                  disabled={!chatId}
                  mutation={previousStepMutation}
                  getLabelFromResponse={(response) =>
                    response.data?.chatPreviousStepButtonPressed?.previousAssistantButtonLabel ?? undefined
                  }
                  colorScheme="orange"
                />
              )}
            </Box>
            <Box>
              {shouldShowButtons && nextStepLabel && nextAssistantData?.chat?.nextAssistantButtonLabel && (
                <AssistantButton<ChatNextStepButtonPressedMutation, { input: { chatId: string } }>
                  chatId={chatId!}
                  buttonLabel={nextStepLabel}
                  onLabelChange={setNextStepLabel}
                  disabled={!chatId}
                  mutation={nextStepMutation}
                  getLabelFromResponse={(response) =>
                    response.data?.chatNextStepButtonPressed?.nextAssistantButtonLabel ?? undefined
                  }
                  colorScheme="green"
                />
              )}
            </Box>
          </Flex>
          <PromptForm pipelineId={pipelineId} chatId={chatId} strategyId={strategyId} />
        </Stack>
      </Flex>
    </Flex>
  )
}

export default Show
