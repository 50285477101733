import { TeamTypeEnum } from '@graphql/queries'

export const basicCardDisplayName = (type: string) => {
  let displayType: string

  switch (type) {
    case TeamTypeEnum.Product:
      displayType = 'Product'
      break
    case TeamTypeEnum.Support:
      displayType = 'Support'
      break
    case TeamTypeEnum.Marketing:
      displayType = 'Marketing'
      break
    case TeamTypeEnum.Sales:
      displayType = 'Sales'
      break
    case TeamTypeEnum.Engineering:
      displayType = 'Engineering'
      break
    default:
      displayType = ''
  }

  return displayType
}

export default basicCardDisplayName
