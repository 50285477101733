import { type FC, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { useRevalidator } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import type { ButtonProps } from '@app/components/ui/button'
import { toaster } from '@app/components/ui/toaster'
import ConfirmPopover from '@app/shared/confirmPopover'
import { useStore } from '@app/store'

interface Props {
  basicCardId?: string
  onDelete?: () => void
  children?: FC<ButtonProps>
}

const DefaultChild: FC<ButtonProps> = (props) => (
  <Button color="error" aria-label="Delete card" variant="outline" {...props}>
    <FiTrash2 />
    Delete
  </Button>
)

const BasicCardDeleteButton: FC<Props> = ({ basicCardId = null, onDelete = () => {}, children = DefaultChild }) => {
  const revalidator = useRevalidator()
  const deleteObject = useStore.use.deleteObject()
  const [loading, setLoading] = useState(false)
  const Child = children || DefaultChild

  const deleteBasicCard = async () => {
    if (!basicCardId) {
      return false
    }

    setLoading(true)

    try {
      const result = await deleteObject({ id: basicCardId, classType: 'basicCard' }, { updatePageCounts: true })
      toaster.create({ title: 'Deleted card' })
      onDelete()
      revalidator.revalidate()
      return result
    } finally {
      setLoading(false)
    }
  }

  return (
    <ConfirmPopover
      onConfirm={deleteBasicCard}
      header="Delete card"
      body="Are you sure? You can&#39;t undo this action afterwards."
      confirmButtonText="Delete"
    >
      <Child loading={loading} />
    </ConfirmPopover>
  )
}

export default BasicCardDeleteButton
