import { Center, Stack, Text } from '@chakra-ui/react'
import lowerFirst from 'lodash/lowerFirst'
import type { FC } from 'react'
import { memo } from 'react'

import useInteraction from '@app/pages/maps/useInteraction'
import BasicCard from '@app/shared/cards/basicCard/basicCard'
import EntityCard from '@app/shared/cards/entity/entityCard'
import MetricCard from '@app/shared/cards/metric/metricCard'
import SubMapCard from '@app/shared/cards/subMap/subMapCard'
import Card from '@app/shared/layout/card'
import type { MapDomainObject } from '@app/types'

type Props = {
  strategyId: string
  query: string
  objects: (MapDomainObject & { rfId: string })[]
}

const ExistingCardsList: FC<Props> = ({ strategyId, query, objects }) => {
  const { onDragStart } = useInteraction(strategyId)
  const resultsCount = objects?.length || 0
  const noSearch = !query
  const noResults = !noSearch && resultsCount === 0

  if (noSearch) {
    return (
      <Center h="50vh" data-testid="existing-cards-list-no-fetched-data">
        <Text color="subtle" fontWeight="light" textAlign="center">
          Use the filters above to search for existing cards.
        </Text>
      </Center>
    )
  }

  return (
    <Stack gap={4} pb={6} data-testid="existing-cards-list">
      <Text color="muted" fontSize="sm" data-testid="number-results">
        {resultsCount} found
      </Text>
      {objects &&
        objects.map((object) => {
          const { classType } = object
          let CardType

          switch (classType) {
            case 'entity':
              CardType = EntityCard
              break
            case 'metric':
              CardType = MetricCard
              break
            case 'strategy':
              CardType = SubMapCard
              break
            case 'basicCard':
              CardType = BasicCard
              break
            default:
          }

          if (!CardType) {
            return null
          }

          const cardData = {}
          // Strip out the recentActivity field from the card data, not useful here
          cardData[lowerFirst(classType)] = { ...object, recentActivity: false }

          return (
            <CardType
              key={`existing-card-${object.rfId}`}
              draggable
              onDragStart={(e) => onDragStart(e, object)}
              _grabbed={{ cursor: 'grabbing' }}
              _hover={{ cursor: 'grab' }}
              className={`react-flow__placed-card drag-drop-card-${object.rfId} unselectable-children`}
              strategyId={strategyId}
              {...cardData}
              zIndex="modal" // This ensures that the element being draggable is above everything else so the browser can create a proper preview
            />
          )
        })}
      {noResults && <Card data-testid="no-results-text">No results found</Card>}
    </Stack>
  )
}

export default memo(ExistingCardsList)
