const dimensionBuilder = (name, id) => ({ name, id })
const metricBuilder = dimensionBuilder

const DIMENSIONS = {
  achievementId: dimensionBuilder('Achievement ID', 'achievementId'),
  adFormat: dimensionBuilder('Ad format', 'adFormat'),
  adSourceName: dimensionBuilder('Ad source', 'adSourceName'),
  adUnitName: dimensionBuilder('Ad unit', 'adUnitName'),
  appVersion: dimensionBuilder('App version', 'appVersion'),
  audienceId: dimensionBuilder('Audience ID', 'audienceId'),
  audienceName: dimensionBuilder('Audience name', 'audienceName'),
  brandingInterest: dimensionBuilder('Interests', 'brandingInterest'),
  browser: dimensionBuilder('Browser', 'browser'),
  campaignId: dimensionBuilder('Campaign ID', 'campaignId'),
  campaignName: dimensionBuilder('Campaign', 'campaignName'),
  character: dimensionBuilder('Character', 'character'),
  city: dimensionBuilder('City', 'city'),
  cityId: dimensionBuilder('City ID', 'cityId'),
  // https://developers.google.com/analytics/devguides/reporting/data/v1/advanced#creating_a_cohort_report
  // cohort: dimensionBuilder('Cohort', 'cohort'),
  // cohortNthDay: dimensionBuilder('Daily cohort', 'cohortNthDay'),
  // cohortNthMonth: dimensionBuilder('Monthly cohort', 'cohortNthMonth'),
  // cohortNthWeek: dimensionBuilder('Weekly cohort', 'cohortNthWeek'),
  contentGroup: dimensionBuilder('Content group', 'contentGroup'),
  contentId: dimensionBuilder('Content ID', 'contentId'),
  contentType: dimensionBuilder('Content type', 'contentType'),
  country: dimensionBuilder('Country', 'country'),
  countryId: dimensionBuilder('Country ID', 'countryId'),
  date: dimensionBuilder('Date', 'date'),
  dateHour: dimensionBuilder('Date + hour (YYYYMMDDHH)', 'dateHour'),
  dateHourMinute: dimensionBuilder('Date hour and minute', 'dateHourMinute'),
  day: dimensionBuilder('Day', 'day'),
  dayOfWeek: dimensionBuilder('Day of week', 'dayOfWeek'),
  defaultChannelGrouping: dimensionBuilder('Default channel grouping', 'defaultChannelGrouping'),
  deviceCategory: dimensionBuilder('Device category', 'deviceCategory'),
  deviceModel: dimensionBuilder('Device model', 'deviceModel'),
  eventName: dimensionBuilder('Event name', 'eventName'),
  fileExtension: dimensionBuilder('File extension', 'fileExtension'),
  fileName: dimensionBuilder('File name', 'fileName'),
  firstSessionDate: dimensionBuilder('First session date', 'firstSessionDate'),
  firstUserCampaignId: dimensionBuilder('First user campaign ID', 'firstUserCampaignId'),
  firstUserCampaignName: dimensionBuilder('First user campaign', 'firstUserCampaignName'),
  firstUserDefaultChannelGrouping: dimensionBuilder(
    'First user default channel grouping',
    'firstUserDefaultChannelGrouping'
  ),
  firstUserGoogleAdsAccountName: dimensionBuilder(
    'First user Google Ads account name',
    'firstUserGoogleAdsAccountName'
  ),
  firstUserGoogleAdsAdGroupId: dimensionBuilder('First user Google Ads ad group ID', 'firstUserGoogleAdsAdGroupId'),
  firstUserGoogleAdsAdGroupName: dimensionBuilder(
    'First user Google Ads ad group name',
    'firstUserGoogleAdsAdGroupName'
  ),
  firstUserGoogleAdsAdNetworkType: dimensionBuilder(
    'First user Google Ads ad network type',
    'firstUserGoogleAdsAdNetworkType'
  ),
  firstUserGoogleAdsCampaignId: dimensionBuilder('First user Google Ads campaign ID', 'firstUserGoogleAdsCampaignId'),
  firstUserGoogleAdsCampaignName: dimensionBuilder('First user Google Ads campaign', 'firstUserGoogleAdsCampaignName'),
  firstUserGoogleAdsCampaignType: dimensionBuilder(
    'First user Google Ads campaign type',
    'firstUserGoogleAdsCampaignType'
  ),
  firstUserGoogleAdsCreativeId: dimensionBuilder('First user Google Ads creative ID', 'firstUserGoogleAdsCreativeId'),
  firstUserGoogleAdsCustomerId: dimensionBuilder('First user Google Ads customer ID', 'firstUserGoogleAdsCustomerId'),
  firstUserGoogleAdsKeyword: dimensionBuilder('First user Google Ads keyword text', 'firstUserGoogleAdsKeyword'),
  firstUserGoogleAdsQuery: dimensionBuilder('First user Google Ads query', 'firstUserGoogleAdsQuery'),
  firstUserManualAdContent: dimensionBuilder('First user manual ad content', 'firstUserManualAdContent'),
  firstUserManualTerm: dimensionBuilder('First user manual term', 'firstUserManualTerm'),
  firstUserMedium: dimensionBuilder('First user medium', 'firstUserMedium'),
  firstUserSource: dimensionBuilder('First user source', 'firstUserSource'),
  firstUserSourceMedium: dimensionBuilder('First user source / medium', 'firstUserSourceMedium'),
  firstUserSourcePlatform: dimensionBuilder('First user source platform', 'firstUserSourcePlatform'),
  fullPageUrl: dimensionBuilder('Full page URL', 'fullPageUrl'),
  googleAdsAccountName: dimensionBuilder('Google Ads account name', 'googleAdsAccountName'),
  googleAdsAdGroupId: dimensionBuilder('Google Ads ad group ID', 'googleAdsAdGroupId'),
  googleAdsAdGroupName: dimensionBuilder('Google Ads ad group name', 'googleAdsAdGroupName'),
  googleAdsAdNetworkType: dimensionBuilder('Google Ads ad network type', 'googleAdsAdNetworkType'),
  googleAdsCampaignId: dimensionBuilder('Google Ads campaign ID', 'googleAdsCampaignId'),
  googleAdsCampaignName: dimensionBuilder('Google Ads campaign', 'googleAdsCampaignName'),
  googleAdsCampaignType: dimensionBuilder('Google Ads campaign type', 'googleAdsCampaignType'),
  googleAdsCreativeId: dimensionBuilder('Google Ads creative ID', 'googleAdsCreativeId'),
  googleAdsCustomerId: dimensionBuilder('Google Ads customer ID', 'googleAdsCustomerId'),
  googleAdsKeyword: dimensionBuilder('Google Ads keyword text', 'googleAdsKeyword'),
  googleAdsQuery: dimensionBuilder('Google Ads query', 'googleAdsQuery'),
  groupId: dimensionBuilder('Group ID', 'groupId'),
  hostName: dimensionBuilder('Hostname', 'hostName'),
  hour: dimensionBuilder('Hour', 'hour'),
  isConversionEvent: dimensionBuilder('Is conversion event', 'isConversionEvent'),
  itemAffiliation: dimensionBuilder('Item affiliation', 'itemAffiliation'),
  itemBrand: dimensionBuilder('Item brand', 'itemBrand'),
  itemCategory: dimensionBuilder('Item category', 'itemCategory'),
  itemCategory2: dimensionBuilder('Item category 2', 'itemCategory2'),
  itemCategory3: dimensionBuilder('Item category 3', 'itemCategory3'),
  itemCategory4: dimensionBuilder('Item category 4', 'itemCategory4'),
  itemCategory5: dimensionBuilder('Item category 5', 'itemCategory5'),
  itemId: dimensionBuilder('Item ID', 'itemId'),
  itemListId: dimensionBuilder('Item list ID', 'itemListId'),
  itemListName: dimensionBuilder('Item list name', 'itemListName'),
  itemName: dimensionBuilder('Item name', 'itemName'),
  itemPromotionCreativeName: dimensionBuilder('Item promotion creative name', 'itemPromotionCreativeName'),
  itemPromotionId: dimensionBuilder('Item promotion ID', 'itemPromotionId'),
  itemPromotionName: dimensionBuilder('Item promotion name', 'itemPromotionName'),
  itemVariant: dimensionBuilder('Item variant', 'itemVariant'),
  landingPage: dimensionBuilder('Landing page', 'landingPage'),
  language: dimensionBuilder('Language', 'language'),
  languageCode: dimensionBuilder('Language code', 'languageCode'),
  level: dimensionBuilder('Level', 'level'),
  linkClasses: dimensionBuilder('Link classes', 'linkClasses'),
  linkDomain: dimensionBuilder('Link domain', 'linkDomain'),
  linkId: dimensionBuilder('Link ID', 'linkId'),
  linkText: dimensionBuilder('Link text', 'linkText'),
  linkUrl: dimensionBuilder('Link URL', 'linkUrl'),
  manualAdContent: dimensionBuilder('Manual ad content', 'manualAdContent'),
  manualTerm: dimensionBuilder('Manual term', 'manualTerm'),
  medium: dimensionBuilder('Medium', 'medium'),
  method: dimensionBuilder('Method', 'method'),
  minute: dimensionBuilder('Minute', 'minute'),
  mobileDeviceBranding: dimensionBuilder('Device brand', 'mobileDeviceBranding'),
  mobileDeviceMarketingName: dimensionBuilder('Device', 'mobileDeviceMarketingName'),
  mobileDeviceModel: dimensionBuilder('Mobile model', 'mobileDeviceModel'),
  month: dimensionBuilder('Month', 'month'),
  newVsReturning: dimensionBuilder('New / returning', 'newVsReturning'),
  nthDay: dimensionBuilder('Nth day', 'nthDay'),
  nthHour: dimensionBuilder('Nth hour', 'nthHour'),
  nthMinute: dimensionBuilder('Nth minute', 'nthMinute'),
  nthMonth: dimensionBuilder('Nth month', 'nthMonth'),
  nthWeek: dimensionBuilder('Nth week', 'nthWeek'),
  nthYear: dimensionBuilder('Nth year', 'nthYear'),
  operatingSystem: dimensionBuilder('Operating system', 'operatingSystem'),
  operatingSystemVersion: dimensionBuilder('OS version', 'operatingSystemVersion'),
  operatingSystemWithVersion: dimensionBuilder('Operating system with version', 'operatingSystemWithVersion'),
  orderCoupon: dimensionBuilder('Order coupon', 'orderCoupon'),
  outbound: dimensionBuilder('Outbound', 'outbound'),
  pageLocation: dimensionBuilder('Page location', 'pageLocation'),
  pagePath: dimensionBuilder('Page path', 'pagePath'),
  pagePathPlusQueryString: dimensionBuilder('Page path + query string', 'pagePathPlusQueryString'),
  pageReferrer: dimensionBuilder('Page referrer', 'pageReferrer'),
  pageTitle: dimensionBuilder('Page title', 'pageTitle'),
  percentScrolled: dimensionBuilder('Percent scrolled', 'percentScrolled'),
  platform: dimensionBuilder('Platform', 'platform'),
  platformDeviceCategory: dimensionBuilder('Platform / device category', 'platformDeviceCategory'),
  region: dimensionBuilder('Region', 'region'),
  screenResolution: dimensionBuilder('Screen resolution', 'screenResolution'),
  searchTerm: dimensionBuilder('Search term', 'searchTerm'),
  sessionCampaignId: dimensionBuilder('Session campaign ID', 'sessionCampaignId'),
  sessionCampaignName: dimensionBuilder('Session campaign', 'sessionCampaignName'),
  sessionDefaultChannelGrouping: dimensionBuilder('Session default channel grouping', 'sessionDefaultChannelGrouping'),
  sessionGoogleAdsAccountName: dimensionBuilder('Session Google Ads account name', 'sessionGoogleAdsAccountName'),
  sessionGoogleAdsAdGroupId: dimensionBuilder('Session Google Ads ad group ID', 'sessionGoogleAdsAdGroupId'),
  sessionGoogleAdsAdGroupName: dimensionBuilder('Session Google Ads ad group name', 'sessionGoogleAdsAdGroupName'),
  sessionGoogleAdsAdNetworkType: dimensionBuilder(
    'Session Google Ads ad network type',
    'sessionGoogleAdsAdNetworkType'
  ),
  sessionGoogleAdsCampaignId: dimensionBuilder('Session Google Ads campaign ID', 'sessionGoogleAdsCampaignId'),
  sessionGoogleAdsCampaignName: dimensionBuilder('Session Google Ads campaign', 'sessionGoogleAdsCampaignName'),
  sessionGoogleAdsCampaignType: dimensionBuilder('Session Google Ads campaign type', 'sessionGoogleAdsCampaignType'),
  sessionGoogleAdsCreativeId: dimensionBuilder('Session Google Ads creative ID', 'sessionGoogleAdsCreativeId'),
  sessionGoogleAdsCustomerId: dimensionBuilder('Session Google Ads customer ID', 'sessionGoogleAdsCustomerId'),
  sessionGoogleAdsKeyword: dimensionBuilder('Session Google Ads keyword text', 'sessionGoogleAdsKeyword'),
  sessionGoogleAdsQuery: dimensionBuilder('Session Google Ads query', 'sessionGoogleAdsQuery'),
  sessionManualAdContent: dimensionBuilder('Session manual ad content', 'sessionManualAdContent'),
  sessionManualTerm: dimensionBuilder('Session manual term', 'sessionManualTerm'),
  sessionMedium: dimensionBuilder('Session medium', 'sessionMedium'),
  sessionSa360AdGroupName: dimensionBuilder('Session SA360 ad group name', 'sessionSa360AdGroupName'),
  sessionSa360CampaignId: dimensionBuilder('Session SA360 campaign ID', 'sessionSa360CampaignId'),
  sessionSa360CampaignName: dimensionBuilder('Session SA360 campaign', 'sessionSa360CampaignName'),
  sessionSa360CreativeFormat: dimensionBuilder('Session SA360 creative format', 'sessionSa360CreativeFormat'),
  sessionSa360EngineAccountId: dimensionBuilder('Session SA360 engine account ID', 'sessionSa360EngineAccountId'),
  sessionSa360EngineAccountName: dimensionBuilder('Session SA360 engine account name', 'sessionSa360EngineAccountName'),
  sessionSa360EngineAccountType: dimensionBuilder('Session SA360 engine account type', 'sessionSa360EngineAccountType'),
  sessionSa360Keyword: dimensionBuilder('Session SA360 keyword text', 'sessionSa360Keyword'),
  sessionSa360Medium: dimensionBuilder('Session SA360 medium', 'sessionSa360Medium'),
  sessionSa360Query: dimensionBuilder('Session SA360 query', 'sessionSa360Query'),
  sessionSa360Source: dimensionBuilder('Session SA360 source', 'sessionSa360Source'),
  sessionSource: dimensionBuilder('Session source', 'sessionSource'),
  sessionSourceMedium: dimensionBuilder('Session source / medium', 'sessionSourceMedium'),
  sessionSourcePlatform: dimensionBuilder('Session source platform', 'sessionSourcePlatform'),
  shippingTier: dimensionBuilder('Shipping tier', 'shippingTier'),
  signedInWithUserId: dimensionBuilder('Signed in with user ID', 'signedInWithUserId'),
  source: dimensionBuilder('Source', 'source'),
  sourceMedium: dimensionBuilder('Source / medium', 'sourceMedium'),
  sourcePlatform: dimensionBuilder('Source platform', 'sourcePlatform'),
  streamId: dimensionBuilder('Stream ID', 'streamId'),
  streamName: dimensionBuilder('Stream name', 'streamName'),
  testDataFilterName: dimensionBuilder('Test data filter name', 'testDataFilterName'),
  transactionId: dimensionBuilder('Transaction ID', 'transactionId'),
  unifiedPagePathScreen: dimensionBuilder('Page path and screen class', 'unifiedPagePathScreen'),
  unifiedPageScreen: dimensionBuilder('Page path + query string and screen class', 'unifiedPageScreen'),
  unifiedScreenClass: dimensionBuilder('Page title and screen class', 'unifiedScreenClass'),
  unifiedScreenName: dimensionBuilder('Page title and screen name', 'unifiedScreenName'),
  userAgeBracket: dimensionBuilder('Age', 'userAgeBracket'),
  userGender: dimensionBuilder('Gender', 'userGender'),
  videoProvider: dimensionBuilder('Video provider', 'videoProvider'),
  videoTitle: dimensionBuilder('Video title', 'videoTitle'),
  videoUrl: dimensionBuilder('Video URL', 'videoUrl'),
  virtualCurrencyName: dimensionBuilder('Virtual currency name', 'virtualCurrencyName'),
  visible: dimensionBuilder('Visible', 'visible'),
  week: dimensionBuilder('Week', 'week'),
  year: dimensionBuilder('Year', 'year')
}

const METRICS = {
  active1DayUsers: metricBuilder('1-day active users', 'active1DayUsers'),
  active28DayUsers: metricBuilder('28-day active users', 'active28DayUsers'),
  active7DayUsers: metricBuilder('7-day active users', 'active7DayUsers'),
  activeUsers: metricBuilder('Active users', 'activeUsers'),
  adUnitExposure: metricBuilder('Ad unit exposure', 'adUnitExposure'),
  addToCarts: metricBuilder('Add-to-carts', 'addToCarts'),
  averagePurchaseRevenue: metricBuilder('Average purchase revenue', 'averagePurchaseRevenue'),
  averagePurchaseRevenuePerPayingUser: metricBuilder('ARPPU', 'averagePurchaseRevenuePerPayingUser'),
  averagePurchaseRevenuePerUser: metricBuilder('Average purchase revenue per user', 'averagePurchaseRevenuePerUser'),
  averageRevenuePerUser: metricBuilder('ARPU', 'averageRevenuePerUser'),
  averageSessionDuration: metricBuilder('Average session duration', 'averageSessionDuration'),
  bounceRate: metricBuilder('Bounce rate', 'bounceRate'),
  cartToViewRate: metricBuilder('Cart-to-view rate', 'cartToViewRate'),
  checkouts: metricBuilder('Checkouts', 'checkouts'),
  // cohortActiveUsers: metricBuilder('Cohort active users', 'cohortActiveUsers'),
  // cohortTotalUsers: metricBuilder('Cohort total users', 'cohortTotalUsers'),
  conversions: metricBuilder('Conversions', 'conversions'),
  crashAffectedUsers: metricBuilder('Crash-affected users', 'crashAffectedUsers'),
  crashFreeUsersRate: metricBuilder('Crash-free users rate', 'crashFreeUsersRate'),
  dauPerMau: metricBuilder('DAU / MAU', 'dauPerMau'),
  dauPerWau: metricBuilder('DAU / WAU', 'dauPerWau'),
  ecommercePurchases: metricBuilder('Ecommerce purchases', 'ecommercePurchases'),
  engagedSessions: metricBuilder('Engaged sessions', 'engagedSessions'),
  engagementRate: metricBuilder('Engagement rate', 'engagementRate'),
  eventCount: metricBuilder('Event count', 'eventCount'),
  eventCountPerUser: metricBuilder('Event count per user', 'eventCountPerUser'),
  eventValue: metricBuilder('Event value', 'eventValue'),
  eventsPerSession: metricBuilder('Events per session', 'eventsPerSession'),
  firstTimePurchaserConversionRate: metricBuilder(
    'First-time purchaser conversion',
    'firstTimePurchaserConversionRate'
  ),
  firstTimePurchasers: metricBuilder('First time purchasers', 'firstTimePurchasers'),
  firstTimePurchasersPerNewUser: metricBuilder('First-time purchasers per new user', 'firstTimePurchasersPerNewUser'),
  itemListClickThroughRate: metricBuilder('Item list click through rate', 'itemListClickThroughRate'),
  itemListClicks: metricBuilder('Item list clicks', 'itemListClicks'),
  itemListViews: metricBuilder('Item list views', 'itemListViews'),
  itemPromotionClickThroughRate: metricBuilder('Item promotion click through rate', 'itemPromotionClickThroughRate'),
  itemPromotionClicks: metricBuilder('Item promotion clicks', 'itemPromotionClicks'),
  itemPromotionViews: metricBuilder('Item promotion views', 'itemPromotionViews'),
  itemPurchaseQuantity: metricBuilder('Item purchase quantity', 'itemPurchaseQuantity'),
  itemRevenue: metricBuilder('Item revenue', 'itemRevenue'),
  itemViews: metricBuilder('Item views', 'itemViews'),
  newUsers: metricBuilder('New users', 'newUsers'),
  organicGoogleSearchAveragePosition: metricBuilder(
    'Organic google search average position',
    'organicGoogleSearchAveragePosition'
  ),
  organicGoogleSearchClickThroughRate: metricBuilder(
    'Organic google search click through rate',
    'organicGoogleSearchClickThroughRate'
  ),
  organicGoogleSearchClicks: metricBuilder('Organic google search clicks', 'organicGoogleSearchClicks'),
  organicGoogleSearchImpressions: metricBuilder('Organic google search impressions', 'organicGoogleSearchImpressions'),
  publisherAdClicks: metricBuilder('Publisher ad clicks', 'publisherAdClicks'),
  publisherAdImpressions: metricBuilder('Publisher ad impressions', 'publisherAdImpressions'),
  purchaseRevenue: metricBuilder('Purchase revenue', 'purchaseRevenue'),
  purchaseToViewRate: metricBuilder('Purchase-to-view rate', 'purchaseToViewRate'),
  purchaserConversionRate: metricBuilder('Purchaser conversion', 'purchaserConversionRate'),
  screenPageViews: metricBuilder('Views', 'screenPageViews'),
  screenPageViewsPerSession: metricBuilder('Views per session', 'screenPageViewsPerSession'),
  sessionConversionRate: metricBuilder('Session conversion rate', 'sessionConversionRate'),
  sessions: metricBuilder('Sessions', 'sessions'),
  sessionsPerUser: metricBuilder('Sessions per user', 'sessionsPerUser'),
  shippingAmount: metricBuilder('Shipping amount', 'shippingAmount'),
  taxAmount: metricBuilder('Tax amount', 'taxAmount'),
  totalAdRevenue: metricBuilder('Total ad revenue', 'totalAdRevenue'),
  totalPurchasers: metricBuilder('Total purchasers', 'totalPurchasers'),
  totalRevenue: metricBuilder('Total revenue', 'totalRevenue'),
  totalUsers: metricBuilder('Total users', 'totalUsers'),
  transactions: metricBuilder('Transactions', 'transactions'),
  transactionsPerPurchaser: metricBuilder('Transactions per purchaser', 'transactionsPerPurchaser'),
  userConversionRate: metricBuilder('User conversion rate', 'userConversionRate'),
  userEngagementDuration: metricBuilder('User engagement', 'userEngagementDuration'),
  wauPerMau: metricBuilder('WAU / MAU', 'wauPerMau')
}

export const GOOGLE_ANALYTICS_V4 = {
  dimensions: DIMENSIONS,
  metrics: METRICS
}

export default GOOGLE_ANALYTICS_V4
