import { Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import OrganizationForm from './components/organizationForm'

import SessionHeader from '@app/pages/sessions/components/sessionHeader'

const Organization: FC = () => (
  <Stack gap="8" w="full" maxW="md" px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }}>
    <SessionHeader title="Setup your organization" />
    <OrganizationForm />
  </Stack>
)

export default Organization
