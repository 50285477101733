import type { Dialog } from '@chakra-ui/react'
import { Stack, Text } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { useRef } from 'react'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'

interface Props
  extends Partial<Pick<Dialog.RootProps, 'open' | 'onOpenChange'> & { success?: boolean; status?: string }> {}

const runningText = (
  <Stack gap={2}>
    <Text>
      Your metric source run has been scheduled and will run soon. We will also pull in any historical data if
      available.
    </Text>

    <Text>This may take a few minutes to complete.</Text>
  </Stack>
)

const skippedText = (
  <Stack gap={2}>
    <Text>Your metric source run has been skipped because another import is already running.</Text>

    <Text>Please check back in a few minutes.</Text>
  </Stack>
)

const failedText = (
  <Stack gap={2}>
    <Text>There was an error running your metric source.</Text>

    <Text>Please use the error information below or contact the DoubleLoop team for assistance.</Text>
  </Stack>
)

const MetricSourceRunResults: FC<Props> = ({ open = false, onOpenChange = () => {}, success = false, status }) => {
  const closeRef = useRef()
  let bodyText = failedText

  if (success) {
    bodyText = runningText
  } else if (status === 'skipped') {
    bodyText = skippedText
  }

  return (
    <DialogRoot
      initialFocusEl={closeRef?.current}
      onOpenChange={onOpenChange}
      open={open}
      placement="center"
      role="alertdialog"
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Run {capitalize(status)}</DialogTitle>
        </DialogHeader>

        <DialogBody>{bodyText}</DialogBody>

        <DialogFooter>
          <DialogActionTrigger asChild>
            <Button ref={closeRef}>Close</Button>
          </DialogActionTrigger>
        </DialogFooter>
        <DialogCloseTrigger />
      </DialogContent>
    </DialogRoot>
  )
}

export default MetricSourceRunResults
