import { Stack } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import { createContext, useMemo, useRef } from 'react'

import { useDrawerContext } from '@app/pages/maps/drawerContext'
import Drawer from '@app/shared/drawer/drawer'

export const ReportContext = createContext({ containerRef: null })

const ReportsDrawer: FC<PropsWithChildren> = ({ children }) => {
  const { onDrawerClose } = useDrawerContext()
  const containerRef = useRef(null)
  const context = useMemo(() => ({ containerRef }), [containerRef])

  return (
    <Drawer onOpenChange={onDrawerClose} open>
      <ReportContext.Provider value={context}>
        <Stack as="section" gap={0} overflow="auto" h="100%" id="lazyload-container">
          {children}
        </Stack>
      </ReportContext.Provider>
    </Drawer>
  )
}

export default ReportsDrawer
