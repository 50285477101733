import { Badge, Box, Text, Flex, Table, HStack, Stack, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { Fragment } from 'react'

import { useAiAssistantEvalResultsQuery } from '@graphql/queries'

const MonoBadge: FC<{ text: string; colorScheme: string; isLast?: boolean }> = ({ text, colorScheme, isLast }) => (
  <Badge mr={isLast ? 0 : 1} fontFamily="mono" fontSize="xs" textTransform="lowercase" colorScheme={colorScheme}>
    {text}
  </Badge>
)

interface MetricValue {
  value: number
  retryPercent?: number
  eval: string
}

interface AssistantData {
  name: string
  eval: {
    retryBelow: number | null
  }
  rows: Array<{
    assistant: string
    model: string
    values: MetricValue[]
  }>
}

interface GroupedData {
  name: string
  eval: {
    retryBelow: number | null
  }
  rows: Record<
    string,
    {
      assistant: string
      model: string
      values: MetricValue[]
    }
  >
}

const calculateChangeType = (current: number, previous: number | null): 'up' | 'down' | undefined => {
  if (previous === null) {
    return undefined
  }
  if (current > previous) {
    return 'up'
  }
  if (current < previous) {
    return 'down'
  }
  return undefined
}

const transformData = (results): AssistantData[] => {
  const groupedByAssistant = results.reduce(
    (acc, result) => {
      const { assistantName } = result
      if (!acc[assistantName]) {
        acc[assistantName] = {
          name: assistantName,
          eval: result.eval,
          rows: {}
        }
      }

      if (!acc[assistantName].rows[result.assistantFingerprint]) {
        acc[assistantName].rows[result.assistantFingerprint] = {
          assistant: result.assistantFingerprint,
          model: result.model,
          values: []
        }
      }

      const relevantResults = results.filter(
        (r) => r.assistantFingerprint === result.assistantFingerprint && r.eval.name === result.eval.name
      )

      const avgScore = Math.round(relevantResults.reduce((sum, r) => sum + r.score, 0) / relevantResults.length)

      const retryPercent = Math.round(
        (relevantResults.filter((r) => r.processedState === 'triggered_retry').length / relevantResults.length) * 100
      )

      if (!acc[assistantName].rows[result.assistantFingerprint].values.find((v) => v.eval === result.eval.name)) {
        acc[assistantName].rows[result.assistantFingerprint].values.push({
          value: avgScore,
          retryPercent,
          eval: result.eval.name
        })
      }

      return acc
    },
    {} as Record<string, GroupedData>
  )

  return Object.values(groupedByAssistant).map((group) => ({
    // @ts-expect-error needs proper type definition
    name: group.name,
    // @ts-expect-error needs proper type definition
    eval: group.eval,
    // @ts-expect-error needs proper type definition
    rows: Object.values(group.rows)
  }))
}

const getMetrics = (section: AssistantData) => {
  const allEvals = new Set<string>()
  section.rows.forEach((row) => {
    row.values.forEach((value) => {
      allEvals.add(value.eval)
    })
  })
  return Array.from(allEvals)
}

const calculateMaxMetrics = (data: AssistantData[]) =>
  Math.max(...data.flatMap((section) => section.rows.map((row) => row.values.length)))

const calculateTotalDataColumns = (metricsCount: number) => metricsCount * 2

const AssistantHeader: FC<{ name: string; totalColumns: number }> = ({ name, totalColumns }) => (
  <Table.Row>
    <Table.ColumnHeader px={4} py={6} colSpan={totalColumns + 1}>
      <Stack gap="2">
        <Stack alignItems="center" direction={{ base: 'column', md: 'row' }} gap="3">
          <HStack>
            <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="medium" wordBreak="break-word">
              {name}
            </Text>
          </HStack>
          <Spacer />
        </Stack>
      </Stack>
    </Table.ColumnHeader>
  </Table.Row>
)

const TableHeader: FC<{ metrics: string[]; maxMetrics: number; columnWidth: string }> = ({
  metrics,
  maxMetrics,
  columnWidth
}) => (
  <>
    <Table.Row>
      <Table.Header w="20%" />
      {metrics.map((metric) => (
        <Table.ColumnHeader key={metric} textAlign="center" colSpan={2}>
          {metric}
        </Table.ColumnHeader>
      ))}
      {Array(maxMetrics - metrics.length)
        .fill(null)
        .map((_, _idx) => (
          <Table.ColumnHeader key={`empty-${Math.random()}`} colSpan={2} />
        ))}
    </Table.Row>
    <Table.Row>
      <Table.ColumnHeader w="20%" rowSpan={1}>
        <MonoBadge text="assistant" colorScheme="purple" />
        <MonoBadge text="model" colorScheme="blue" isLast />
      </Table.ColumnHeader>
      {metrics.map((_, _idx) => (
        <Fragment key={`metric-${Math.random()}`}>
          <Table.ColumnHeader w={columnWidth} textAlign="center">
            Score
          </Table.ColumnHeader>
          <Table.ColumnHeader w={columnWidth} textAlign="center">
            Retries
          </Table.ColumnHeader>
        </Fragment>
      ))}
      {Array(maxMetrics - metrics.length)
        .fill(null)
        .map((_, _idx) => (
          <Fragment key={`empty-${Math.random()}`}>
            <Table.Header w={columnWidth} textAlign="center" />
            <Table.Header w={columnWidth} textAlign="center" />
          </Fragment>
        ))}
    </Table.Row>
  </>
)

const TableRow: FC<{
  row: {
    assistant: string
    model: string
    values: MetricValue[]
  }
  metricsCount: number
  columnWidth: string
  previousValues?: MetricValue[]
}> = ({ row, metricsCount, columnWidth, previousValues }) => (
  <Table.Row>
    <Table.Cell w="20%">
      <MonoBadge text={row.assistant} colorScheme="purple" />
      <MonoBadge text={row.model} colorScheme="blue" isLast />
    </Table.Cell>
    {row.values.map((value, valueIdx) => {
      const previousValue = previousValues ? previousValues[valueIdx]?.value : null
      const previousRetry = previousValues ? previousValues[valueIdx]?.retryPercent : null
      const scoreChange = calculateChangeType(value.value, previousValue)
      const retryChange = calculateChangeType(value.retryPercent || 0, previousRetry)

      return (
        <Fragment key={`row-${Math.random()}`}>
          <Table.Cell w={columnWidth} textAlign="center">
            {value.value}%
            {scoreChange && (
              <Text as="span" color={scoreChange === 'up' ? 'green.500' : 'red.500'}>
                {scoreChange === 'up' ? '↑' : '↓'}
              </Text>
            )}
          </Table.Cell>
          <Table.Cell w={columnWidth} textAlign="center">
            {value.retryPercent}%
            {retryChange && (
              <Text as="span" color={retryChange === 'up' ? 'red.500' : 'green.500'}>
                {retryChange === 'up' ? '↑' : '↓'}
              </Text>
            )}
          </Table.Cell>
        </Fragment>
      )
    })}
    {Array.from({ length: Math.max(0, metricsCount - row.values.length) }).map((_, _idx) => (
      <Fragment key={`empty-${Math.random()}`}>
        <Table.Cell w={columnWidth} textAlign="center">
          -
        </Table.Cell>
        <Table.Cell w={columnWidth} textAlign="center">
          -
        </Table.Cell>
      </Fragment>
    ))}
  </Table.Row>
)

const PerformanceMetrics: FC = () => {
  const [result] = useAiAssistantEvalResultsQuery({
    variables: {
      states: ['complete', 'triggered_retry']
    }
  })

  const { data: queryData, fetching, error } = result

  if (fetching) {
    return (
      <Box overflow="hidden" p={4} bg="white" borderRadius="lg">
        <Text>Loading...</Text>
      </Box>
    )
  }

  if (error || !queryData?.aiAssistantEvalResults) {
    return (
      <Box overflow="hidden" p={4} bg="white" borderRadius="lg">
        <Text>Error loading metrics</Text>
      </Box>
    )
  }

  const transformedData = transformData(queryData.aiAssistantEvalResults)
  const maxMetricsCount = calculateMaxMetrics(transformedData)
  const totalDataColumns = calculateTotalDataColumns(maxMetricsCount)
  const columnWidth = `${80 / totalDataColumns}%`

  return (
    <Box overflow="hidden" bg="white" borderRadius="lg">
      <Box p={4} borderBottomWidth={1}>
        <Flex align="center" justify="space-between">
          <Box>
            <Text fontSize="lg" fontWeight="medium">
              Performance Metrics
            </Text>
            <Text color="gray.600" fontSize="sm">
              Version comparison across AI assistants
            </Text>
          </Box>
        </Flex>
      </Box>

      <Box p={4}>
        {transformedData.map((section, idx) => (
          <Box key={section.name} overflowX="auto" mb={idx < transformedData.length - 1 ? 8 : 0}>
            <Table.Root>
              <Table.Header>
                <AssistantHeader name={section.name} totalColumns={totalDataColumns} />
                <TableHeader metrics={getMetrics(section)} maxMetrics={maxMetricsCount} columnWidth={columnWidth} />
              </Table.Header>
              <Table.Body>
                {section.rows.map((row, rowIdx) => (
                  <TableRow
                    key={`table-row-section-${section.name}-row-${row.assistant}`}
                    row={row}
                    metricsCount={maxMetricsCount}
                    columnWidth={columnWidth}
                    previousValues={rowIdx > 0 ? section.rows[rowIdx - 1].values : undefined}
                  />
                ))}
              </Table.Body>
            </Table.Root>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default PerformanceMetrics
