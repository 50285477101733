import type { MenuItemProps } from '@chakra-ui/react'
import { Link as ChakraLink } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { MenuItem } from '@app/components/ui/menu'

interface SidebarMenuItemProps extends Omit<MenuItemProps, 'value'> {
  icon: ReactNode
  title: string
  href: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  isExternal?: boolean
}

const SidebarMenuItem = ({ title, icon, href, target = '_self', isExternal = false }: SidebarMenuItemProps) => {
  if (isExternal) {
    return (
      <MenuItem value={title} rounded="md" asChild>
        <ChakraLink href={href} rel="onoopener noreferrer" target={target}>
          {icon} {title}
        </ChakraLink>
      </MenuItem>
    )
  }
  return (
    <MenuItem value={title} rounded="md" asChild>
      <Link to={href}>
        {icon} {title}
      </Link>
    </MenuItem>
  )
}

export default SidebarMenuItem
