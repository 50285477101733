import type { IconButtonProps } from '@chakra-ui/react'
import { IconButton, VStack } from '@chakra-ui/react'
import type { FC } from 'react'

import { Tooltip } from '@app/components/ui/tooltip'
import { useDrawerContext } from '@app/pages/maps/drawerContext'
import ICON_MAP from '@app/utils/iconMap'

const DrawerCloseButton: FC<Omit<IconButtonProps, 'aria-label'>> = ({ ...rest }) => {
  const { onDrawerClose } = useDrawerContext()

  return (
    <Tooltip
      content={
        <VStack gap={0}>
          <div>Close</div>
          <div>(esc)</div>
        </VStack>
      }
      showArrow
    >
      <IconButton aria-label="close drawer" colorPalette="gray" onClick={onDrawerClose} {...rest}>
        <ICON_MAP.Close />
      </IconButton>
    </Tooltip>
  )
}

export default DrawerCloseButton
