import { Box, IconButton, Link, HStack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router-dom'

import {
  DialogBody,
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot
} from '@app/components/ui/dialog'
import { Tooltip } from '@app/components/ui/tooltip'

const PreviewModal: FC = () => {
  const { submapId: strategyId } = useParams()
  const navigate = useNavigate()

  const handleClose = () => {
    navigate('..')
  }

  return (
    <DialogRoot onOpenChange={handleClose} open placement="center" size="xl">
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader px={6} py={4}>
          <HStack>
            <Text fontSize="sm">Strategy Preview</Text>
            <Tooltip content="Go to map">
              <IconButton aria-label="Go to map" asChild colorPalette="gray" size="2xs" variant="ghost">
                <Link href={`/strategy/${strategyId}`} rel="onoopener noreferrer" target="_blank">
                  <FiExternalLink />
                </Link>
              </IconButton>
            </Tooltip>
          </HStack>
        </DialogHeader>

        <DialogCloseTrigger />

        <DialogBody p={0} borderTopWidth="1px" borderTopStyle="solid" borderTopColor="gray.300">
          <Box>
            <iframe title="submap strategy" src={`/strategy/${strategyId}/map`} width="100%" height="600px" />
          </Box>
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  )
}

export default memo(PreviewModal)
