import type { TableRowProps } from '@chakra-ui/react'
import { useCallback } from 'react'

import useGetObjectPage from '@app/hooks/useGetObjectPage'
import type { BoundedState } from '@app/store/types'

const useGenerateSelectableTableRowFormatter = <T extends { id: string } = { id: string }>(
  collectionType: keyof BoundedState['page'],
  selected: (string | number)[],
  setSelected: (selected: (string | number)[]) => void,
  additionalProps?: Partial<TableRowProps>
): ((object: T) => Partial<TableRowProps>) => {
  const { collection } = useGetObjectPage(collectionType)

  return useCallback(
    (object: T) => {
      const onClick = (e) => {
        if (e.shiftKey) {
          const clickedIndex = collection.findIndex((mdo) => mdo.id === object.id)

          const selectedSet = new Set(selected)
          const orderedSelected = collection.filter((mdo) => selectedSet.has(mdo.id)).map((mdo) => mdo.id)
          const lastSelected = orderedSelected[orderedSelected.length - 1]

          let lastSelectedIndex: number
          if (lastSelected) {
            lastSelectedIndex = collection.findIndex((mdo) => mdo.id === lastSelected)
          } else {
            lastSelectedIndex = 0
          }

          const newSelected = collection
            .slice(Math.min(lastSelectedIndex, clickedIndex), Math.max(lastSelectedIndex, clickedIndex) + 1)
            .map((mdo) => mdo.id)
          setSelected(Array.from(new Set([...orderedSelected, ...newSelected])))
        } else if (selected.includes(object.id)) {
          setSelected(selected.filter((selectedId) => selectedId !== object.id))
        } else {
          setSelected([...selected, object.id])
        }
      }

      const base = {
        onClick,
        ...(additionalProps || {})
      }

      if (selected.includes(object.id)) {
        return {
          variant: 'selected',
          ...base
        }
      }

      return base
    },
    [additionalProps, collection, selected, setSelected]
  )
}

export default useGenerateSelectableTableRowFormatter
