import type { FC } from 'react'
import { Link } from 'react-router-dom'

import type { ButtonProps } from '@app/components/ui/button'
import { Button } from '@app/components/ui/button'
import type { RecurringReportConfigurationServiceEnum } from '@graphql/types'

interface Props extends ButtonProps {
  strategyId: string
  reportType: RecurringReportConfigurationServiceEnum
}

const SendReportButton: FC<Props> = ({ strategyId, reportType, ...rest }) => {
  const link = `/strategy/${strategyId}/map/reports/recurring/create`

  return (
    <Button asChild size="xs" variant="outline" {...rest}>
      <Link to={link} state={{ reportType }}>
        Send recurring report
      </Link>
    </Button>
  )
}

export default SendReportButton
