import { Stack } from '@chakra-ui/react'
import type { ReactNode, FC } from 'react'

interface PageHeaderWrapperProps {
  children?: ReactNode
}

const PageHeaderSection: FC<PageHeaderWrapperProps> = ({ children }) => <Stack gap={4}>{children}</Stack>

export default PageHeaderSection
