import { Box, Container, useBreakpointValue } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { useColorModeValue } from '@app/components/ui/color-mode'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Navbar from '@app/shared/layout/shells/components/navbar'
import Sidebar from '@app/shared/layout/shells/components/sidebar'
import GraphqlSubscriptionShell from '@app/shared/layout/shells/graphqlSubscriptionShell'
import MultiAccountBanner from '@app/shared/multiAccountBanner'

interface AppShellProps {
  children?: ReactNode
}

const AppShell = ({ children }: AppShellProps) => {
  const { user } = useStoreCurrentUser()
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false })

  let nav = <Navbar />

  if (isDesktop) {
    nav = <Sidebar />
  }

  const sidebarWidthDesktopWidth = '280px'
  const sidebarWidth = isDesktop ? sidebarWidthDesktopWidth : '100%'
  const sidebarHeight = isDesktop ? '100vh' : 'auto'

  return (
    <GraphqlSubscriptionShell strategyId={null}>
      <Box minH="100vh">
        {/* Navigation */}
        <Box
          pos={isDesktop ? 'fixed' : 'relative'}
          zIndex={1}
          top={0}
          left={0}
          overflow="hidden"
          w={sidebarWidth}
          h={sidebarHeight}
          shadow={useColorModeValue('xs', 'xs-dark')}
        >
          {user && nav}
        </Box>

        {/* Main Content */}
        <Box overflow="auto" minH="100vh" ml={isDesktop ? sidebarWidthDesktopWidth : 0} bg="bg.canvas">
          <MultiAccountBanner />
          <Container>{children || <Outlet />}</Container>
        </Box>
      </Box>
    </GraphqlSubscriptionShell>
  )
}

export default AppShell
