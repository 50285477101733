import { Paywall as StiggPaywall, useStiggContext } from '@stigg/react-sdk'
import type { FC } from 'react'

import { actionMutation } from '@graphql/client'
import { BillingProvisionSubscription } from '@graphql/documents/billing.graphql'

interface Props {
  onProvision?: () => void
}

const Paywall: FC<Props> = ({ onProvision = () => {} }) => {
  const { refreshData } = useStiggContext()

  const provisionSubscription = async (input) => actionMutation(BillingProvisionSubscription, input)

  const onSubscribe = async (args) => {
    const { customer, plan, selectedBillingPeriod } = args

    if (plan.id === 'plan-double-loop-scale') {
      window.open('https://calendly.com/doubleloop/onboarding', '_blank').focus()

      return
    }

    if (customer?.id) {
      const response = await provisionSubscription({
        planId: plan.id,
        billingPeriod: selectedBillingPeriod,
        priceUnitAmount: args?.plan?.metadata?.minEditorQuantity
          ? parseFloat(args?.plan?.metadata?.minEditorQuantity)
          : null,
        successUrl: `${window.location.origin}/settings/billing`,
        cancelUrl: `${window.location.origin}/settings/billing`
      })

      if (response?.data?.billingProvisionSubscription?.response?.status === 'PAYMENT_REQUIRED') {
        window.location.href = response.data.billingProvisionSubscription.response?.checkoutUrl
      }

      refreshData()
      onProvision()
    }
  }

  return <StiggPaywall highlightedPlanId="plan-double-loop-north-star" onPlanSelected={onSubscribe} />
}

export default Paywall
