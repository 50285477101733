import type { NativeSelect } from '@chakra-ui/react'
import type { FC } from 'react'

import { NativeSelectRoot, NativeSelectField } from '@app/components/ui/native-select'
import { SegmentedDataTypeEnum } from '@graphql/types'

type Props = Omit<NativeSelect.FieldProps, 'children'>
const MetricSegmentedDataTypeSelect: FC<Props> = (props) => (
  <NativeSelectRoot>
    <NativeSelectField {...props}>
      <option value={SegmentedDataTypeEnum.Sum}>Sum</option>
      <option value={SegmentedDataTypeEnum.Average}>Average</option>
    </NativeSelectField>
  </NativeSelectRoot>
)

export default MetricSegmentedDataTypeSelect
