import { Input, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import CSRFInput from '@app/next/forms/csrfInput'
import FormAlert from '@app/next/forms/formAlert'
import useForm from '@app/next/forms/useForm'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'

const SamlOrganization: FC = () => {
  const { ssoIdentifier: ssoId } = useParams()
  const [ssoIdentifier, setSsoIdentifier] = useState(ssoId || '')
  const { errors } = useForm({})

  const onChange = (event) => setSsoIdentifier(event.target.value)

  return (
    <Stack gap="8" w="full" maxW="md" px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }}>
      <SessionHeader
        title="Sign in with SSO"
        subtitle="Using email and password instead?"
        linkText="Sign in"
        linkUrl="/sign_in"
      />

      <Stack gap="6">
        <FormAlert description={errors?.global?.message} />
        <form id="sso-form" action={`/auth/saml/${ssoIdentifier}`} method="post">
          <Stack gap="5">
            <Field
              label="Organization SSO identifier"
              id="sso_identifier"
              errorText={errors?.ssoIdentifier?.message}
              invalid={!!errors?.ssoIdentifier}
              required
            >
              <Input
                defaultValue={ssoIdentifier}
                name="sso_identifier"
                onChange={onChange}
                placeholder="Organization SSO Identifier"
              />
            </Field>
            <CSRFInput />
          </Stack>
        </form>
        <Button form="sso-form" size="md" type="submit">
          Continue
        </Button>
      </Stack>
    </Stack>
  )
}

export default SamlOrganization
