import { Box, HStack } from '@chakra-ui/react'
import { Panel } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'

import AutoLayoutButton from './nav/autoLayoutButton'
import ButtonWrapper from './nav/buttonWrapper'

import { Tooltip } from '@app/components/ui/tooltip'
import AiChatMenu from '@app/pages/maps/components/aiChat/components/aiChatMenu'
import CopyMapToDoubleLoopButton from '@app/pages/maps/components/map/copyMapToDoubleLoopButton'
import MapCardFilters from '@app/pages/maps/components/map/filters/cardFilters/mapCardFilters'
import MapDateFilters from '@app/pages/maps/components/map/filters/dateFilters/mapDateFilters'
import MapSegmentFilters from '@app/pages/maps/components/map/filters/segmentFilters/mapSegmentFilters'
import MapExpandAllNodes from '@app/pages/maps/components/map/mapExpandAllNodes'
import ZoomButtons from '@app/pages/maps/components/map/nav/zoomButtons'
import ToggleMetricData from '@app/pages/maps/components/map/toggleMetricData'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import Can from '@app/shared/authorization/can'

type Props = {
  strategyId: string
}
const MapFiltering: FC<Props> = ({ strategyId }) => {
  const { canEdit } = usePermissionsContext()

  return (
    <Panel position="bottom-right">
      <ButtonWrapper>
        <HStack px={1} py={1}>
          <Tooltip content="Auto Layout" showArrow>
            <Box>
              <AutoLayoutButton strategyId={strategyId} />
            </Box>
          </Tooltip>
          <Tooltip content="AI Assistants" showArrow>
            <Box>
              <AiChatMenu strategyId={strategyId} />
            </Box>
          </Tooltip>
          <Can I="view" an="admin">
            <Tooltip content="Copy map to DoubleLoop Organization" showArrow>
              {/* the wrapping tooltip in addCardMenu.tsx is sending a silent onClick */}
              {/* prop in the ...props spread, so we have to override that */}
              <Box>
                <CopyMapToDoubleLoopButton strategyId={strategyId} />
              </Box>
            </Tooltip>
          </Can>
          <MapSegmentFilters strategyId={strategyId} canEdit={canEdit} />
          <MapCardFilters canEdit={canEdit} />
          <MapExpandAllNodes />
          <ToggleMetricData strategyId={strategyId} />
          <MapDateFilters strategyId={strategyId} canEdit={canEdit} />
          <ZoomButtons />
        </HStack>
      </ButtonWrapper>
    </Panel>
  )
}

MapFiltering.displayName = 'MapFiltering'

export default memo(MapFiltering)
