import { Box, IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useMemo } from 'react'

import { Tooltip, type TooltipProps } from '@app/components/ui/tooltip'
import useGetSelectedDomainObjects from '@app/hooks/useGetSelectedDomainObjects'
import type { BulkLabelableObject } from '@app/pages/maps/components/nodes/components/toolbar/components/bulkLabels/bulkLabelsPopover'
import BulkLabelsPopover from '@app/pages/maps/components/nodes/components/toolbar/components/bulkLabels/bulkLabelsPopover'
import type { MapDomainObject } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

const labelableTypes = new Set(['entity', 'metric', 'strategy'])
const isLabelable = (object: Pick<MapDomainObject, 'classType'>): object is BulkLabelableObject =>
  labelableTypes.has(object?.classType)

type Props = Partial<TooltipProps> & {
  strategyId: string
}

const BulkLabelsButton: FC<Props> = ({ strategyId, ...tooltipProps }) => {
  const objects = useGetSelectedDomainObjects(strategyId)
  const domainObjects = useMemo(() => objects.filter(isLabelable) as BulkLabelableObject[], [objects])

  const disabled = domainObjects.length === 0

  return (
    <BulkLabelsPopover domainObjects={domainObjects}>
      <Box>
        <Tooltip content="Label" {...tooltipProps}>
          <IconButton borderRadius="none" aria-label="label-button" disabled={disabled} size="sm" variant="outline">
            <ICON_MAP.LabelAction />
          </IconButton>
        </Tooltip>
      </Box>
    </BulkLabelsPopover>
  )
}

export default BulkLabelsButton
