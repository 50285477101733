import { Icon } from '@chakra-ui/react'
import { FiCode } from 'react-icons/fi'

import blockButtonMaker from './blockButtonMaker'

const CodeBlockButton = blockButtonMaker({
  blockType: 'code-block',
  children: (
    <Icon boxSize={6}>
      <FiCode />
    </Icon>
  )
})

export default CodeBlockButton
