import type Uppy from '@uppy/core'
import type { UIPluginOptions } from '@uppy/core'
import { UIPlugin } from '@uppy/core'
import type { OperationResult } from 'urql'

import type { FileTicketMeta, RemoteFile } from '@app/shared/uploader/types'
import type { UploadTicketGenerateMutation, UploadTicketGenerateMutationVariables } from '@graphql/queries'

type Options = UIPluginOptions & {
  getTicket: (vars: UploadTicketGenerateMutationVariables) => Promise<OperationResult<UploadTicketGenerateMutation>>
}

class GenerateUploaderTicketPlugin extends UIPlugin<Options, FileTicketMeta, RemoteFile> {
  getTicket: Options['getTicket']

  constructor(uppy: Uppy<FileTicketMeta, RemoteFile>, opts?: Options) {
    super(uppy, opts)

    this.uppy = uppy
    this.id = 'GenerateUploaderTicketPlugin'
    this.type = 'authenticator'
    this.getTicket = opts.getTicket
  }

  generateTickets = (fileIds: string[]): Promise<void> => {
    const { uppy } = this

    const promises = fileIds.map(async (fileId) => {
      const file = uppy.getFile(fileId)
      uppy.emit('preprocess-progress', file, {
        mode: 'indeterminate',
        message: 'Getting upload ticket…'
      })

      const ticketResult = await this.getTicket({ input: {} })
      const ticket = ticketResult.data?.uploadTicketGenerate?.ticket

      if (!ticket) {
        uppy.log(
          `[GenerateUploaderTicketPlugin] Failed to retrieve ticket for ${file.id}: ${ticketResult.error.message}`,
          'warning'
        )
        return
      }

      uppy.setFileMeta(file.id, { ticket })
    })

    const emitPreprocessCompleteForAll = () => {
      fileIds.forEach((fileId) => {
        const file = this.uppy.getFile(fileId)
        this.uppy.emit('preprocess-complete', file)
      })
    }

    // Emit `preprocess-complete` for all files at once, to avoid
    // weird interim states in the UI.
    return Promise.all(promises).then(emitPreprocessCompleteForAll)
  }

  install() {
    this.uppy.addPreProcessor(this.generateTickets)
  }

  uninstall() {
    this.uppy.removePreProcessor(this.generateTickets)
  }
}

export default GenerateUploaderTicketPlugin
