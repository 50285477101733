import { useEffect } from 'react'

type Identifier = {
  channel: string
  [key: string]: string | number
}

type OnMessage<T, U extends string> = {
  (message: { data: T; event: U }): void
}

const useEventSource = <T = object, U extends string = string>(
  identifier: Identifier,
  onMessage: OnMessage<T, U>
): void => {
  useEffect(() => {
    const url = `${import.meta.env.PUBLIC_ANYCABLE_WS_HOST || 'http://localhost:8080'}/events`
    let source: EventSource | null = null

    try {
      source = new EventSource(`${url}?identifier=${encodeURIComponent(JSON.stringify(identifier))}`, {
        withCredentials: true
      })
      source.onmessage = (e) => {
        const data = JSON.parse(e.data)
        onMessage(data)
      }
      source.onerror = () => {
        source?.close()
      }

      return () => {
        source.close()
      }
    } catch (error) {
      source?.close()
      return () => {}
    }
  }, [onMessage, identifier])
}

export default useEventSource
