import type { StackProps } from '@chakra-ui/react'
import { Group, HStack, Spacer, Text, useBreakpointValue } from '@chakra-ui/react'
import queryString from 'query-string'
import type { FC, ReactElement } from 'react'
import { PiArrowLeft, PiArrowRight } from 'react-icons/pi'
import { useLocation, useSearchParams } from 'react-router-dom'

import { Button } from '@app/components/ui/button'

export const usePage = () => {
  const [searchParams] = useSearchParams()

  return parseInt(searchParams.get('page'), 10) || 1
}

export const paginationSummary = (page, limitValue, totalCount) => {
  const start = page * limitValue - (limitValue - 1)
  const end = Math.min(start + limitValue - 1, totalCount)
  const summary = totalCount === 0 ? 'No results' : `Showing ${start} to ${end} of ${totalCount}`

  return summary
}

const paginationOptions = (page = 1, totalPages = 1) => {
  const current = parseInt(`${page}`, 10)
  const last = parseInt(`${totalPages}`, 10)
  const delta = 2
  const left = current - delta
  const right = current + delta + 1
  const range = []
  const rangeWithDots = []
  let slider

  for (let i = 1; i <= last; i += 1) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i)
    }
  }

  range.forEach((i) => {
    if (slider) {
      if (i - slider === 2) {
        rangeWithDots.push(slider + 1)
      } else if (i - slider !== 1) {
        rangeWithDots.push('...')
      }
    }

    rangeWithDots.push(i)

    slider = i
  })

  return rangeWithDots
}

const PageButton = ({ item, page, paginate }) => {
  let paginationItem

  if (parseInt(item, 10) === parseInt(page, 10)) {
    paginationItem = (
      <Button variant="subtle" size="xs" onClick={() => paginate(item)}>
        {item}
      </Button>
    )
  } else if (item === '...') {
    paginationItem = (
      <Button size="xs" variant="outline" disabled>
        {item}
      </Button>
    )
  } else {
    paginationItem = (
      <Button size="xs" variant="outline" onClick={() => paginate(item)}>
        {item}
      </Button>
    )
  }

  return paginationItem
}

interface PreviousButtonProps {
  page: number
  previousText: string | ReactElement
  paginate?: (page: number) => void
}

const PreviousButton: FC<PreviousButtonProps> = ({ page, previousText, paginate = () => {} }) => {
  const pageInt = parseInt(`${page}`, 10)
  const previousDisabled = pageInt === 1

  return (
    <Button
      size="xs"
      disabled={previousDisabled}
      onClick={() => !previousDisabled && paginate(pageInt - 1)}
      variant="outline"
    >
      {previousText}
    </Button>
  )
}

interface NextButtonProps {
  page: number
  totalPages: number
  nextText: string | ReactElement
  paginate?: (page: number) => void
}

const NextButton: FC<NextButtonProps> = ({ page, totalPages, nextText, paginate = () => {} }) => {
  const pageInt = parseInt(`${page}`, 10)
  const nextDisabled = pageInt === totalPages

  return (
    <Button size="xs" disabled={nextDisabled} onClick={() => !nextDisabled && paginate(pageInt + 1)} variant="outline">
      {nextText}
    </Button>
  )
}

interface PaginationProps extends Omit<StackProps, 'page'> {
  page?: number
  totalPages?: number
  previousText?: string | ReactElement
  nextText?: string | ReactElement
  totalCount?: number
  limitValue?: number
  onPaginate?: (page: number) => void
  scrollContainer?: string
  showPageButtons?: boolean
  showPaginationSummary?: null | boolean
  simple?: boolean
}

const Pagination: FC<PaginationProps> = ({
  page = 0,
  totalPages = 0,
  previousText = <PiArrowLeft />,
  nextText = <PiArrowRight />,
  totalCount = 0,
  limitValue = 0,
  onPaginate = null,
  scrollContainer = null,
  simple = false,
  showPageButtons = true,
  showPaginationSummary = null,
  ...rest
}) => {
  const location = useLocation()
  const searchParams = queryString.parse(location.search)
  const [, setSearchParams] = useSearchParams()
  const summaryBreakpointValue = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: false,
    '70em': true,
    xl: true,
    '2xl': true
  })

  if (!page || !totalPages) {
    return null
  }

  let paginate

  if (onPaginate) {
    paginate = onPaginate
  } else {
    paginate = (newPage) => {
      if (newPage !== 1) {
        searchParams.page = newPage
      } else {
        delete searchParams.page
      }

      setSearchParams(searchParams)

      if (scrollContainer) {
        document.getElementById(scrollContainer).scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  }

  const showPagination = totalCount && totalPages > 1

  if (!showPagination) {
    return null
  }

  return (
    <HStack {...rest}>
      {(showPaginationSummary || (summaryBreakpointValue && !simple)) && (
        <>
          <Text color="muted" fontSize="sm">
            {paginationSummary(page, limitValue, totalCount)}
          </Text>
          <Spacer />
        </>
      )}
      <Group>
        <PreviousButton page={page} previousText={previousText} paginate={paginate} />
        {showPageButtons &&
          paginationOptions(page, totalPages).map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <PageButton key={`pagination-${index}`} item={item} page={page} paginate={paginate} />
          ))}
        <NextButton page={page} totalPages={totalPages} nextText={nextText} paginate={paginate} />
      </Group>
    </HStack>
  )
}

export default Pagination
