import type { EntityWorkflowStateEnum } from '@graphql/types'

export const totalEntities = (progresses: Partial<Record<EntityWorkflowStateEnum, number>>): number =>
  Object.values(progresses).reduce((acc, curr) => acc + curr, 0)

export const calculatePercent = (finished: number, total: number): string => {
  // Avoid the NaN divide by zero error
  if (total === 0) {
    return '0'
  }

  return ((finished / total) * 100).toFixed(0).toString()
}
