import get from 'lodash/get'
import type { FC, ReactNode } from 'react'
import { useParams } from 'react-router-dom'

import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import type { GraphAggregate } from '@app/types'
import type {
  GraphImpactfulEventsQuery,
  GraphMetricsQueryVariables,
  ImportanceEnum,
  StrategyGraphMetricsQueryVariables
} from '@graphql/queries'
import {
  useGraphEventsQuery,
  useGraphImpactfulEventsQuery,
  useGraphMetricEventsQuery,
  useGraphMetricsQuery,
  useStrategyGraphEntityEventsQuery,
  useStrategyGraphMetricEventsQuery,
  useStrategyGraphMetricsQuery
} from '@graphql/queries'
import type { IntervalEnum, Metric } from '@graphql/types'

type Importance = ImportanceEnum

type ImpactfulEntity = GraphImpactfulEventsQuery['impactfulEntities'][0]

interface GraphFilters {
  range?: string
  interval?: IntervalEnum
  start_date?: string
  end_date?: string
  q?: string
  importance?: Importance | Importance[]
  contributor?: string | string[]
  labels?: string | string[]
  source_name?: string | string[]
  story_type?: string | string[]
  workflow_state?: string | string[]
}

type GraphDataFetcherProps = {
  filters?: GraphFilters
  fetchEvents?: boolean
  fetchMetricEvents?: boolean
  fetchImpacts?: boolean
  cumulative?: boolean
  entityId?: string
  metricId?: string
  id?: string
  children: (
    eventData: GraphAggregate,
    impactfulEntities: ImpactfulEntity[],
    metric: Pick<Metric, 'name' | 'metricDataPoints' | 'positiveDirection'>
  ) => ReactNode
}

type EventProps = {
  filters?: GraphFilters
  id?: string
  entityId?: string
  metricId?: string
}

type UseFetchGraphEvents = (
  args: Pick<GraphDataFetcherProps, 'entityId' | 'metricId' | 'fetchMetricEvents' | 'filters'> & { pause: boolean }
) => GraphAggregate
const useFetchGraphEvents: UseFetchGraphEvents = ({ entityId, metricId, filters, pause }) => {
  const { strategyId } = useParams()

  let eventsQuery
  let eventsVariables: EventProps = { filters }
  let key: string

  if (strategyId) {
    if (entityId) {
      eventsQuery = useStrategyGraphEntityEventsQuery
      eventsVariables = { ...eventsVariables, id: strategyId, entityId }
      key = 'strategy.entityEventsGraph'
    } else {
      eventsQuery = useStrategyGraphMetricEventsQuery
      eventsVariables = { ...eventsVariables, id: strategyId, metricId }
      key = 'strategy.metricEventsGraph'
    }
  } else {
    eventsQuery = useGraphMetricEventsQuery
    eventsVariables = { ...eventsVariables, metricId }
    key = 'metricEventsGraph'
  }

  const [{ data }] = eventsQuery({
    variables: eventsVariables,
    pause
  })

  // 'date' is just the field we aggregate on in the backend. No need to spread that further.
  return get(data, key)?.date
}

type UseFetchGraphMetric = (
  fetchEvents: boolean,
  filters: GraphFilters,
  cumulative: boolean,
  strategyId: string,
  metricId: string
) => Pick<Metric, 'name' | 'metricDataPoints' | 'positiveDirection'>
const useFetchGraphMetric: UseFetchGraphMetric = (fetchEvents, filters, cumulative, strategyId, metricId) => {
  const range = !fetchEvents ? 'all' : filters.range
  const { interval } = filters

  let metricQuery
  let key: string
  let metricVariables: Partial<StrategyGraphMetricsQueryVariables> | Partial<GraphMetricsQueryVariables> = {
    range,
    interval,
    startDate: filters.start_date,
    endDate: filters.end_date,
    cumulative,
    forecast: true
  }

  if (strategyId) {
    metricQuery = useStrategyGraphMetricsQuery
    metricVariables = { ...metricVariables, id: strategyId, metricId }
    key = 'strategy.metric'
  } else {
    metricQuery = useGraphMetricsQuery
    metricVariables = { ...metricVariables, id: metricId }
    key = 'metric'
  }

  const [{ data: metricData }] = metricQuery({
    variables: metricVariables,
    pause: !metricId
  })

  return get(metricData, key)
}

const GraphDataFetcher: FC<GraphDataFetcherProps> = ({
  filters = {},
  fetchEvents = true,
  fetchMetricEvents = false,
  fetchImpacts = true,
  cumulative = false,
  entityId,
  metricId,
  children
}) => {
  const { strategyId } = useParams()

  const metricEventsData = useFetchGraphEvents({
    entityId,
    metricId,
    fetchMetricEvents,
    filters,
    pause: !fetchMetricEvents || !metricId
  })

  const [{ data: rawEventsData }] = useGraphEventsQuery({
    variables: {
      filters
    },
    pause: !fetchEvents || fetchMetricEvents
  })
  const eventsData = rawEventsData?.eventsGraph

  const [{ data: rawImpactData }] = useGraphImpactfulEventsQuery({
    variables: {
      filters,
      metricId
    },
    pause: !fetchImpacts
  })
  const impactfulEntities = rawImpactData?.impactfulEntities

  const metric = useFetchGraphMetric(fetchEvents, filters, cumulative, strategyId, metricId)

  return children(eventsData || metricEventsData, impactfulEntities, metric)
}

export default withSuspenseWrapper(GraphDataFetcher)
