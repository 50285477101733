import { Box, Text } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'

import InvitationsListActions from './invitationsListActions'

import InvitationsFetcher from '@app/fetchers/invitationsFetcher'
import useGetObjects from '@app/hooks/useGetObjects'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Table from '@app/shared/table'
import type { Column, ColumnCellProps, Data } from '@app/shared/table/types'
import type { Invitation } from '@graphql/types'

interface CellProps {
  data: Invitation
}

const InvitationActionsCell: FC<CellProps> = ({ data: invitation }) => (
  <InvitationsListActions invitation={invitation} />
)

const InvitationSentAtCell: FC<CellProps> = ({ data: invitation }) => {
  const { formatDateTimeInUserTimeZone } = useStoreCurrentUser()

  return <Text title={invitation.lastSentAt}>{formatDateTimeInUserTimeZone(invitation.lastSentAt)}</Text>
}

const InvitationsRoleCell: FC<CellProps> = ({ data: invitation }) => <Text>{capitalize(invitation.role)}</Text>

interface Props {}

const InvitationsList: FC<Props> = () => {
  const invitations = useGetObjects('invitation')
  const sortedInvitations = sortBy(invitations, ['lastSentAt'])

  const columns: Column<Data>[] = [
    {
      header: 'Email',
      accessor: 'email'
    },
    {
      header: 'Role',
      cell: InvitationsRoleCell as unknown as FC<ColumnCellProps<Data>>
    },
    {
      header: 'Sent at',
      cell: InvitationSentAtCell as unknown as FC<ColumnCellProps<Data>>
    },
    {
      header: '',
      cell: InvitationActionsCell as unknown as FC<ColumnCellProps<Data>>
    }
  ]

  return (
    <Box overflow="auto">
      <InvitationsFetcher />
      <Table columns={columns} data={sortedInvitations as Data[]} />
    </Box>
  )
}

export default InvitationsList
