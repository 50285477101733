import { Flex, Box } from '@chakra-ui/react'
import { createContext, useEffect, useMemo, useRef } from 'react'
import { Outlet, useLocation, useParams, useNavigate } from 'react-router-dom'

import List from './list'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'

export const ReportContext = createContext({ containerRef: null })

const Home = () => {
  const { strategyId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const containerRef = useRef(null)
  const context = useMemo(() => ({ containerRef }), [containerRef])

  // We are using the non-subscribed version of the store here because we only
  // care about our initial state and not any updates.
  const reports = useGetObjectsByProperties('report', { strategyId })

  const redirecting = Object.keys(reports).length >= 1 && location.pathname.search(/reports\/?\??$/g) !== -1

  // This redirects us to the first item if we land directly on the reports index.
  useEffect(() => {
    if (redirecting) {
      navigate(`/strategy/${strategyId}/reports/${reports[0].id}`, { replace: true })
    }
  }, [navigate, redirecting, reports, strategyId])

  if (redirecting) {
    return null
  }

  return (
    <ReportContext.Provider value={context}>
      <Flex ref={containerRef} overflow="hidden" h="100%" bgColor="bg.muted">
        <List />
        <Box flex="1" overflow="auto" h="100%">
          <Outlet />
        </Box>
      </Flex>
    </ReportContext.Provider>
  )
}

export default Home
