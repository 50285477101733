import { Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState, useRef } from 'react'

import { Field } from '@app/components/ui/field'
import ContributorsAutocomplete from '@app/shared/autocomplete/contributors/contributorsAutocomplete'
import type { FormErrors } from '@app/types'
import type { EntityContribution } from '@graphql/queries'

interface Props {
  name?: string
  label?: string | null
  placeholder?: string
  defaultValue?: Pick<EntityContribution, 'id' | 'contributor'>[]
  helperText?: React.ReactNode
  errors?: FormErrors
}

const ContributorAutocompleteInput: FC<Props> = ({
  name = '',
  label = null,
  placeholder = '',
  helperText = null,
  errors = {},
  defaultValue = [],
  ...rest
}) => {
  const [contributors, setContributors] = useState(defaultValue)
  const ref = useRef()

  const handleChange = (newLabels) => {
    setContributors(newLabels)
  }

  return (
    <Field
      label={label}
      errorText={errors[name] && errors[name].message}
      helperText={helperText}
      id={name}
      invalid={!!errors[name]}
    >
      <VisuallyHidden asChild>
        <Input ref={ref} id={name} name={name} readOnly value={JSON.stringify(contributors)} />
      </VisuallyHidden>
      <Stack w="100%">
        <ContributorsAutocomplete
          id={`${name}-autocomplete`}
          onChange={handleChange}
          inputRef={ref}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...rest}
        />
      </Stack>
    </Field>
  )
}

export default ContributorAutocompleteInput
