import type { NodeTypes } from '@xyflow/react'

import BasicCardNode from './basicCardNode'
import CommentThreadNode from './commentThreadNode'
import EntityNode from './entityNode'
import ImageNode from './imageNode'
import MetricNode from './metricNode'
import NoteNode from './noteNode'
import SectionNode from './sectionNode'
import StrategyNode from './strategyNode'

const nodeTypes: NodeTypes = {
  // @ts-expect-error Something is wrong in the bowels of xyflow NodeProps definition
  basicCard: BasicCardNode,
  // @ts-expect-error Something is wrong in the bowels of xyflow NodeProps definition
  commentThread: CommentThreadNode,
  // @ts-expect-error Something is wrong in the bowels of xyflow NodeProps definition
  entity: EntityNode,
  // @ts-expect-error Something is wrong in the bowels of xyflow NodeProps definition
  metric: MetricNode,
  // @ts-expect-error Something is wrong in the bowels of xyflow NodeProps definition
  note: NoteNode,
  // @ts-expect-error Something is wrong in the bowels of xyflow NodeProps definition
  section: SectionNode,
  // @ts-expect-error Something is wrong in the bowels of xyflow NodeProps definition
  mapImage: ImageNode,
  // @ts-expect-error Something is wrong in the bowels of xyflow NodeProps definition
  strategy: StrategyNode
}

export default nodeTypes
