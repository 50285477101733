import type { IconProps } from '@chakra-ui/react'

import type { CardSize } from '@app/types'

type CardIconBoxSize = (defaultIconBoxSize: number, cardSize: CardSize) => IconProps['boxSize']
const cardIconBoxSize: CardIconBoxSize = (defaultIconBoxSize, cardSize) => {
  if (cardSize === 'normal') {
    return defaultIconBoxSize
  }

  return Math.round(defaultIconBoxSize * 1.2)
}

export default cardIconBoxSize
