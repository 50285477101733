// Heavily (very heavily) inspired by:
// https://github.com/compwright/passport-saml-metadata/blob/master/src/reader.js

import { DOMParser } from '@xmldom/xmldom'
import camelCase from 'lodash/camelCase'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import xpath from 'xpath'

const defaultOptions = {
  authnRequestBinding: 'HTTP-Redirect',
  throwExceptions: false
}

class IdPMetadataReader {
  options: { authnRequestBinding: string; throwExceptions: boolean }

  query: (query: string) => xpath.SelectReturnType

  constructor(metadata, options = defaultOptions) {
    const doc = new DOMParser().parseFromString(metadata)

    this.options = { ...defaultOptions, ...options }

    const select = xpath.useNamespaces({
      md: 'urn:oasis:names:tc:SAML:2.0:metadata',
      claim: 'urn:oasis:names:tc:SAML:2.0:assertion',
      sig: 'http://www.w3.org/2000/09/xmldsig#'
    })

    this.query = (query) => {
      try {
        return select(query, doc)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(`Could not read xpath query "${query}"`, e)
        throw e
      }
    }
  }

  get entityId() {
    try {
      return this.query('//md:EntityDescriptor/@entityID')[0].value
    } catch (e) {
      if (this.options.throwExceptions) {
        throw e
      } else {
        return undefined
      }
    }
  }

  get identityProviderUrl() {
    try {
      // Get all of the SingleSignOnService elements in the XML, sort them by the index (if provided)
      const singleSignOnServiceElements = sortBy(
        this.query('//md:IDPSSODescriptor/md:SingleSignOnService') as Node[],
        (singleSignOnServiceElement) => {
          const indexAttribute = find((singleSignOnServiceElement as unknown as { attributes }).attributes, {
            name: 'index'
          })

          if (indexAttribute) {
            return indexAttribute.value
          }

          return 0
        }
      )

      // Find the specified authentication binding, if not available default to the first binding in the list
      const singleSignOnServiceElement =
        find(singleSignOnServiceElements, (element) =>
          find((element as unknown as { attributes }).attributes, {
            value: `urn:oasis:names:tc:SAML:2.0:bindings:${this.options.authnRequestBinding}`
          })
        ) || singleSignOnServiceElements[0]

      // Return the location
      return find((singleSignOnServiceElement as unknown as { attributes }).attributes, { name: 'Location' }).value
    } catch (e) {
      if (this.options.throwExceptions) {
        throw e
      } else {
        return undefined
      }
    }
  }

  get signingCerts() {
    try {
      return (
        this.query(
          '//md:IDPSSODescriptor/md:KeyDescriptor[@use="signing" or not(@use)]/sig:KeyInfo/sig:X509Data/sig:X509Certificate'
        ) as Node[]
      ).map((node) => (node as { firstChild }).firstChild.data.replace(/[\r\n\t\s]/gm, ''))
    } catch (e) {
      if (this.options.throwExceptions) {
        throw e
      } else {
        return undefined
      }
    }
  }

  get signingCert() {
    try {
      return this.signingCerts[0].replace(/[\r\n\t\s]/gm, '')
    } catch (e) {
      if (this.options.throwExceptions) {
        throw e
      } else {
        return undefined
      }
    }
  }

  get claimSchema() {
    try {
      return (this.query('//md:IDPSSODescriptor/claim:Attribute/@Name') as Node[]).reduce((claims, node) => {
        try {
          const name = node.nodeValue
          const description = (
            this.query(`//md:IDPSSODescriptor/claim:Attribute[@Name="${name}"]/@FriendlyName`)[0] as { value: string }
          ).value
          const camelized = camelCase(description)
          // eslint-disable-next-line no-param-reassign
          claims[node.nodeValue] = { name, description, camelCase: camelized }
        } catch (e) {
          if (this.options.throwExceptions) {
            throw e
          }
        }
        return claims
      }, {})
    } catch (e) {
      if (this.options.throwExceptions) {
        throw e
      }
      return {}
    }
  }
}

export default IdPMetadataReader
