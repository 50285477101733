import type { FC } from 'react'
import { useId } from 'react'

import type { AvatarProps } from '@app/components/ui/avatar'
import { Avatar } from '@app/components/ui/avatar'
import { Tooltip } from '@app/components/ui/tooltip'

interface Props extends AvatarProps {
  name: string
}

const TooltipAvatar: FC<Props> = ({ name, ...props }) => {
  const id = useId()

  return (
    <Tooltip ids={{ trigger: id }} showArrow content={name}>
      <Avatar ids={{ root: id }} name={name} {...props} />
    </Tooltip>
  )
}

export default TooltipAvatar
