import { Input, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useActionData, useNavigation, Form } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import FormAlert from '@app/next/forms/formAlert'
import useForm from '@app/next/forms/useForm'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'

interface ResetPasswordActionData {
  success?: boolean
}

const ForgotPassword: FC = () => {
  const [emailSent, setEmailSent] = useState(false)
  const { errors } = useForm({})
  const navigation = useNavigation()
  const actionData: ResetPasswordActionData = useActionData()

  useEffect(() => {
    if (navigation.state === 'loading' && actionData?.success === true) {
      setEmailSent(true)
    }
  }, [actionData?.success, navigation.state])

  return (
    <Stack gap="8">
      <SessionHeader
        title="Forgot your password?"
        subtitle="Remembered your password?"
        linkText="Sign in"
        linkUrl="/sign_in"
      />

      <Stack gap="6">
        <FormAlert description={errors?.global?.message} status="success" />
        <Form id="forgot-password-form" method="post">
          <Stack gap="5">
            <Field label="Email" errorText={errors?.email?.message} id="email" invalid={!!errors?.email} required>
              <Input
                autoComplete="email"
                id="email"
                name="email"
                placeholder="Enter the email you used to sign up with"
                type="email"
              />
            </Field>
          </Stack>
        </Form>
        <Stack gap={4}>
          <Button
            disabled={emailSent}
            form="forgot-password-form"
            loading={navigation.state === 'submitting' || navigation.state === 'loading'}
            size="md"
            type="submit"
          >
            {emailSent ? 'Email sent, check your inbox' : 'Send reset email'}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ForgotPassword
