import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import MetricCorrelationsTable from '@app/pages/metrics/components/metricCorrelationsTable'

const MetricCorrelationsTab: FC = () => {
  const { nodeId: metricId } = useParams()
  const metric = useGetObject(metricId, 'metric')

  if (!metric) {
    return null
  }

  return <MetricCorrelationsTable metric={metric} />
}

export default MetricCorrelationsTab
