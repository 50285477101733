import { loaderQuery } from '@graphql/client'
import { StrategyExistingObjectAutocomplete } from '@graphql/documents/strategy.graphql'

const queryExistingObjects = async (request) => {
  const formData = await request.formData()
  const input = Object.fromEntries(formData.entries())
  const scopeFields = ['source_name', 'foreign_type']

  input.scope = {}
  scopeFields.forEach((field) => {
    input.scope[field] = input[field]
    delete input[field]
  })

  const { data } = await loaderQuery(StrategyExistingObjectAutocomplete, input)

  return { data: data?.strategy?.existingObjectAutocomplete }
}

export default queryExistingObjects
