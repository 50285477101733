import { Group, Input, Stack } from '@chakra-ui/react'
import { format } from 'date-fns'
import type { FC } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { useStore } from '@app/store'
import type { MetricDataPoint } from '@graphql/queries'

interface Props {
  metricDataPoint?: MetricDataPoint
}

const MetricDataPointForm: FC<Props> = ({ metricDataPoint }: Props) => {
  const { metricId, nodeId } = useParams()
  const id = metricId || nodeId
  const addMetricDataPoint = useStore.use.addMetricDataPoint()
  const updateMetricDataPoint = useStore.use.updateMetricDataPoint()
  const navigate = useNavigate()

  const dateDefault = metricDataPoint?.date || format(new Date(), 'yyyy-MM-dd')

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const formProps = Object.fromEntries(formData)

    if (metricDataPoint) {
      updateMetricDataPoint(metricDataPoint.id, formProps.date as string, formProps.value as string)
    } else {
      addMetricDataPoint(id, formProps.date as string, formProps.value as string)
    }

    navigate('..')
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={5} p={6}>
        <Field label="Date" required>
          <Input defaultValue={dateDefault} name="date" required type="date" />
        </Field>
        <Field label="Value" required>
          <Input
            defaultValue={metricDataPoint?.value}
            name="value"
            // errors={errors}
            step="0.0001"
            type="number"
          />
        </Field>

        <Group justifyContent="end">
          <Button asChild variant="outline">
            <Link to="..">Cancel</Link>
          </Button>
          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </form>
  )
}

export default MetricDataPointForm
