import { Link as ChakraLink, Stack, Text } from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import ListLabels from '@app/pages/shared/listLabels'
import type { Strategy } from '@graphql/queries'

interface Props {
  data: Strategy
}

const StrategyName: FC<Props> = ({ data: strategy }) => {
  const { id, name, labels } = strategy

  return (
    <Stack>
      <ChakraLink
        asChild
        data-cy="map-name"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Link to={id}>
          <Text minW="xs" fontSize="md" whiteSpace="normal" wordBreak="break-all" title={name}>
            {truncate(name, { length: 100 })}
          </Text>
        </Link>
      </ChakraLink>
      <ListLabels labels={labels} />
    </Stack>
  )
}

export default StrategyName
