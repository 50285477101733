import { Box, Center, Circle, Icon, Text } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import { PiHeadCircuit } from 'react-icons/pi'

const EmptyStatePrompt: FC<PropsWithChildren> = ({ children = null }) => (
  <Center h="full">
    <Box>
      <Center gap="6">
        <Circle
          color="fg.default"
          bg="colorPalette.solid"
          outline="8px solid"
          outlineColor="colorPalette.subtle"
          size="12"
        >
          <Icon boxSize={6} color="fg.inverted">
            <PiHeadCircuit />
          </Icon>
        </Circle>
        <Text color="fg.muted" fontSize="xl" fontWeight="medium">
          {children}
        </Text>
      </Center>
      <Center mt={6}>
        <Text fontSize="sm">It might take a few minutes for your first message to process</Text>
      </Center>
    </Box>
  </Center>
)

export default EmptyStatePrompt
