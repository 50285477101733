import { DEVELOPMENT_TUNNEL_HOST, GOOGLE_SCOPE, SLACK_BOT_SCOPE, SLACK_USER_SCOPE } from '../lib/globals'

const isDevelopment = import.meta.env.MODE === 'development'

const {
  PUBLIC_GOOGLE_CLIENT_ID: GOOGLE_CLIENT_ID,
  PUBLIC_SLACK_KEY: SLACK_KEY,
  PUBLIC_JIRA_CLIENT_ID: JIRA_CLIENT_ID,
  PUBLIC_LINEAR_CLIENT_ID: LINEAR_CLIENT_ID,
  PUBLIC_ASANA_CLIENT_ID: ASANA_CLIENT_ID,
  PUBLIC_PRODUCTBOARD_CLIENT_ID: PRODUCTBOARD_CLIENT_ID,
  PUBLIC_NGROK_HOSTNAME: NGROK_HOSTNAME
} = import.meta.env
const DEV_HOST = NGROK_HOSTNAME ? `https://${NGROK_HOSTNAME}` : DEVELOPMENT_TUNNEL_HOST
const REDIRECT_HOST = isDevelopment ? DEV_HOST : window?.location?.origin
// For sources that allow `localhost` as a redirect uri
const LOCALHOST_REDIRECT_HOST = isDevelopment ? 'http://localhost:3000' : window?.location?.origin

const GOOGLE_BASE = 'https://accounts.google.com/o/oauth2/auth'
const GOOGLE_REDIRECT_URI = `${LOCALHOST_REDIRECT_HOST}/webhooks/connect/google_oauth2`
const GOOGLE_AUTH_URL = `${GOOGLE_BASE}?redirect_uri=${GOOGLE_REDIRECT_URI}&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=${GOOGLE_SCOPE}&access_type=offline&prompt=consent`

const JIRA_REDIRECT_URI = `${LOCALHOST_REDIRECT_HOST}/webhooks/connect/jira`
const JIRA_WEBHOOK_URL =
  `${REDIRECT_HOST}/events/webhook/jira?api_token=:apiToken` +
  // eslint-disable-next-line no-template-curly-in-string
  '&issue_id=${issue.id}&issue_key=${issue.key}&project_id=${project.id}&project_key=${project.key}&version_id=${version.id}&account_id=${modifiedUser.accountId}'

const LINEAR_REDIRECT_URI = `${REDIRECT_HOST}/webhooks/connect/linear`

const SLACK_BASE = 'https://slack.com/oauth/v2/authorize'
const SLACK_REDIRECT_URI = `${REDIRECT_HOST}/webhooks/connect/slack`
const SLACK_AUTH_URL = `${SLACK_BASE}?client_id=${SLACK_KEY}&scope=${SLACK_BOT_SCOPE}&user_scope=${SLACK_USER_SCOPE}&redirect_uri=${SLACK_REDIRECT_URI}`

const SHORTCUT_WEBHOOK_URL = `${REDIRECT_HOST}/events/webhook/shortcut?api_token=:apiToken`

const ASANA_REDIRECT_URI = `${LOCALHOST_REDIRECT_HOST}/webhooks/connect/asana`
// https://app.asana.com/-/oauth_authorize?response_type=code&client_id=1204687918697871&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fwebhooks%2Fconnect%2Fasana&state=<STATE_PARAM>
const ASANA_AUTH_URL = `https://app.asana.com/-/oauth_authorize?client_id=${ASANA_CLIENT_ID}&redirect_uri=${ASANA_REDIRECT_URI}&response_type=code&state=integrate`

const PRODUCTBOARD_REDIRECT_URI = `${LOCALHOST_REDIRECT_HOST}/webhooks/connect/productboard`
const PRODUCTBOARD_AUTH_URL = `https://app.productboard.com/oauth2/authorize?client_id=${PRODUCTBOARD_CLIENT_ID}&redirect_uri=${encodeURIComponent(
  PRODUCTBOARD_REDIRECT_URI
)}&response_type=code&state=integrate`

const Routes = {
  root: '/',
  auth: '/auth/:provider',

  entities: '/events',
  entity: '/events/:id',

  acsUrl: '/auth/saml/:organization/callback',
  samlOrganizationParam: '/sso/:ssoIdentifier',

  // External Routes
  doubleloopProductUpdates: 'https://app.doubleloop.app/strategy/3481/changelog',

  googleAuthentication: GOOGLE_AUTH_URL,
  slackAuthentication: SLACK_AUTH_URL,
  jiraAuthentication: `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${JIRA_CLIENT_ID}&scope=read%3Ajira-user%20read%3Ajira-work%20offline_access&redirect_uri=${JIRA_REDIRECT_URI}&state=integrate&response_type=code&prompt=consent`,
  jiraWebhookUrl: JIRA_WEBHOOK_URL,
  shortcutWebhookUrl: SHORTCUT_WEBHOOK_URL,
  linearAuthentication: `https://linear.app/oauth/authorize?client_id=${LINEAR_CLIENT_ID}&redirect_uri=${LINEAR_REDIRECT_URI}&response_type=code&scope=read&state=integrate`,
  asanaAuthentication: ASANA_AUTH_URL,
  productboardAuthentication: PRODUCTBOARD_AUTH_URL,

  chat: 'https://calendly.com/doubleloop/30min',
  slackCommunity: 'https://join.slack.com/t/doubleloopmembers/shared_invite/zt-wrg3rwhc-gB782rL1ONuzpyTqbtBkfA',

  docs: 'https://docs.doubleloop.app',
  docsUsingMetrics: 'https://docs.doubleloop.app/measure/how-to-add-metric-data',
  docsSetupGithub: 'https://docs.doubleloop.app/build/github-setup-instructions',
  docsSetupJira: 'https://docs.doubleloop.app/build/our-jira-app',
  docsSetupLooker: 'https://docs.doubleloop.app/measure/looker-integration',
  docsSetupSlack: 'https://docs.doubleloop.app/instructions/slack-setup',
  docsSharing: 'https://docs.doubleloop.app/private-and-public-stakeholder-views',
  docsStrategyConcepts: 'https://docs.doubleloop.app/strategy-map/strategy-map-concepts',

  termsOfService: 'https://doubleloop.app/terms-of-use',
  privacyPolicy: 'https://doubleloop.app/privacy-policy',

  // Direct file downloads
  downloadMetricCSV: '/downloads/metric_csv/:metricId',
  downloadStrategyPlaybook: '/downloads/strategy_playbook/:strategyId',
  downloadStrategyWorksCSV: '/downloads/strategy_works/:strategyId',
  downloadStrategyMetricsCSV: '/downloads/strategy_metrics/:strategyId'
}

export const origin = () => {
  const o = window.location.origin

  return window.location.hostname === 'localhost' ? 'https://dev.doubleloop.app' : o
}

/*
 * This is a function creator that creates function for generating
 * url path based on the routes above.
 * e.g. Paths.entityPath({ id: 14 }) produces the path /events/14
 */

type PathOptions = {
  fullPath?: boolean
  search?: string
}

type PathHelper = (params?: Record<string, string>, options?: PathOptions) => string

type PathsHelper = Record<string, PathHelper>

const Paths: PathsHelper = Object.keys(Routes).reduce((hash, key) => {
  const helperKey = `${key}Path`
  const helper: PathHelper = (params = {}, options = {}) => {
    const search = options.search || ''
    let url = Routes[key].replace(/\?$/g, '')

    Object.keys(params).forEach((param) => {
      url = url.replace(`:${param}`, params[param])
    })
    url = `${url}${search}`

    return options.fullPath ? `${origin()}${url}` : url
  }

  return { ...hash, [helperKey]: helper }
}, {})

const pathWithToken = (path, token) => {
  const separator = path.includes('?') ? '&' : '?'

  return token ? `${path}${separator}token=${token}` : path
}

const pathWithSearchParams = (path) => {
  const currentQueryParams = window.location.search

  return currentQueryParams ? `${path}${currentQueryParams}` : path
}

// the strategy path needs to be brought into compliance to eliminate the case statement
const showRouteByTypeAndId = (type, id) => `/${type.toLowerCase()}/${id}`

const nullifyEmptyStrings = (input) => {
  const output = { ...input }

  Object.keys(input).forEach((key) => {
    if (input[key] === '') {
      output[key] = null
    }
  })

  return output
}

export { Routes, Paths, pathWithToken, pathWithSearchParams, showRouteByTypeAndId, nullifyEmptyStrings }
