import { HStack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize, MapDomainSection } from '@app/types'

type Props = {
  section: MapDomainSection
  size?: CardSize
}

const CollapsedSection: FC<Props> = ({ section, size = 'normal' }) => {
  const { name } = section
  const fontSize = cardFontSize('md', size)

  return (
    <HStack>
      <Text fontSize={fontSize} fontWeight="semibold">
        {name || 'Untitled Section'}
      </Text>
    </HStack>
  )
}

export default CollapsedSection
