import { HStack, Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import { Button } from '@app/components/ui/button'
import { Step, StepContent, Steps, useSteps } from '@app/shared/steps'
import type { MapDomainIntegration, StepType } from '@app/types'

interface ActionsAreaProps {
  steps: StepType[]
  stepIndex: number
  nextStep?: () => void
  prevStep?: () => void
  skipSpinner?: boolean
  skipFinish?: boolean
}

const ActionsArea: FC<ActionsAreaProps> = ({
  steps,
  stepIndex,
  nextStep = () => {},
  prevStep = () => {},
  skipSpinner = false,
  skipFinish = false
}) => {
  const isFirstStep = stepIndex === 0
  const isLastStep = stepIndex === steps.length - 1

  return (
    <>
      <Button
        disabled={isFirstStep}
        onClick={isFirstStep ? () => {} : prevStep}
        variant={skipFinish ? 'solid' : 'ghost'}
      >
        Back
      </Button>
      {(!skipFinish || !isLastStep) && (
        <Button loading={isLastStep && !skipSpinner} loadingText="Waiting for activity..." onClick={nextStep}>
          {isLastStep ? 'Finish' : 'Next'}
        </Button>
      )}
    </>
  )
}

interface IntegrationInstallationProps<
  I extends Pick<MapDomainIntegration, 'activitiesCount'> = Pick<MapDomainIntegration, 'activitiesCount'>
> {
  integration?: I
  steps?: StepType<I>[]
  skipSpinner?: boolean
  skipFinish?: boolean
}

const IntegrationInstallation: FC<IntegrationInstallationProps> = ({
  integration = null,
  steps = [],
  skipSpinner = false,
  skipFinish = false
}) => {
  let initialStep = 0
  steps.forEach((step) => {
    if (integration && step.completed && step.completed(integration)) {
      initialStep += 1
    }
  })

  const { nextStep, prevStep, activeStep } = useSteps({ initialStep })

  if (integration && integration.activitiesCount > 0) {
    return null
  }

  return (
    <Steps activeStep={activeStep}>
      {steps.map((step, index) => (
        <Step key={step.title} title={step.title}>
          <StepContent>
            <Stack gap="4">
              {step.description}
              <HStack>
                <ActionsArea
                  steps={steps}
                  stepIndex={index}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  skipSpinner={skipSpinner}
                  skipFinish={skipFinish}
                />
              </HStack>
            </Stack>
          </StepContent>
        </Step>
      ))}
    </Steps>
  )
}

export default IntegrationInstallation
