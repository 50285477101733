import type { BoxProps } from '@chakra-ui/react'
import { Box, Circle, Stack, Separator, Text } from '@chakra-ui/react'
import { url as gravatarUrl } from 'gravatar'
import type { FC } from 'react'
import { memo, Suspense, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Avatar } from '@app/components/ui/avatar'
import { Tooltip } from '@app/components/ui/tooltip'
import useGetObject from '@app/hooks/useGetObject'
import Comments from '@app/shared/comments/comments'
import type { MapDomainNode } from '@app/types'
import { useStrategyCommentThreadCommentsQuery } from '@graphql/queries'
import type { CommentThread } from '@graphql/types'

interface Props extends BoxProps {
  commentThread?: CommentThread
  node?: MapDomainNode
  onClick?: () => void
  onDoubleClick?: () => void
}

const CommentThreadCommentsFetcher: FC<{ id: string }> = ({ id }) => {
  const { strategyId } = useParams()
  const [commentData, setCommentData] = useState(null)
  const [{ data }] = useStrategyCommentThreadCommentsQuery({
    variables: { strategyId, commentThreadId: id },
    pause: !!commentData
  })

  useEffect(() => {
    if (data) {
      setCommentData(data)
    }
  }, [data])

  return null
}

const CommentThreadCard: FC<Props> = ({ commentThread, node, ...rest }) => {
  const { commentsCount, creatorId, id } = commentThread
  const creator = useGetObject(creatorId, 'user')
  const { selected } = node

  return (
    <Box
      pos="relative"
      display="inline-block"
      maxW="lg"
      p={2}
      // use parent's border radius - parent's border width to get these
      // https://drafts.csswg.org/css-backgrounds-3/#corner-shaping
      border="1px solid #ccc"
      borderRadius="29px 29px 29px 0"
      bgColor="bg"
      {...rest}
    >
      <Stack gap={4} separator={<Separator />}>
        <Box pos="relative">
          <Tooltip aria-label={creator.name} showArrow content={creator.name}>
            <Avatar
              borderWidth={1}
              borderColor="white"
              name={creator.name}
              size="lg"
              src={gravatarUrl(creator.email, { s: '80', protocol: 'https', d: '404' })}
            />
          </Tooltip>
          {commentsCount > 0 && (
            <Circle pos="absolute" bottom={-1} left={-1} borderWidth={1} borderColor="border" bgColor="bg" size={4}>
              <Text color="fg.muted" fontSize="xs" fontWeight="semibold">
                {commentsCount}
              </Text>
            </Circle>
          )}
        </Box>
        {selected && (
          <Box>
            <Suspense>
              <CommentThreadCommentsFetcher id={id} />
            </Suspense>
            <Comments
              commentableId={id}
              commentableType="CommentThread"
              p={0}
              className="nodgrag"
              display={selected ? 'block' : 'none'}
              py={2}
              zIndex={1000}
              minWidth={64}
            />
          </Box>
        )}
      </Stack>
    </Box>
  )
}

export default memo(CommentThreadCard)
