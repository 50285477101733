import { Text } from '@chakra-ui/react'
import type { FC } from 'react'

import cardFontSize from '@app/shared/cards/cardFontSize'
import { totalEntities, calculatePercent } from '@app/shared/cards/entity/helpers'
import type { CardSize } from '@app/types'
import type { EntityWorkflowStateEnum } from '@graphql/types'

interface Props {
  progresses: Record<EntityWorkflowStateEnum, number>
  size?: CardSize
}

const CardProgressPercent: FC<Props> = ({ progresses, size = 'normal' }) => {
  const fontSize = cardFontSize('sm', size)

  if (Object.keys(progresses).length === 0) {
    return null
  }

  const total = totalEntities(progresses)
  const finished = progresses.finished || 0
  const percent = calculatePercent(finished, total)

  return <Text fontSize={fontSize}>{percent}% done</Text>
}

export default CardProgressPercent
