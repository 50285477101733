import { Text } from '@chakra-ui/react'
import pluralize from 'pluralize'
import type { FC } from 'react'

import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize } from '@app/types'

interface Props {
  issueCount?: number
  size?: CardSize
}

const CardIssues: FC<Props> = ({ issueCount, size = 'normal' }) => {
  const fontSize = cardFontSize('sm', size)

  if (typeof issueCount !== 'number') {
    return null
  }

  return (
    <Text fontSize={fontSize}>
      {issueCount} {pluralize('issue', issueCount)}
    </Text>
  )
}

export default CardIssues
