import { Box } from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef, useState } from 'react'

import { PopoverRoot, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow } from '@app/components/ui/popover'
import type { TagProps } from '@app/components/ui/tag'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import CardStatusTag from '@app/shared/cards/components/cardStatusTag'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import { useStore } from '@app/store'
import type { CardSize } from '@app/types'
import type { BasicCard, NodeObjectInput } from '@graphql/types'
import { DomainObjectStatusEnum } from '@graphql/types'

type DomainObject<T extends BasicCard> = Pick<T, 'id' | 'classType'> & Partial<Pick<T, 'status'>>

interface Props extends Omit<TagProps, 'size'> {
  domainObject: DomainObject<BasicCard>
  size?: CardSize
}

const DomainObjectStatusPopover: FC<Props> = ({ domainObject, size = 'normal', ...rest }) => {
  const { user } = useStoreCurrentUser()
  const initialFocusRef = useRef()
  const [open, setOpen] = useState(false)
  const updateObject = useStore.use.updateObject()
  const { id, classType, status } = domainObject

  if (!['admin', 'editor'].includes(user?.role)) {
    return <CardStatusTag ml={2} status={status} cardSize={size} {...rest} />
  }

  const handleItemClick = (value: string) => {
    updateObject({ [classType]: { id, status: value as DomainObjectStatusEnum } } as NodeObjectInput)

    setOpen(!open)
  }

  return (
    <PopoverRoot initialFocusEl={initialFocusRef.current} lazyMount onOpenChange={(e) => setOpen(e.open)} open={open}>
      <PopoverTrigger>
        <Box cursor="pointer" onClick={() => setOpen(!open)}>
          <CardStatusTag status={status} showChevron cardSize={size} {...rest} />
        </Box>
      </PopoverTrigger>
      <PopoverContent maxW={40}>
        <PopoverArrow />
        <PopoverBody m={0} p={0}>
          <SearchList
            searchField="status"
            currentValue={status}
            initialFocusRef={initialFocusRef}
            onChange={handleItemClick}
          >
            <SearchListItem text="Idea" value={DomainObjectStatusEnum.Idea} />
            <SearchListItem text="Next" value={DomainObjectStatusEnum.Next} />
            <SearchListItem text="Active" value={DomainObjectStatusEnum.Active} />
            <SearchListItem text="Learning review" value={DomainObjectStatusEnum.LearningReview} />
            <SearchListItem text="Done" value={DomainObjectStatusEnum.Done} />
          </SearchList>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default DomainObjectStatusPopover
