import isEmpty from 'lodash/isEmpty'
import type { FC } from 'react'
import { memo, useRef } from 'react'

import Node from './components/node'

import useGetNode from '@app/hooks/useGetNode'
import CommentThreadCard from '@app/shared/cards/commentThread/commentThreadCard'
import type { MapDomainNode } from '@app/types'
import type { CommentThread } from '@graphql/types'

const CommentThreadNode: FC<MapDomainNode> = (rfNode) => {
  const { id } = rfNode

  const node = useGetNode(id)
  const ref = useRef() // Can we get rid of the ref?

  const comment = node?.data

  if (!comment || isEmpty(comment)) {
    return null
  }

  return (
    <Node nodeRef={ref} node={node} display="inline">
      {/* <CommentToolbar node={node} /> */}
      <CommentThreadCard commentThread={comment as CommentThread} node={node} />
    </Node>
  )
}

export default memo(CommentThreadNode)
