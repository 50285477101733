import { Input, Stack } from '@chakra-ui/react'
import type { ChangeEventHandler, FC, PropsWithChildren } from 'react'
import { BiSearch } from 'react-icons/bi'

import { Field } from '@app/components/ui/field'
import { InputGroup } from '@app/components/ui/input-group'

type Props = PropsWithChildren<{
  onChange: ChangeEventHandler<HTMLFormElement>
}>

const MapDrawerQueryForm: FC<Props> = ({ children, onChange, ...formProps }) => (
  <form onChange={onChange} {...formProps}>
    <Stack py={4}>
      {children}
      <Field>
        <InputGroup startElement={<BiSearch />} w="full">
          <Input name="query" placeholder="Find..." />
        </InputGroup>
      </Field>
    </Stack>
  </form>
)

export default MapDrawerQueryForm
