import type { IconButtonProps } from '@chakra-ui/react'
import type { MutableRefObject } from 'react'
import { forwardRef, useState } from 'react'
import type { OperationResult } from 'urql'

import { toaster } from '@app/components/ui/toaster'
import type { MarkdownEditorRef } from '@app/next/forms/markdownEditor/markdownEditor'
import AiFieldUpdateButton from '@app/pages/metrics/components/aiFieldUpdateButton'
import { actionMutation } from '@graphql/client'
import { MetricTechnicalDescriptionSuggest } from '@graphql/documents/ai_assistant.graphql'
import type { MetricTechnicalDescriptionSuggestMutation } from '@graphql/queries'

type Props = Partial<IconButtonProps> & {
  metricId: string
}

const AiPopulateTechnicalDescriptionButton: ReturnType<typeof forwardRef<MarkdownEditorRef, Props>> = forwardRef(
  ({ metricId, ...props }, ref) => {
    const [loading, setLoading] = useState(false)

    const onClick = async () => {
      setLoading(true)

      try {
        const { data, error }: OperationResult<MetricTechnicalDescriptionSuggestMutation> = await actionMutation(
          MetricTechnicalDescriptionSuggest,
          {
            metricId
          }
        )

        if (error?.graphQLErrors?.length) {
          toaster.create({
            title: 'Error',
            description: error.graphQLErrors[0].message,
            type: 'error'
          })

          return
        }

        // force this cast, as we know it's always an object (i.e. not a function)
        const forwardedRef = ref as MutableRefObject<MarkdownEditorRef>

        const result = data?.metricTechnicalDescriptionSuggest?.result
        const { setValue } = forwardedRef?.current || {}

        if (setValue && result) {
          setValue(result)
        }
      } finally {
        setLoading(false)
      }
    }

    return (
      <AiFieldUpdateButton
        loading={loading}
        onClick={onClick}
        data-cy="metric-technicaldescription-suggest"
        {...props}
      />
    )
  }
)

export default AiPopulateTechnicalDescriptionButton
