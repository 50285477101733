import { HStack, Icon, IconButton, Link, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { Tooltip } from '@app/components/ui/tooltip'
import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'
import { toSentenceCase } from '@app/utils/stringHelpers'
import type { Entity } from '@graphql/queries'

const humanReadableSource = (name, foreignType) => {
  let description = ''

  if (foreignType) {
    description = `(${toSentenceCase(foreignType)})`
  }

  return `${toSentenceCase(name)} ${description}`
}

export interface SourceProps {
  entity?: Pick<Entity, 'sourceName' | 'sourceUrl' | 'foreignType'>
  asButton?: boolean
  size?: CardSize
}

const Source: FC<SourceProps> = ({ entity = null, asButton = true, size = 'normal' }) => {
  const fontSize = cardFontSize('sm', size)

  if (!entity) {
    return null
  }

  const { sourceName, sourceUrl, foreignType } = entity
  const source = toSentenceCase(sourceName)
  let child

  if (!sourceName || ['api', 'web'].includes(sourceName)) {
    return null
  }

  if (asButton) {
    const IconEl = ICON_MAP[source]
    child = (
      <IconButton aria-label="Click to visit source" colorPalette="gray" variant="ghost">
        <IconEl />
      </IconButton>
    )
  } else {
    const IconEl = ICON_MAP[source]
    child = (
      <HStack color="gray.700" fontSize={fontSize}>
        <Icon boxSize={size === 'normal' ? 4 : 6} color={COLOR_MAP[source]}>
          <IconEl />
        </Icon>
        <Text color="gray.400" fontSize={fontSize}>
          {humanReadableSource(sourceName, foreignType)}
        </Text>
      </HStack>
    )
  }

  const iconContainer = sourceUrl ? (
    <Link href={sourceUrl} rel="onoopener noreferrer" target="_blank">
      <Tooltip content={`Go to ${humanReadableSource(sourceName, foreignType)}`}>{child}</Tooltip>
    </Link>
  ) : (
    child
  )

  return iconContainer
}

export default Source
