import { Link as ChakraLink, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import MetricSegments from './metricSegments'

import Can from '@app/shared/authorization/can'
import type { Metric, SegmentFilter } from '@graphql/queries'

interface Props {
  data: {
    id: string
    date: string
    segmentFilters: SegmentFilter[]
    metric: Metric
  }
}

const MetricDate: FC<Props> = ({ data }) => {
  const { id, date, segmentFilters } = data

  const component = (
    <Text>
      {date}
      {/* THIS IS FACTORING IN USER OFFSET SO THE DATES LOOK 1 DAY OFF. */}
      {/* {format(new Date(date), 'PPP')} */}
    </Text>
  )

  return (
    <Can I="update" a="metricDataPoint" passThrough>
      {(allowed) => {
        if (allowed) {
          return (
            <Stack>
              <ChakraLink asChild>
                <Link to={`${id}/edit`}>{component}</Link>
              </ChakraLink>
              {segmentFilters && <MetricSegments data={data} />}
            </Stack>
          )
        }

        return component
      }}
    </Can>
  )
}

export default MetricDate
