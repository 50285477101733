import { useShallow } from 'zustand/react/shallow'

import { useStore, getObjectsByProperties } from '@app/store'
import type { MapDomainComment } from '@app/types'

type UseGetComments = (commentableType: string, commentableId: string) => MapDomainComment[]

const useGetComments: UseGetComments = (commentableType, commentableId) =>
  useStore(
    useShallow((state) =>
      getObjectsByProperties(state, 'comment', { commentableType, commentableId }).sort(function compare(a, b) {
        const dateA = new Date(a.createdAt).valueOf()
        const dateB = new Date(b.createdAt).valueOf()

        return dateB - dateA
      })
    )
  )

export default useGetComments
