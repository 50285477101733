'use client'

import { ChakraProvider, createSystem, defaultConfig } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'

import { ColorModeProvider } from './color-mode'

const system = createSystem(defaultConfig, {
  globalCss: {
    body: {
      colorPalette: 'cyan'
    }
  },
  theme: {
    tokens: {
      colors: {
        gray: {
          25: { value: '#FCFBFC' },
          50: { value: '#f4f8fa' },
          950: { value: '#14151e' }
        }
      },
      fonts: {
        body: { value: 'var(--font-geist)' }
      }
    },

    semanticTokens: {
      radii: {
        l1: { value: '0.125rem' },
        l2: { value: '0.25rem' },
        l3: { value: '0.375rem' }
      },
      colors: {
        'bg.canvas': {
          value: {
            base: '{colors.gray.25}',
            _light: '{colors.gray.25}',
            _dark: '{colors.gray.950}'
          }
        }
      }
    }
  }
})

export const Provider = (props: PropsWithChildren) => (
  <ChakraProvider value={system}>
    <ColorModeProvider>{props.children}</ColorModeProvider>
  </ChakraProvider>
)
