import { Center, Container, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC, FormEventHandler } from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { toaster } from '@app/components/ui/toaster'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import { useReportCreateMutation } from '@graphql/queries'

type Props = {
  strategyId: string
}
const ReportsEmpty: FC<Props> = ({ strategyId }) => {
  const [loading, setLoading] = useState(false)
  const [, createReport] = useReportCreateMutation()
  const navigate = useNavigate()

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    setLoading(true)

    createReport({ input: { strategyId, name: 'Untitled Report' } })
      .then((response) => {
        if (response?.data?.reportCreate?.report) {
          navigate(`${response.data.reportCreate.report.id}`)
        } else {
          const error =
            response.error.message || response?.data?.reportCreate?.errors[0]?.message || 'Failed to create the report'
          toaster.create({ type: 'error', title: 'Error', description: error })
        }
      })
      .catch((error) => {
        toaster.create({ type: 'error', title: 'Error', description: error })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Center w="100%">
      <Container maxW="lg" p={8}>
        <Stack gap={6}>
          <HStack>
            <Spacer />
            <DrawerCloseButton variant="ghost" />
          </HStack>

          <Text fontSize="xl" fontWeight="semibold" textAlign="center">
            Create your first report
          </Text>
          <Text textAlign="center">
            Reports are snapshots of how your strategy is performing that you can share with your team or stakeholders.
          </Text>
          <Button asChild>
            <Link to="recurring/create">Create a recurring report</Link>
          </Button>
          <form onSubmit={onSubmit}>
            <Button w="100%" loading={loading} type="submit">
              Create a one-time summary
            </Button>
          </form>
        </Stack>
      </Container>
    </Center>
  )
}

export default ReportsEmpty
