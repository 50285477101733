import type { FC } from 'react'

import ConfirmPopover from '@app/shared/confirmPopover'
import { useInvitationDeleteMutation } from '@graphql/queries'
import type { Invitation } from '@graphql/types'

interface Props {
  invitation: Invitation
  children?: React.ReactNode
}

const InvitationDeleteButton: FC<Props> = ({ invitation, children = null }) => {
  const [, deleteInvitation] = useInvitationDeleteMutation()

  const handleDeleteInvitation = async () => {
    await deleteInvitation({
      input: {
        invitationId: invitation.id
      }
    })
  }

  return (
    <ConfirmPopover
      onConfirm={() => handleDeleteInvitation()}
      header="Delete account"
      body="Are you sure you'd like to delete this invitation?"
      confirmButtonText="Confirm"
    >
      {children}
    </ConfirmPopover>
  )
}

export default InvitationDeleteButton
