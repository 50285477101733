import { HStack, IconButton, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiMail, FiTrash2 } from 'react-icons/fi'

import { toaster } from '@app/components/ui/toaster'
import { Tooltip } from '@app/components/ui/tooltip'
import InvitationDeleteButton from '@app/pages/settings/organization/components/invitationDeleteButton'
import Can from '@app/shared/authorization/can'
import type { Invitation } from '@graphql/queries'
import { useInvitationResendMutation } from '@graphql/queries'

interface Props {
  invitation: Invitation
}

const InvitationsListActions: FC<Props> = ({ invitation }) => {
  const [, invitationResend] = useInvitationResendMutation()

  const resendInvitationHandler = async () => {
    await invitationResend({
      input: { invitationId: invitation.id }
    })

    toaster.create({
      title: 'Invitation resent',
      description: 'We shot off another invite email, they should have it soon.',
      type: 'success',
      duration: 5000
    })
  }

  return (
    <Can I="update" an="account">
      <HStack gap={0}>
        <Spacer />
        <Tooltip content="Resend invite">
          <IconButton aria-label="Resend invite" onClick={resendInvitationHandler} variant="ghost">
            <FiMail />
          </IconButton>
        </Tooltip>
        <InvitationDeleteButton invitation={invitation}>
          <Tooltip content="Delete invite">
            <IconButton color="error" aria-label="Rescind invite" variant="ghost">
              <FiTrash2 />
            </IconButton>
          </Tooltip>
        </InvitationDeleteButton>
      </HStack>
    </Can>
  )
}

export default InvitationsListActions
