import { HStack, Link, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { ClipboardIconButton, ClipboardInput, ClipboardLabel, ClipboardRoot } from '@app/components/ui/clipboard'
import { InputGroup } from '@app/components/ui/input-group'
import type { Strategy } from '@graphql/queries'
import { SharingTypesEnum } from '@graphql/queries'

interface Props {
  strategy: Pick<Strategy, 'id' | 'token'>
  sharing: SharingTypesEnum
}

const StrategyFormUrl: FC<Props> = ({ strategy = null, sharing = SharingTypesEnum.PrivateAccess }) => {
  let url = ''

  if (strategy?.id) {
    const strategyToken = sharing === SharingTypesEnum.TokenAccess ? strategy.token : null
    const shareUrl = `${window.location.origin}/strategy/${strategy.id}/map`

    url = strategyToken ? `${shareUrl}?token=${strategyToken}` : shareUrl
  }

  if (!strategy) {
    return null
  }

  return (
    <Stack gap={4}>
      <HStack gap={1}>
        <Text fontSize="sm" fontWeight="medium">
          Access link -
        </Text>
        <Link color="link" fontSize="sm" wordBreak="break-all" href={url} rel="onoopener noreferrer" target="_blank">
          Open in new tab
        </Link>
      </HStack>
      <ClipboardRoot value={url}>
        <ClipboardLabel>Share URL</ClipboardLabel>
        <InputGroup width="full" endElement={<ClipboardIconButton me="-2" />}>
          <ClipboardInput readOnly value={url} />
        </InputGroup>
      </ClipboardRoot>
    </Stack>
  )
}

export default StrategyFormUrl
