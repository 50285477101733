import { IconButton, Spinner } from '@chakra-ui/react'
import type { FC } from 'react'

import { Tooltip } from '@app/components/ui/tooltip'
import useDuplicateNodes from '@app/hooks/useDuplicateNodes'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  nodes: Parameters<typeof useDuplicateNodes>[0]
}

const DuplicateButton: FC<Props> = ({ nodes }) => {
  const { duplicate, loading } = useDuplicateNodes(nodes)

  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  const handleClick = () => duplicate()

  return (
    <Tooltip content="Copy">
      <IconButton borderRadius="none" aria-label="copy" onClick={handleClick} size="sm" variant="outline">
        {loading ? <Spinner size="xs" /> : <ICON_MAP.Copy />}
      </IconButton>
    </Tooltip>
  )
}

export default DuplicateButton
