import type { FC } from 'react'
import { memo } from 'react'

import AiAssistantButton from './components/aiAssistantButton'
import CommentsButton from './components/commentsButton'
import CommonButtons from './components/commonButtons'
import DetailsButton from './components/detailsButton'
import EventsButton from './components/eventsButton'
import Toolbar from './components/toolbar'

import type { MapDomainNode } from '@app/types'

interface Props {
  node: MapDomainNode
}

const BasicCardToolbar: FC<Props> = ({ node }) => {
  const { strategyId } = node
  const route = `/strategy/${strategyId}/map/basicCard/${node.data.id}`

  return (
    <Toolbar strategyId={strategyId}>
      <DetailsButton path={route} />
      <EventsButton path={`${route}/events`} />
      <CommentsButton path={`${route}/comments`} />
      <AiAssistantButton node={node} />
      <CommonButtons node={node} />
    </Toolbar>
  )
}

export default memo(BasicCardToolbar)
