import {
  Box,
  Center,
  Fieldset,
  Group,
  HStack,
  Input,
  Spacer,
  Stack,
  StackSeparator,
  VisuallyHidden
} from '@chakra-ui/react'
import set from 'lodash/set'
import type { FC, FormEventHandler } from 'react'
import { useCallback, useState } from 'react'
import Img from 'react-cloudimage-responsive'
import { useAsyncValue, Form } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { FileUploadDropzone, FileUploadRoot } from '@app/components/ui/file-upload'
import { Skeleton } from '@app/components/ui/skeleton'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import FormAlert from '@app/next/forms/formAlert'
import ToggleInput from '@app/next/forms/toggleInput'
import UppyRoot from '@app/next/forms/uppyRoot'
import useForm from '@app/next/forms/useForm'
import SsoForm from '@app/pages/settings/organization/components/ssoForm'
import StatIntervals from '@app/pages/settings/organization/components/statIntervals'
import GatedFeature from '@app/shared/gatedFeature'
import withAwait from '@app/shared/withAwait'
import type { Organization } from '@graphql/queries'
import { useOrganizationUpdateMutation } from '@graphql/queries'
import type { OrganizationUpdateInput } from '@graphql/types'

interface Props {
  uploading?: boolean
  logo?: string
}

const LogoPreview: FC<Props> = ({ uploading = false, logo = null }) => {
  let rendered = null

  if (!logo) {
    return null
  }

  if (uploading) {
    rendered = <Skeleton w="full" h="256px" />
  } else {
    const params = { w: '256', h: '256', org_if_sml: '1', func: 'bound' }

    rendered = (
      <Center h="256px">
        <Img params={params} src={logo} alt="logo" lazyLoading={false} doNotReplaceURL />
      </Center>
    )
  }

  return (
    <Box w="full" h="256px" bg="bg" borderWidth={1} borderColor="border">
      {rendered}
    </Box>
  )
}

const OrganizationForm = () => {
  const [uploading, setUploading] = useState(false)
  const { errors } = useForm({})
  const organization = useAsyncValue() as Organization
  const googleSheet = useGetObjectsByProperties('googleSheet', { organizationLevel: true })?.[0]
  const [, updateOrganization] = useOrganizationUpdateMutation()

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      const form = e.currentTarget
      const data = new FormData(form)
      const entries = Array.from(data.entries())

      const input: OrganizationUpdateInput = {
        organizationId: organization?.id
      }

      for (const [key, value] of entries) {
        set(input, key, value)
      }

      try {
        const response = await updateOrganization({ input })

        if (!response?.data?.organizationUpdate?.organization) {
          form.reset()
        }
      } catch (_e) {
        // nothing
      }
    },
    [organization?.id, updateOrganization]
  )

  return (
    <Stack gap={{ base: 4, sm: 6 }} separator={<StackSeparator />}>
      <Form onSubmit={onSubmit} id="organization-form">
        <VisuallyHidden asChild>
          <Input defaultValue={organization?.id} name="organizationId" />
        </VisuallyHidden>
        <Fieldset.Root>
          <Stack>
            <Fieldset.Legend>General</Fieldset.Legend>
            <Fieldset.HelperText>
              These details are used to customize your experience and changelogs that you can share with stakeholders or
              customers.
            </Fieldset.HelperText>
          </Stack>
          <Fieldset.Content>
            <FormAlert
              description={(errors as { global: { message: string } })?.global?.message}
              title="Failed to update your organization!"
            />
            <Stack gap="5">
              <Field label="Name" required invalid={!!errors?.name} errorText={errors?.name?.message}>
                <Input bg="bg" defaultValue={organization?.name} name="name" placeholder="Acme Co." />
              </Field>
              <Field label="Homepage URL" invalid={!!errors?.homepageUrl} errorText={errors?.homepageUrl?.message}>
                <Input
                  bg="bg"
                  defaultValue={organization?.homepageUrl}
                  name="homepageUrl"
                  placeholder="https://acme.co"
                />
              </Field>
              <Field label="Twitter handle" invalid={!!errors?.twitterUrl} errorText={errors?.twitterUrl?.message}>
                <Input bg="bg" defaultValue={organization?.twitterUrl} name="twitterUrl" placeholder="@acme" />
              </Field>
              <Field label="Primary color" errorText={errors?.primaryColor?.message}>
                <Input
                  w="full"
                  px={0}
                  defaultValue={organization?.primaryColor || 'blue.700'}
                  name="primaryColor"
                  type="color"
                />
              </Field>
              <UppyRoot
                fileTypes={['image/gif', 'image/jpeg', 'image/png']}
                onUploadStart={() => setUploading(true)}
                onUploadSuccess={() => setUploading(false)}
                onUploadError={() => setUploading(false)}
              >
                {({ uploading: logoUploading, shrineHash, onFileAccept }) => (
                  <Field label="Logo">
                    <HStack w="full">
                      <FileUploadRoot
                        gap="1"
                        onFileAccept={onFileAccept}
                        maxW="xl"
                        alignItems="stretch"
                        maxFiles={1}
                        accept={['image/gif', 'image/jpeg', 'image/png']}
                      >
                        <VisuallyHidden asChild>
                          <Input name="logo" readOnly value={shrineHash} />
                        </VisuallyHidden>
                        <FileUploadDropzone
                          label="Drag and drop or click here to upload a logo"
                          description=".png, .jpg up to 10MB"
                        />
                      </FileUploadRoot>
                      <Spacer />
                      <LogoPreview logo={organization?.logo} uploading={logoUploading} />
                    </HStack>
                  </Field>
                )}
              </UppyRoot>

              <Field label="Preferred data import time">
                <Input
                  bg="bg"
                  defaultValue={organization?.defaultImportTime || '08:00'}
                  name="defaultImportTime"
                  pattern="[0-9]{1,2}:[0-9]{2}(\s?[aApP]\.?[mM]\.?)?"
                  type="time"
                />
              </Field>

              <Field label="Default statistic intervals">
                <Stack w="full">
                  <StatIntervals organization={organization} errors={errors} />
                </Stack>
              </Field>

              {googleSheet && (
                <Field
                  label="Auto-import Google sheet data"
                  invalid={!!errors?.autoImportGoogleSheet}
                  errorText={errors?.autoImportGoogleSheet?.message}
                >
                  <ToggleInput name="autoImportGoogleSheet" defaultValue={organization?.autoImportGoogleSheet} />
                </Field>
              )}
            </Stack>
            <Group justifyContent="end">
              <Button disabled={uploading} type="submit">
                Save settings
              </Button>
            </Group>
          </Fieldset.Content>
        </Fieldset.Root>
      </Form>

      <GatedFeature featureId="feature-sso">
        {({ entitlement }) => {
          if (!entitlement) {
            return null
          }

          if (entitlement && !entitlement.hasAccess) {
            return null
          }

          return (
            <Form method="post" id="organization-sso-form" encType="multipart/form-data" onSubmit={onSubmit}>
              <VisuallyHidden asChild>
                <Input defaultValue={organization?.id} name="organizationId" />
              </VisuallyHidden>
              <SsoForm organization={organization} errors={errors} loading={uploading} />
            </Form>
          )
        }}
      </GatedFeature>
    </Stack>
  )
}

export default withAwait(OrganizationForm, 'organization')
