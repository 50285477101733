import { Icon } from '@chakra-ui/react'
import { useState } from 'react'
import { BiSync } from 'react-icons/bi'

import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@app/components/ui/menu'
import { toaster } from '@app/components/ui/toaster'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import Can from '@app/shared/authorization/can'
import ICON_MAP from '@app/utils/iconMap'
import { useGoogleSheetImportMutation } from '@graphql/queries'

const GoogleSheetSidebarMenu = () => {
  const googleSheet = useGetObjectsByProperties('googleSheet', { organizationLevel: true })?.[0]

  const [, importSheet] = useGoogleSheetImportMutation()
  const [loading, setLoading] = useState(false)

  if (!googleSheet) {
    return null
  }

  const onImport = () => {
    setLoading(true)

    importSheet({
      input: {
        googleSheetId: googleSheet?.id
      }
    }).finally(() => {
      setLoading(false)
    })

    toaster.create({
      title: 'Importing started',
      description: 'We are importing your data from Google Sheets. This may take a few minutes.'
    })
  }

  return (
    <Can I="update" a="metric">
      <MenuRoot>
        <MenuTrigger fontSize="sm" aria-label="Additional actions">
          <Icon _hover={{ bgColor: 'bg.subtle' }}>
            <ICON_MAP.Ellipsis />
          </Icon>
        </MenuTrigger>
        <MenuContent>
          <MenuItem value="import_latest_from_google_sheets" disabled={loading} onClick={onImport}>
            <BiSync />
            Import latest data from Google Sheets
          </MenuItem>
        </MenuContent>
      </MenuRoot>
    </Can>
  )
}

export default GoogleSheetSidebarMenu
