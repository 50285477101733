import { Fieldset, Group, Input, Stack, Separator, Textarea, VisuallyHidden } from '@chakra-ui/react'
import toLower from 'lodash/toLower'
import type { FC } from 'react'
import { useState } from 'react'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { FileInput, FileUploadLabel, FileUploadRoot } from '@app/components/ui/file-upload'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import type { FormErrors } from '@app/types'
import IdPMetadataReader from '@app/utils/idpMetadataReader'
import { origin, Paths } from '@app/utils/routeHelpers'
import type { Organization } from '@graphql/queries'

interface SSOUrlProps {
  label: string
  value: string
}

const SSOUrl: FC<SSOUrlProps> = ({ label, value }) => (
  <Field label={label}>
    <Input bg="bg" readOnly size="sm" value={value} />
  </Field>
)

interface SsoFormProps {
  organization: Organization
  errors?: FormErrors
  loading?: boolean
}

const SsoForm: FC<SsoFormProps> = ({ organization, errors = {}, loading = false }) => {
  const [idpEntityId, setIdpEntityId] = useState(organization.idpEntityId || '')
  const [idpSsoServiceUrl, setIdpSsoServiceUrl] = useState(organization.idpSsoServiceUrl || '')
  const [idpCert, setIdpCert] = useState(organization.idpCert || '')

  const handleMetadataUpload = (e) => {
    if (!(e.acceptedFiles.length > 0)) {
      return
    }

    const file = e.acceptedFiles[0]

    const reader = new FileReader()
    const listener = (event) => {
      const metadata = new IdPMetadataReader(event.target.result)

      setIdpEntityId(metadata.entityId)
      setIdpSsoServiceUrl(metadata.identityProviderUrl)
      setIdpCert(metadata.signingCert)
      reader.removeEventListener('load', listener)
    }

    reader.addEventListener('load', listener)

    reader.readAsText(file)
  }

  return (
    <>
      <VisuallyHidden asChild>
        <Input defaultValue={organization?.id} name="organizationId" />
      </VisuallyHidden>
      <Fieldset.Root>
        <Stack>
          <Fieldset.Legend>Single Sign On</Fieldset.Legend>
          <Fieldset.HelperText>SAML based SSO configuration</Fieldset.HelperText>
        </Stack>
        <Fieldset.Content>
          <SSOUrl
            label="SSO Login URL"
            value={Paths.samlOrganizationParamPath({ ssoIdentifier: organization.ssoIdentifier }, { fullPath: true })}
          />
          <SSOUrl
            label="ACS URL"
            value={Paths.acsUrlPath({ organization: toLower(organization.name) }, { fullPath: true })}
          />
          <SSOUrl label="Audience URI / SP Entity ID" value={origin()} />
          <Separator />
          <Stack gap="5">
            <Field label="Provider" invalid={!!errors?.ssoProvider} errorText={errors?.ssoProvider?.message}>
              <NativeSelectRoot>
                <NativeSelectField name="ssoProvider" defaultValue={organization.ssoProvider} bg="bg">
                  <option value="azure">Azure</option>
                  <option value="google">Google</option>
                  <option value="okta">Okta</option>
                  <option value="custom">Custom</option>
                </NativeSelectField>
              </NativeSelectRoot>
            </Field>
            <Field label="Issuer" invalid={!!errors?.issuer} errorText={errors?.issuer?.message}>
              <Input
                bg="bg"
                defaultValue={organization.issuer}
                name="issuer"
                placeholder="Issuer (required for Azure)"
              />
            </Field>
            <Field label="Entity ID" invalid={!!errors?.idpEntityId} errorText={errors?.idpEntityId?.message}>
              <Input
                bg="bg"
                name="idpEntityId"
                onChange={(e) => setIdpEntityId(e.target.value)}
                placeholder="Entity ID"
                value={idpEntityId}
              />
            </Field>
            <Field label="SSO URL" invalid={!!errors?.idpSsoServiceUrl} errorText={errors?.idpSsoServiceUrl?.message}>
              <Input
                bg="bg"
                name="idpSsoServiceUrl"
                onChange={(e) => setIdpSsoServiceUrl(e.target.value)}
                placeholder="SSO URL"
                value={idpSsoServiceUrl}
              />
            </Field>
            <Field label="X.509 certificate" required invalid={!!errors?.idpCert} errorText={errors?.idpCert?.message}>
              <Textarea
                bg="bg"
                name="idpCert"
                onChange={(e) => setIdpCert(e.target.value)}
                placeholder="X.509 Certificate"
                rows={10}
                value={idpCert}
              />
            </Field>
            <FileUploadRoot gap={1} accept="text/xml" onFileChange={(e) => handleMetadataUpload(e)}>
              <FileUploadLabel>Upload IdP metadata file</FileUploadLabel>
              <FileInput placeholder="IdP metadata.xml file" bg="bg" />
            </FileUploadRoot>
          </Stack>
          <Group justifyContent="end">
            <Button disabled={loading} type="submit">
              Save settings
            </Button>
          </Group>
        </Fieldset.Content>
      </Fieldset.Root>
    </>
  )
}

export default SsoForm
