import type { Alert as AlertProps } from '@chakra-ui/react'
import { Stack, Text, HStack, Icon, Separator } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import pluralize from 'pluralize'
import type { FC } from 'react'

import CollapsedNode from './collapsedNode'

import { Alert } from '@app/components/ui/alert'
import { Tooltip } from '@app/components/ui/tooltip'
import useGetIncomingCollapsedNodes from '@app/hooks/useGetIncomingCollapsedNodes'
import CardSection from '@app/shared/cards/components/cardSection'
import type { CardSize, MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

type Props = {
  node: MapDomainNode
}

const keyToDisplayName = (key: string) => {
  switch (key) {
    case 'work':
      return 'Work'
    default:
      return pluralize(capitalize(key))
  }
}

const keyToIcon = (key: string) => {
  switch (key) {
    case 'Key Result':
      return ICON_MAP.Metric
    default:
      return ICON_MAP[capitalize(key)]
  }
}

const netConfidenceScore = (confidenceScore) => {
  if (confidenceScore === null) {
    return {}
  }

  // if -100 to -1 return a hash of "Needs Improvement" and color red
  // if 0 to 30 return a hash of "Okay" and color yellow
  // if 31 to 70 return a hash of "Good Confidence" and color light green
  // if 71 to 100 return a hash of "Great" and color green
  if (confidenceScore < 0) {
    return {
      text: 'Needs Improvement',
      status: 'error',
      score: confidenceScore
    }
  }
  if (confidenceScore < 31) {
    return {
      text: 'Okay',
      status: 'warning',
      score: confidenceScore
    }
  }
  if (confidenceScore < 71) {
    return {
      text: 'Good',
      status: 'success',
      score: confidenceScore
    }
  }
  return {
    text: 'Great',
    status: 'success',
    score: confidenceScore
  }
}

const CollapsedNodes: FC<Props> = ({ node }) => {
  const { nodes, groups, confidenceScore } = useGetIncomingCollapsedNodes(node)
  const confidence = netConfidenceScore(confidenceScore)

  if (!nodes.length) {
    return null
  }

  const groupKeys = Object.keys(groups)
  // Metrics -> Basic Cards -> Works -> Strategy
  const sortAlgorithm = (key) => {
    switch (key) {
      case 'Metric':
        return 0
      case 'work':
        return 4
      case 'Strategy':
        return 5
      case 'Section':
        return 6
      default:
        // basic card free form types
        return 2
    }
  }

  const netConfidenceScoreDisplay = () => {
    if (isEmpty(confidence)) {
      return null
    }

    return (
      <Tooltip aria-label={confidence.score} content={`Net confidence score: ${confidence.score}%`}>
        <Alert title={`Confidence: ${confidence.text}`} status={confidence.status as AlertProps.RootProps['status']} />
      </Tooltip>
    )
  }

  const sortedKeys = sortBy(groupKeys, sortAlgorithm)
  return sortedKeys.map((key, index) => {
    const IconComponent = keyToIcon(key)

    return (
      <Stack key={`node-collapsed-group-${node.id}-${key}`}>
        {index === 0 && netConfidenceScoreDisplay()}
        {index > 0 && <Separator borderColor="border.emphasized" borderTopWidth="1px" />}
        <CardSection>
          <Stack>
            {key !== 'null' && (
              <HStack gap={1}>
                {keyToIcon(key) && (
                  <Icon boxSize={4} color="fg.subtle">
                    <IconComponent />
                  </Icon>
                )}
                <Text px={1} color="fg.muted" fontSize="md" fontWeight="semibold">
                  {keyToDisplayName(key)}
                </Text>
              </HStack>
            )}
            <Stack>
              {groups[key].map((nodeObject) => {
                const collapsedNode = nodes.find((n) => n.id === nodeObject.rfId)

                return (
                  <CollapsedNode
                    key={`node-${collapsedNode.id}-node-object-${nodeObject.id}`}
                    node={collapsedNode}
                    nodeObject={nodeObject}
                    size={node?.metadata?.size || ('normal' as CardSize)}
                  />
                )
              })}
            </Stack>
          </Stack>
        </CardSection>
      </Stack>
    )
  })
}

export default CollapsedNodes
