import { Text } from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import type { FC } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import type { Account } from '@graphql/types'

interface Props {
  data: Account
}

const OrganizationCell: FC<Props> = ({ data: account }) => {
  const { user } = useStoreCurrentUser()
  const { organizationId } = account

  return (
    <Text
      color={user?.organization?.id === organizationId ? 'colorPalette.fg' : 'fg'}
      fontSize="sm"
      fontWeight={user?.organization?.id === organizationId ? 'semibold' : ''}
      title={account.organizationName}
    >
      {truncate(account.organizationName, { length: 50 })}
    </Text>
  )
}

export default OrganizationCell
