import { Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import MetricFormatSelect from './metricFormatSelect'

import { Field } from '@app/components/ui/field'
import ToggleInput from '@app/next/forms/toggleInput'
import type { FormErrors, MapDomainMetric } from '@app/types'
import { DisplayFormatEnum } from '@graphql/queries'

type Props = {
  metric: MapDomainMetric
  errors: FormErrors
}

const MetricDisplayFormatSection: FC<Props> = ({ metric, errors }) => {
  const [displayFormat, setDisplayFormat] = useState(metric?.displayFormat)
  const displayRedecimalPercentages = displayFormat === DisplayFormatEnum.Percent

  return (
    <Stack>
      <Field label="Format" required invalid={!!errors?.displayFormat} errorText={errors?.displayFormat?.message}>
        <MetricFormatSelect
          name="displayFormat"
          placeholder={null}
          defaultValue={metric?.displayFormat}
          onChange={(e) => setDisplayFormat(e.target.value as DisplayFormatEnum)}
        />
      </Field>
      {displayRedecimalPercentages && (
        <Field
          label="Multiply values by 100"
          invalid={!!errors?.redecimalPercentages}
          errorText={errors?.redecimalPercentages?.message}
        >
          <ToggleInput name="redecimalPercentages" defaultValue={metric?.redecimalPercentages} />
        </Field>
      )}
    </Stack>
  )
}

export default MetricDisplayFormatSection
