import { Outlet, useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import Header from '@app/pages/entities/components/header'
import { PageStack } from '@app/shared/layout'

const Show = () => {
  const { nodeId, strategyId } = useParams()

  const entity = useGetObject(nodeId, 'entity')

  return (
    <PageStack>
      <Header entity={entity} strategyId={strategyId} />
      <Outlet />
    </PageStack>
  )
}

export default Show
