import type { RouteObject } from 'react-router-dom'
import { defer } from 'react-router-dom'

import MetricSourceShow from '@app/pages/metrics/components/metricSource/metricSourceShow'
import MetricSourceTab from '@app/pages/metrics/tabs/metricSourceTab'
import { useStore } from '@app/store'
import { requiresAuthorization } from '@app/utils/auth'
import { Credentials } from '@graphql/documents/credential.graphql'
import { GoogleIntegration } from '@graphql/documents/integration.graphql'
import { MetricSource } from '@graphql/documents/metric_source.graphql'
import type { MetricSourceQuery } from '@graphql/queries'

const loadMetricSource = async ({ params }) => {
  const { nodeId } = params
  const { loaderQuery: storeLoaderQuery } = useStore.getState()

  // creating, showing, and editing are all done in this route, so we need the creds.
  storeLoaderQuery(Credentials)

  // Some Google sources need to refer to the org's Integration for inputs
  storeLoaderQuery(GoogleIntegration)

  const metricSourcePromise = storeLoaderQuery<MetricSourceQuery>(MetricSource, {
    metricId: nodeId
  })

  return defer({ metricSource: metricSourcePromise })
}

const routes: (namespace?: string | null) => RouteObject = (namespace) => ({
  path: 'source',
  id: namespace ? `${namespace}-metric-source` : 'metric-source',
  loader: async ({ params }) => {
    await requiresAuthorization('create', 'metricSource')

    return loadMetricSource({ params })
  },
  element: <MetricSourceTab />,
  children: [
    {
      index: true,
      element: <MetricSourceShow />
    },
    {
      path: 'activities',
      element: <MetricSourceShow />
    }
  ]
})

export default routes
