import type { FC, MutableRefObject } from 'react'
import { useImperativeHandle, useState } from 'react'

import LookFieldSelectInput from './lookFieldSelectInput'
import LookSelectInput from './lookSelectInput'

import { Field } from '@app/components/ui/field'
import type { MetricSourceFormApi } from '@app/types'
import type { MetricSource } from '@graphql/types'

export type Props = {
  metricSource: Pick<MetricSource, 'configuration'> | null
  credentialId: string
  disabled: boolean
  apiRef: MutableRefObject<MetricSourceFormApi>
}

const LookerApi: FC<Props> = ({ apiRef, disabled, credentialId, metricSource }) => {
  const defaultLookId = metricSource?.configuration?.look_id || ''
  const [lookId, setLookId] = useState<string>(defaultLookId)

  useImperativeHandle(
    apiRef,
    () => ({
      reset: () => {
        setLookId(defaultLookId)
      },
      clear: () => {
        setLookId('')
      }
    }),
    [defaultLookId]
  )

  return (
    <>
      <Field disabled={disabled} required>
        <LookSelectInput
          name="configuration.look_id"
          credentialId={credentialId}
          placeholder={null}
          defaultValue={defaultLookId}
          onChange={(look) => setLookId(look.value)}
        />
      </Field>
      {lookId && (
        <>
          <Field label="Date field" disabled={disabled} required>
            <LookFieldSelectInput
              name="configuration.date_field"
              placeholder=""
              defaultValue={metricSource?.configuration?.date_field}
              credentialId={credentialId}
              lookId={lookId}
            />
          </Field>
          <Field label="Value field" disabled={disabled} required>
            <LookFieldSelectInput
              name="configuration.value_field"
              placeholder=""
              defaultValue={metricSource?.configuration?.value_field}
              credentialId={credentialId}
              lookId={lookId}
            />
          </Field>
        </>
      )}
    </>
  )
}

export default LookerApi
