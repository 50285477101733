import type { IconButtonProps } from '@chakra-ui/react'
import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiList } from 'react-icons/pi'

import ICON_MAP from '@app/utils/iconMap'

interface Props extends IconButtonProps {
  open: boolean
  show?: boolean
}

// eslint-disable-next-line import/prefer-default-export
export const ToggleButton: FC<Props> = ({ open, show = true, ...iconButtonProps }) => {
  if (!show) {
    return null
  }

  return (
    <IconButton zIndex="skipLink" color={open ? 'white' : 'default'} aria-label="Toggle menu" {...iconButtonProps}>
      {open ? <ICON_MAP.Close /> : <PiList />}
    </IconButton>
  )
}
