import { Box, Container, Flex, Stack } from '@chakra-ui/react'
import groupBy from 'lodash/groupBy'
import type { ChangeEvent, FC } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { EmptyState } from '@app/components/ui/empty-state'
import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import Goals from '@app/pages/goals/components/goals'
import SendReportButton from '@app/pages/shared/sendReportButton'
import Can from '@app/shared/authorization/can'
import { Card, PageStack } from '@app/shared/layout'
import type { Goal } from '@graphql/types'
import { GoalStatusEnum, RecurringReportConfigurationServiceEnum } from '@graphql/types'

const Index: FC = () => {
  const { strategyId } = useParams()
  const [goalFilter, setGoalFilter] = useState<GoalStatusEnum>(GoalStatusEnum.Current)
  const goals = useGetObjectsByProperties('goal', {
    status: goalFilter
  }) as Goal[]
  const groupedGoals = groupBy(goals || [], 'metricId')

  const handleFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setGoalFilter(e.target.value as GoalStatusEnum)
  }

  return (
    <PageStack>
      <Container maxW="3xl">
        <Stack gap={8}>
          <Card>
            <Flex gap={2}>
              <Box flex={1}>
                <Field label="Status">
                  <NativeSelectRoot>
                    <NativeSelectField placeholder="" value={goalFilter} onChange={handleFilterChange} bg="bg">
                      <option value={GoalStatusEnum.Past}>Past</option>
                      <option value={GoalStatusEnum.Current}>Current</option>
                      <option value={GoalStatusEnum.Future}>Future</option>
                    </NativeSelectField>
                  </NativeSelectRoot>
                </Field>
              </Box>
              <Can I="view" a="beta">
                <Box mt="26px">
                  <SendReportButton
                    strategyId={strategyId}
                    reportType={RecurringReportConfigurationServiceEnum.SendGoals}
                    variant="subtle"
                    size="md"
                  />
                </Box>
              </Can>
            </Flex>
          </Card>
          <Stack gap={4}>
            {Object.keys(groupedGoals).map((goalKey) => (
              <Card key={goalKey}>
                <Goals goals={groupedGoals[goalKey]} />
              </Card>
            ))}
          </Stack>
          {goals.length === 0 ? (
            <EmptyState title="No goals yet" description="Add some metric goals to see them here." />
          ) : null}
        </Stack>
      </Container>
    </PageStack>
  )
}

export default Index
