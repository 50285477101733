import capitalize from 'lodash/capitalize'

// eslint-disable-next-line import/prefer-default-export
export const toSentenceCase = (str) => capitalize(str.split('_').join(' '))

export const protocolifyLink = (link) => (!/^https?:\/\//i.test(link) ? `https://${link}` : link)

// Generated via ChatGPT
export const stringToColor = (str) => {
  // Generate a hash code from the input string
  let hashCode = 0
  for (let i = 0; i < str.length; i += 1) {
    hashCode = str.charCodeAt(i) + ((hashCode << 5) - hashCode) // eslint-disable-line no-bitwise
  }

  // Convert the hash code to a positive value
  hashCode = Math.abs(hashCode)

  // Convert the hash code to a hex color code
  const colorHex = `#${(hashCode % 0xffffff).toString(16).padStart(6, '0')}`

  return colorHex
}
