import { useSearchParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import { useStore, getNodesSelector } from '@app/store'
import type { MapDomainNode } from '@app/types'

type UseGetNodes = (strategyId: string) => MapDomainNode[]

const useGetNodes: UseGetNodes = (strategyId) => {
  const [searchParams] = useSearchParams()

  return useStore(useShallow((state) => getNodesSelector(state, strategyId, searchParams)))
}

export default useGetNodes
