import { useParams } from 'react-router-dom'

import Asana from './components/asana'
import Google from './components/google'
import Jira from './components/jira'
import Linear from './components/linear'
import Looker from './components/looker'
import Productboard from './components/productboard'
import Shortcut from './components/shortcut'
import Slack from './components/slack'

const Show = () => {
  const { name } = useParams()

  const cardMap = {
    jira: <Jira />,
    shortcut: <Shortcut />,
    linear: <Linear />,
    looker: <Looker />,
    slack: <Slack />,
    google: <Google />,
    asana: <Asana />,
    productboard: <Productboard />
  }

  return cardMap[name]
}

export default Show
