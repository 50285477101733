import type { FC } from 'react'

import { Avatar, AvatarGroup } from '@app/components/ui/avatar'
import type { CardSize } from '@app/types'
import profileImage from '@app/utils/profileImage'
import type { EntityContribution } from '@graphql/queries'

interface Props {
  contributors?: EntityContribution[]
  size?: CardSize
}

const CardContributors: FC<Props> = ({ contributors = [], size = 'normal' }) => {
  if (!contributors || contributors.length === 0) {
    return null
  }

  return (
    <AvatarGroup size={size === 'normal' ? 'xs' : 'sm'}>
      {contributors.slice(0, 3).map((contributor) => (
        <Avatar
          key={contributor?.id}
          name={contributor?.contributor?.name}
          src={profileImage(contributor?.contributor?.email)}
          title={contributor?.contributor?.name}
        />
      ))}
      {contributors.length > 3 && <Avatar fallback={`+ ${contributors.length - 3}`} />}
    </AvatarGroup>
  )
}

export default CardContributors
