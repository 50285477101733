import { Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import Table from '@app/shared/table'
import type { MetricSourceTestPayload } from '@graphql/queries'

interface MetricSourceTestResultsProps {
  testData?: MetricSourceTestPayload
  open?: boolean
  onClose?: () => void
}

const MetricSourceTestResults: FC<MetricSourceTestResultsProps> = ({ testData, open = false, onClose = () => {} }) => {
  const closeRef = useRef()
  let results

  const { data, error } = testData?.results || {}

  if (data) {
    const columns = []
    const keys = Object.keys(data[0] || {})

    keys.forEach((key) => {
      columns.push({ header: key, accessor: key })
    })

    results = <Table columns={columns} data={data.slice(0, 5)} />
  } else if (error) {
    results = (
      <Text pt={2} color="muted">
        {error}
      </Text>
    )
  }

  return (
    <DialogRoot
      initialFocusEl={closeRef?.current}
      onOpenChange={onClose}
      open={open}
      placement="center"
      role="alertdialog"
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Test Results</DialogTitle>
        </DialogHeader>

        <DialogBody>{results}</DialogBody>

        <DialogFooter>
          <DialogActionTrigger asChild>
            <Button ref={closeRef} variant="outline">
              Close
            </Button>
          </DialogActionTrigger>
        </DialogFooter>
        <DialogCloseTrigger />
      </DialogContent>
    </DialogRoot>
  )
}

export default MetricSourceTestResults
