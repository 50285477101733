import type { XYPosition } from '@xyflow/react'
import { getNodesBounds } from '@xyflow/react'

import type { MapDomainNode } from '@app/types'

type CopyPasteNode = Pick<MapDomainNode, 'nodeId' | 'classType' | 'strategyId'>

export const isMimeTypeImage = (type: string) => type.startsWith('image/')
export const encodeNodeCSV = (nodes: MapDomainNode[]) => {
  const { x, y } = getNodesBounds(nodes)

  // Right now this only takes nodes and prefixes them with DLN. If we want to encode
  // more data, we could check the type and build a prefix map like DLN for nodes, DLE for edges, etc.
  const csvArray = nodes.map((node) => `DLN:${node.nodeId},${node.classType},${node.strategyId}`)

  const csv = csvArray.join('\n')
  const boundingBox = `${x},${y}`

  return [csv, boundingBox].join('||')
}

export const decodeNodeCSV = (encoded: string) => {
  const [csv, boundingBoxString = ''] = encoded.split('||')
  const [x, y] = boundingBoxString.split(',').map((n) => parseInt(n, 10))

  const nodes = csv.split('\n').map((row) => {
    const [nodeId, classType, strategyId] = row.split(',')

    return { nodeId: nodeId.replace('DLN:', ''), classType, strategyId }
  })

  return {
    nodes,
    boundingBox: {
      x,
      y
    }
  }
}

export const nodesToBlob = (nodes: MapDomainNode[]): Blob => {
  const csv = encodeNodeCSV(nodes)

  return new Blob([csv], { type: 'text/plain' })
}

export const nodesFromBlob = async (blob: Blob): Promise<{ nodes: CopyPasteNode[]; boundingBox: XYPosition }> => {
  const text = await blob.text()
  return decodeNodeCSV(text)
}
