import type { ZustandFuncSelectors } from 'auto-zustand-selectors-hook'
import { createSelectorFunctions } from 'auto-zustand-selectors-hook'
import type { StateCreator } from 'zustand'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { initialAppState, appSlice } from './store/app'
import { initialMapSliceState, mapsSlice } from './store/maps'

import type { BoundedState, StoreState } from '@app/store/types'

export const initialBoundedState = initialAppState && initialMapSliceState

export const storeSlice: StateCreator<StoreState> = (set) => ({
  reset: () => {
    set(initialBoundedState as BoundedState)
  },
  initializeState: (state: BoundedState) => {
    set(state)
  }
})

export const useBoundedStore = create<BoundedState>()(
  devtools(
    (...a) =>
      ({
        ...storeSlice(...a),
        ...appSlice(...a),
        ...mapsSlice(...a)
      }) as BoundedState,
    {
      enabled: import.meta.env.PUBLIC_ENV === 'development'
    }
  )
)

export const useStore = createSelectorFunctions(useBoundedStore) as typeof useBoundedStore &
  ZustandFuncSelectors<BoundedState>
export * from './store/selectors'
