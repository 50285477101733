import type { FC, SuspenseProps } from 'react'
import { useMemo, Suspense } from 'react'

type WithSuspenseWrapper = <T>(WrappedComponent: FC<T>, fallback?: SuspenseProps['fallback']) => FC<T>

const withSuspenseWrapper: WithSuspenseWrapper =
  (WrappedComponent, fallback = null) =>
  (props) => {
    const memoizedWrapped = useMemo(() => <WrappedComponent {...props} />, [props])

    return <Suspense fallback={fallback}>{memoizedWrapped}</Suspense>
  }

export default withSuspenseWrapper
