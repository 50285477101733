import { useCallback, useMemo } from 'react'
import { useRevalidator } from 'react-router-dom'

import useEventSource from '@app/hooks/useEventSource'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { useStore } from '@app/store'
import type { BoundedState } from '@app/store/types'
import type { BroadcastEventsEnum } from '@graphql/types'

type Callback<T> = Parameters<typeof useEventSource<T, BroadcastEventsEnum>>[1]
type AddObjectData = Parameters<BoundedState['addObject']>[0]

const useSubscribeToOrganizationEvents = <T extends AddObjectData = AddObjectData>(callback?: Callback<T>) => {
  const { user } = useStoreCurrentUser()
  const organizationId = user?.organization?.id
  const addObject = useStore.use.addObject()
  const { revalidate } = useRevalidator()

  const id = useMemo(
    () => ({
      channel: 'EventsChannel',
      organization_id: organizationId
    }),
    [organizationId]
  )

  const cb: Callback<T> = useCallback(
    (d) => {
      const { event, data } = d

      if (event === 'DOMAIN_OBJECT_UPDATE') {
        addObject(data)
      } else if (event === 'REVALIDATE') {
        revalidate()
      }

      callback?.(d)
    },
    [addObject, callback, revalidate]
  )

  useEventSource<T, BroadcastEventsEnum>(id, cb)
}

export default useSubscribeToOrganizationEvents
