import type { RouteObject } from 'react-router-dom'

import Evals from '@app/pages/evals'

const routes: RouteObject = {
  path: 'evals',
  element: <Evals />
}

export default routes
