import { Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import { PasswordInput } from '@app/components/ui/password-input'
import type { FormErrors } from '@app/types'
import type { Credential } from '@graphql/types'

type Props = {
  credential?: Pick<Credential, 'apiKey' | 'region'>
}

const AmplitudeAuth: FC<Props> = ({ credential = {} }) => {
  const errors: FormErrors = {}
  const { apiKey, region } = credential

  return (
    <Stack>
      <Field required label="API Key" invalid={!!errors.apiKey} errorText={errors?.apiKey?.message}>
        <Input autoComplete="off" data-1p-ignore defaultValue={apiKey} name="apiKey" placeholder="Amplitude API key" />
      </Field>
      <Field
        helperText={!isEmpty(credential) ? 'Leave blank to keep the current secret or fill in to set a new one' : null}
        label="Secret Key"
        invalid={!!errors?.secretKey}
        errorText={errors?.secretKey?.message}
        required={isEmpty(credential)}
      >
        <PasswordInput name="secretKey" placeholder="Amplitude secret key" autoComplete="off" data-1p-ignore />
      </Field>
      <Field label="Region">
        <NativeSelectRoot>
          <NativeSelectField name="region" defaultValue={region}>
            <option value="us">US</option>
            <option value="eu">EU</option>
          </NativeSelectField>
        </NativeSelectRoot>
      </Field>
      {!!Object.keys(credential || {}).length && (
        <VisuallyHidden asChild>
          <Input defaultValue="secretKey" name="secretFields" />
        </VisuallyHidden>
      )}
    </Stack>
  )
}

export default AmplitudeAuth
