import { Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import ClassificationsTable from './classificationsTable'
import Contributors from './contributors'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import EntityLabelsAutocomplete, {
  type Props as EntityLabelsAutocompleteProps
} from '@app/shared/autocomplete/labels/entityLabelsAutocomplete'
import type { MapDomainEntity } from '@app/types'

export interface Props {
  entity: EntityLabelsAutocompleteProps['entity'] & Pick<MapDomainEntity, 'entityContributions' | 'classifications'>
  isListView?: boolean
}

const EditorDetails: FC<Props> = ({ entity, isListView = false }) => {
  const { user } = useStoreCurrentUser()

  if (!entity || !user || isListView) {
    return null
  }

  const { entityContributions, classifications, labels } = entity

  return (
    <Stack gap={6} my={8}>
      {(labels?.length || 0) > 0 && <EntityLabelsAutocomplete entity={entity} onItemClick={null} />}
      <Contributors entityContributions={entityContributions} />
      <ClassificationsTable classifications={classifications} />
    </Stack>
  )
}

export default EditorDetails
