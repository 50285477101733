import { SimpleGrid } from '@chakra-ui/react'
import type { FC } from 'react'

import PlaybookCard from './playbookCard'

import type { Playbook } from '@graphql/queries'

interface Props {
  playbooks: Playbook[]
}

const PlaybookGrid: FC<Props> = ({ playbooks, ...rest }) => (
  <SimpleGrid rowGap={{ base: '8', md: '10' }} columnGap={{ base: '4', md: '6' }} minChildWidth="365px" {...rest}>
    {playbooks.map((playbook) => (
      <PlaybookCard key={`playbook-card-${playbook.id}`} playbook={playbook} />
    ))}
  </SimpleGrid>
)

export default PlaybookGrid
