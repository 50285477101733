import { IconButton, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { Link, useLoaderData, useNavigate } from 'react-router-dom'

import RecurringReportConfigurationForm from './components/recurringReportConfigurationForm'

import { toaster } from '@app/components/ui/toaster'
import DrawerHeader from '@app/pages/reports/components/drawerHeader'
import { ReportContext } from '@app/pages/reports/home'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import type { RecurringReportConfigurationQuery } from '@graphql/queries'
import { useRecurringReportConfigurationUpdateMutation } from '@graphql/queries'
import { RecurringReportConfigurationServiceEnum } from '@graphql/types'

const backLink = '../../..'

const Edit: FC = () => {
  const { recurringReportConfiguration } = useLoaderData() as {
    recurringReportConfiguration: RecurringReportConfigurationQuery['recurringReportConfiguration']
  }
  const [, updateRecurringReport] = useRecurringReportConfigurationUpdateMutation()
  const navigate = useNavigate()

  const onCancel = () => {
    navigate(backLink)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const input = Object.fromEntries(formData.entries()) as Record<string, string>

    await updateRecurringReport({
      input: {
        ...input,
        recurringReportConfigurationId: recurringReportConfiguration.id,
        name: input.name || 'Untitled Report',
        schedule: input.schedule || 'FREQ=WEEKLY;BYDAY=MO',
        service:
          (input.service as RecurringReportConfigurationServiceEnum) ||
          RecurringReportConfigurationServiceEnum.EmitReport
      }
    })

    toaster.create({
      title: 'Report updated!',
      description: 'Your recurring report was successfully saved',
      type: 'success'
    })

    navigate(backLink)
  }

  return (
    <ReportContext.Consumer>
      {() => (
        <Stack gap={4} w="3xl" maxW="100%" mx="auto">
          <DrawerHeader px={4}>
            <IconButton aria-label="go back" asChild variant="ghost">
              <Link to={backLink}>
                <FiArrowLeft />
              </Link>
            </IconButton>
            <Text>Editing {recurringReportConfiguration.name}</Text>
            <Spacer />
            <DrawerCloseButton variant="ghost" />
          </DrawerHeader>

          <Stack gap={4} px={4} pb={4}>
            <RecurringReportConfigurationForm
              onCancel={onCancel}
              recurringReportConfiguration={recurringReportConfiguration}
              onSubmit={onSubmit}
            />
          </Stack>
        </Stack>
      )}
    </ReportContext.Consumer>
  )
}

export default Edit
