import { Icon, IconButton } from '@chakra-ui/react'
import type { IconButtonProps } from '@chakra-ui/react'
import type { FC, MouseEventHandler } from 'react'

import { Tooltip } from '@app/components/ui/tooltip'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'

interface Props extends IconButtonProps {
  favoritableType: string
  favoritableId: string
  favoriteId?: string
  iconBoxSize?: number
  show?: boolean
}
const FavoriteToggleButton: FC<Props> = ({
  favoritableType,
  favoritableId,
  favoriteId = null,
  iconBoxSize = null,
  show = true,
  ...rest
}) => {
  const favoriteObject = useStore.use.favoriteObject()
  const unfavoriteObject = useStore.use.unfavoriteObject()

  const favorited = !!favoriteId

  if (!show) {
    return null
  }

  const icon = favorited ? (
    <Icon boxSize={iconBoxSize} color="yellow.500" data-cy="favorited">
      <ICON_MAP.Favorited />
    </Icon>
  ) : (
    <Icon boxSize={iconBoxSize} data-cy="not-favorited">
      <ICON_MAP.Favorite />
    </Icon>
  )

  const onClick: MouseEventHandler = (e) => {
    e.stopPropagation()
    const favoriteableObject = { id: favoritableId, classType: favoritableType }

    if (favorited) {
      unfavoriteObject(favoriteableObject)
    } else {
      favoriteObject(favoriteableObject)
    }
  }

  return (
    <Tooltip content={favorited ? 'Added to favorites' : 'Add to favorites'} showArrow>
      <IconButton aria-label="Toggle as a favorite" onClick={onClick} variant="ghost" {...rest}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}

export default FavoriteToggleButton
