let csrfToken

// This will not work with server hydration.
if (typeof window !== 'undefined' && window.document) {
  csrfToken = window.document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
}

const CSRFInput = () => <input type="hidden" name="authenticity_token" value={csrfToken} />

export default CSRFInput
