import { Stack } from '@chakra-ui/react'
import { useReactFlow } from '@xyflow/react'
import { useCallback, useEffect } from 'react'
import { Outlet, useLoaderData, useParams } from 'react-router-dom'

import { idAndTypeToRfId } from '@app/pages/maps/components/nodes/helpers'
import { useDrawerContext } from '@app/pages/maps/drawerContext'
import Drawer from '@app/shared/drawer/drawer'
import { useStore } from '@app/store'

const StrategyDrawer = () => {
  const { nodeId } = useParams()
  const { nodeType } = useLoaderData() as { nodeType: string }
  const { onDrawerClose } = useDrawerContext()
  const { deleteElements } = useReactFlow()
  const getNodeById = useStore.use.getNodeById()
  const node = getNodeById(idAndTypeToRfId(nodeId, nodeType))

  const onSuccessHandler = useCallback(() => {
    onDrawerClose()
  }, [onDrawerClose])

  const onCancelHandler = onSuccessHandler

  const nodeExists = !!node

  useEffect(() => {
    if (!nodeExists && nodeType !== 'comment') {
      onSuccessHandler()
    }
  }, [nodeExists, nodeType, onSuccessHandler])

  const deleteHandler = () => {
    deleteElements({ nodes: [node] })

    onDrawerClose()
  }

  return (
    <Drawer onOpenChange={onDrawerClose} open>
      <Stack as="section" gap={0} overflow="auto" h="100%" id="lazyload-container">
        <Outlet context={[onSuccessHandler, onCancelHandler, deleteHandler]} />
      </Stack>
    </Drawer>
  )
}

export default StrategyDrawer
