import { url as gravatarUrl } from 'gravatar/lib/gravatar'

type ProfileImageQueryOptions = Record<string, string> & {
  cdn?: string
  protocol?: string
}

type ProfileImage = (email: string | null, queryOptions?: ProfileImageQueryOptions) => string | null

const profileImage: ProfileImage = (email, queryOptions = {}) => {
  if (!email) {
    return null
  }

  // This var is just for typing, as gravatar lib doesn't have them.
  const url: string = gravatarUrl(email, { d: '404', s: '32', protocol: 'https', ...queryOptions })

  return url
}

export default profileImage
