import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectRoot, NativeSelectField } from '@app/components/ui/native-select'
import { IntervalEnum } from '@graphql/types'

type Props = {
  interval: IntervalEnum
  setInterval: (interval: IntervalEnum) => void
}

const IntervalSelect: FC<Props> = ({ interval, setInterval }) => (
  <Field label="Group by" data-testid="interval-select">
    <NativeSelectRoot>
      <NativeSelectField
        key="interval"
        name="interval_select"
        onChange={(e) => setInterval(e.target.value as IntervalEnum)}
        value={interval}
        bg="bg"
      >
        <option value={IntervalEnum.Day}>Day</option>
        <option value={IntervalEnum.Week}>Week</option>
        <option value={IntervalEnum.Month}>Month</option>
        <option value={IntervalEnum.Quarter}>Quarter</option>
        <option value={IntervalEnum.Year}>Year</option>
      </NativeSelectField>
    </NativeSelectRoot>
  </Field>
)

export default IntervalSelect
