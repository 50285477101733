import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { useEntityDeleteAttachmentMutation } from '@graphql/queries'

interface Props {
  entityId: string
  attachmentId: string
  onDelete?: () => void
}

const EntityDeleteAttachmentButton: FC<Props> = ({ entityId, attachmentId, onDelete = () => {} }) => {
  const [open, setOpen] = useState(false)
  const cancelRef = useRef()

  const [, entityDeleteAttachment] = useEntityDeleteAttachmentMutation()

  const deleteEntity = () => {
    setOpen(false)
    entityDeleteAttachment({ input: { entityId, attachmentId } }).then(onDelete)
  }

  return (
    <>
      <IconButton aria-label="Delete Attachment" onClick={() => setOpen(true)} size="xs">
        <FiTrash2 />
      </IconButton>

      <DialogRoot
        initialFocusEl={cancelRef?.current}
        onOpenChange={(e) => setOpen(e.open)}
        open={open}
        placement="center"
        role="alertdialog"
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete attachment</DialogTitle>
          </DialogHeader>

          <DialogBody>Are you sure? You can&#39;t undo this action afterwards.</DialogBody>

          <DialogFooter>
            <DialogActionTrigger asChild>
              <Button ref={cancelRef} variant="outline">
                Cancel
              </Button>
            </DialogActionTrigger>
            <Button ml={3} bgColor="error" onClick={deleteEntity}>
              Delete
            </Button>
          </DialogFooter>
          <DialogCloseTrigger />
        </DialogContent>
      </DialogRoot>
    </>
  )
}

export default EntityDeleteAttachmentButton
