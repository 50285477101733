import { Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import type { FC } from 'react'
// import type { ChangeEvent, FC } from 'react'

import { Field } from '@app/components/ui/field'
import { PasswordInput } from '@app/components/ui/password-input'
import type { FormErrors } from '@app/types'
import type { Credential } from '@graphql/types'

type Props = {
  credential?: Pick<Credential, 'clientId' | 'host'>
}

const LookerApiAuth: FC<Props> = ({ credential = {} }) => {
  const errors: FormErrors = {}
  const { clientId, host } = credential

  // const onInvalid = (event: ChangeEvent<HTMLInputElement>) => {
  //   event.target.setCustomValidity('Please enter your Looker URL, e.g. https://mycompany.cloud.looker.com/api/4.0')
  // }

  return (
    <Stack>
      <Field label="Client ID" required invalid={!!errors?.clientId} errorText={errors?.clientId?.message}>
        <Input
          autoComplete="off"
          data-1p-ignore
          defaultValue={clientId}
          name="clientId"
          placeholder="Looker client ID"
        />
      </Field>
      <Field
        label="Client secret"
        invalid={!!errors?.clientSecret}
        errorText={errors?.clientSecret?.message}
        required={isEmpty(credential)}
        helperText={!isEmpty(credential) ? 'Leave blank to keep the current secret or fill in to set a new one' : null}
      >
        <PasswordInput name="clientSecret" placeholder="Looker client secret" autoComplete="off" data-1p-ignore />
      </Field>
      <Field label="API endpoint" required>
        <Input
          autoComplete="off"
          data-1p-ignore
          defaultValue={host}
          // onInvalid={onInvalid}
          name="host"
          pattern="https://.*looker.*"
          placeholder="https://mycompany.cloud.looker.com/api/4.0"
        />
      </Field>
      {!!Object.keys(credential || {}).length && (
        <VisuallyHidden asChild>
          <Input defaultValue="clientSecret" name="secretFields" />
        </VisuallyHidden>
      )}
    </Stack>
  )
}

export default LookerApiAuth
