import type { Dispatch, FC, ProviderProps } from 'react'
import { createContext, useContext, useMemo, useReducer } from 'react'

import type { Upload } from '@app/types'

type UploadsContextState = Upload[]
type UploadsContextAction =
  | { type: 'append'; payload: Upload }
  | { type: 'clear' }
  | { type: 'remove'; payload: Upload }
type UploadsContextValue = {
  uploads: UploadsContextState
  dispatch: Dispatch<UploadsContextAction>
}

const UploadsContext = createContext<UploadsContextValue>({
  uploads: [],
  dispatch: () => {}
})

const init = (initialState = []) => initialState

const reducer = (state: UploadsContextState, action: UploadsContextAction): UploadsContextState => {
  if (action.type === 'append') {
    return [...state, action.payload]
  }

  if (action.type === 'remove') {
    return state.filter((upload) => upload.url !== action.payload.url)
  }

  if (action.type === 'clear') {
    return []
  }

  return state
}

const UploadsContextProvider: FC<Partial<ProviderProps<UploadsContextValue>>> = (props) => {
  const [state, dispatch] = useReducer<typeof reducer, UploadsContextState>(reducer, [], init)

  const value = useMemo(
    () => ({
      uploads: state,
      dispatch
    }),
    [dispatch, state]
  )

  return <UploadsContext.Provider value={value} {...props} />
}

const useUploadsContext = () => {
  const context = useContext(UploadsContext)

  if (context === undefined) {
    throw new Error('useUploadsContext must be used within UploadHashesContext.Provider')
  }

  return context
}

export { UploadsContextProvider, useUploadsContext }
