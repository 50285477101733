import { Text, Link as ChakraLink, HStack } from '@chakra-ui/react'
import type { ReactElement } from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import type { ColumnCellProps, Data } from '@app/shared/table/types'
import type { Strategy } from '@graphql/types'

interface Strategies extends Data {
  strategies: Pick<Strategy, 'id' | 'name'>[]
}

const MapsCell = <T extends Strategies = Strategies>({ data }: ColumnCellProps<T>): ReactElement => {
  if (data?.strategies?.length === 0) {
    return null
  }

  return (
    <HStack wrap="wrap" whiteSpace="normal">
      <Text>Maps:</Text>
      {data?.strategies?.map((strategy, index) => (
        <Fragment key={strategy.id}>
          <ChakraLink
            key={strategy.id}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Link to={`/strategy/${strategy.id}`}>{strategy.name}</Link>
          </ChakraLink>
          {index < data.strategies.length - 1 && ', '}
        </Fragment>
      ))}
    </HStack>
  )
}

export default MapsCell
