import { Button, HStack, Stack, Text } from '@chakra-ui/react'
import pluralize from 'pluralize'
import type { FC } from 'react'
import { useState } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import MetricSourceActivityShow from '@app/pages/metrics/components/metricSourceActivity/metricSourceActivityShow'
import type { UserData } from '@app/types'
import { useMetricSourceActivitiesQuery } from '@graphql/queries'
import type { MetricSource } from '@graphql/types'

interface Props {
  metricSource: Pick<
    MetricSource,
    'id' | 'lastRunAt' | 'erroredAt' | 'errorMessage' | 'scheduled' | 'name' | 'metricId'
  >
}

const formatDate = (dateTime: string, formatDateTimeInUserTimeZone: UserData['formatDateTimeInUserTimeZone']) => {
  const date = formatDateTimeInUserTimeZone(dateTime, 'L/d/yyyy')
  const time = formatDateTimeInUserTimeZone(dateTime, 'h:mm a')

  return `${date} at ${time}`
}

const MetricSourceImportStatus: FC<Props> = ({ metricSource }) => {
  const [open, setOpen] = useState(false)
  const { formatDateTimeInUserTimeZone } = useStoreCurrentUser()
  const [{ data }] = useMetricSourceActivitiesQuery({
    variables: { metricSourceId: metricSource.id, page: 1, limit: 1 }
  })

  const activities = data?.metricSourceActivities?.collection
  const { id: importId, processedCount = 0, totalCount = 0 } = activities?.[0] || {}
  const { lastRunAt, erroredAt, errorMessage, name, metricId } = metricSource

  if (erroredAt) {
    const onErrorClick = () => {
      // DEV NOTE: the import ID can get out of sync with the record, if the previous
      // one was successful, and then it starts failing due to e.g. a bad credential.
      // Activities are not created if we can't even make the request, so it
      // might come up in a help request that the import mentioned in the message
      // was successful, and new ones just won't get created.
      window.Intercom('showNewMessage', `Please help me with this metric import:\nMetric: ${name} (${metricId})`)
    }

    return (
      <Stack>
        <HStack gap={2}>
          <Text fontSize="sm" fontWeight="semibold">
            Last import:
          </Text>
          <Text color="error" fontSize="sm">
            Errored {formatDate(erroredAt, formatDateTimeInUserTimeZone)}
          </Text>
          <Button onClick={onErrorClick} size="2xs" variant="outline">
            Get help
          </Button>
        </HStack>
        <Text fontSize="sm">{errorMessage}</Text>
      </Stack>
    )
  }

  if (lastRunAt && importId) {
    let count = 0

    if (totalCount > 0) {
      count = totalCount
    } else if (processedCount > 0) {
      count = processedCount
    }

    return (
      <HStack gap={2}>
        <MetricSourceActivityShow importId={importId} open={open} onOpenChange={(e) => setOpen(e.open)} />
        <Text fontSize="sm" fontWeight="semibold">
          Last import:
        </Text>
        <Text display="inline" fontSize="sm">
          {count} data {pluralize('point', count)} on {formatDate(lastRunAt, formatDateTimeInUserTimeZone)}
        </Text>
        <Button onClick={() => setOpen(true)} size="2xs" variant="outline">
          Preview
        </Button>
      </HStack>
    )
  }

  return null
}

export default MetricSourceImportStatus
