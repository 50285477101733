import { Card, Stack, StackSeparator } from '@chakra-ui/react'
import type { FC } from 'react'

import Task from './task'

import type { Task as TaskType } from '@graphql/types'

interface Props {
  date: string
  tasks: TaskType[]
}

const TaskDateGroup: FC<Props> = ({ date, tasks }) => {
  const midDayDate = new Date(date)
  midDayDate.setHours(12, 0, 0, 0)

  return (
    <Card.Root>
      <Card.Header>Imports for {Intl.DateTimeFormat().format(midDayDate)}</Card.Header>
      <Card.Body>
        <Stack gap={6} separator={<StackSeparator />}>
          {tasks.map((task) => (
            <Task key={task.id} task={task} />
          ))}
        </Stack>
      </Card.Body>
    </Card.Root>
  )
}

export default TaskDateGroup
