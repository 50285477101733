/* eslint-disable react/jsx-no-constructed-context-values */
import { Children, useMemo } from 'react'

import StepConnector from './stepConnector'
import StepContext from './stepContext'

const Steps = (props) => {
  const { activeStep, children } = props
  return useMemo(
    () => (
      <>
        {Children.toArray(children).map((step, i, arr) => (
          <StepContext.Provider
            key={(step as { key: string }).key}
            value={{
              isActive: activeStep === i,
              isCompleted: activeStep > i,
              isLastStep: arr.length !== i + 1,
              step: i + 1
            }}
          >
            {step}
            {arr.length !== i + 1 && <StepConnector />}
          </StepContext.Provider>
        ))}
      </>
    ),
    [activeStep, children]
  )
}

export default Steps
