import type { FC } from 'react'
import { useState } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import CustomRangeModal from '@app/shared/graph/customRangeModal'

const CUSTOM = 'custom'

type Change = {
  startDate: string
  endDate: string
  range: string
}

interface Props {
  range: string
  startDate?: string
  endDate?: string
  setRange: (range: string) => void
  setStartDate: (startDate: string) => void
  setEndDate: (endDate: string) => void
  labelText?: string
  inline?: boolean
  onChange?: (arg0: Change) => void
  onCustomRangeCancel?: () => void
  selectProps?: Record<string, unknown>
}

const RangeSelect: FC<Props> = ({
  range,
  startDate = null,
  endDate = null,
  setRange,
  setStartDate,
  setEndDate,
  labelText = 'Date Range',
  inline = false,
  onChange = ({ startDate: sd, endDate: ed, range: r }) => ({ sd, ed, r }),
  onCustomRangeCancel = null,
  selectProps = {}
}) => {
  const onChangeProp = onChange
  const [open, setOpen] = useState(false)

  const handleClose = ({
    open: isOpen = false,
    status = null,
    startDate: newStartDate = null,
    endDate: newEndDate = null
  }) => {
    setOpen(isOpen)

    if (status !== 'cancelled') {
      setStartDate(newStartDate)
      setEndDate(newEndDate)
      setRange(CUSTOM)
      onChangeProp?.({
        startDate: newStartDate,
        endDate: newEndDate,
        range: CUSTOM
      })
    } else if (onCustomRangeCancel) {
      onCustomRangeCancel()
    } else {
      setRange('trends')
    }
  }

  const customText = range === CUSTOM ? `${startDate || '(none)'} to ${endDate || '(none)'}` : 'Custom'

  return (
    <Field label={labelText} data-testid="range-select" orientation={inline ? 'horizontal' : 'vertical'}>
      <CustomRangeModal open={open} onOpenChange={handleClose} />
      <NativeSelectRoot size="xs">
        <NativeSelectField
          name="range_select"
          onChange={(e) => {
            if (e.target.value === CUSTOM) {
              if (!open) {
                setOpen(true)
              }
            } else {
              setStartDate(null)
              setEndDate(null)
              setRange(e.target.value)
              onChangeProp?.({
                startDate: null,
                endDate: null,
                range: e.target.value
              })
            }
          }}
          value={range}
          {...selectProps}
        >
          <option value="trends">Trends</option>
          <option value="30d">Last 30 days</option>
          <option value="3m">Last 3 months</option>
          <option value="6m">Last 6 months</option>
          <option value="1y">Last year</option>
          <option value="all">All time</option>
          <option value="custom">{customText}</option>
        </NativeSelectField>
      </NativeSelectRoot>
    </Field>
  )
}

export default RangeSelect
