import { Icon } from '@chakra-ui/react'
import { AiOutlineOrderedList } from 'react-icons/ai'

import blockButtonMaker from './blockButtonMaker'

const OrderedListButton = blockButtonMaker({
  blockType: 'ordered-list-item',
  children: (
    <Icon boxSize={6}>
      <AiOutlineOrderedList />
    </Icon>
  )
})

export default OrderedListButton
