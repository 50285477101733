import type { NativeSelect } from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import type { FC } from 'react'
import { useCallback, memo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import type { Omit } from '@graphql/queries'
import { useStrategyFilterSelectOptionsQuery } from '@graphql/queries'

interface Props extends Omit<NativeSelect.FieldProps, 'children'> {
  field: string
}

const MapFilterSelectInput: FC<Props> = ({ field, ...selectProps }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [{ data }] = useStrategyFilterSelectOptionsQuery()

  const strategies = data?.strategies?.collection

  const updateSearchParams = useCallback(
    debounce((value) => {
      if (value) {
        searchParams.set(field, value)
      } else {
        searchParams.delete(field)
      }

      if (searchParams.get('page')) {
        searchParams.delete('page')
      }

      setSearchParams(searchParams)
    }, 100),
    [searchParams, setSearchParams]
  )

  const onChange = useCallback(({ target: { value } }) => {
    updateSearchParams(value)
  }, [])

  if (!strategies || strategies.length === 0) {
    return null
  }

  return (
    <Field>
      <NativeSelectRoot size="md">
        <NativeSelectField data-cy="map-filter-select" onChange={onChange} {...selectProps} bg="bg">
          <option value="">Select map</option>
          <option value="none">Not on any maps</option>
          {strategies.map((strategy) => (
            <option key={strategy.id} value={strategy.id}>
              {strategy.name}
            </option>
          ))}
        </NativeSelectField>
      </NativeSelectRoot>
    </Field>
  )
}

export default memo(MapFilterSelectInput)
