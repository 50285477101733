import { HStack, Icon, Flex } from '@chakra-ui/react'
import type { FC, ReactElement } from 'react'
import { cloneElement, useState } from 'react'

import { iconArray } from '../customIcons'

import { useColorModeValue as mode } from '@app/components/ui/color-mode'
import { PopoverRoot, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow } from '@app/components/ui/popover'

interface Props {
  value: string
  color?: string
  onClick?: (arg0: string) => void
  children: ReactElement
}

const IconPickerPopover: FC<Props> = ({ value, color, onClick, children }) => {
  const [open, setOpen] = useState(false)
  const onToggle = () => setOpen(!open)
  const trigger = cloneElement(children, { onClick: onToggle })
  const hoverColor = mode('gray.100', 'gray.700')
  const iconColor = color ? `${color}.500` : 'gray.500'

  const handleClick = (e: string) => {
    if (onClick) {
      onClick(e)
    }

    setOpen(!open)
  }

  return (
    <PopoverRoot size="md" onOpenChange={(e) => setOpen(e.open)} open={open}>
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <HStack justify="center" wrap="wrap" gap={2} pt={2}>
            {iconArray.map(([name, icon]) => {
              const IconEl = icon

              return (
                <Flex
                  key={name}
                  align="flex-start"
                  p={2}
                  borderWidth={2}
                  borderColor={value === name ? 'teal.500' : 'transparent'}
                  borderRadius="sm"
                  _hover={{ bgColor: hoverColor }}
                  cursor="pointer"
                  onClick={() => handleClick(name)}
                >
                  <Icon boxSize={5} color={iconColor}>
                    <IconEl />
                  </Icon>
                </Flex>
              )
            })}
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default IconPickerPopover
