import { useRouteLoaderData } from 'react-router-dom'

import type { StrategyChangelogEntityQuery } from '@graphql/queries'

type ChangelogEntity = StrategyChangelogEntityQuery['strategyChangelogEntity']
type UseChangelogEntity = (routeId?: string) => ChangelogEntity | undefined

const useChangelogEntity: UseChangelogEntity = (routeId: 'changelogEntity') => {
  const { entity } = (useRouteLoaderData(routeId) || {}) as {
    entity?: ChangelogEntity
  }

  return entity
}

export default useChangelogEntity
