import type { FC } from 'react'
import { useEffect, useState } from 'react'

import CardSection from './cardSection'

import LabelsInput from '@app/next/forms/inline/labelsInput'
import type { CardSize, MapDomainObject } from '@app/types'

interface Props {
  domainObject: MapDomainObject
  fieldName?: string
  size?: CardSize
}

const CardLabels: FC<Props> = ({ domainObject, fieldName, size = 'normal' }) => {
  const [labels, setLabels] = useState(domainObject?.[fieldName])

  useEffect(() => {
    if (domainObject?.[fieldName] !== labels) {
      setLabels(domainObject?.[fieldName])
    }
  }, [domainObject, domainObject?.[fieldName], fieldName, labels])

  if (!domainObject?.[fieldName]?.length) {
    return null
  }

  return (
    <CardSection>
      <LabelsInput domainObject={domainObject} name={fieldName} size={size === 'normal' ? 'md' : 'lg'} />
    </CardSection>
  )
}

export default CardLabels
