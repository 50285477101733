import { IconButton, Spinner } from '@chakra-ui/react'
import type { FC, MouseEventHandler } from 'react'
import { useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import ICON_MAP from '@app/utils/iconMap'
import { useMapCloneMutation } from '@graphql/queries'

interface Props {
  strategyId: string | null
}

const MapCloneButton: FC<Props> = ({ strategyId }) => {
  const [loading, setLoading] = useState(false)
  const [, mapClone] = useMapCloneMutation()

  const { revalidate } = useRevalidator()

  if (!strategyId) {
    return null
  }

  const onClick: MouseEventHandler = (e) => {
    e.stopPropagation()
    setLoading(true)
    return mapClone({ input: { strategyId } })
      .then(revalidate)
      .finally(() => setLoading(false))
  }

  return (
    <IconButton aria-label="Copy strategy" data-cy="map-clone-button" onClick={onClick} variant="ghost">
      {loading ? <Spinner size="xs" /> : <ICON_MAP.Copy />}
    </IconButton>
  )
}

export default MapCloneButton
