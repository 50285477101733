import { useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import DataTable from '../components/dataTable'

import MetricDataPointsFetcher from '@app/fetchers/metricDataPointsFetcher'
import useGetObject from '@app/hooks/useGetObject'
import useGetObjectPage from '@app/hooks/useGetObjectPage'
import MetricGraph from '@app/pages/metrics/components/metricGraph'
import type { MetricDataPoint } from '@graphql/types'

function listablePoint(
  point: MetricDataPoint
): point is MetricDataPoint & Required<Pick<MetricDataPoint, 'id' | 'value' | 'segmentFilters'>> {
  return typeof !!point.id && typeof point.value === 'number' && !!point.segmentFilters
}

const MetricDataList = () => {
  const [loading, setLoading] = useState(true)
  const { nodeId } = useParams()
  const metric = useGetObject(nodeId, 'metric')
  const { collection, metadata } = useGetObjectPage('metricDataPoint')
  const points = collection.filter(listablePoint)

  return (
    <>
      <MetricDataPointsFetcher setLoading={setLoading} />
      <MetricGraph metric={metric} />
      <Outlet />
      <DataTable metric={metric} collection={points} metadata={metadata} loading={loading} />
    </>
  )
}

export default MetricDataList
