import { useState } from 'react'

const useSteps = (options) => {
  const { initialStep } = options
  const [activeStep, setActiveStep] = useState(initialStep)

  const nextStep = () => setActiveStep(activeStep + 1)
  const prevStep = () => setActiveStep(activeStep - 1)
  const reset = () => setActiveStep(initialStep)

  return { nextStep, prevStep, reset, activeStep }
}

export default useSteps
