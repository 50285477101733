import type { Cable } from '@anycable/web'
import type { SubscriptionOperation } from '@urql/core'
import queryString from 'query-string'
import type { AnyVariables, OperationResult } from 'urql'
import { Observable } from 'zen-observable-ts'

// based on https://github.com/rmosolgo/graphql-ruby/blob/master/javascript_client/src/subscriptions/ActionCableLink.ts
//      and https://github.com/urql-graphql/urql/issues/787

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Payload<TData = any, V extends AnyVariables = AnyVariables> = {
  result: OperationResult<TData, V>
  more: boolean
}

class AnyCableExchange {
  cable: Cable

  channelName: string

  actionName: string

  connectionParams: object

  constructor(options: { cable: Cable; channelName?: string; actionName?: string; connectionParams?: object }) {
    this.cable = options.cable
    this.channelName = options.channelName || 'GraphqlChannel'
    this.actionName = options.actionName || 'execute'
    this.connectionParams = options.connectionParams || {}
  }

  request(operation: SubscriptionOperation) {
    return new Observable((observer) => {
      const channelId = Math.round(Date.now() + Math.random() * 100000).toString(16)
      const { actionName, cable, channelName } = this
      const { operationName } = operation
      const channel = cable.subscribeTo(channelName, {
        channelId,
        ...this.connectionParams
      })

      channel.on('connect', () => {
        const { token } = queryString.parse(window.location.search)

        return channel.perform(actionName, {
          query: operation.query || null,
          variables: operation.variables,
          // TODO: add this to support persisted queries, but operationId is not currently available
          // operationId: (operation as { operationId?: string }).operationId,
          operationName,
          extensions: {
            token
          }
        })
      })

      channel.on('message', (payload: Payload) => {
        if (payload.result.data || payload.result.error) {
          observer.next(payload.result)
        }

        if (!payload.more) {
          observer.complete()
        }
      })

      return { closed: cable.state === 'closed', unsubscribe: () => cable.unsubscribe(channel) }
    })
  }
}

export default AnyCableExchange
