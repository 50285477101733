import { Icon } from '@chakra-ui/react'
import { AiOutlineUnorderedList } from 'react-icons/ai'

import blockButtonMaker from './blockButtonMaker'

const UnorderedListButton = blockButtonMaker({
  blockType: 'unordered-list-item',
  children: (
    <Icon boxSize={6}>
      <AiOutlineUnorderedList />
    </Icon>
  )
})

export default UnorderedListButton
