import { Icon } from '@chakra-ui/react'
import { MdFormatQuote } from 'react-icons/md'

import blockButtonMaker from './blockButtonMaker'

const BlockquoteButton = blockButtonMaker({
  blockType: 'blockquote',
  children: (
    <Icon boxSize={6}>
      <MdFormatQuote />
    </Icon>
  )
})

export default BlockquoteButton
