import type { Avatar } from '@chakra-ui/react'

import type { CardSize } from '@app/types'

type CardAvatarSize = (defaultAvatarSize: Avatar.RootProps['size'], cardSize: CardSize) => Avatar.RootProps['size']
const cardAvatarSize: CardAvatarSize = (defaultAvatarSize, cardSize) => {
  if (cardSize === 'normal') {
    return defaultAvatarSize
  }

  switch (cardSize) {
    case 'large':
      return 'xs'
    default:
      return defaultAvatarSize
  }
}

export default cardAvatarSize
