import type { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'

import type { ButtonProps } from '@app/components/ui/button'
import { Button } from '@app/components/ui/button'

interface Props extends ButtonProps {
  label: string
  url: string
  leftIcon?: ReactElement
  rightIcon?: ReactElement
}

const NavigationButton: FC<Props> = ({ label, url, leftIcon = null, rightIcon = null, ...buttonProps }) => (
  <Button asChild aria-label={label} size="xs" variant="outline" {...buttonProps}>
    <Link to={url}>
      {leftIcon} {label} {rightIcon}
    </Link>
  </Button>
)

export default NavigationButton
