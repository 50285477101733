import { Box, IconButton } from '@chakra-ui/react'
import { ErrorBoundary } from '@sentry/react'
import type { FC } from 'react'
import { useState } from 'react'
import type { OperationResult } from 'urql'

import { Button } from '@app/components/ui/button'
import {
  PopoverRoot,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseTrigger,
  PopoverHeader
} from '@app/components/ui/popover'
import { Tooltip } from '@app/components/ui/tooltip'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import track from '@app/lib/analyticsHelper'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'
import { StrategyMetricsSuggest } from '@graphql/documents/ai_assistant.graphql'
import type { StrategyMetricsSuggestMutation } from '@graphql/queries'

type Props = {
  strategyId: string
  metricId: string
}

const ButtonInner: FC<Props> = ({ strategyId, metricId }) => {
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const { user } = useStoreCurrentUser()
  const updateObject = useStore.use.updateObject()
  const actionMutation = useStore.use.actionMutation()

  const updateMetric = (params = {}) => {
    track('aiselect:metric', user, { metricId, ...params })

    return updateObject({
      metric: {
        id: metricId,
        ...params
      }
    })
  }

  const onSelect = (name: string) => updateMetric({ name })
  const loader = async () => {
    setLoading(true)
    try {
      const { data, error: graphqlError }: OperationResult<StrategyMetricsSuggestMutation> = await actionMutation(
        StrategyMetricsSuggest,
        {
          metricId,
          strategyId
        }
      )

      setSuggestions(data?.strategyMetricsSuggest?.result)
      setError(graphqlError?.name ? 'AI Prompt invalid' : data?.strategyMetricsSuggest?.error)
    } finally {
      setLoading(false)
    }
  }

  const body = () => {
    if (loading) {
      return <Box>Loading...</Box>
    }

    if (error) {
      return <Box>Error: {error}</Box>
    }

    return suggestions?.map((name) => (
      <Box key={name}>
        <Button key={name} onClick={() => onSelect(name)} variant="ghost">
          {name}
        </Button>
      </Box>
    ))
  }

  return (
    <PopoverRoot onOpenChange={loader}>
      <PopoverTrigger asChild>
        <Box>
          <Tooltip content="AI Suggestion">
            <IconButton
              borderRadius="none"
              aria-label="ai suggestion"
              onClick={() => {
                track("aisuggest:'metric", user)
              }}
              size="sm"
              variant="outline"
            >
              <ICON_MAP.AiPrompt />
            </IconButton>
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <PopoverContent w="fit-content" minW="xs">
        <PopoverCloseTrigger />
        <PopoverHeader>Suggested metrics</PopoverHeader>
        <PopoverBody>{body()}</PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

// A separate loader so we can do the editor check prior to running any other gql queries.
const SuggestMetricsButton: FC<Props> = (props) => {
  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  return (
    <ErrorBoundary>
      <ButtonInner {...props} />
    </ErrorBoundary>
  )
}

export default SuggestMetricsButton
