import { Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { formatStat } from '@app/utils/metricHelpers'
import type { Metric } from '@graphql/queries'

interface Data {
  value?: number
  metric: Metric
}

interface Props {
  data: Data
}

const MetricValue: FC<Props> = ({ data: { value, metric } }) => {
  const formatted = formatStat(value || 0, metric)
  return <Text data-testid="metric-value">{formatted}</Text>
}

export default MetricValue
