import { Icon, Input, Link, Text, Stack, VisuallyHidden } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import type { FC } from 'react'
import { FiHelpCircle } from 'react-icons/fi'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import { PasswordInput } from '@app/components/ui/password-input'
import type { Credential } from '@graphql/types'

type Props = {
  credential?: Pick<Credential, 'projectId' | 'region' | 'workspaceId' | 'username'>
}

const MixpanelAuth: FC<Props> = ({ credential = {} }) => {
  const { projectId, region, workspaceId, username } = credential

  return (
    <Stack>
      <Field
        required
        label={
          <>
            <Text display="inline">Project ID </Text>
            <Link
              verticalAlign="middle"
              color="link"
              href="https://help.mixpanel.com/hc/en-us/articles/115004490503-Project-Settings#project-id"
              rel="onoopener noreferrer"
              target="_blank"
            >
              <Icon boxSize="4">
                <FiHelpCircle />
              </Icon>
            </Link>
          </>
        }
      >
        <Input
          autoComplete="off"
          data-1p-ignore
          defaultValue={projectId}
          name="projectId"
          placeholder="Mixpanel Project ID"
        />
      </Field>
      <Field label="Region">
        <NativeSelectRoot>
          <NativeSelectField name="region" defaultValue={region}>
            <option value="us">US</option>
            <option value="eu">EU</option>
          </NativeSelectField>
        </NativeSelectRoot>
      </Field>
      <Field label="Workspace ID">
        <Input
          autoComplete="off"
          data-1p-ignore
          defaultValue={workspaceId}
          name="workspaceId"
          placeholder="(Optional) Mixpanel Workspace ID"
        />
      </Field>
      <Field required label="Username">
        <Input
          autoComplete="off"
          data-1p-ignore
          defaultValue={username}
          name="username"
          placeholder="Mixpanel service account name"
        />
      </Field>
      <Field
        label="Password"
        required={isEmpty(credential)}
        helperText={
          !isEmpty(credential) ? 'Leave blank to keep the current password or fill in to set a new password' : null
        }
      >
        <PasswordInput
          name="password"
          placeholder="Mixpanel service account password"
          autoComplete="off"
          data-1p-ignore
        />
      </Field>
      {!!Object.keys(credential || {}).length && (
        <VisuallyHidden asChild>
          <Input defaultValue="password" name="secretFields" />
        </VisuallyHidden>
      )}
    </Stack>
  )
}

export default MixpanelAuth
