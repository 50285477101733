import { useShallow } from 'zustand/react/shallow'

import { useStore, getEdgesSelector } from '@app/store'
import type { MapDomainEdge } from '@app/types'

type UseGetEdges = (strategyId: string) => MapDomainEdge[]

const useGetEdges: UseGetEdges = (strategyId) => useStore(useShallow((state) => getEdgesSelector(state, strategyId)))

export default useGetEdges
