import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

const initializeSentry = () => {
  const { PUBLIC_SENTRY_DSN } = import.meta.env

  Sentry.init({
    dsn: PUBLIC_SENTRY_DSN === '0' ? null : PUBLIC_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
    ],
    environment: import.meta.env.MODE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2
  })
}

// re-exporting Sentry for simplifying imports elsewhere
export { initializeSentry, Sentry }
