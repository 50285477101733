const determineFilename = (contentDisposition: string) => {
  const filenameMatch = contentDisposition.match(/filename="(.+?)"/)

  if (!filenameMatch) {
    return 'download'
  }

  return filenameMatch[1]
}

type FetchParams = Parameters<typeof fetch>

// https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch
// This allows more control over the request cycle, which is useful for long-running requests.
const fetchDownload: typeof fetch = async (input: FetchParams[0], init: FetchParams[1]) => {
  const response = await fetch(input, init)

  if (!response.ok) {
    throw new Error(`Failed to download data: ${response.statusText} - ${await response.text()}`)
  }

  const blob = await response.blob()
  let url: string

  try {
    url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url

    const filename = determineFilename(response.headers.get('content-disposition'))
    a.download = decodeURIComponent(filename)

    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
    a.remove() // afterwards we remove the element again

    return response
  } finally {
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url)
    }, 100)
  }
}

export default fetchDownload
