import { Text, Flex, HStack } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'

import type { Classification } from '@graphql/queries'

export interface Props {
  classifications: Pick<Classification, 'id' | 'category' | 'name'>[]
  restrictCategories?: string[]
}

const Classifications: FC<Props> = ({ classifications, restrictCategories = null, ...rest }) => {
  if (!classifications.length) {
    return null
  }

  let classificationsToDisplay = classifications

  if (restrictCategories) {
    classificationsToDisplay = classifications.filter((classification) =>
      restrictCategories.includes(classification.category)
    )
  }

  if (!classificationsToDisplay.length) {
    return null
  }

  return (
    <HStack wrap="wrap" {...rest}>
      {sortBy(classificationsToDisplay, (classification) => `${classification.category}:${classification.name}`).map(
        ({ id, category, name }) => (
          <Flex
            key={`${id}-${category}-${name}`}
            align="flex-start"
            px={2}
            py={1}
            color="emphasized"
            border="1px"
            borderColor="border"
            borderRadius="sm"
            bgColor="bg"
          >
            <Text fontSize="sm" wordBreak="break-word">
              {capitalize(category.replace('_', ' '))}: {name}
            </Text>
          </Flex>
        )
      )}
    </HStack>
  )
}

export default Classifications
