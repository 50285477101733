import { Box, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import AccountsListActions from '@app/pages/settings/user/components/accountsListActions'
import type { Account } from '@graphql/types'

interface Props {
  data: Account
}

const UserActionsCell: FC<Props> = ({ data: account }) => {
  const { organizationId } = account
  const { user } = useStoreCurrentUser()

  if (user?.organization?.id === organizationId) {
    return (
      <Box m={2}>
        <Spacer />
        &nbsp;
      </Box>
    )
  }

  return <AccountsListActions account={account} />
}

export default UserActionsCell
