import { HStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import ConfidenceRatingPopover from '@app/next/confidenceRatingPopover'
import OwnerPopover from '@app/next/ownerPopover'
import CommentsCount from '@app/pages/maps/components/nodes/components/commentsCount'
import cardAvatarSize from '@app/shared/cards/cardAvatarSize'
import cardFontSize from '@app/shared/cards/cardFontSize'
import CardSection from '@app/shared/cards/components/cardSection'

const CardSharedActions = ({ domainObject, size, route = null, children }) => {
  const { commentsCount } = domainObject
  const avatarSize = cardAvatarSize('xs', size)
  const fontSize = cardFontSize('sm', size)

  const commentBubble = <CommentsCount commentsCount={commentsCount} showWhenNoComments size="lg" />
  const commentsDisplay = route ? <Link to={`${route}/comments`}>{commentBubble}</Link> : commentBubble

  return (
    <CardSection size={size}>
      <HStack gap={1}>
        <OwnerPopover fontSize={fontSize} object={domainObject} avatarProps={{ size: avatarSize, mr: 2 }} />
        <ConfidenceRatingPopover domainObject={domainObject} fontSize={fontSize} size={size} />
        {commentsDisplay}
        {children}
      </HStack>
    </CardSection>
  )
}

export default CardSharedActions
