import type { HTMLChakraProps } from '@chakra-ui/react'
import { HStack, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { Avatar } from '@app/components/ui/avatar'
import profileImage from '@app/utils/profileImage'
import type { User } from '@graphql/queries'

interface Props {
  user?: Partial<User>
  displayEmail?: boolean
}

const overflowEllipsisProps: HTMLChakraProps<'p'> = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}

const UserProfile: FC<Props> = ({ user = null, displayEmail = true, ...rest }) => {
  if (!user) {
    return null
  }

  return (
    <HStack flexShrink={0} gap="4" px="2" {...rest}>
      <Avatar name={user.name} size="sm" src={profileImage(user.email)} />
      <Stack direction="column" gap={1}>
        <Text color={user.disabledAt ? 'fg.subtle' : 'fg'} fontSize="sm" {...overflowEllipsisProps}>
          {user.name}
        </Text>
        {displayEmail && (
          <Text
            color="fg.muted"
            fontSize="xs"
            lineHeight="shorter"
            wordBreak="break-all"
            {...overflowEllipsisProps}
            maxW={80}
          >
            {user.email}
          </Text>
        )}
      </Stack>
    </HStack>
  )
}

export default UserProfile
