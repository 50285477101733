import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import AvatarList from './contributors/avatarList'

import type { EntityContribution } from '@graphql/queries'

interface Props {
  entityContributions: EntityContribution[]
}

const Contributors: FC<Props> = ({ entityContributions = [] }) => {
  if (!entityContributions.length) {
    return null
  }

  return (
    <Stack>
      <Text fontSize="sm">Contributors</Text>
      <AvatarList contributions={entityContributions} />
    </Stack>
  )
}

export default Contributors
