import { Box } from '@chakra-ui/react'

import useStep from './useStep'

import { useColorModeValue } from '@app/components/ui/color-mode'

const StepConnector = () => {
  const { isCompleted, isActive } = useStep()
  const accentColor = useColorModeValue('blue.500', 'blue.300')

  return (
    <Box
      h="6"
      mt={isActive ? '0' : '2'}
      ms="4"
      mb="2"
      ps="4"
      borderStartWidth="1px"
      borderStartColor={isCompleted ? accentColor : 'inherit'}
    />
  )
}

export default StepConnector
