import type { SkeletonProps as ChakraSkeletonProps, CircleProps } from '@chakra-ui/react'
import { Skeleton as ChakraSkeleton, Circle, Stack } from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface SkeletonCircleProps extends ChakraSkeletonProps {
  size?: CircleProps['size']
}

export const SkeletonCircle = (props: SkeletonCircleProps) => {
  const { size, ...rest } = props
  return (
    <Circle asChild size={size}>
      <ChakraSkeleton {...rest} />
    </Circle>
  )
}

export interface SkeletonTextProps extends ChakraSkeletonProps {
  lineClamp?: number
}

export const SkeletonText = forwardRef<HTMLDivElement, SkeletonTextProps>(function SkeletonText(props, ref) {
  const { lineClamp = 3, gap, ...rest } = props
  return (
    <Stack ref={ref} gap={gap} w="full">
      {Array.from({ length: lineClamp }).map((_, index) => (
        <ChakraSkeleton key={index} h="4" {...props} _last={{ maxW: '80%' }} {...rest} />
      ))}
    </Stack>
  )
})

export const Skeleton = ChakraSkeleton
