import { Box, Group } from '@chakra-ui/react'
import type { FC, FormEventHandler, PropsWithChildren } from 'react'
import { memo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import {
  PopoverRoot,
  PopoverArrow,
  PopoverFooter,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseTrigger,
  PopoverBody
} from '@app/components/ui/popover'
import { toaster } from '@app/components/ui/toaster'
import { Tooltip } from '@app/components/ui/tooltip'
import FormAlert from '@app/next/forms/formAlert'
import SlackChannelInput from '@app/next/forms/slackChannelInput'
import { useEntitySlackMutation } from '@graphql/queries'

type Props = PropsWithChildren<{
  entityId: string
}>

const SlackSendPopover: FC<Props> = ({ entityId, children }) => {
  const [error, setError] = useState<Error | null>(null)
  const [open, setOpen] = useState(false)

  const { nodeId, strategyId: sId } = useParams()
  const strategyId = sId || nodeId

  const [, sendToSlack] = useEntitySlackMutation()

  const sendEntityToSlack = async (e: Parameters<FormEventHandler<HTMLFormElement>>[0]) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const formValues = Object.fromEntries(formData)

    try {
      await sendToSlack({
        input: { channelId: formValues.channelId as string, entityId, strategyId }
      })

      toaster.create({
        title: 'Sent to Slack',
        placement: 'top',
        type: 'success'
      })
      setOpen(false)
    } catch (err) {
      setError(err)
    }
  }

  const errorMessage = error?.message

  return (
    <PopoverRoot onOpenChange={(e) => setOpen(e.open)} open={open}>
      <>
        <Tooltip content="Share to Slack" positioning={{ placement: 'top' }}>
          <Box display="inline-block">
            <PopoverTrigger>{children}</PopoverTrigger>
          </Box>
        </Tooltip>
        <PopoverContent overflow="unset">
          <form onSubmit={(e) => sendEntityToSlack(e)}>
            <PopoverArrow />
            <PopoverCloseTrigger />
            <PopoverBody>
              <FormAlert description={errorMessage} title="Failed to send to Slack!" />
              <Field label="Slack Channel" required invalid={!!errorMessage} errorText={errorMessage}>
                <SlackChannelInput name="channelId" prepopulateFromCookie />
              </Field>
            </PopoverBody>
            <PopoverFooter justifyContent="end">
              <Group justifyContent="end">
                <Button onClick={() => setOpen(false)} variant="outline">
                  Cancel
                </Button>
                <Button type="submit">Send to Slack</Button>
              </Group>
            </PopoverFooter>
          </form>
        </PopoverContent>
      </>
    </PopoverRoot>
  )
}

export default memo(SlackSendPopover)
