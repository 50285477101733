import { HStack, Input, VisuallyHidden } from '@chakra-ui/react'
import type { NativeSelect } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { parse, serialize } from 'tinyduration'

import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'

interface Props extends Partial<NativeSelect.FieldProps> {
  name: string
  defaultValue?: string | null
}

const DurationInput: FC<Props> = ({ name, defaultValue = null, ...rest }) => {
  let defaultAmount = 1
  let defaultInterval = 'days'

  if (defaultValue) {
    const parsedDuration = parse(defaultValue)
    ;[defaultInterval, defaultAmount] = Object.entries(parsedDuration).find(([, v]) => v > 0)
  }

  const [amount, setAmount] = useState(defaultAmount || 1)
  const [interval, setInterval] = useState(defaultInterval || 'days')

  const defaultDuration = serialize({
    [interval]: amount
  })
  const [outputDuration, setOutputDuration] = useState(defaultDuration)

  const onAmountChange = (e) => {
    const newAmount = e?.target?.value

    setAmount(newAmount)

    if (!newAmount) {
      return
    }

    const duration = serialize({
      [interval]: newAmount
    })

    setOutputDuration(duration)
  }

  const onIntervalChange = (e) => {
    const newInterval = e?.target?.value

    if (!newInterval) {
      return
    }

    const duration = serialize({
      [newInterval]: amount
    })

    setInterval(newInterval)
    setOutputDuration(duration)
  }

  return (
    <>
      <VisuallyHidden asChild>
        <Input name={name} readOnly value={outputDuration} />
      </VisuallyHidden>
      <HStack w="full">
        <Input flex="10" w="5rem" bg="bg" min={1} onChange={onAmountChange} type="number" value={amount} />
        <NativeSelectRoot flex="30" ml={2}>
          <NativeSelectField placeholder="" onChange={onIntervalChange} value={interval} {...rest}>
            <option value="days">Days</option>
            <option value="weeks">Weeks</option>
            <option value="months">Months</option>
            <option value="years">Years</option>
          </NativeSelectField>
        </NativeSelectRoot>
      </HStack>
    </>
  )
}

export default DurationInput
