import { Box, IconButton, Spinner } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'

import { Button } from '@app/components/ui/button'
import type { ButtonProps } from '@app/components/ui/button'
import {
  PopoverRoot,
  PopoverHeader,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseTrigger
} from '@app/components/ui/popover'
import { toaster } from '@app/components/ui/toaster'
import { Tooltip } from '@app/components/ui/tooltip'
import useGetObject from '@app/hooks/useGetObject'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import track from '@app/lib/analyticsHelper'
import { useStore } from '@app/store'
import type { MapDomainNode, MapDomainObject } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'
import { ProductInitiativesSuggest } from '@graphql/documents/ai_assistant.graphql'
import type { ProductInitiativesSuggestMutation, ProductInitiativesSuggestMutationVariables } from '@graphql/queries'
import type { NodeObjectInput } from '@graphql/types'

type ChildFunc = (props: Partial<ButtonProps>) => ReactNode

interface Props {
  node: Pick<MapDomainNode, 'nodeId' | 'data'>
  children?: ChildFunc
  onComplete?: () => void
}

const buttonStyles = {
  borderRadius: 'none'
}

const defaultChildren: ChildFunc = ({ loading, onClick }) => (
  <IconButton {...buttonStyles} aria-label="Suggest new name" onClick={onClick} size="sm" variant="outline">
    {loading ? <Spinner size="xs" /> : <ICON_MAP.AiPrompt />}
  </IconButton>
)

const SuggestProductInitiativesButton: FC<Props> = ({ node, children = defaultChildren, onComplete = () => {} }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [suggestions, setSuggestions] = useState<string[]>([])
  const actionMutation = useStore.use.actionMutation()
  const { user } = useStoreCurrentUser()
  const updateObject = useStore.use.updateObject()
  const domainObject = useGetObject(node.data.id, node.data.classType) as MapDomainObject

  const loader = async () => {
    setOpen(!open)
    setLoading(true)

    try {
      const result = await actionMutation<
        ProductInitiativesSuggestMutation,
        ProductInitiativesSuggestMutationVariables
      >(ProductInitiativesSuggest, {
        nodeId: node.nodeId
      })

      if (result.error) {
        if (result.error.networkError) {
          toaster.create({ title: 'Error', description: result.error.networkError.message, type: 'error' })
        } else {
          const errors = result.error.graphQLErrors.map((e) => e.message).join(', ')
          toaster.create({ title: 'Error', description: errors, type: 'error' })
        }
      } else {
        setSuggestions(result.data?.productInitiativesSuggest?.result)
      }
    } catch (e) {
      toaster.create({ title: 'Error', description: e.message, type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const onSelect = (name) => {
    track('aiselect:product_initiative', user, { nodeId: node.nodeId, name })

    setOpen(!open)
    onComplete()

    return updateObject({
      [node.data.classType]: {
        id: domainObject.id,
        name
      }
    } as NodeObjectInput)
  }

  const body = () => {
    if (loading) {
      return <Box>Loading...</Box>
    }

    return suggestions?.map((name) => (
      <Box key={name}>
        <Button key={name} onClick={() => onSelect(name)} variant="ghost">
          {name}
        </Button>
      </Box>
    ))
  }

  return (
    <PopoverRoot portalled onOpenChange={loader} open={open}>
      <PopoverTrigger asChild>
        <Box>
          <Tooltip content="Suggest product initiatives">{children({ loading })}</Tooltip>
        </Box>
      </PopoverTrigger>
      <PopoverContent w="fit-content" minW="xs">
        <PopoverCloseTrigger />
        <PopoverHeader>Suggested product initiatives</PopoverHeader>
        <PopoverBody>{body()}</PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default SuggestProductInitiativesButton
