import { Icon, Box, Link as ChakraLink, Heading, Image, Stack, HStack, Text } from '@chakra-ui/react'
import Img from 'react-cloudimage-responsive'
import { FiArrowLeft } from 'react-icons/fi'
import { useAsyncValue, Link } from 'react-router-dom'

import Header from './components/header'
import PlaybookButtons from './components/playbookButtons'
import PlaybookMeta from './components/playbookMeta'

import WhatIsAPlaybook from '@app/pages/playbooks/components/whatIsAPlaybook'
import { PageHeader, PageStack } from '@app/shared/layout'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import withAwait from '@app/shared/withAwait'
import type { Playbook } from '@graphql/queries'

const Show = () => {
  const {
    data: { playbook }
  } = useAsyncValue() as { data: { playbook: Playbook } }
  const { description, hero, name, shortDescription } = playbook

  return (
    <PageStack pb={{ base: 12, md: 24, lg: 48 }}>
      <Header showPlaybooksCta={false} />
      <PageHeader title={name} subtitle={shortDescription}>
        <PlaybookButtons playbook={playbook} size="lg" />
      </PageHeader>
      <ChakraLink asChild>
        <Link to="/playbooks">
          <HStack>
            <Icon>
              <FiArrowLeft />
            </Icon>
            <Text>Playbook gallery</Text>
          </HStack>
        </Link>
      </ChakraLink>
      <Box
        p={{ base: 4, md: 8, lg: 10 }}
        bgGradient="to-r"
        borderRadius="sm"
        bgColor="bg.muted"
        gradientFrom="blue.100"
        gradientTo="blue.50"
      >
        <Image borderRadius={5} objectFit="cover" asChild draggable={false}>
          <Img src={hero} alt={name} doNotReplaceURL={false} lazyLoading={false} />
        </Image>
      </Box>
      <Heading color="emphasized" fontSize="2xl" fontWeight="medium">
        About this playbook
      </Heading>
      <Stack direction={{ base: 'column', md: 'row' }} gap="16">
        <Box flex="1">
          <Stack gap="8">
            <MarkdownDisplay text={description} />
            <WhatIsAPlaybook />
          </Stack>
        </Box>
        <Stack flex="1" gap={8} maxW="sm">
          <PlaybookMeta playbook={playbook} />
          <PlaybookButtons playbook={playbook} />
        </Stack>
      </Stack>
    </PageStack>
  )
}

export default withAwait(Show, 'playbook')
