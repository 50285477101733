import { Input, HStack } from '@chakra-ui/react'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import { RANDOM_DATA_STD_DEV, RANDOM_DATA_UPWARD_MOVE_PERCENTAGE } from '@app/lib/globals'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'id' | 'configuration'> | null
  disabled: boolean
}

const DummyData: FC<Props> = ({ metricSource, disabled }) => {
  const {
    std_deviation: stdDev = RANDOM_DATA_STD_DEV,
    upward_move_percentage: upwardMovePercentage = RANDOM_DATA_UPWARD_MOVE_PERCENTAGE
  } = metricSource?.configuration || {}

  return (
    <HStack>
      <Field label="Standard deviation" required disabled={disabled}>
        <Input defaultValue={stdDev} name="configuration.std_deviation" type="number" />
      </Field>
      <Field label="Upward move percentage" required disabled={disabled}>
        <Input defaultValue={upwardMovePercentage} name="configuration.upward_move_percentage" type="number" />
      </Field>
    </HStack>
  )
}

export default DummyData
