import type { StackProps } from '@chakra-ui/react'
import { Stack } from '@chakra-ui/react'
import type { ReactNode } from 'react'

interface PageStackProps extends StackProps {
  children: ReactNode
}

const PageStack = ({ children, ...rest }: PageStackProps) => (
  <Stack gap={{ base: '4', md: '6' }} py={4} {...rest}>
    {children}
  </Stack>
)

export default PageStack
