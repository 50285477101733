import { Box, HStack, Spacer, Spinner, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useContext } from 'react'

import FilterSegmentNameOption from './filterSegmentNameOption'
import FilterSegmentValueOptions from './filterSegmentValueOptions'
import MapSegmentFiltersModalContext from './mapSegmentFiltersModalContext'

import useGetObject from '@app/hooks/useGetObject'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStrategyMetricDataPointSegmentNamesQuery } from '@graphql/queries'

const optionBuilder = (segmentName: { id: string; name: string }) => (
  <FilterSegmentNameOption key={segmentName.id} option={segmentName} />
)

const Fetcher: FC<{ id: string }> = ({ id }) => {
  useStrategyMetricDataPointSegmentNamesQuery({
    variables: { id }
  })

  return null
}

const SuspenseFetcher = withSuspenseWrapper(Fetcher)

type Props = {
  strategyId: string
}

const SegmentFilters: FC<Props> = ({ strategyId }) => {
  const strategy = useGetObject(strategyId, 'strategy')
  const { activeSegmentName } = useContext(MapSegmentFiltersModalContext)

  const segmentNames = strategy?.metricDataPointSegmentNames

  const builder = () => {
    if (activeSegmentName) {
      return <FilterSegmentValueOptions segmentName={activeSegmentName} strategyId={strategyId} />
    }

    return segmentNames?.map((segmentName: { id: string; name: string }) => optionBuilder(segmentName))
  }

  return (
    <Box overflowX="hidden" overflowY="auto" maxH="330px">
      {typeof segmentNames === 'undefined' && (
        <HStack overflowX="hidden" overflowY="auto" maxH="100px" p={2}>
          <Text color="fg.muted" fontSize="sm">
            Fetching segments...
          </Text>
          <Spacer />
          <Spinner size="sm" />
        </HStack>
      )}
      {segmentNames && segmentNames.length === 0 && (
        <Box overflowX="hidden" overflowY="auto" maxH="100px">
          <Text p={2} color="fg.muted" fontSize="sm">
            No segments available for this map
          </Text>
        </Box>
      )}
      {builder()}
      <SuspenseFetcher id={strategyId} />
    </Box>
  )
}

export default SegmentFilters
