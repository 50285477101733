import { type Dialog } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import MetricIntegrationModal from './metricIntegrationModal'

import { MenuItem } from '@app/components/ui/menu'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import type { Credential } from '@graphql/types'

type Props = Partial<Dialog.RootProps> & {
  credential: Credential
}

const MetricIntegrationEditModal: FC<Props> = (props) => {
  const [open, setOpen] = useState(false)

  const { user } = useStoreCurrentUser()

  if (!user) {
    return null
  }

  return (
    <>
      <MenuItem value="edit_integration" onClick={() => setOpen(true)}>
        Edit integration...
      </MenuItem>

      <MetricIntegrationModal open={open} onOpenChange={(e) => setOpen(e.open)} {...props} />
    </>
  )
}

export default MetricIntegrationEditModal
