import type { TextProps } from '@chakra-ui/react'
import { Box, IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PopoverArrow, PopoverBody, PopoverContent, PopoverRoot, PopoverTrigger } from '@app/components/ui/popover'
import { Tooltip } from '@app/components/ui/tooltip'
import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import ConfidenceRatingTag from '@app/next/domainObjects/confidenceRating/confidenceRatingTag'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import { useStore } from '@app/store'
import type { CardSize } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'
import { toSentenceCase } from '@app/utils/stringHelpers'
import { ConfidenceRatingEnum } from '@graphql/queries'
import type { BasicCard, Metric, Strategy, Entity, NodeObjectInput } from '@graphql/types'

interface PlaceholderProps {
  size?: CardSize
}

const ConfidencePlaceholder: FC<PlaceholderProps> = memo(() => {
  const { strategyId } = useParams()
  const isAnonymousUser = useIsAnonymousUser(strategyId)

  if (strategyId && isAnonymousUser) {
    return null
  }

  return (
    <Tooltip aria-label="Confidence level" content="Confidence level">
      <IconButton color="fg.subtle" aria-label="Confidence level" variant="ghost">
        <ICON_MAP.Confidence />
      </IconButton>
    </Tooltip>
  )
})

interface ShowProps {
  confidenceRating: ConfidenceRatingEnum
  showChevron?: boolean
  size?: CardSize
}

const ConfidenceRatingShow: FC<ShowProps> = ({ confidenceRating, showChevron = true, size = 'normal' }) => {
  const color = COLOR_MAP[`Confidence-${confidenceRating}`] || 'gray'
  const displayText = toSentenceCase(confidenceRating)

  return (
    <ConfidenceRatingTag
      showChevron={showChevron}
      rating={displayText}
      color={color}
      size={size === 'normal' ? 'md' : 'lg'}
      fontSize={size === 'normal' ? 'sm' : 'lg'}
      tooltip=""
    />
  )
}

type ConfidenceTypes = Metric | BasicCard | Strategy | Entity

interface Props extends TextProps {
  domainObject: Pick<ConfidenceTypes, 'id' | 'classType' | 'confidenceRating'>
  size?: CardSize
}

const ConfidenceRatingPopover: FC<Props> = ({ domainObject, size = 'normal', ...rest }) => {
  const { user } = useStoreCurrentUser()
  const initialFocusRef = useRef()
  const [open, setOpen] = useState(false)
  const updateObject = useStore.use.updateObject()
  const { id, classType, confidenceRating } = domainObject
  const editor = ['admin', 'editor'].includes(user?.role)

  const viewElement = confidenceRating ? (
    <ConfidenceRatingShow confidenceRating={confidenceRating} showChevron={editor} size={size} />
  ) : (
    <ConfidencePlaceholder size={size} {...rest} />
  )

  if (!editor) {
    return viewElement
  }

  const handleItemClick = (value: string) => {
    updateObject({ [classType]: { id, confidenceRating: value as ConfidenceRatingEnum } } as NodeObjectInput)

    setOpen(!open)
  }

  return (
    <PopoverRoot
      portalled
      initialFocusEl={initialFocusRef?.current}
      lazyMount
      onOpenChange={(e) => setOpen(e.open)}
      open={open}
    >
      <PopoverTrigger asChild>
        <Box display="inline" cursor="pointer" onClick={() => setOpen(!open)}>
          {viewElement}
        </Box>
      </PopoverTrigger>
      <PopoverContent maxW={40}>
        <PopoverArrow />
        <PopoverBody m={0} p={0}>
          <SearchList
            searchField="type"
            currentValue={confidenceRating}
            initialFocusRef={initialFocusRef}
            onChange={handleItemClick}
          >
            <SearchListItem text="None" value={null} />
            <SearchListItem text="On track" value={ConfidenceRatingEnum.OnTrack} />
            <SearchListItem text="At Risk" value={ConfidenceRatingEnum.AtRisk} />
            <SearchListItem text="Off Track" value={ConfidenceRatingEnum.OffTrack} />
          </SearchList>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default ConfidenceRatingPopover
