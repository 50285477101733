import type { ButtonProps, IconProps } from '@chakra-ui/react'
import { Box, Icon } from '@chakra-ui/react'
import type { FC, FormEvent } from 'react'
import type { IconType } from 'react-icons'

import { Button } from '@app/components/ui/button'
import CSRFInput from '@app/next/forms/csrfInput'

type Props = {
  url?: string
  buttonTitle: string
  buttonIcon: IconType | FC<IconProps>
  buttonDisabled?: boolean
  buttonProps?: ButtonProps
  iconProps?: IconProps
}

const ProviderForm: FC<Props> = ({
  buttonTitle,
  buttonIcon,
  url = '',
  buttonDisabled = false,
  buttonProps = {},
  iconProps = {}
}) => {
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    // allow this to be used within another form, as it's fully self-contained
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.submit()
  }

  const IconEl = buttonIcon

  return (
    <Box>
      <form action={url} method="post" onSubmit={onSubmit}>
        <CSRFInput />

        <Button w="100%" disabled={buttonDisabled} size="md" type="submit" variant="outline" {...buttonProps}>
          <Icon boxSize={4} {...iconProps}>
            <IconEl />
          </Icon>
          {buttonTitle}
        </Button>
      </form>
    </Box>
  )
}

export default ProviderForm
