import { HStack, Input, Spacer, Text } from '@chakra-ui/react'
import { useState } from 'react'
import type { FC } from 'react'

import { Button } from '@app/components/ui/button'
import {
  DialogBody,
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogActionTrigger
} from '@app/components/ui/dialog'
import { Field } from '@app/components/ui/field'
import { RANDOM_DATA_STD_DEV, RANDOM_DATA_UPWARD_MOVE_PERCENTAGE } from '@app/lib/globals'
import { useStore } from '@app/store'
import { MetricPopulateEmpty } from '@graphql/documents/metric.graphql'
import type { Strategy } from '@graphql/types'

interface Props {
  strategy: Pick<Strategy, 'id'>
}

const PopulateMetricsWithDummyData: FC<Props> = ({ strategy }) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const { actionMutation } = useStore.getState()
  const { id: strategyId } = strategy

  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    const formData = new FormData(e.target)
    const formObject = Object.fromEntries(formData.entries())
    const input = {}

    // This only works for the two fields in the form, if more are added, this will need to be updated
    Object.keys(formObject).forEach((key) => {
      const value = formObject[key] as string

      input[key] = parseFloat(value)
    })

    actionMutation(MetricPopulateEmpty, { strategyId, ...input }, {}, { strategyId }).finally(() => {
      setLoading(false)

      setOpen(false)
    })
  }

  return (
    <>
      <HStack>
        <Text fontSize="sm" fontWeight="medium">
          Populate metrics with random data
        </Text>
        <Spacer />
        <Button onClick={() => setOpen(true)} variant="subtle">
          Populate
        </Button>
      </HStack>

      <DialogRoot onOpenChange={(e) => setOpen(e.open)} open={open} placement="center">
        <DialogBackdrop />
        <DialogContent>
          <form onSubmit={onSubmit}>
            <DialogHeader>
              <DialogTitle>Populate metrics with random data</DialogTitle>
            </DialogHeader>
            <DialogCloseTrigger />
            <DialogBody>
              <Field label="Standard deviation" required>
                <Input defaultValue={RANDOM_DATA_STD_DEV} name="stdDeviation" type="number" />
              </Field>
              <Field label="Upward move percentage" required>
                <Input defaultValue={RANDOM_DATA_UPWARD_MOVE_PERCENTAGE} name="upwardMovePercentage" type="number" />
              </Field>
            </DialogBody>
            <DialogFooter>
              <DialogActionTrigger asChild>
                <Button variant="outline">Cancel</Button>
              </DialogActionTrigger>
              <Button loading={loading} type="submit">
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </DialogRoot>
    </>
  )
}

export default PopulateMetricsWithDummyData
