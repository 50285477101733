import type { FC } from 'react'
import { useEffect, useState } from 'react'

import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useAccountsQuery } from '@graphql/queries'

interface Props {
  showInactive?: boolean
}

const AccountsFetcher: FC<Props> = ({ showInactive }) => {
  const [includeDisabled] = useState(showInactive)
  const [accountData, setAccountData] = useState(null)

  const [{ data }] = useAccountsQuery({
    variables: { includeDisabled },
    pause: !!accountData && includeDisabled === showInactive
  })

  useEffect(() => {
    if (!data) {
      return
    }

    setAccountData(data)
  }, [showInactive, data])

  return null
}

export default withSuspenseWrapper(AccountsFetcher)
