import { useToken } from '@chakra-ui/react'
import { NodeResizer as RFResizer, useViewport } from '@xyflow/react'
import type { NodeResizerProps, ResizeParams } from '@xyflow/react'
import type { FC } from 'react'

import { scaleSizeToMapZoom } from '@app/pages/maps/components/nodes/helpers'
import { useStore } from '@app/store'

interface Props extends NodeResizerProps {
  strategyId: string
  nodeId: string
  baseZoom?: number
  maxZoom?: number
}

const NodeResizer: FC<Props> = ({ strategyId, nodeId, baseZoom = 15, maxZoom = 40, onResizeEnd = null, ...rest }) => {
  const [resizingBorderColor] = useToken('colors', ['blue.500'])
  const { zoom } = useViewport()
  const resizerHandleDimensions = scaleSizeToMapZoom(baseZoom, zoom, maxZoom)
  const updateNode = useStore.use.updateNode()

  const defaultResizeHandler = (_, params: ResizeParams) => {
    const { width: newWidth, height: newHeight, x, y } = params

    updateNode(strategyId, nodeId, { position: { x, y }, width: Math.floor(newWidth), height: Math.floor(newHeight) })
  }

  const resizeHandler = onResizeEnd || defaultResizeHandler

  const handleStyle = {
    width: resizerHandleDimensions,
    height: resizerHandleDimensions,
    backgroundColor: 'white',
    border: '2px solid',
    borderColor: resizingBorderColor
  }

  return <RFResizer handleStyle={handleStyle} onResizeEnd={resizeHandler} {...rest} />
}

export default NodeResizer
