import type { FileUploadFileAcceptDetails } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import AwsS3, { type AwsBody } from '@uppy/aws-s3'
import type { UppyOptions } from '@uppy/core'
import Uppy from '@uppy/core'
import type { FC } from 'react'
import { useState } from 'react'

import { MAX_AWS_FILE_SIZE } from '@app/lib/globals'

export type Meta = Record<string, unknown>
type RenderProps = {
  uppy: Uppy<Meta, AwsBody>
  uploading: boolean
  fileName: string
  shrineHash: string
  onFileAccept: (details: FileUploadFileAcceptDetails) => void
}

interface Props extends Omit<UppyOptions<Meta, AwsBody>, 'restrictions'> {
  children: (props: RenderProps) => JSX.Element
  fileTypes?: string[]
  onUploadStart?: (data) => void
  onUploadSuccess?: (filedata, file, response) => void
  onUploadError?: (error: Error) => void
}

const UppyRoot: FC<Props> = ({
  children,
  fileTypes = [],
  onUploadStart = (_data) => {},
  onUploadSuccess = (_filedata, _file, _response) => {},
  onUploadError = (_error) => {},
  ...rest
}) => {
  const [uploading, setUploading] = useState(false)
  const [fileName, setFileName] = useState('')
  const [shrineHash, setShrineHash] = useState('')

  const uppyOptions: UppyOptions<Meta, AwsBody> = {
    ...{
      autoProceed: true,
      onBeforeUpload: () => {
        setUploading(true)

        return true
      },
      locale: {
        strings: { chooseFiles: 'Choose a file to upload' },
        pluralize(_n: number): number {
          throw new Error('Function not implemented.')
        }
      },
      restrictions: {
        maxNumberOfFiles: 1,
        maxFileSize: MAX_AWS_FILE_SIZE,
        allowedFileTypes: fileTypes,
        minFileSize: 0,
        maxTotalFileSize: 0,
        minNumberOfFiles: 1,
        requiredMetaFields: []
      }
    },
    ...rest
  }

  const uppy = new Uppy(uppyOptions)

  uppy.use(AwsS3, {
    endpoint: '/',
    headers: {
      'x-amz-server-side-encryption': 'AES256'
    },
    shouldUseMultipart: true
  })

  uppy
    .on('file-added', (file) => {
      if (file.type.includes('image/')) {
        const { data } = file // is a Blob instance
        const url = URL.createObjectURL(data)
        const image = new Image()

        image.src = url
        image.onload = () => {
          uppy.setFileMeta(file.id, { width: image.width, height: image.height })
          URL.revokeObjectURL(url)
        }
      }

      setFileName(file.name)
      setUploading(true)
    })
    .on('upload', (data) => {
      onUploadStart(data)
    })
    .on('upload-success', (file, response) => {
      let additionalMetadata = {}

      if (file.type.includes('image/') && file.meta?.height && file.meta?.width) {
        additionalMetadata = { height: file.meta.height, width: file.meta.width }
      }

      const uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache[\w]*\/([^?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
          ...additionalMetadata
        }
      })

      onUploadSuccess(uploadedFileData, file, response)
      setUploading(false)
      setShrineHash(uploadedFileData)
    })
    .on('error', (error) => {
      onUploadError(error)
    })

  const onFileAccept = (event) => {
    event.files.forEach((file) => {
      try {
        uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file
        })
      } catch (err) {
        Sentry.captureException(err)
      }
    })
  }

  // const ChildrenElement = children
  return children({ uppy, uploading, fileName, shrineHash, onFileAccept })
}

export default UppyRoot
