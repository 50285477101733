import { Input, VisuallyHidden } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useState } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import { GOOGLE_ANALYTICS_V4 } from '@app/utils/googleAnalyticsV4'
import { useGoogleIntegrationQuery } from '@graphql/queries'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'configuration'> | null
  disabled: boolean
}

const GoogleAnalyticsV4: FC<Props> = ({ metricSource, disabled }) => {
  const [{ data }] = useGoogleIntegrationQuery()
  const [gametricName, setGametricName] = useState('')
  const [propertyName, setPropertyName] = useState('')

  const { googleIntegration } = data || {
    googleIntegration: {
      id: '',
      propertiesV4: [
        {
          id: '',
          display_name: ''
        }
      ]
    }
  }

  const { metrics } = GOOGLE_ANALYTICS_V4

  return (
    <>
      <VisuallyHidden asChild>
        <Input name="integrationId" readOnly value={googleIntegration?.id} />
      </VisuallyHidden>
      <VisuallyHidden asChild>
        <Input name="configuration.gametric.name" readOnly value={gametricName} />
      </VisuallyHidden>
      <VisuallyHidden asChild>
        <Input name="configuration.property.name" readOnly value={propertyName} />
      </VisuallyHidden>
      <Field label="Property" required disabled={disabled}>
        <NativeSelectRoot>
          <NativeSelectField
            name="configuration.property.id"
            placeholder="Choose a property"
            onBlur={(e) => {
              setPropertyName(e.target.options[e.target.selectedIndex].text)
            }}
            defaultValue={metricSource?.configuration?.property?.id}
          >
            {googleIntegration?.propertiesV4.map((property) => (
              <option key={property.id} value={property.id}>
                {property.display_name} ({property.id})
              </option>
            ))}
          </NativeSelectField>
        </NativeSelectRoot>
      </Field>
      <Field label="Metric" required disabled={disabled}>
        <NativeSelectRoot>
          <NativeSelectField
            name="configuration.gametric.id"
            placeholder="Choose a metric"
            onChange={(e) => {
              setGametricName(e.target.options[e.target.selectedIndex].text)
            }}
            defaultValue={metricSource?.configuration?.gametric?.id}
          >
            {sortBy(metrics, 'name').map((metric) => (
              <option key={metric.id} value={metric.id}>
                {metric.name}
              </option>
            ))}
          </NativeSelectField>
        </NativeSelectRoot>
      </Field>
    </>
  )
}

export default GoogleAnalyticsV4
