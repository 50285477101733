import type { InputProps } from '@chakra-ui/react'
import { Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState, useCallback } from 'react'

import { Field } from '@app/components/ui/field'
import type { Props as LabelsAutocompleteProps } from '@app/shared/autocomplete/labels/labelsAutocomplete'
import LabelsAutocomplete from '@app/shared/autocomplete/labels/labelsAutocomplete'
import type { FormErrors } from '@app/types'

export type AutocompleteLabel = { id: string | number; name: string }

export interface Props extends LabelsAutocompleteProps {
  name: string
  label?: string
  // placeholder?: string
  defaultValue?: AutocompleteLabel[]
  valueTransformer?: (labels: AutocompleteLabel[]) => InputProps['value']
  required?: boolean
  helperText?: React.ReactNode
  errors?: FormErrors
}

const LabelAutocompleteInput: FC<Props> = ({
  name,
  label = null,
  placeholder = '',
  helperText = null,
  errors = {},
  defaultValue = [] as AutocompleteLabel[],
  valueTransformer = (labels: AutocompleteLabel[]) => JSON.stringify(labels.map((lbl) => lbl.name)),
  ...rest
}) => {
  const [labels, setLabels] = useState(defaultValue)

  const handleChange: LabelsAutocompleteProps['onChange'] = useCallback((newLabels) => {
    setLabels(newLabels.map((newLabel) => ({ id: newLabel.value, name: newLabel.label })))
  }, [])

  return (
    <Field errorText={errors[name]?.message} helperText={helperText} label={label} id={name} invalid={!!errors[name]}>
      <VisuallyHidden asChild>
        <Input id={name} name={name} readOnly value={valueTransformer(labels)} />
      </VisuallyHidden>
      <Stack w="100%">
        <LabelsAutocomplete
          id={`${name}-autocomplete`}
          onChange={handleChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...rest}
        />
      </Stack>
    </Field>
  )
}

export default LabelAutocompleteInput
