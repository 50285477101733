import { Box, Fieldset, Group, Input, Stack } from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import type { FormEventHandler } from 'react'
import { useRef, useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { InputGroup } from '@app/components/ui/input-group'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import { toaster } from '@app/components/ui/toaster'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import FormAlert from '@app/next/forms/formAlert'
import Can from '@app/shared/authorization/can'
import { RoleEnum, useInvitationCreateMutation } from '@graphql/queries'

const InvitationForm = () => {
  const { user } = useStoreCurrentUser()
  const [error, setError] = useState<Error | null>(null)
  const ref = useRef<HTMLInputElement>()
  const { revalidate } = useRevalidator()
  const [, invitationCreate] = useInvitationCreateMutation()

  const successCallback = (invitation) => {
    revalidate()

    toaster.create({
      title: 'Invitation successful!',
      description: 'They should receive the email soon.',
      type: 'success'
    })

    ahoy.track('user:sent_invitation', {
      email: invitation.email
    })
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()

    setError(null)

    const formData = new FormData(event.currentTarget)
    const values = Object.fromEntries(formData.entries())

    try {
      await invitationCreate({
        input: {
          email: values.email as string,
          role: values.role as RoleEnum
        }
      })

      if (ref?.current) {
        ref.current.value = ''
      }

      successCallback(values)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <Can I="create" an="invitation">
      <form onSubmit={onSubmit}>
        <Fieldset.Root>
          <Stack>
            <Fieldset.Legend>Invite users</Fieldset.Legend>
            <Fieldset.HelperText>Add users to keep your team informed</Fieldset.HelperText>
          </Stack>
          <Fieldset.Content>
            <FormAlert description={error?.message} title="Failed to send the invitation!" />
            <Field required>
              <InputGroup
                width="full"
                endElement={
                  <Box me="-3">
                    <NativeSelectRoot>
                      <NativeSelectField name="role" placeholder="" maxW="max-content" bg="bg">
                        {user.role === RoleEnum.Admin && <option value={RoleEnum.Admin}>Admin</option>}
                        <option value={RoleEnum.Editor}>Editor</option>
                        <option value={RoleEnum.Viewer}>Viewer</option>
                      </NativeSelectField>
                    </NativeSelectRoot>
                  </Box>
                }
              >
                <Input
                  ref={ref}
                  bg="bg"
                  autoComplete="email"
                  data-cy="invitation-email-input"
                  name="email"
                  placeholder="Invite a colleague by email address"
                  type="email"
                />
              </InputGroup>
            </Field>
            <Group justifyContent="end">
              <Button type="submit">Send invitation</Button>
            </Group>
          </Fieldset.Content>
        </Fieldset.Root>
      </form>
    </Can>
  )
}

export default InvitationForm
