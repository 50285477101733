import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'

import { Tooltip } from '@app/components/ui/tooltip'
import useGetIncomingCollapsedNodes from '@app/hooks/useGetIncomingCollapsedNodes'
import { useStore } from '@app/store'
import type { MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  node: MapDomainNode
}

const ExpandCollapsedIncomersButton: FC<Props> = ({ node }) => {
  const { strategyId } = node
  const { nodes: collapsedNodes } = useGetIncomingCollapsedNodes(node)
  const expandNodes = useStore.use.expandNodes()

  const handleExpand = () => {
    expandNodes(strategyId, collapsedNodes)
  }

  if (!collapsedNodes.length) {
    return null
  }

  return (
    <Tooltip content={`Uncollapse all cards (${collapsedNodes.length})`}>
      <IconButton borderRadius="none" aria-label="Uncollapse cards" onClick={handleExpand} size="sm" variant="outline">
        <ICON_MAP.ExpandAction />
      </IconButton>
    </Tooltip>
  )
}

export default ExpandCollapsedIncomersButton
