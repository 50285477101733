import type { IconProps } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import type { FC } from 'react'

const Productboard: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 256 168">
      <path fill="#FF2638" d="m85.327 83.997l85.327 83.996H0z" />
      <path fill="#FFC600" d="m0 0l85.327 83.997L170.654 0z" />
      <path fill="#0079F2" d="m85.341 83.997l85.327 83.996l85.327-83.996L170.668 0z" />
    </svg>
  </Icon>
)

export default Productboard
