import type { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

import { CARD_FILTER_SIGIL } from '@app/lib/globals'
import type { Props as ContainersSelectProps } from '@app/next/changelog/components/containersSelect'
import ContainersSelect from '@app/next/changelog/components/containersSelect'

const param = `${CARD_FILTER_SIGIL}containerNodeIds`

type Props = {
  strategyId: string | null
}

const ContainerFilters: FC<Props> = ({ strategyId }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const defaultContainerNodeIds = searchParams.get(param)?.split(',') || []

  const onChange: ContainersSelectProps['onChange'] = (selected) => {
    if (selected.length > 0) {
      searchParams.set(param, selected.map((s) => s.id).join(','))
    } else {
      searchParams.delete(param)
    }

    setSearchParams(searchParams)
  }

  return (
    <ContainersSelect
      size="sm"
      onChange={onChange}
      defaultContainerNodeIds={defaultContainerNodeIds}
      strategyId={strategyId}
    />
  )
}

export default ContainerFilters
