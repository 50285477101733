import { Text, Textarea } from '@chakra-ui/react'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'configuration'> | null
  disabled: boolean
}

const QueryEditor: FC<Props> = ({ metricSource, disabled }) => (
  <Field
    label="Query"
    disabled={disabled}
    required
    helperText={
      <Text>
        Your query must return the following columns: <strong>date</strong> and <strong>value</strong>.
      </Text>
    }
  >
    <Textarea
      defaultValue={metricSource?.configuration?.query}
      name="configuration.query"
      placeholder="Query"
      rows={5}
    />
  </Field>
)

export default QueryEditor
