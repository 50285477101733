import { Separator, Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import Goal from '@app/pages/goals/components/goal'
import GoalMetricHeader from '@app/pages/goals/components/goalMetricHeader'
import type { Goal as GoalType, Metric, User } from '@graphql/types'

interface Props {
  goals: GoalType[]
}

const Goals: FC<Props> = ({ goals }) => {
  const metric = useGetObject(goals[0]?.metricId, 'metric') as Metric
  const owner = useGetObject(metric?.ownerId, 'user') as User

  if (!metric) {
    return null
  }

  return (
    <Stack>
      <GoalMetricHeader metric={metric} owner={owner} />
      <Separator mb={2} borderColor="border" />
      <Stack gap={4}>
        {goals.map((goal) => (
          <Goal key={goal.id} goal={goal} metric={metric} />
        ))}
      </Stack>
    </Stack>
  )
}

export default Goals
