import { useAsyncValue } from 'react-router-dom'

import MetricDataPointForm from './components/metricDataPointForm'

import withAwait from '@app/shared/withAwait'
import type { MetricDataPoint } from '@graphql/queries'

type ResolvedMetricDataPoint = {
  metricDataPoint?: MetricDataPoint
}

interface MetricDataPointPromise {
  data?: ResolvedMetricDataPoint
}

const Edit = () => {
  const {
    data: { metricDataPoint }
  }: MetricDataPointPromise = useAsyncValue()

  if (!metricDataPoint) {
    return null
  }

  return <MetricDataPointForm metricDataPoint={metricDataPoint} />
}

export default withAwait(Edit, 'metricDataPoint')
