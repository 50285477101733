import { HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Tag } from '@app/components/ui/tag'
import CorrelationScoreDescription from '@app/pages/correlations/components/correlationScoreDescription'
import { shouldFlipColors } from '@app/pages/maps/components/edges/helpers'
import { useStore } from '@app/store'
import type { MapDomainEdge } from '@app/types'
import { correlationScoreColor } from '@app/utils/metricHelpers'

interface Props {
  edge: MapDomainEdge
}

const CorrelationDetails: FC<Props> = ({ edge }) => {
  const { correlationScore, sourceId, targetId } = edge
  const getNodeById = useStore.use.getNodeById()
  const source = getNodeById(sourceId)
  const target = getNodeById(targetId)

  if (!correlationScore) {
    return null
  }

  const flipColors = shouldFlipColors(source, target)
  const scoreColor = correlationScoreColor(correlationScore, flipColors)

  return (
    <Stack gap={4}>
      <HStack>
        <Text>Correlation Score:</Text>
        <Tag color="white" bgColor={scoreColor.color}>
          {correlationScore}
        </Tag>
        <Spacer />
        <Button size="xs" variant="outline" asChild>
          <Link to={`/correlations/graph/${sourceId}/${targetId}`}>See Comparison</Link>
        </Button>
      </HStack>
      <CorrelationScoreDescription />
    </Stack>
  )
}

export default CorrelationDetails
