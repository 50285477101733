import { format as formatDateTime, toZonedTime } from 'date-fns-tz'
import type { RequestPolicy, UseQueryState } from 'urql'

import type { UserData } from '@app/types'
import { loaderQuery } from '@graphql/client'
import { CurrentUser } from '@graphql/documents/user.graphql'
import { type CurrentUserQuery, useCurrentUserQuery } from '@graphql/queries'

type BuildUserData = (result: Pick<UseQueryState<CurrentUserQuery>, 'data' | 'fetching' | 'error'>) => UserData

const buildUserData: BuildUserData = (result) => {
  const { data, fetching: loading, error } = result

  const user = data?.me
  const identityId = data?.identityId
  const userTimeZone = user?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
  const dateTimeInUserTimeZone = (dateTime: string | Date) => toZonedTime(new Date(dateTime), userTimeZone)

  const formatDateTimeInUserTimeZone = (dateTime: string | Date, format = 'MMMM do, yyy') =>
    formatDateTime(dateTimeInUserTimeZone(dateTime), format, { timeZone: userTimeZone })

  return {
    loading,
    error,
    user,
    identityId,
    dateTimeInUserTimeZone,
    formatDateTimeInUserTimeZone
  }
}

export const fetchCurrentUser = async (requestPolicy: RequestPolicy = 'cache-first') => {
  const result = await loaderQuery<CurrentUserQuery>(CurrentUser, {}, { requestPolicy })

  return buildUserData({ ...result, fetching: false })
}

const useCurrentUser = (requestPolicy: RequestPolicy = 'cache-first') => {
  const [result] = useCurrentUserQuery({
    requestPolicy
  })

  return buildUserData(result)
}

export default useCurrentUser
