import { Input, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { Form, useNavigation } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import FormAlert from '@app/next/forms/formAlert'
import UrlInput from '@app/next/forms/urlInput'
import useForm from '@app/next/forms/useForm'
import { CardHeader } from '@app/shared/layout'

const OrganizationForm: FC = () => {
  const { errors } = useForm({}) as { errors: { global?: { message?: string }; name?: string; homepageUrl?: string } }
  const navigation = useNavigation()

  return (
    <Stack gap="6">
      <CardHeader title="Almost there!" subtitle="Add your organization details to finish setting up your account." />

      <Form id="organization-form" method="post">
        <Stack gap="5">
          <FormAlert description={errors?.global?.message} title="Failed to create the organization!" />

          <Field label="Organization name" id="name" invalid={!!errors.name} required>
            <Input id="name" name="name" placeholder="Enter the name of your organization" />
          </Field>
          <Field label="Company website" id="homepageUrl" invalid={!!errors.homepageUrl}>
            <UrlInput id="homepageUrl" name="homepageUrl" placeholder="https://website.com" />
          </Field>
        </Stack>
      </Form>
      <Stack gap={4}>
        <Button
          form="organization-form"
          loading={navigation.state === 'submitting' || navigation.state === 'loading'}
          size="md"
          type="submit"
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  )
}

export default OrganizationForm
