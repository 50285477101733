import type { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import useSubscribeToOrganizationEvents from '@app/hooks/useSubscribeToOrganizationEvents'
import {
  useMapStructureUpdatedSubscription,
  useOrganizationCrudSubscription,
  useStrategyFieldsSubscription
} from '@graphql/queries'

interface GraphqlSubscriptionShellProps {
  children?: ReactNode
  strategyId: string | null
}
const GraphqlSubscriptionShell = ({ children, strategyId }: GraphqlSubscriptionShellProps) => {
  const { user } = useStoreCurrentUser()

  useSubscribeToOrganizationEvents()
  const organizationId = user?.organization?.id
  useOrganizationCrudSubscription({
    variables: {
      organizationId
    },
    pause: !organizationId
  })
  useStrategyFieldsSubscription({
    variables: {
      strategyId
    },
    pause: !strategyId
  })
  useMapStructureUpdatedSubscription({
    variables: {
      strategyId
    },
    pause: !strategyId
  })

  return children || <Outlet />
}

export default GraphqlSubscriptionShell
