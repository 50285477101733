import type { FC } from 'react'

import { toaster } from '@app/components/ui/toaster'
import ConfirmPopover from '@app/shared/confirmPopover'
import { useAccountSwitchMutation } from '@graphql/queries'
import type { Account } from '@graphql/types'

interface Props {
  account: Account
  children?: React.ReactNode
}

const AccountSwitchButton: FC<Props> = ({ account, children = null }) => {
  const [, switchAccount] = useAccountSwitchMutation()

  const handleSwitchAccount = async () => {
    const res = await switchAccount({
      input: {
        accountId: account.id
      }
    })

    if (res.error) {
      toaster.create({
        title: 'Account switch',
        description: 'We were unable to switch your account, please contact support.',
        type: 'error'
      })
    } else {
      window.location.reload()
    }
  }

  return (
    <ConfirmPopover
      onConfirm={() => handleSwitchAccount()}
      header="Switch account"
      body="Are you sure you'd like to switch accounts?"
      confirmButtonText="Confirm"
    >
      {children}
    </ConfirmPopover>
  )
}

export default AccountSwitchButton
