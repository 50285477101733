import { useReactFlow } from '@xyflow/react'
import { useCallback } from 'react'
import type { AnyVariables } from 'urql'

import useMousePosition from '@app/hooks/useMousePosition'
import { useStore } from '@app/store'
import type { Nodes } from '@app/types'
import generateRandomName from '@app/utils/uniqueNameGenerator'

const NAME_GEN_OVERRIDES = {
  basicCard: generateRandomName
}

const EMPTY = {}

type Arguments = {
  mutationVariables?: AnyVariables
}

type Callback = (strategyId: string, nodeType: Nodes) => void
type UseCreateNewObject = (args?: Arguments) => Callback

const useCreateNewObject: UseCreateNewObject = ({ mutationVariables = EMPTY }) => {
  const { screenToFlowPosition } = useReactFlow()
  const getLatestMousePosition = useMousePosition()

  const addNode = useStore.use.addNode()

  return useCallback<Callback>(
    (strategyId, nodeType) => {
      const name = NAME_GEN_OVERRIDES[nodeType]?.() || `${generateRandomName()} ${nodeType}`
      const mousePosition = getLatestMousePosition()
      const position = screenToFlowPosition(mousePosition)
      const objectData = {
        name,
        ...mutationVariables
      }

      addNode({
        strategyId,
        nodeData: {
          position
        },
        objectType: nodeType,
        objectData
      })
    },
    [screenToFlowPosition, getLatestMousePosition, mutationVariables, addNode]
  )
}

export default useCreateNewObject
