import type { Table as ChakraTableProps } from '@chakra-ui/react'
import { Table as ChakraTable } from '@chakra-ui/react'
import uniqueId from 'lodash/uniqueId'
import type { FC } from 'react'

import HeaderColumn from './components/headerColumn'
import RowColumn from './components/rowColumn'
import type { Data, TableProps } from './types'

type Props<TCellData extends Data> = ChakraTableProps.RootProps & TableProps<TCellData>

const Table = <TCellData extends Data = Data>({
  columns,
  data,
  rowFormatter = (_data) => ({}),
  showHeader = true,
  customHeader = null,
  ...rest
}: Props<TCellData>): ReturnType<FC<Props<TCellData>>> => (
  <ChakraTable.Root {...rest}>
    {showHeader && (
      <ChakraTable.Header>
        <ChakraTable.Row>
          {columns.map((column) => (
            <HeaderColumn key={column.key || column.header.toString() || uniqueId()} column={column} />
          ))}
        </ChakraTable.Row>
      </ChakraTable.Header>
    )}
    {customHeader}
    <ChakraTable.Body>
      {data.map((row) => (
        <ChakraTable.Row key={`row-${row.id}`} _hover={{ bgColor: 'bg.muted' }} {...rowFormatter(row)}>
          {columns.map((column) => (
            <RowColumn<TCellData>
              key={`${column.key || column.header.toString() || uniqueId()}-${row.id}`}
              column={column}
              row={row}
            />
          ))}
        </ChakraTable.Row>
      ))}
    </ChakraTable.Body>
  </ChakraTable.Root>
)

export default Table
