import { Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import UserForm from './components/userForm'

import SessionHeader from '@app/pages/sessions/components/sessionHeader'

const User: FC = () => (
  <Stack gap="8" w="full" maxW="md" px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }}>
    <SessionHeader title="Set up your account" />
    <UserForm />
  </Stack>
)

export default User
