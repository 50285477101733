import { type Select, createListCollection } from '@chakra-ui/react'
import Cookies from 'js-cookie'
import isEmpty from 'lodash/isEmpty'
import { memo, useEffect, useMemo, useState } from 'react'
import type { FC, CSSProperties } from 'react'
import { FixedSizeList as List } from 'react-window'

import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from '@app/components/ui/select'
import { SELECTED_SLACK_CHANNEL_COOKIE } from '@app/lib/globals'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useSlackChannelsQuery } from '@graphql/queries'

interface MenuItemRendererProps {
  index: number
  style: CSSProperties
  data: { id: string; label: string }[]
}

const MenuItemRenderer = memo(({ index, style, data }: MenuItemRendererProps) => {
  const item = data[index]

  return (
    <SelectItem item={item} key={item.id} style={style}>
      {item.label}
    </SelectItem>
  )
})

interface Props extends Omit<Select.RootProps, 'collection'> {
  placeholder?: string
  clearable?: boolean
  prepopulateFromCookie?: boolean
}

const SlackChannelInput: FC<Props> = ({
  placeholder = 'Select a slack channel',
  clearable = false,
  prepopulateFromCookie = false,
  ...rest
}) => {
  const cookiesApi = Cookies.withAttributes({ sameSite: 'lax' })
  const [slackChannels, setSlackChannels] = useState([])

  const [{ data, fetching }] = useSlackChannelsQuery()

  const channels = useMemo(
    () =>
      createListCollection({
        items: slackChannels || [],
        itemToString: (item) => item.label,
        itemToValue: (item) => item.id
      }),
    [slackChannels]
  )

  useEffect(() => {
    if (data) {
      const sortedChannels = (data?.slackChannels || []).sort((a, b) => a.label.localeCompare(b.label))

      setSlackChannels(sortedChannels)
    }
  }, [data])

  if (prepopulateFromCookie && isEmpty(rest.defaultValue)) {
    const cookie = cookiesApi.get(SELECTED_SLACK_CHANNEL_COOKIE)
    const parsedCookie = cookie ? JSON.parse(cookie) : null

    if (cookie) {
      try {
        rest.defaultValue = [parsedCookie?.value]
      } catch (_e) {
        // ignore parse errors, and leave initialValue empty
      }
    }
  }

  const onChange = (e) => {
    const value = e.value[0]
    cookiesApi.set(SELECTED_SLACK_CHANNEL_COOKIE, JSON.stringify({ value }))

    rest?.onValueChange?.(e)
  }

  return (
    <SelectRoot collection={channels} {...rest} lazyMount disabled={fetching} onValueChange={onChange}>
      <SelectTrigger clearable={clearable}>
        <SelectValueText placeholder={fetching ? 'Loading slack channels...' : placeholder} />
      </SelectTrigger>
      <SelectContent zIndex="popover">
        <List height={300} itemCount={channels.items.length} itemSize={35} width="100%" itemData={channels.items}>
          {MenuItemRenderer}
        </List>
      </SelectContent>
    </SelectRoot>
  )
}

export default withSuspenseWrapper(SlackChannelInput)
