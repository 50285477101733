import { useMemo, useState } from 'react'
import type { FC, ChangeEvent } from 'react'

import { buildEntitySources, deriveType, showForeignTypesInput } from '@app/pages/maps/components/addCards/helpers'
import ForeignTypeInput from '@app/pages/maps/components/addCards/queryExisting/foreignTypeInput'
import SourceNameInput from '@app/pages/maps/components/addCards/queryExisting/sourceNameInput'
import type { Nodes } from '@app/types'
import type { SourcesEntitySource } from '@graphql/types'

type Props = {
  sources: SourcesEntitySource[]
  type: string
}

const QueryExistingEntityFields: FC<Props> = ({ sources, type }) => {
  const typeValue = deriveType(type)
  const [sourceNameValue, setSourceNameValue] = useState(type)
  const [foreignTypeValue, setForeignTypeValue] = useState('')
  const sourceType = sources?.find((entity) => entity.sourceName === sourceNameValue)
  const foreignTypeOptions = sourceType?.entityTypes || []
  const inputSources = useMemo(() => buildEntitySources(sources), [sources])

  const handleSourceNameChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSourceNameValue(e.target.value as Nodes)
    setForeignTypeValue('')
  }

  const handleForeignTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setForeignTypeValue(e.target.value)
  }

  return (
    <>
      <SourceNameInput
        value={sourceNameValue}
        typeValue={typeValue}
        sources={inputSources}
        onChange={handleSourceNameChange}
      />
      <ForeignTypeInput
        value={foreignTypeValue}
        show={showForeignTypesInput(sourceNameValue)}
        sources={foreignTypeOptions}
        onChange={handleForeignTypeChange}
      />
    </>
  )
}

export default QueryExistingEntityFields
