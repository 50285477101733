import { Group } from '@chakra-ui/react'
import type { NodeToolbarProps } from '@xyflow/react'
import { NodeToolbar } from '@xyflow/react'
import type { FC, ReactNode } from 'react'

import useGetIsSelecting from '@app/hooks/useGetIsSelecting'
import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'

interface Props extends NodeToolbarProps {
  strategyId: string
  children: ReactNode
}

const Toolbar: FC<Props> = ({ strategyId, children, ...rest }) => {
  const isSelecting = useGetIsSelecting()
  const isAnonymousUser = useIsAnonymousUser(strategyId)

  return (
    !isSelecting &&
    !isAnonymousUser && (
      <NodeToolbar {...rest}>
        <Group bg="bg" attached colorPalette="gray">
          {children}
        </Group>
      </NodeToolbar>
    )
  )
}

export default Toolbar
