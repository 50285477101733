import { Fieldset, HStack, Spacer, Stack, StackSeparator, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import UserForm from './components/userForm'

import { Button } from '@app/components/ui/button'
import AccountsList from '@app/pages/settings/user/components/accountsList'
import { PageStack } from '@app/shared/layout'

export const Edit = () => (
  <PageStack separator={<StackSeparator />}>
    <Fieldset.Root>
      <Stack>
        <Fieldset.Legend>Profile</Fieldset.Legend>
        <Fieldset.HelperText>Update your user profile</Fieldset.HelperText>
      </Stack>
      <Fieldset.Content>
        <UserForm />
      </Fieldset.Content>
    </Fieldset.Root>
    <Fieldset.Root>
      <Stack>
        <Fieldset.Legend>
          <HStack>
            <Text>Accounts</Text>
            <Spacer />
            <Button asChild size="xs">
              <Link to="/onboarding/organization">Create organization</Link>
            </Button>
          </HStack>
        </Fieldset.Legend>
        <Fieldset.HelperText>Access to multiple organizations</Fieldset.HelperText>
      </Stack>
      <Fieldset.Content>
        <AccountsList />
      </Fieldset.Content>
    </Fieldset.Root>
  </PageStack>
)

export default Edit
