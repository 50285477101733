import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import type { Dispatch, FC, ProviderProps } from 'react'
import { useState, createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { useSearchParams } from 'react-router-dom'

import type { SortDir } from '@app/types'

const PREFIX = 'order.'

type StorageType = 'url' | 'state'

type SortHeaderState = Record<string, SortDir>

type SortHeaderContextAction = {
  type: 'setState' | 'replace'
  payload: SortHeaderState
}

type SetSearchParams = (newParams: URLSearchParams) => void
type SortHeaderStatefulContextAction = SortHeaderContextAction & {
  searchParams: URLSearchParams
  setSearchParams: SetSearchParams
}

type SortHeaderContextValue = {
  state: SortHeaderState
  dispatch: Dispatch<SortHeaderContextAction>
}

const SortHeaderContext = createContext<SortHeaderContextValue>(undefined)

type Props = Partial<ProviderProps<SortHeaderContextValue>> & {
  defaultState?: SortHeaderState
  storageType?: StorageType
}

const reducer = (state: SortHeaderState, action: SortHeaderStatefulContextAction): SortHeaderState => {
  const { type, payload, searchParams, setSearchParams } = action

  searchParams.delete('page')

  if (type === 'setState') {
    // Set the state _without_ updating params.
    // Used only when initializing the context, to
    // avoid polluting the URL with default params.
    return payload
  }

  if (type === 'replace') {
    Object.keys(state).forEach((key) => {
      const urlParam = `${PREFIX}${key}`

      searchParams.delete(urlParam)
    })

    Object.keys(payload).forEach((key) => {
      const urlParam = `${PREFIX}${key}`

      if (payload[key]) {
        searchParams.set(urlParam, payload[key])
      }
    })

    setSearchParams(searchParams)

    return pickBy(payload, (value) => !!value)
  }

  return state
}

const init = (initialState = {}) => initialState

const useSortContextStorage = (storageType: StorageType): [URLSearchParams, SetSearchParams] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchState, setSearchState] = useState(new URLSearchParams({}))

  if (storageType === 'url') {
    return [searchParams, setSearchParams]
  }

  return [searchState, setSearchState]
}

const SortHeaderContextProvider: FC<Props> = ({ children, defaultState, storageType = 'url' }) => {
  const [searchParams, setSearchParams] = useSortContextStorage(storageType)
  const [state, rawDispatch] = useReducer<typeof reducer, SortHeaderState>(reducer, {}, init)

  const dispatch = useCallback(
    (action: SortHeaderContextAction) => {
      rawDispatch({ ...action, searchParams, setSearchParams })
    },
    [searchParams, setSearchParams]
  )

  const value = useMemo(
    () => ({
      state,
      dispatch
    }),
    [dispatch, state]
  )

  useEffect(() => {
    const orderParams = pickBy(Object.fromEntries(searchParams.entries()), (_v, k) => k.startsWith(PREFIX))

    if (!isEmpty(orderParams)) {
      const paramState = Object.fromEntries(
        Object.entries(orderParams).map(([k, v]) => [k.replace(PREFIX, ''), v])
      ) as SortHeaderState

      dispatch({ type: 'setState', payload: paramState })

      return
    }

    if (defaultState) {
      dispatch({ type: 'setState', payload: defaultState })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <SortHeaderContext.Provider value={value}>{children}</SortHeaderContext.Provider>
}

const useSortHeaderContext: () => SortHeaderContextValue = () => {
  const context = useContext(SortHeaderContext)

  if (context === undefined) {
    throw new Error('useSortHeaderContext must be used within SortHeaderContext.Provider')
  }

  return context
}

export { SortHeaderContextProvider, useSortHeaderContext }
