import { Input, Link, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'configuration'> | null
  disabled: boolean
}

const Looker: FC<Props> = ({ metricSource, disabled }) => (
  <>
    <Text py={2} color="subtle" fontSize="sm">
      For more information on configuring Looker, please visit{' '}
      <Link
        color="link"
        href="https://docs.doubleloop.app/measure/looker-integration"
        rel="onoopener noreferrer"
        target="_blank"
      >
        our Looker Doc
      </Link>
      .
    </Text>
    <Field label="Date field" disabled={disabled} required>
      <Input
        defaultValue={metricSource?.configuration?.date_field}
        name="configuration.date_field"
        placeholder="Column name containing dates"
      />
    </Field>
    <Field label="Value field" disabled={disabled} required>
      <Input
        defaultValue={metricSource?.configuration?.value_field}
        name="configuration.value_field"
        placeholder="Column name containing values"
      />
    </Field>
  </>
)

export default Looker
