import type { FC } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import UserProfile from '@app/shared/userProfile'
import type { Account } from '@graphql/types'

interface Props {
  data: Account
}

const NameCell: FC<Props> = ({ data: account }) => {
  const user = useGetObject(account?.user?.id, 'user')

  if (!user) {
    return null
  }

  return <UserProfile user={user} />
}

export default NameCell
