import OrganizationForm from './components/organizationForm'

import { PageStack } from '@app/shared/layout'

export const Edit = () => (
  <PageStack>
    <OrganizationForm />
  </PageStack>
)

export default Edit
