import type { FC, ReactNode } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import { Button } from '@app/components/ui/button'
import { toaster } from '@app/components/ui/toaster'
import ConfirmPopover from '@app/shared/confirmPopover'
import { useStore } from '@app/store'

type Props = {
  goalId: string
  children?: ReactNode
}

const defaultChildren = (
  <Button color="error" aria-label="Delete key result" variant="outline">
    <FiTrash2 /> Delete
  </Button>
)

const GoalDeleteButton: FC<Props> = ({ goalId, children = defaultChildren }) => {
  const deleteObject = useStore.use.deleteObject()

  const onConfirm = async () =>
    deleteObject({ id: goalId, classType: 'goal' }).then(() => {
      toaster.create({ title: 'Deleted key result' })
    })

  return (
    <ConfirmPopover
      onConfirm={onConfirm}
      header="Delete key result"
      body="Are you sure? You can&#39;t undo this action afterwards."
      confirmButtonText="Delete"
    >
      {children}
    </ConfirmPopover>
  )
}

export default GoalDeleteButton
