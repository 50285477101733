import { Input } from '@chakra-ui/react'
import type { ChangeEvent } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectRoot, NativeSelectField } from '@app/components/ui/native-select'
import type { Metric } from '@graphql/queries'

type M = Pick<Metric, 'id' | 'name'>
type Props = {
  source: M
  target: M
  laggedId?: string | null
  setLaggedId?: (id: string) => void
  lagDays?: number | string
  setLagDays?: (days: string) => void
}

const LagInput: (props: Props) => JSX.Element = ({
  source,
  target,
  laggedId = null,
  setLaggedId = () => {},
  lagDays = 0,
  setLagDays = () => {}
}) => {
  const onChangeLaggedId = (e: ChangeEvent<HTMLSelectElement>) => {
    setLaggedId(e.target.value)
  }

  const onChangeLagDays = (e: ChangeEvent<HTMLInputElement>) => {
    setLagDays(e.target.value)
  }

  return (
    <>
      <Field label="Lagging metric" key="lagged-id-field" data-testid="lag-input">
        <NativeSelectRoot>
          <NativeSelectField bgColor="bg" name="lagged-id" onChange={onChangeLaggedId} value={laggedId}>
            <option value="">Do not apply lag</option>
            <option value={source.id}>{source.name}</option>
            <option value={target.id}>{target.name}</option>
          </NativeSelectField>
        </NativeSelectRoot>
      </Field>
      <Field label="Days of lag" key="lagged-days-field">
        <Input
          bg="bg"
          id="lag-days-input"
          onChange={onChangeLagDays}
          step="1"
          type="number"
          value={lagDays.toString()}
        />
      </Field>
    </>
  )
}

export default LagInput
