import type { NativeSelect } from '@chakra-ui/react'
import type { FC } from 'react'

import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'

type Props = Omit<NativeSelect.FieldProps, 'children'> & {
  startYear?: number
  endYear?: number
}

const YearSelectInput: FC<Props> = ({ startYear = 2000, endYear = new Date().getFullYear(), ...rest }: Props) => {
  const length = endYear - startYear + 1

  return (
    <NativeSelectRoot>
      <NativeSelectField {...rest} data-testid="year-select-input">
        {Array.from({ length }, (_, i) => (
          <option key={i} value={startYear + i}>
            {startYear + i}
          </option>
        )).reverse()}
      </NativeSelectField>
    </NativeSelectRoot>
  )
}

export default YearSelectInput
