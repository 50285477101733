import ahoy from 'ahoy.js'
import type { FC } from 'react'
import { useState, useRef } from 'react'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import type { Integration } from '@graphql/queries'
import { useDomainObjectsDeleteMutation } from '@graphql/queries'

interface Props {
  integration: Pick<Integration, 'id'>
  onDelete?: () => void
}

const IntegrationDeleteButton: FC<Props> = ({ integration, onDelete = () => {} }) => {
  const [open, setOpen] = useState(false)
  const cancelRef = useRef()
  const [, deleteIntegration] = useDomainObjectsDeleteMutation()
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setOpen(false)

    setLoading(true)

    try {
      await deleteIntegration({
        input: {
          objects: [
            {
              integration: [integration.id]
            }
          ]
        }
      })
    } finally {
      ahoy.track('integration:deleted', { integrationId: integration.id })
      setLoading(false)
      onDelete()
    }

    return true
  }

  return (
    <>
      <Button colorPalette="red" loading={loading} onClick={() => setOpen(true)}>
        Disconnect
      </Button>

      <DialogRoot
        initialFocusEl={cancelRef?.current}
        onOpenChange={(e) => setOpen(e.open)}
        open={open}
        placement="center"
        role="alertdialog"
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Remove integration</DialogTitle>
          </DialogHeader>

          <DialogBody>
            Are you sure? You will need to reconnect the integration to have new data flow in and out of DoubleLoop.
          </DialogBody>

          <DialogFooter>
            <DialogActionTrigger asChild>
              <Button ref={cancelRef} variant="outline">
                Cancel
              </Button>
            </DialogActionTrigger>
            <Button ml={3} bgColor="error" onClick={onClick}>
              Disconnect
            </Button>
          </DialogFooter>
          <DialogCloseTrigger />
        </DialogContent>
      </DialogRoot>
    </>
  )
}

export default IntegrationDeleteButton
