import { Link, Stack, Text } from '@chakra-ui/react'

const GoogleBigQueryCredentialInstructions = () => (
  <Stack p={4} borderWidth={1} borderRadius="base" bgColor="yellow.50">
    <Text>
      See{' '}
      <Link
        color="link"
        href="https://cloud.google.com/docs/authentication/getting-started"
        rel="onoopener noreferrer"
        target="_blank"
      >
        Google&apos;s documentation
      </Link>{' '}
      for information on how to setup a service account and download your credentials file.
    </Text>
    <Text>
      Ensure that your service account user has <strong>BigQuery Data Viewer</strong> and{' '}
      <strong>BigQuery Job User</strong> permissions in the{' '}
      <Link
        color="link"
        href="https://console.cloud.google.com/iam-admin/iam"
        rel="onoopener noreferrer"
        target="_blank"
      >
        Cloud Console IAM
      </Link>{' '}
      configuration.
    </Text>
    <Text>If you prefer more granular permissions, the specific required permissions are as follows:</Text>
    <Text fontWeight="bold">
      bigquery.jobs.create, bigquery.datasets.get, bigquery.tables.getData, bigquery.tables.list
    </Text>
  </Stack>
)

export default GoogleBigQueryCredentialInstructions
