import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import SlackIcon from '@app/images/slack'
import IntegrationConnection from '@app/pages/settings/integrations/components/integrationConnection'
import PageHeader from '@app/shared/layout/pageHeader'
import PageStack from '@app/shared/layout/pageStack'
import withAwait from '@app/shared/withAwait'
import { Routes } from '@app/utils/routeHelpers'
import { IntegrationSourceNamesEnum } from '@graphql/types'

const Slack = () => {
  const integration = useGetObjectsByProperties('integration', { sourceName: IntegrationSourceNamesEnum.Slack })[0]

  return (
    <PageStack>
      <PageHeader title="Slack" subtitle="Send DoubleLoop information to Slack." />
      <IntegrationConnection
        name="Slack"
        icon={<SlackIcon />}
        items="messages, channels, and users"
        integration={integration}
        connectUrl={Routes.slackAuthentication}
        configureUrl="https://slack.com/apps/A01FY4LG43V-doubleloop?tab=settings"
      />
    </PageStack>
  )
}

export default withAwait(Slack, 'integration')
