import type { ChangeEventHandler, FocusEventHandler } from 'react'
import { useCallback } from 'react'

import { useStore } from '@app/store'
import type { MapDomainObject } from '@app/types'
import type { NodeObjectInput } from '@graphql/types'

type EditableDiv = HTMLDivElement & { contentEditable: true; name?: string; value?: string }
type TargetElements = Pick<EditableDiv | HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement, 'name' | 'value'>

interface EventHandlers<T extends TargetElements> {
  onChange: ChangeEventHandler<T>
  onBlur: FocusEventHandler<T>
}

type UseInlineEditorProps = <T extends TargetElements>(
  domainObject: MapDomainObject,
  domainObjectUpdateId?: string
) => EventHandlers<T>

// TODO: DEBOUNCE THIS
const useInlineEditorProps: UseInlineEditorProps = <T extends TargetElements>(
  domainObject: MapDomainObject,
  domainObjectUpdateId = 'id'
) => {
  const updateObject = useStore.use.updateObject()

  const onBlur = useCallback<FocusEventHandler<T>>(
    (event) => {
      const updatedObject = {
        [domainObject.classType]: {
          id: domainObject.id,
          [event.target.name]: event.target.value
        }
      }

      updateObject(updatedObject as NodeObjectInput)

      // THIS IS CRAPPY. IN THE CASE THIS IS SET ITS GOING TO DO A FAILED UPDATE TO THE BACKEND IN THE FIRST ONE TO ONLY DO IT RIGHT THE SECOND TIME
      if (domainObjectUpdateId !== 'id') {
        updateObject({
          [domainObject.classType]: {
            id: domainObject[domainObjectUpdateId],
            [event.target.name]: event.target.value
          }
        } as NodeObjectInput)
      }
    },
    [domainObject, updateObject]
  )

  const onChange = useCallback<ChangeEventHandler<T>>(
    (event) => {
      const updatedObject = {
        [domainObject.classType]: {
          id: domainObject.id,
          [event.target.name]: event.target.value
        }
      }

      updateObject(updatedObject as NodeObjectInput, true)
    },
    [domainObject, updateObject]
  )

  return { onChange, onBlur }
}

export default useInlineEditorProps
