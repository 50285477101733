import { Box, Circle, Collapsible, Heading, HStack, Icon } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiCheck } from 'react-icons/fi'

import useStep from './useStep'

import { useColorModeValue } from '@app/components/ui/color-mode'

interface Props {
  title: string
  children: React.ReactNode
}

const Step: FC<Props> = (props) => {
  const { title, children, ...boxProps } = props
  const { isActive, isCompleted, step } = useStep()

  const accentColor = useColorModeValue('blue.500', 'blue.300')
  const mutedColor = useColorModeValue('gray.600', 'whiteAlpha.800')
  const activeColor = useColorModeValue('white', 'black')

  const completedColor = isCompleted ? accentColor : mutedColor

  return (
    <Box {...boxProps}>
      <HStack gap="4">
        <Circle
          color={isActive ? activeColor : completedColor}
          fontWeight="bold"
          bg={isActive ? accentColor : 'transparent'}
          borderWidth={isActive ? '0px' : '1px'}
          borderColor={isCompleted ? accentColor : 'inherit'}
          size="8"
        >
          {isCompleted ? (
            <Icon>
              <FiCheck />
            </Icon>
          ) : (
            step
          )}
        </Circle>
        <Heading color={isActive || isCompleted ? 'inherit' : mutedColor} fontSize="md" fontWeight="normal">
          {title}
        </Heading>
      </HStack>
      <Collapsible.Root open={isActive}>
        <Collapsible.Content>{children}</Collapsible.Content>
      </Collapsible.Root>
    </Box>
  )
}

export default Step
