import { HStack, Kbd, Text } from '@chakra-ui/react'
import { FiSend } from 'react-icons/fi'

import { Button } from '@app/components/ui/button'
import { Tooltip } from '@app/components/ui/tooltip'

const SubmitButton = ({ disabled }) => {
  const label = disabled ? (
    'Waiting'
  ) : (
    <HStack>
      <Text>Submit</Text>
      <Kbd>enter</Kbd>
    </HStack>
  )

  return (
    <Tooltip aria-label={disabled ? 'Waiting' : 'Submit'} content={label}>
      <Button variant="plain" colorScheme="gray" disabled={disabled} loading={disabled} size="sm" type="submit">
        <FiSend />
      </Button>
    </Tooltip>
  )
}

export default SubmitButton
