import type { Dialog as ChakraDialog } from '@chakra-ui/react'
import { Input, Stack } from '@chakra-ui/react'
import { format } from 'date-fns-tz'
import type { FC, FormEvent } from 'react'
import { useState, useRef } from 'react'
import * as yup from 'yup'

import { Button } from '@app/components/ui/button'
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { Field } from '@app/components/ui/field'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import FormAlert from '@app/next/forms/formAlert'

const emptyStringToNil = (current, original) => (original === '' ? null : current)

const validationSchema = yup.object({
  startDate: yup.date().transform(emptyStringToNil),
  endDate: yup
    .date()

    .transform(emptyStringToNil)
    .when('startDate', {
      is: (startDate) => startDate !== null,
      then: () =>
        yup
          .date()
          .nullable()
          .transform(emptyStringToNil)
          .min(yup.ref('startDate'), 'end date can not be before start date')
    })
})
// interface DateRange extends yup.InferType<typeof validationSchema> {}

const formatFieldDate = (fieldDate, timeZone) => {
  if (!fieldDate) {
    return undefined
  }

  return format(fieldDate, 'uuuu-MM-dd', { timeZone })
}

interface Props {
  open?: boolean
  onOpenChange?: (openChange: {
    open: boolean
    status?: 'cancelled' | 'success'
    startDate?: string
    endDate?: string
  }) => void
}

type InteractOutsideEvent = Parameters<ChakraDialog.RootProps['onInteractOutside']>[0]

type OnCancel = (e?: KeyboardEvent | MouseEvent | FormEvent | InteractOutsideEvent) => void

const CustomRangeModal: FC<Props> = ({ open = false, onOpenChange = () => {} }) => {
  const { user } = useStoreCurrentUser()
  const now = formatFieldDate(new Date(), user.timeZone)
  const formRef = useRef<HTMLFormElement>()
  const [error, setError] = useState<string | null>(null)

  const onCancel: OnCancel = (e = null): void => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    onOpenChange({ open: false, status: 'cancelled' })
  }

  const onSubmit = async (e) => {
    setError(null)
    e.preventDefault()

    const formData = new FormData(formRef.current)
    const startDate = formatFieldDate(formData.get('startDate'), user.timeZone)
    const endDate = formatFieldDate(formData.get('endDate'), user.timeZone)

    try {
      await validationSchema.validate({ startDate, endDate })

      onOpenChange({ open: false, status: 'success', startDate, endDate })
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <DialogRoot
      closeOnEscape={false}
      closeOnInteractOutside={false}
      onOpenChange={onOpenChange}
      onEscapeKeyDown={onCancel}
      onInteractOutside={onCancel}
      open={open}
      placement="center"
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Custom Date Filter</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger onClick={onCancel} />
        <DialogBody>
          <form ref={formRef} onSubmit={onSubmit}>
            <FormAlert title="Unable to update" description={error} />
            <Stack>
              <Field label="Start Date">
                <Input defaultValue={now} name="startDate" type="date" />
              </Field>
              <Field label="End Date">
                <Input defaultValue={now} name="endDate" type="date" />
              </Field>
            </Stack>
          </form>
        </DialogBody>

        <DialogFooter>
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>
          <Button ml={3} onClick={onSubmit}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  )
}

export default CustomRangeModal
