import ahoy from 'ahoy.js'
import { defer } from 'react-router-dom'

import List from '@app/pages/playbooks/list'
import Show from '@app/pages/playbooks/show'
import { AppShell } from '@app/shared/layout'
import { requiresNoUserOrValidated } from '@app/utils/auth'
import { actionMutation, loaderQuery } from '@graphql/client'
import { Playbook, PlaybookApply, Playbooks } from '@graphql/documents/playbook.graphql'

const loadPlaybooks = async () => {
  const playbooksPromise = loaderQuery(Playbooks)

  return defer({ playbooks: playbooksPromise })
}

const loadPlaybook = async ({ params }) => {
  const playbookPromise = loaderQuery(Playbook, { id: params.id })

  return defer({ playbook: playbookPromise })
}

const usePlaybook = async ({ params }) => {
  const { id: playbookId } = params

  return actionMutation(PlaybookApply, { playbookId })
    .then((resp) => {
      ahoy.track('playbook:used', { playbookId })

      if (resp.error) {
        throw resp
      }

      const { strategy, errors } = resp.data.playbookApply

      if (errors?.length) {
        throw errors
      }

      return { strategy }
    })
    .catch((error) => ({
      error
    }))
}

const routes = {
  path: 'playbooks',
  element: <AppShell />,
  loader: requiresNoUserOrValidated,
  children: [
    {
      index: true,
      loader: loadPlaybooks,
      element: <List />,
      handle: {
        headData: () => ({
          title: 'Strategy playbooks & map templates | DoubleLoop',
          description:
            'Kickstart your strategy by using playbooks from top thinkers in the industry. ' +
            'Playbooks in DoubleLoop are data-connected flowcharts. DoubleLoop helps your team ' +
            'to understand the business impact of their work, stay aligned, and test their ' +
            'assumptions with real data.',
          imageUrl: 'https://app.doubleloop.app/meta-tag-images/playbooks.png'
        })
      }
    },
    {
      path: ':id',
      loader: loadPlaybook,
      action: usePlaybook,
      element: <Show />
    }
  ]
}

export default routes
