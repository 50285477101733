import { Box } from '@chakra-ui/react'
import type { FC } from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

interface EntityMarkdownViewProps {
  field: string
}

const EntityMarkdownView: FC<EntityMarkdownViewProps> = ({ field }) => (
  <div className="markdown-body">
    <Markdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{field}</Markdown>
  </div>
)

interface EntityDateProps {
  date: Date
}

const EntityDate: FC<EntityDateProps> = ({ date, ...rest }) => {
  const { formatDateTimeInUserTimeZone } = useStoreCurrentUser()

  if (!date) {
    return null
  }

  return (
    <Box title={date.toString()} {...rest}>
      {formatDateTimeInUserTimeZone(date)}
    </Box>
  )
}

export { EntityMarkdownView, EntityDate }
