import { Heading, HStack, Spacer, Stack, Text } from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import set from 'lodash/set'
import type { ChangeEventHandler, FC } from 'react'
import { useState, memo, useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams } from 'react-router-dom'

import useGetObjects from '@app/hooks/useGetObjects'
import PlaybooksList from '@app/pages/maps/components/addPlaybooks/playbooksList'
import MapDrawer from '@app/pages/maps/components/drawer/mapDrawer'
import MapDrawerQueryForm from '@app/pages/maps/components/drawer/mapDrawerQueryForm'
import { useDrawerContext } from '@app/pages/maps/drawerContext'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'

type Props = {
  headerText?: string
}

const AddPlaybookDrawer: FC<Props> = ({ headerText = null }) => {
  const { strategyId } = useParams()
  const { onDrawerClose } = useDrawerContext()
  const { canEdit } = usePermissionsContext()
  const playbooks = useGetObjects('playbook')
  const [query, setQuery] = useState('')
  const filtered = query
    ? playbooks.filter((playbook) => {
        const lowerQuery = query.toLowerCase()
        return (
          (playbook?.category && playbook.category.toLowerCase().includes(lowerQuery)) ||
          (playbook?.name && playbook.name.toLowerCase().includes(lowerQuery))
        )
      })
    : playbooks

  useHotkeys('esc', () => onDrawerClose())

  // If they land here on refresh, close the drawer and redirect to the map
  useEffect(() => {
    if (!canEdit) {
      onDrawerClose()
    }
  }, [canEdit, onDrawerClose])

  if (!canEdit) {
    return null
  }

  const onChange = debounce<ChangeEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault()

    const formData = new FormData(e.target.form)
    const formObject = Object.fromEntries(formData.entries())

    const variables = {
      query: ''
    }

    Object.entries(formObject).forEach(([key, value]) => {
      set(variables, key, value)
    })

    setQuery(variables.query)
  }, 50)

  return (
    <MapDrawer data-testid="add-playbook-drawer">
      <Stack as="section" gap={4} px={4}>
        <HStack>
          <Heading size="md">{headerText || 'Playbooks'}</Heading>
          <Spacer />
          <DrawerCloseButton variant="ghost" />
        </HStack>
      </Stack>
      <Stack as="section" gap={0} px={4}>
        <MapDrawerQueryForm data-testid="add-existing-form" onChange={onChange}>
          <Text color="subtle" fontSize="sm">
            Select playbooks
          </Text>
        </MapDrawerQueryForm>
      </Stack>
      <Stack as="section" gap={0} overflow="auto" px={4}>
        <PlaybooksList strategyId={strategyId} playbooks={filtered} />
      </Stack>
    </MapDrawer>
  )
}

export default memo(AddPlaybookDrawer)
