import { HStack, Text } from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import type { FC } from 'react'

import type { Metric, SegmentFilter } from '@graphql/types'

interface Props {
  data: {
    value?: number
    metric: Metric
    segmentFilters: SegmentFilter[]
  }
}

const MetricSegments: FC<Props> = ({ data: { segmentFilters } }) =>
  segmentFilters.map((segmentFilter) => {
    const {
      id,
      segmentName: { name },
      segmentValue: { value: filterValue }
    } = segmentFilter

    return (
      <HStack key={id}>
        <Text fontSize="xs" fontWeight="semibold">
          {name}:
        </Text>
        <Text fontSize="xs" title={filterValue}>
          {truncate(filterValue, { length: 20 })}
        </Text>
      </HStack>
    )
  })

export default MetricSegments
