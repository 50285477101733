import type { Table as TableProps } from '@chakra-ui/react'
import { Table } from '@chakra-ui/react'
import type { FC } from 'react'

type Props = TableProps.RootProps & {
  labelsWidth?: string
}

const DetailsTable: FC<Props> = ({ labelsWidth = '30%', ...props }) => (
  <Table.Root css={{ '& td:first-of-type': { width: labelsWidth } }} {...props} />
)

export default DetailsTable
