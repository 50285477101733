import type { FC } from 'react'

import type { ButtonProps } from '@app/components/ui/button'
import { MenuItem } from '@app/components/ui/menu'
import { useRecurringReportConfigurationRunMutation } from '@graphql/queries'

type Props = ButtonProps & {
  recurringReportConfigurationId: string
}

const RecurringReportConfigurationRunButton: FC<Props> = ({ recurringReportConfigurationId }) => {
  const [, run] = useRecurringReportConfigurationRunMutation()

  const onClick = () =>
    run({
      input: {
        recurringReportConfigurationId
      }
    })

  return (
    <MenuItem value="run_now" onClick={onClick}>
      Run now
    </MenuItem>
  )
}

export default RecurringReportConfigurationRunButton
