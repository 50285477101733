import AnyCableExchange from '@cable/anyCableExchange'
import cable from '@cable/client'
import * as Sentry from '@sentry/react'
import type { DocumentNode } from 'graphql'
import queryString from 'query-string'
import type { AnyVariables, Client, DocumentInput, OperationContext, OperationResult, TypedDocumentNode } from 'urql'
import { cacheExchange, createClient, fetchExchange, mapExchange, subscriptionExchange } from 'urql'

import type { StoreOperations } from '@graphql/test/handleGraphqlResult'
import handleGraphqlResult from '@graphql/test/handleGraphqlResult'

let csrfToken: string
if (typeof window !== 'undefined' && window.document) {
  csrfToken = window.document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
}

const anyCableExchange = new AnyCableExchange({ cable })

const exchanges = (storeOperations: StoreOperations) => [
  cacheExchange,
  mapExchange({
    // onOperation(operation) {
    //   console.log('operation', operation)
    // },
    onError(error, _operation) {
      Sentry.captureException(error)
    },
    onResult(result) {
      handleGraphqlResult(result, storeOperations)

      return result
    }
  }),
  fetchExchange,
  subscriptionExchange({
    forwardSubscription(request) {
      return anyCableExchange.request(request)
    }
  })
]

const params = queryString.parse(window.location.search)

const headers = {
  'X-CSRF-Token': csrfToken
}

if (params.token) {
  headers['X-DL-TOKEN'] = params.token
}

sessionStorage.setItem('X-DL-VERSION', window.dlVersion)

export const makeClient: (operations: StoreOperations) => Client = (operations) =>
  createClient({
    url: '/graphql',
    exchanges: exchanges(operations),
    suspense: true,
    fetch: (input, init) =>
      fetch(input, init).then((response) => {
        const clientVersion = sessionStorage.getItem('X-DL-VERSION')
        const serverVersion = response.headers.get('X-DL-VERSION')

        if (!serverVersion) {
          return response
        }

        if (!clientVersion) {
          sessionStorage.setItem('X-DL-VERSION', serverVersion)
        } else if (serverVersion !== clientVersion) {
          const outdatedClient = new CustomEvent('outdatedClient', {
            detail: {
              serverVersion,
              clientVersion
            }
          })

          window.dispatchEvent(outdatedClient)
        }

        return response
      }),
    fetchOptions: {
      credentials: 'include',
      headers
    },
    requestPolicy: 'cache-and-network'
  })

const client: Client = makeClient(null)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loaderQuery = <T = any, V extends AnyVariables = AnyVariables>(
  query: string | DocumentNode | TypedDocumentNode<T, V>,
  variables: V = undefined,
  context: Partial<OperationContext> = {}
): Promise<OperationResult<T>> => client.query<T, V>(query, variables, context).toPromise()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const actionMutation = <T = any,>(
  mutation: DocumentInput<T, AnyVariables>,
  input = {},
  context = {},
  variables = {}
): Promise<OperationResult<T>> => client.mutation<T>(mutation, { input, ...variables }, context).toPromise()

export default client
