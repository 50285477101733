import type { CenterProps } from '@chakra-ui/react'
import { Center } from '@chakra-ui/react'
import type { FC } from 'react'

import SuspenseLoader from './loaders/suspenseLoader'

const Loader: FC<CenterProps> = ({ ...props }) => (
  <Center h="100vh" {...props}>
    <SuspenseLoader />
  </Center>
)

export default Loader
