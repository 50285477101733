import { METRIC_SOURCES } from '@app/lib/globals'
import { INTEGRATIONS } from '@app/pages/settings/integrations/constants'
import type { SourcesEntitySource, SourcesMetricSource } from '@graphql/types'

const OBJECT_TYPES_TO_SHOW = ['entity', 'work', 'metric']
export const WORK_SOURCES_TO_SHOW = ['asana', 'jira', 'linear', 'productboard', 'shortcut'] as const

export const buildMetricSources = (sources: SourcesMetricSource[]) =>
  sources.map((source) => ({
    display: METRIC_SOURCES[source.sourceName].display,
    value: source.sourceName
  }))

export const buildEntitySources = (sources: SourcesEntitySource[]) =>
  sources
    .filter((source) => ['slack', 'google'].includes(source.sourceName) === false)
    .map((source) => ({
      display: INTEGRATIONS[source.sourceName].display,
      value: source.sourceName,
      entityTypes: source.entityTypes
    }))

export const deriveType = (type) => {
  let returnType = type

  if (WORK_SOURCES_TO_SHOW.includes(type) || type === 'work') {
    returnType = 'entity'
  }

  return returnType
}

export const showSourceNameInput = (typeValue) => OBJECT_TYPES_TO_SHOW.includes(typeValue)
export const showForeignTypesInput = (type) => WORK_SOURCES_TO_SHOW.includes(type)

export const showAddButton = (type) => !WORK_SOURCES_TO_SHOW.includes(type)
