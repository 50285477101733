import { Group, Input, Stack } from '@chakra-ui/react'
import type { ChangeEventHandler, FC, FormEventHandler } from 'react'
import { useCallback, useState } from 'react'
import { Form } from 'react-router-dom'

import { useMapCardFiltersModalContext } from './mapCardFiltersModalContext'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'

type Props = {
  defaultValue: string
}

const FilterNodeName: FC<Props> = ({ defaultValue }) => {
  const { onFilter, setActiveFacetName, onClose } = useMapCardFiltersModalContext()
  const [name, setName] = useState(defaultValue || '')

  const onCancel = useCallback(() => {
    setActiveFacetName(null)
  }, [setActiveFacetName])

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setName(e.target.value)
  }, [])

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()
      onFilter('name', name)
      onClose()
    },
    [name, onFilter, onClose]
  )

  return (
    <Form onSubmit={onSubmit}>
      <Stack px={4} py={2}>
        <Field label="Name">
          <Input autoFocus name="name" onChange={onChange} placeholder="Filter by name..." value={name} />
        </Field>
        <Group justifyContent="end">
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </Group>
      </Stack>
    </Form>
  )
}

export default FilterNodeName
