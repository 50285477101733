import isEmpty from 'lodash/isEmpty'
import type { FC } from 'react'
import { memo, useRef } from 'react'

import Node from './components/node'
import EntityToolbar from './components/toolbar/entityToolbar'

import useGetNode from '@app/hooks/useGetNode'
import EntityCard from '@app/shared/cards/entity/entityCard'
import type { MapDomainNode } from '@app/types'

const EntityNode: FC<MapDomainNode> = (rfNode) => {
  const { id } = rfNode
  const node = useGetNode(id)
  const ref = useRef()

  const entity = node?.data
  const strategyId = node?.strategyId

  if (!entity || isEmpty(entity)) {
    return null
  }

  return (
    <Node
      topHandle={{ type: 'source' }}
      leftHandle={{ type: 'source' }}
      rightHandle={{ type: 'source' }}
      bottomHandle={{ type: 'source' }}
      nodeRef={ref}
      node={node}
    >
      <EntityToolbar node={node} />
      <EntityCard strategyId={strategyId} entity={entity} node={node} />
    </Node>
  )
}

export default memo(EntityNode)
