import { Link } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import {
  PopoverRoot,
  PopoverHeader,
  PopoverArrow,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseTrigger
} from '@app/components/ui/popover'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { abilitiesFor } from '@app/shared/authorization/caslProvider'

const CreateMapButton = () => {
  const { user } = useStoreCurrentUser()

  const abilities = abilitiesFor(user)
  const canUpdateStrategy = abilities.can('update', 'strategy')

  if (canUpdateStrategy) {
    return (
      <Button asChild>
        <Link to="/playbooks">Create map</Link>
      </Button>
    )
  }

  return (
    <PopoverRoot lazyMount>
      <PopoverTrigger>
        <Button>Create map</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseTrigger />
        <PopoverHeader>Editor Required</PopoverHeader>
        <PopoverBody>Please contact a workspace admin, to have editor credentials set on your account.</PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default CreateMapButton
