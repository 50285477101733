import { Box } from '@chakra-ui/react'
import type { FC } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import AccountsListActions from '@app/pages/settings/organization/components/accountsListActions'
import type { Account } from '@graphql/types'

interface Props {
  data: Account
}

const UserActionsCell: FC<Props> = ({ data: account }) => {
  const { userId } = account
  const { user } = useStoreCurrentUser()

  if (user.id === userId) {
    return <Box m={2}>&nbsp;</Box>
  }

  return <AccountsListActions account={account} />
}

export default UserActionsCell
