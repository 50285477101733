import { HStack, IconButton, Text, type TextProps } from '@chakra-ui/react'
import pluralize from 'pluralize'
import { type FC } from 'react'

import MultiDeleteButton from './multiDeleteButton'

import { Tooltip } from '@app/components/ui/tooltip'
import useGetObjectsByIds from '@app/hooks/useGetObjectsByIds'
import type { BulkLabelableObject } from '@app/pages/maps/components/nodes/components/toolbar/components/bulkLabels/bulkLabelsPopover'
import BulkLabelsPopover from '@app/pages/maps/components/nodes/components/toolbar/components/bulkLabels/bulkLabelsPopover'
import Can from '@app/shared/authorization/can'
import ICON_MAP from '@app/utils/iconMap'

type Props = TextProps & {
  type: 'basicCard' | 'entity' | 'metric' | 'strategy'
  selected: (string | number)[]
  onDelete?: () => void
}

const MultiOps: FC<Props> = ({ type, selected, onDelete, ...rest }) => {
  const count = selected.length

  const domainObjects = useGetObjectsByIds(
    type,
    selected.map((s) => s.toString())
  ) as BulkLabelableObject[]

  if (count === 0) {
    return null
  }

  return (
    <HStack>
      <Text whiteSpace="nowrap" {...rest}>
        {count} {pluralize('row', count)} selected
      </Text>
      <Can I="update" a={type}>
        <Tooltip content="Add labels" showArrow>
          <span>
            <BulkLabelsPopover domainObjects={domainObjects}>
              <IconButton color="error" aria-label={`Delete ${type}`} data-cy={`${type}-delete-button`} variant="ghost">
                <ICON_MAP.LabelAction />
              </IconButton>
            </BulkLabelsPopover>
          </span>
        </Tooltip>
      </Can>
      <Can I="delete" a={type}>
        <Tooltip content="Delete" showArrow>
          <span>
            <MultiDeleteButton type={type} selected={selected} onDelete={onDelete} />
          </span>
        </Tooltip>
      </Can>
    </HStack>
  )
}

export default MultiOps
