import type { FC, RefObject } from 'react'
import { useState } from 'react'
import { AiOutlineQuestion } from 'react-icons/ai'
import { FiCheck, FiX } from 'react-icons/fi'

import type { ButtonProps } from '@app/components/ui/button'
import { Button } from '@app/components/ui/button'
import { toaster } from '@app/components/ui/toaster'
import { actionMutation } from '@graphql/client'
import { CredentialAttributesTest } from '@graphql/documents/credential.graphql'
import type { CredentialAttributesTestMutation } from '@graphql/queries'

type Props = ButtonProps & {
  formRef: RefObject<HTMLFormElement>
}

enum CredentialState {
  Success,
  Failure,
  Indeterminate
}

const TestCredentialsButton: FC<Props> = ({ formRef, ...rest }) => {
  const [credentialState, setCredentialState] = useState(CredentialState.Indeterminate)
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)
    const formData = new FormData(formRef.current)

    const result = await actionMutation<CredentialAttributesTestMutation>(
      CredentialAttributesTest,
      Object.fromEntries(formData.entries())
    )

    setLoading(false)
    const success = result.data?.credentialAttributesTest?.success

    if (success) {
      setCredentialState(CredentialState.Success)
    } else {
      setCredentialState(CredentialState.Failure)
      const errors = result.data?.credentialAttributesTest?.errors
      const error = errors?.[0]?.message

      toaster.create({
        title: 'Test failed',
        description: error || 'Unknown error',
        type: 'error'
      })
    }
  }

  let additional: Partial<ButtonProps>
  let icon = <AiOutlineQuestion />

  if (credentialState === CredentialState.Success) {
    icon = <FiCheck />
    additional = {
      colorPalette: 'green'
    }
  } else if (credentialState === CredentialState.Failure) {
    icon = <FiX />
    additional = {
      colorPalette: 'red'
    }
  } else {
    additional = {
      colorPalette: 'gray'
    }
  }

  return (
    <Button size="sm" variant="subtle" loading={loading} onClick={onClick} {...additional} {...rest}>
      {icon} Test credentials
    </Button>
  )
}

export default TestCredentialsButton
