import { useMemo } from 'react'

import useGetObjects from '@app/hooks/useGetObjects'
import { iconMap as basicCardIconMap } from '@app/pages/maps/components/nodes/components/basicType/customIcons'
import type { MapDomainBasicCard, MapDomainEntity, MapDomainMetric, MapDomainObject } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'

type ContainedObject = MapDomainMetric | MapDomainBasicCard | MapDomainEntity

const upcaseFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

function isBasicCard(o: MapDomainObject): o is MapDomainBasicCard {
  return o.classType === 'basicCard'
}

function isContainedObject(o: MapDomainObject): o is ContainedObject {
  return o?.classType === 'metric' || o?.classType === 'basicCard' || o?.classType === 'entity'
}

function cardTypeName(base: string) {
  return base === 'BasicCard' ? 'Card' : base
}

function useGetCardTypeOptions(domainObjects: MapDomainObject[]) {
  const cardTypes = useGetObjects('cardType')

  return useMemo(
    () =>
      domainObjects.map((domainObject) => {
        if (!isContainedObject(domainObject)) {
          return null
        }

        const idx = upcaseFirst(domainObject.classType)

        if (isBasicCard(domainObject)) {
          const cardType = cardTypes.find((ct) => ct.id === domainObject.cardTypeId)

          if (cardType) {
            const icon = basicCardIconMap[cardType.icon] || ICON_MAP[idx]
            const color = cardType.color || COLOR_MAP[idx]

            return {
              name: domainObject.name,
              type: cardType.name || 'Card',
              icon,
              color
            }
          }
        }

        const icon = ICON_MAP[idx]
        const color = COLOR_MAP[idx]

        return {
          name: domainObject.name,
          type: cardTypeName(idx),
          icon,
          color
        }
      }),
    [cardTypes, domainObjects]
  )
}

export default useGetCardTypeOptions
