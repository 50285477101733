import type { FC } from 'react'
import { useEffect, useState } from 'react'

import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useInvitationsQuery } from '@graphql/queries'

interface Props {}

const InvitationsFetcher: FC<Props> = () => {
  const [invitationsData, setInvitationsData] = useState(null)

  const [{ data }] = useInvitationsQuery({
    variables: {},
    pause: !!invitationsData
  })

  useEffect(() => {
    if (!data) {
      return
    }

    setInvitationsData(data)
  }, [data])

  return null
}

export default withSuspenseWrapper(InvitationsFetcher)
