import { useParams } from 'react-router-dom'

import GoalForm from './goalForm'

import useGetObject from '@app/hooks/useGetObject'
import { Card } from '@app/shared/layout'

const Create = () => {
  const { nodeId } = useParams()
  const metric = useGetObject(nodeId, 'metric')

  return (
    <Card data-testid="create">
      <GoalForm goal={null} metric={metric} />
    </Card>
  )
}

export default Create
