import { useCallback, useMemo, useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import { toaster } from '@app/components/ui/toaster'
import { useStore } from '@app/store'
import type { MapDomainNode } from '@app/types'
import type { NodesDuplicateInput } from '@graphql/types'

type DupeNode = Pick<MapDomainNode, 'nodeId'>

type DuplicateFunction = (
  nodeOverride?: DupeNode[],
  strategyId?: string,
  options?: { variables?: Partial<NodesDuplicateInput> }
) => Promise<void>
type UseDuplicateNodes = (nodes: DupeNode | DupeNode[]) => {
  duplicate: DuplicateFunction
  loading: boolean
}

const useDuplicateNodes: UseDuplicateNodes = (nodes) => {
  const [loading, setLoading] = useState(false)
  const { revalidate } = useRevalidator()
  const nodeArray = useMemo(() => (Array.isArray(nodes) ? nodes : [nodes]), [nodes])

  const duplicateNodes = useStore.use.duplicateNodes()

  const duplicate: DuplicateFunction = useCallback(
    async (nodeOverride, strategyId, options = {}) => {
      let duplicatedNodes = []

      if (nodeOverride) {
        duplicatedNodes = Array.isArray(nodeOverride) ? nodeOverride : [nodeOverride]
      } else {
        duplicatedNodes = nodeArray
      }

      setLoading(true)

      try {
        const promise = new Promise<void>((resolve) => {
          duplicateNodes(
            duplicatedNodes.map((n) => n.nodeId),
            strategyId,
            options
          )
          revalidate()

          resolve()
        })

        toaster.promise(promise, {
          success: {
            title: `Duplicating ${duplicatedNodes.length} cards`
          },
          error: {
            title: 'Error duplicating cards'
          },
          loading: {
            title: 'Duplicating cards'
          }
        })

        await promise
      } finally {
        setLoading(false)
      }
    },
    [duplicateNodes, nodeArray, revalidate]
  )

  return {
    duplicate,
    loading
  }
}

export default useDuplicateNodes
