import type { HTMLChakraProps } from '@chakra-ui/react'
import { Box, Stack, Textarea, VisuallyHidden } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { forwardRef, useState } from 'react'

import { Field } from '@app/components/ui/field'
import type { MarkdownEditorRef } from '@app/next/forms/markdownEditor/markdownEditor'
import MarkdownEditor from '@app/next/forms/markdownEditor/markdownEditor'
import type { FormErrors } from '@app/types'

interface Props extends HTMLChakraProps<'textarea'> {
  name: string
  label?: ReactNode
  placeholder?: string
  defaultValue?: string
  required?: boolean
  helperText?: ReactNode
  errors?: FormErrors
  className?: string
}

const FauxInputBox = ({ children, ...rest }) => (
  <Box
    pos="relative"
    w="full"
    minW={0}
    textAlign="start"
    borderWidth="1px"
    borderColor="border"
    borderRadius="l2"
    _disabled={{ layerStyle: 'disabled' }}
    appearance="none"
    outline={0}
    {...rest}
  >
    {children}
  </Box>
)

const RichInput: ReturnType<typeof forwardRef<MarkdownEditorRef, Props>> = forwardRef<MarkdownEditorRef, Props>(
  (
    {
      name,
      label = '',
      placeholder = '',
      required = false,
      helperText = null,
      errors = {},
      defaultValue = '',
      ...rest
    },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue)

    return (
      <Field
        helperText={helperText}
        errorText={errors[name] && (errors[name].message as string)}
        label={label}
        cursor="text"
        invalid={!!errors[name]}
        required={required}
      >
        <VisuallyHidden>
          <Textarea name={name} readOnly value={value} />
        </VisuallyHidden>
        <Stack w="100%">
          <FauxInputBox>
            <MarkdownEditor
              placeholder={placeholder}
              initialValue={defaultValue}
              onValueChange={setValue}
              ref={ref}
              {...rest}
            />
          </FauxInputBox>
        </Stack>
      </Field>
    )
  }
)

export default RichInput
