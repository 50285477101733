import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import type { FC } from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'

interface Props extends BoxProps {
  text?: string
}

const MarkdownDisplay: FC<Props> = ({ text = '', ...rest }) => {
  if (!text) {
    return null
  }
  return (
    <Box {...rest} className="markdown-body">
      <Markdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{text}</Markdown>
    </Box>
  )
}

export default MarkdownDisplay
