import { Box, Center, Container, Flex, Heading, Link, Stack, Text, useBreakpointValue } from '@chakra-ui/react'
import type { FC } from 'react'
import { Outlet } from 'react-router-dom'

import Wordmark from '@app/images/wordmark'

const SessionShell: FC = () => (
  <Flex flex="1" h="100vh">
    <Box flex="1" bg="colorPalette.solid" hideBelow="lg">
      <Box w="full" h="full">
        <Flex direction="column" h="full" px={{ base: '4', md: '8' }} color="on-accent">
          <Flex align="center" h="24">
            <Link href="https://www.doubleloop.app">
              <Wordmark boxSize={48} color="white" />
            </Link>
          </Flex>
          <Flex align="center" flex="1">
            <Stack gap="8">
              <Stack gap="6">
                <Heading color="white" size={useBreakpointValue({ md: 'lg', xl: 'xl' })}>
                  Orient your strategy around impact.
                </Heading>
                <Text maxW="md" fontSize="lg" fontWeight="medium">
                  DoubleLoop is a strategy development platform for teams to maximize and show impact.
                </Text>
              </Stack>
            </Stack>
          </Flex>
          <Flex align="center" h="24">
            <Text color="on-accent-subtle" fontSize="sm">
              © {new Date().getFullYear()} DoubleLoop. All rights reserved.
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
    <Box flex="1.5" py={{ base: '24', md: '32' }}>
      <Center h="full">
        <Container maxW="md">
          <Outlet />
        </Container>
      </Center>
    </Box>
  </Flex>
)

export default SessionShell
