import isEmpty from 'lodash/isEmpty'

import type { SortDir } from '@app/types'

type ExtractOrderings = (
  searchParams: URLSearchParams,
  fields?: string | string[] | null
) => Record<string, SortDir> | null

const extractOrderings: ExtractOrderings = (searchParams, fields = null) => {
  const list = Array.isArray(fields) ? fields : [fields]
  let result: Record<string, SortDir>

  if (!fields || fields.length === 0) {
    // if no fields are specified, extract all orderings
    result = Array.from(searchParams.keys()).reduce((acc, key) => {
      if (key.startsWith('order.')) {
        acc[key.slice(6)] = searchParams.get(key) as SortDir
      }

      return acc
    }, {})
  } else {
    result = list.reduce((acc, field) => {
      const sp = searchParams.get(`order.${field}`)

      if (sp) {
        acc[field] = sp
      }

      return acc
    }, {})
  }

  return isEmpty(result) ? null : result
}

export default extractOrderings
