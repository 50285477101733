import { FcGoogle } from 'react-icons/fc'

import { Button } from '@app/components/ui/button'

const GoogleIntegrationButton = ({ ...rest }) => (
  <Button variant="outline" {...rest}>
    <FcGoogle />
    Continue with Google
  </Button>
)

export default GoogleIntegrationButton
