import { Box } from '@chakra-ui/react'

import useStep from './useStep'

import { useColorModeValue } from '@app/components/ui/color-mode'

const StepContent = (props) => {
  const { isLastStep } = useStep()
  return (
    <Box
      mt="2"
      ms="4"
      ps="8"
      pb="3"
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize="sm"
      borderStartWidth={isLastStep ? '1px' : '0'}
      {...props}
    />
  )
}

export default StepContent
