import { Box } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import type { CardSize } from '@app/types'

type Props = {
  hidden?: boolean
  children: ReactNode
  collapse?: boolean
  size?: CardSize
}

const CardSection: FC<Props> = ({ hidden = false, collapse = false, children, size = 'normal', ...rest }) => {
  const uncollapsedSize = size === 'large' ? { px: 8, py: 8 } : { px: 4, py: 4 }
  const sectionPadding = collapse ? { px: 0, py: 0 } : uncollapsedSize

  if (hidden) {
    return null
  }

  return (
    <Box {...sectionPadding} {...rest}>
      {children}
    </Box>
  )
}

export default CardSection
