import { useToken } from '@chakra-ui/react'

import { useColorModeValue as mode } from '@app/components/ui/color-mode'

const StiggTheme = () => {
  const [blue, gray100, gray300, gray600, gray700, gray800, gray900] = useToken('colors', [
    'blue.500',
    'gray.100',
    'gray.300',
    'gray.600',
    'gray.700',
    'gray.800',
    'gray.900'
  ])

  const theme = {
    palette: {
      primary: blue,
      backgroundHighlight: mode(gray100, gray600),
      backgroundPaper: mode('white', gray800),
      text: {
        primary: mode(gray900, 'white'),
        secondary: mode(gray700, gray100),
        disabled: mode(gray600, gray300)
      }
    },
    typography: {
      bodyFontFamily: 'Inter Variable',
      headingFontFamily: 'Inter Variable'
    },
    layout: {
      planMinWidth: '295px',
      planMaxWidth: '295px',
      ctaAlignment: 'center'
    }
  }

  return theme
}

export default StiggTheme
