import { Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import type { FC } from 'react'

interface Props {
  happenedAt: string
}

const EntityDate: FC<Props> = ({ happenedAt }) => (
  <Text fontSize="sm" fontWeight="light" title={new Date(happenedAt).toString()}>
    {format(new Date(happenedAt), 'MMMM do, yyy')}
  </Text>
)

export default EntityDate
