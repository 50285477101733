import { IconButton } from '@chakra-ui/react'
import { RichUtils } from 'draft-js'

function inlineButtonMaker({ style, children }) {
  return function InlineStyleButton({ buttonProps = {}, getEditorState, setEditorState }) {
    const toggleStyle = (event) => {
      event.preventDefault()
      setEditorState(RichUtils.toggleInlineStyle(getEditorState(), style))
    }

    const preventBubblingUp = (event) => {
      event.preventDefault()
    }

    const styleIsActive = () => getEditorState && getEditorState().getCurrentInlineStyle().has(style)

    return (
      <IconButton
        aria-label="inline-button-maker"
        onClick={toggleStyle}
        onMouseDown={preventBubblingUp}
        role="button"
        size="xs"
        type="button"
        variant={styleIsActive() ? 'solid' : 'outline'}
        {...buttonProps}
      >
        {children}
      </IconButton>
    )
  }
}

export default inlineButtonMaker
