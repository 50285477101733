import { BsFiletypeCsv } from 'react-icons/bs'
import { HiOutlineLockClosed } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { MenuContent, MenuItem, MenuRoot, MenuSeparator, MenuTrigger } from '@app/components/ui/menu'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import GoogleSheetsMenuGroup from '@app/pages/metrics/components/googleSheetsMenuGroup'
import MetricCSVModal from '@app/pages/metrics/components/metricCSVModal'

const ListActionsMenu = () => {
  const googleSheet = useGetObjectsByProperties('googleSheet', { organizationLevel: true })?.[0]

  return (
    <MenuRoot>
      <MenuTrigger asChild>
        <Button>...</Button>
      </MenuTrigger>
      <MenuContent>
        <MetricCSVModal OpenerComponent={MenuItem} value="opener" openerChildren={<BsFiletypeCsv />} variant="ghost" />
        <MenuSeparator />
        <MenuItem value="credentials">
          <HiOutlineLockClosed />
          <Link to="credentials">Credentials</Link>
        </MenuItem>
        <MenuSeparator />
        <GoogleSheetsMenuGroup googleSheet={googleSheet} />
      </MenuContent>
    </MenuRoot>
  )
}

export default ListActionsMenu
