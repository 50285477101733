import { useMemo, useState } from 'react'
import type { ChangeEvent, FC } from 'react'

import { buildMetricSources, deriveType } from '@app/pages/maps/components/addCards/helpers'
import SourceNameInput from '@app/pages/maps/components/addCards/queryExisting/sourceNameInput'
import type { Nodes } from '@app/types'
import type { SourcesMetricSource } from '@graphql/types'

type Props = {
  sources: SourcesMetricSource[]
  type: string
}

const QueryExistingMetricFields: FC<Props> = ({ sources, type }) => {
  const typeValue = deriveType(type)
  const [sourceNameValue, setSourceNameValue] = useState(type)

  const inputSources = useMemo(() => buildMetricSources(sources), [sources])

  const handleSourceNameChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSourceNameValue(e.target.value as Nodes)
  }

  return (
    <SourceNameInput
      value={sourceNameValue}
      typeValue={typeValue}
      sources={inputSources}
      onChange={handleSourceNameChange}
    />
  )
}

export default QueryExistingMetricFields
