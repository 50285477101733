import { Stack } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import { ChakraReactSelect } from '@app/shared/autocomplete/chakraAutocompletes'
import type { ChakraReactSelectProps, ReactSelectAutocompleteValue } from '@app/shared/autocomplete/chakraAutocompletes'
import { useLookerApiLooksQuery } from '@graphql/queries'

type Props = Omit<ChakraReactSelectProps<ReactSelectAutocompleteValue, false>, 'defaultValue'> & {
  credentialId: string
  defaultValue?: string
}

const LookSelectInput: FC<Props> = ({ credentialId, disabled, defaultValue, ...selectInputProps }) => {
  const [{ data, fetching }] = useLookerApiLooksQuery({
    variables: {
      credentialId
    }
  })

  const options = data?.lookerApiLooks || []

  const allOpts = sortBy(
    options.map(({ label, value }) => ({ label, value })),
    'label'
  )
  const defaultObject = defaultValue ? allOpts.find((opt) => opt.value === defaultValue) : null

  return (
    <Field label="Look" disabled={disabled || fetching} required>
      <Stack w="full">
        <ChakraReactSelect
          disabled={disabled || fetching}
          initialValue={defaultObject}
          options={allOpts}
          placeholder="Select a look"
          noOptionsMessage="No matching looks found"
          isClearable={false}
          {...selectInputProps}
        />
      </Stack>
    </Field>
  )
}

export default LookSelectInput
