import { Group, IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { FaSlack } from 'react-icons/fa'
import { FiEdit2 } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'

import Source, { type SourceProps } from './source'

import { Tooltip } from '@app/components/ui/tooltip'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Can from '@app/shared/authorization/can'
import EntityDeleteButton from '@app/shared/entities/entityDeleteButton'
import SlackSendPopover from '@app/shared/slack/slackSendPopover'
import { pathWithSearchParams } from '@app/utils/routeHelpers'
import type { Entity } from '@graphql/types'

export interface ActionsProps {
  entity: SourceProps['entity'] & Pick<Entity, 'id'>
  isListView: boolean
}

const ListViewActions: FC<ActionsProps> = ({ entity, isListView }) => {
  // I feel like this is a hack but I can't think about how to do it without a lot of indirection
  const editPath = isListView ? `${entity.id}/edit` : 'edit'
  const editEntityPath = pathWithSearchParams(editPath)

  return (
    <>
      <Source entity={entity} />
      <SlackSendPopover entityId={entity.id}>
        <IconButton aria-label="Share event" colorPalette="gray" size="xs" variant="ghost">
          <FaSlack />
        </IconButton>
      </SlackSendPopover>
      <Can I="update" an="event">
        <Tooltip content="Edit event">
          <IconButton
            aria-label="Edit event"
            asChild
            colorPalette="gray"
            data-cy="edit-entity-button"
            size="xs"
            variant="ghost"
          >
            <Link to={editEntityPath}>
              <FiEdit2 />
            </Link>
          </IconButton>
        </Tooltip>
      </Can>
    </>
  )
}

const ShowViewActions: FC<ActionsProps> = ({ entity, isListView }) => {
  const navigate = useNavigate()

  if (isListView) {
    return null
  }

  return (
    <Can I="delete" an="event">
      <EntityDeleteButton
        entityId={entity.id}
        onDelete={() => {
          navigate('..')
        }}
      />
    </Can>
  )
}

const Actions: FC<ActionsProps> = ({ entity = null, isListView = false, ...rest }) => {
  const { user } = useStoreCurrentUser() // This is where we can apply editor vs viewer permissions

  if (!entity || !user) {
    return null
  }

  return (
    <Group gap={1} {...rest}>
      <ListViewActions entity={entity} isListView={isListView} />
      <ShowViewActions entity={entity} isListView={isListView} />
    </Group>
  )
}

export default Actions
