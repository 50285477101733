import { Center } from '@chakra-ui/react'
import { Suspense, memo } from 'react'
import { Outlet, Await, useLoaderData, useAsyncValue } from 'react-router-dom'
import type { OperationResult } from 'urql'

import { MetricSourceTabContextProvider } from '../components/metricSource/metricSourceTabContext'

import ErrorPage from '@app/shared/errorPage'
import SuspenseLoader from '@app/shared/loaders/suspenseLoader'
import type { MetricSource, MetricSourceQuery } from '@graphql/queries'

const MetricSourceTab = () => {
  const {
    data: { metricSource }
  } = useAsyncValue() as OperationResult<MetricSourceQuery>

  return <Outlet context={{ metricSource }} />
}

export default memo((props) => {
  const data = useLoaderData() as { metricSource: MetricSource }

  return (
    <Suspense
      fallback={
        <Center h="50vh">
          <SuspenseLoader />
        </Center>
      }
    >
      <Await resolve={data.metricSource} errorElement={<ErrorPage />}>
        <MetricSourceTabContextProvider>
          <MetricSourceTab {...props} />
        </MetricSourceTabContextProvider>
      </Await>
    </Suspense>
  )
})
