import { useShallow } from 'zustand/react/shallow'

import { useStore } from '@app/store'
import type { MapDomainNode } from '@app/types'

type UseGetNode = (id: string) => MapDomainNode

const useGetNode: UseGetNode = (id) => useStore(useShallow((state) => state.getNodeById(id)))

export default useGetNode
