import type { RouteObject } from 'react-router-dom'
import { defer, Outlet } from 'react-router-dom'

import Index from '@app/pages/goals/index'
import Create from '@app/pages/metrics/components/goals/create'
import Edit from '@app/pages/metrics/components/goals/edit'
import MetricGoalTab from '@app/pages/metrics/tabs/metricGoalTab'
import { useStore } from '@app/store'
import { requiresAuthorization } from '@app/utils/auth'
import { loaderQuery } from '@graphql/client'
import { Goals, Goal } from '@graphql/documents/goal.graphql'
import type { GoalsQuery, GoalsQueryVariables } from '@graphql/queries'

const loadGoals = async ({ params }) => {
  const metricId = params.metricId || params.nodeId

  const { loaderQuery: storeLoaderQuery } = useStore.getState()

  const resp = await storeLoaderQuery<GoalsQuery, GoalsQueryVariables>(Goals, { metricId })
  const goals = resp?.data?.metric?.goals || []

  return { goals }
}

const loadGoal = async ({ params }) => {
  const { data } = await loaderQuery(Goal, {
    id: params.goalId
  })

  return defer({ goal: data?.goal })
}

export const mapRoutes: RouteObject = {
  path: 'key-results',
  element: <Index />
}

const routes: (namespace: string | null) => RouteObject = (namespace) => ({
  path: 'key_results',
  id: namespace ? `${namespace}-goals` : 'goals',
  loader: loadGoals,
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <MetricGoalTab />
    },
    {
      path: 'new',
      loader: async () => requiresAuthorization('create', 'goal'),
      element: <Create />
    },
    {
      path: ':goalId',
      loader: loadGoal,
      element: <Outlet />,
      id: namespace ? `${namespace}-goal` : 'goal',
      children: [
        {
          path: 'edit',
          loader: async () => requiresAuthorization('update', 'goal'),
          element: <Edit />
        }
      ]
    }
  ]
})

export default routes
