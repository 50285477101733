import { Group } from '@chakra-ui/react'
import type { FC } from 'react'

import { Button } from '@app/components/ui/button'
import useGetObject from '@app/hooks/useGetObject'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  strategyId: string
}

const ToggleMetricData: FC<Props> = ({ strategyId }) => {
  const strategy = useGetObject(strategyId, 'strategy')
  const updateObject = useStore.use.updateObject()
  const hideMetricData = strategy?.hideMetricData || false
  const buttonText = hideMetricData ? 'Show metric data' : 'Hide metric data'

  const toggleMetricData = () => {
    const updatedStrategy = { strategy: { id: strategy.id, hideMetricData: !hideMetricData } }

    updateObject(updatedStrategy, true)
  }

  return (
    <Group colorPalette="gray">
      <Button aria-label="Toggle metric data" onClick={toggleMetricData} size="2xs" variant="ghost">
        <ICON_MAP.ExpandAction /> {buttonText}
      </Button>
    </Group>
  )
}

export default ToggleMetricData
