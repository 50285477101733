import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'

import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@app/components/ui/menu'
import { Tooltip } from '@app/components/ui/tooltip'
import useGetEdges from '@app/hooks/useGetEdges'
import useGetNodes from '@app/hooks/useGetNodes'
import { hasLoop } from '@app/pages/maps/components/nodes/helpers'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import { useStore } from '@app/store'
import type { MapDomainNode } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  node: MapDomainNode
}

const CollapseNodeButton: FC<Props> = ({ node }) => {
  const { strategyId } = node
  const nodes = useGetNodes(strategyId)
  const edges = useGetEdges(strategyId)
  const collapseNode = useStore.use.collapseNode()
  const collapseNodes = useStore.use.collapseNodes()
  const getIncomers = useStore.use.getIncomers()

  const incomers = getIncomers(node)
  const hasIncomers = incomers.length > 0

  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  const disabled = hasLoop(node, Object.values(nodes), Object.values(edges))

  const buttonStyles = {
    borderRadius: 'none',
    'aria-label': 'Collapse cards'
  }

  const collapseCard = (
    <Tooltip content="Collapse card">
      <IconButton {...buttonStyles} onClick={() => collapseNode(strategyId, node)} size="sm" variant="outline">
        <ICON_MAP.CollapseAction />
      </IconButton>
    </Tooltip>
  )

  const menuOptions = (
    <MenuRoot>
      <MenuTrigger asChild>
        <span>
          <Tooltip content="Collapse card">
            <IconButton {...buttonStyles} size="sm" variant="outline">
              <ICON_MAP.CollapseAction />
            </IconButton>
          </Tooltip>
        </span>
      </MenuTrigger>
      <MenuContent>
        <Tooltip content="Cannot collapse a loop" disabled={!disabled}>
          <MenuItem value="Collapse downstream" disabled={disabled} onClick={() => collapseNode(strategyId, node)}>
            Collapse downstream
          </MenuItem>
        </Tooltip>

        <Tooltip content="Cannot collapse a loop" disabled={!disabled}>
          <MenuItem
            value="Collapse all upstream cards"
            disabled={disabled}
            onClick={() => collapseNodes(strategyId, incomers)}
          >
            Collapse all upstream cards
          </MenuItem>
        </Tooltip>
      </MenuContent>
    </MenuRoot>
  )

  return hasIncomers ? menuOptions : collapseCard
}

export default CollapseNodeButton
