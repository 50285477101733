import type { DialogRootProps } from '@chakra-ui/react'
import delay from 'lodash/delay'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'

import CardFilters from './cardFilters'
import { filtersToInput } from './helpers'
import MapCardFiltersModalContext from './mapCardFiltersModalContext'
import type { MapCardFiltersModalContextType } from './mapCardFiltersModalContext'

import { DialogBody, DialogContent, DialogRoot } from '@app/components/ui/dialog'
import type { NodeFiltersInput } from '@graphql/types'

type Props = Partial<
  Pick<MapCardFiltersModalContextType, 'cardFilters' | 'open' | 'onOpen' | 'onFilter'> &
    Pick<DialogRootProps, 'onOpenChange'>
>

const CardFiltersModal: FC<Props> = ({
  cardFilters = [],
  open,
  onOpen = () => {},
  onOpenChange = (_e) => {},
  onFilter = () => {}
}) => {
  const [activeFacetName, setActiveFacetName] = useState<keyof NodeFiltersInput>(null)

  const handleClose = useCallback(() => {
    onOpenChange({ open: false })
    delay(() => {
      setActiveFacetName(null)
    }, 500)
  }, [onOpenChange])

  const providerValue = useMemo(
    () => ({
      cardFilters,
      open,
      onOpen,
      onClose: handleClose,
      activeFacetName,
      setActiveFacetName,
      onFilter
    }),
    [cardFilters, open, onOpen, handleClose, activeFacetName, onFilter]
  )

  const filtersInput = useMemo(() => filtersToInput(cardFilters), [cardFilters])

  return (
    <MapCardFiltersModalContext.Provider value={providerValue}>
      <DialogRoot onOpenChange={handleClose} open={open} modal={false} closeOnInteractOutside={false}>
        <DialogContent shadow="dark-lg">
          <DialogBody p={0}>
            <CardFilters filters={filtersInput} />
          </DialogBody>
        </DialogContent>
      </DialogRoot>
    </MapCardFiltersModalContext.Provider>
  )
}

export default CardFiltersModal
