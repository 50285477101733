import { Textarea } from '@chakra-ui/react'
import { forwardRef, useEffect } from 'react'
import type { RefObject } from 'react'

import { Field } from '@app/components/ui/field'

const ChatTextarea = ({ disabled, ...rest }, ref: RefObject<HTMLTextAreaElement>) => {
  const resizeTextBox = (event) => {
    const textarea = event.currentTarget
    textarea.style.height = 'auto'
    const borderHeight = textarea.offsetHeight - textarea.clientHeight
    textarea.style.height = `${textarea.scrollHeight + borderHeight}px`
  }

  useEffect(() => {
    const { current } = ref

    if (disabled && current) {
      resizeTextBox({ currentTarget: current })
    }
  }, [disabled, ref])

  return (
    <Field disabled={disabled}>
      <Textarea
        ref={ref}
        maxH="200px"
        pe="9"
        _placeholder={{ color: 'fg.subtle' }}
        resize="none"
        autoFocus
        name="prompt"
        onInput={resizeTextBox}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            e.currentTarget.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
          }
        }}
        placeholder="Ask me anything..."
        rows={3}
        {...rest}
      />
    </Field>
  )
}

export default forwardRef(ChatTextarea)
