import { Collapsible, HStack, Icon, Spacer, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

import MarkdownDisplay from '@app/shared/markdownDisplay'

interface Props {
  sourceSummary?: string
}

const SourceSummary: FC<Props> = ({ sourceSummary }: { sourceSummary: string }) => {
  const [show, setShow] = useState(false)
  const handleToggle = () => setShow(!show)

  if (!sourceSummary) {
    return null
  }

  const ShowIcon = show ? FiChevronUp : FiChevronDown

  return (
    <Stack m={2} p={2} borderRadius="sm" bgColor="bg.subtle">
      <Collapsible.Root open={show}>
        <Collapsible.Content>
          <MarkdownDisplay text={sourceSummary} />
        </Collapsible.Content>
      </Collapsible.Root>
      <HStack cursor="pointer" onClick={handleToggle}>
        <Text fontSize="sm" fontWeight="semibold">
          Show source summary
        </Text>
        <Spacer />
        <Icon w={6} h={6}>
          <ShowIcon />
        </Icon>
      </HStack>
    </Stack>
  )
}

export default SourceSummary
