import type { Task as TaskType } from '@graphql/types'
import { TaskStatusesEnum, TaskTypesEnum } from '@graphql/types'

export const progressColorForTask = (task: TaskType) => {
  const { status } = task
  let progressColor = 'green'

  if (status === TaskStatusesEnum.InProgress) {
    progressColor = 'blue'
  } else if (status === TaskStatusesEnum.Failed) {
    progressColor = 'red'
  }

  return progressColor
}

export const progressPercentForTask = (task: TaskType) => {
  const { progress, status } = task
  let progressPercent = progress || 0

  if (status === TaskStatusesEnum.InProgress) {
    progressPercent ||= 50
  } else if (status === TaskStatusesEnum.Failed) {
    progressPercent = 100
  } else if (status === TaskStatusesEnum.Completed) {
    progressPercent = 100
  }

  return progressPercent
}

export const linkForTask = (task: TaskType) => {
  const { domainObjectId, taskType } = task

  if (taskType === TaskTypesEnum.MetricSourceImport || taskType === TaskTypesEnum.MetricActivityProcess) {
    return `/metrics/${domainObjectId}/source`
  }

  return null
}
