import isArray from 'lodash/isArray'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'

export const buildParameters = (currentParameters, updatedParameter, updatedValue) => {
  const updatedParameters = { ...currentParameters }

  if (!currentParameters[updatedParameter]) {
    updatedParameters[updatedParameter] = updatedValue
  } else if (currentParameters[updatedParameter] === updatedValue) {
    delete updatedParameters[updatedParameter]
  } else if (currentParameters[updatedParameter] && updatedValue === false) {
    updatedParameters[updatedParameter] = undefined
  } else if (
    isArray(updatedParameters[updatedParameter]) &&
    currentParameters[updatedParameter].includes(updatedValue)
  ) {
    updatedParameters[updatedParameter] = updatedParameters[updatedParameter].filter((value) => value !== updatedValue)
  } else if (isArray(updatedParameters[updatedParameter])) {
    updatedParameters[updatedParameter].push(updatedValue)
  } else {
    updatedParameters[updatedParameter] = [updatedParameters[updatedParameter], updatedValue]
  }

  return updatedParameters
}

export const useParameters = () => {
  const location = useLocation()
  const currentQueryParams = queryString.parse(location.search)
  const navigate = useNavigate()

  const stringifyQueryParameters = (parameterObject, currentParameters = currentQueryParams) => {
    let newParameters = currentParameters ? { ...currentParameters } : {}

    Object.keys(parameterObject).forEach((key) => {
      newParameters = buildParameters(newParameters, key, parameterObject[key])
    })

    // On every change query we reset the page and limit params.
    delete newParameters.page
    delete newParameters.limit

    return queryString.stringifyUrl({
      url: location.pathname,
      query: newParameters
    })
  }

  // TODO: Replace all instances of this with setQueryParameters
  const setQueryParameter = (parameter, value) => {
    const url = stringifyQueryParameters({ [parameter]: value })
    navigate(url)
  }

  // Takes an object of shape { key: value, key2: value } and iterates
  const setQueryParameters = (parameterObject) => {
    const url = stringifyQueryParameters(parameterObject, {})
    navigate(url)
  }

  return { setQueryParameter, setQueryParameters, stringifyQueryParameters }
}
