import type { FC } from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title?: string
  description?: string
  url?: string
  siteName?: string
  imageUrl?: string
}

const HeadMetaData: FC<Props> = ({
  title = 'DoubleLoop',
  description = 'Stay in the loop with our product updates and metrics',
  url = 'https://doubleloop.app',
  siteName = '',
  imageUrl = ''
}) => (
  <Helmet>
    <title>
      {title} {siteName && siteName.length && `| ${siteName}`}
    </title>
    <meta name="description" content={description} />
    <meta name="twitter:card" content={description} />
    <meta name="twitter:site" content={url} />
    <meta property="og:site_name" content={siteName} />
    <meta property="og:title" content={title} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={imageUrl} />
  </Helmet>
)

export default HeadMetaData
