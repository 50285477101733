import { Box, Collapsible, Group, HStack, Input, Stack } from '@chakra-ui/react'
import { format } from 'date-fns'
import type { FC } from 'react'
import { useState } from 'react'
import { RiImageAddFill } from 'react-icons/ri'
import { Form, useLocation, useNavigate, useParams } from 'react-router-dom'
import type { FormProps } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import ContributorAutocompleteInput from '@app/next/forms/autocomplete/contributorAutocompleteInput'
import LabelAutocompleteInput from '@app/next/forms/autocomplete/labelAutocompleteInput'
import FormAlert from '@app/next/forms/formAlert'
import RichInput from '@app/next/forms/richInput'
import useForm from '@app/next/forms/useForm'
import type { Props as ClassificationsProps } from '@app/shared/entities/classifications'
import Classifications from '@app/shared/entities/classifications'
import ExistingEntityAttachmentUploadButton from '@app/shared/entities/existingEntityAttachmentUploadButton'
import NewEntityAttachmentUploadButton from '@app/shared/entities/newEntityAttachmentUploadButton'
import { EntityMarkdownView } from '@app/shared/entities/shared'
import { UploadsContextProvider } from '@app/shared/entities/uploadsContext'
import EntityImages from '@app/shared/entity/entityImages'
import NewEntityImages from '@app/shared/entity/newEntityImages'
import type { MapDomainEntity } from '@app/types'
import type { Label } from '@graphql/types'
import { ImpactEnum, ImportanceEnum } from '@graphql/types'

const labelToPickerItem = (label) => ({ id: label, name: label, label })

type EntityClassifications = Partial<Pick<ClassificationsProps, 'classifications'>>
type EntityLabels = Partial<Label>[]

interface ClassificationsInputProps {
  entity: EntityClassifications
}

const ClassificationsInput: FC<ClassificationsInputProps> = ({ entity }) => (
  <Field label="Classifications">
    <Box w="100%">
      <Classifications classifications={entity?.classifications} />
    </Box>
  </Field>
)

const uploadButtonContent = (
  <Button aria-label="Upload Attachments">
    <RiImageAddFill />
    Upload image
  </Button>
)

interface EntityFormProps extends FormProps {
  entity?: Omit<
    MapDomainEntity,
    | 'organization'
    | 'commentsCount'
    | 'classifications'
    | 'thumbnail'
    | 'labels'
    | 'rfId'
    | 'containerEntityContainments'
  > &
    EntityClassifications & { labels?: EntityLabels }
  onCancel?: () => void
  onDelete?: () => void
  onSuccess?: () => void
  children?: FormProps['children']
}

const EntityForm: FC<EntityFormProps> = ({ entity = null, onCancel, onSuccess, children, ...rest }) => {
  const { strategyId, reportId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const labels = entity?.labels || location?.state?.labels?.map(labelToPickerItem) || []
  const [open, setOpen] = useState(false)
  const { errors } = useForm({
    onSuccess: () => {
      if (onSuccess) {
        onSuccess()

        return
      }

      // TODO: fix this routing mess
      navigate(strategyId && !reportId ? '../../' : '..', { relative: 'path' })
    }
  })

  const handleCancel = () => {
    if (onCancel) {
      onCancel()

      return
    }

    navigate('..', { relative: 'path' })
  }

  const uploadButton = entity ? (
    <ExistingEntityAttachmentUploadButton entityId={entity.id}>
      {uploadButtonContent}
    </ExistingEntityAttachmentUploadButton>
  ) : (
    <NewEntityAttachmentUploadButton>{uploadButtonContent}</NewEntityAttachmentUploadButton>
  )

  const imagePreview = entity ? (
    <EntityImages entity={entity} scrollContainer="entity-form" />
  ) : (
    <NewEntityImages scrollContainer="entity-form" />
  )

  const defaultDate = entity?.happenedAt ? entity.happenedAt : new Date()
  const parseValue = typeof defaultDate === 'string' ? new Date(defaultDate) : defaultDate
  const happenedAt = format(parseValue, "yyyy-MM-dd'T'HH:mm")

  return (
    <UploadsContextProvider>
      <Form id="entity-form" method="post" {...rest}>
        <FormAlert
          description={(errors as { global: { message: string } })?.global?.message}
          title="Failed to edit the event!"
        />
        <Stack gap="5">
          <Field label="Title" required invalid={!!errors?.name} errorText={errors?.name?.message}>
            <Input data-cy="entity-name" defaultValue={entity?.name} name="name" placeholder="What happened?" />
          </Field>
          <Field label="When did the event occur">
            <Input defaultValue={happenedAt} name="happenedAt" type="datetime-local" />
          </Field>
          <Field label="Due date" invalid={!!errors?.dueDate} errorText={errors?.dueDate?.message}>
            <Input defaultValue={entity?.dueDate} name="dueDate" placeholder="Due date (optional)" type="date" />
          </Field>
          <HStack>
            <Box w="50%">
              <Field label="Importance" invalid={!!errors?.importance} errorText={errors?.importance?.message}>
                <NativeSelectRoot>
                  <NativeSelectField name="importance" placeholder="" defaultValue={entity?.importance}>
                    <option value={ImportanceEnum.Minor}>Minor</option>
                    <option value={ImportanceEnum.Medium}>Medium</option>
                    <option value={ImportanceEnum.Major}>Major</option>
                  </NativeSelectField>
                </NativeSelectRoot>
              </Field>
            </Box>
            <Box w="50%">
              <Field label="Impact" invalid={!!errors?.impact} errorText={errors?.impact?.message}>
                <NativeSelectRoot>
                  <NativeSelectField name="impact" placeholder="" defaultValue={entity?.impact}>
                    <option value="">None</option>
                    <option value={ImpactEnum.Positive}>Positive Impact</option>
                    <option value={ImpactEnum.Negative}>Negative Impact</option>
                  </NativeSelectField>
                </NativeSelectRoot>
              </Field>
            </Box>
          </HStack>
          <Stack gap={4}>
            <RichInput
              name="summary"
              label="Summary"
              placeholder="Details about what what happened"
              defaultValue={entity?.summary || ''}
              errors={errors}
            />
            {entity?.sourceSummary && (
              <>
                <Button onClick={() => setOpen(!open)}>{open ? 'Hide' : 'Show'} Source Summary</Button>
                <Collapsible.Root open={open}>
                  <Collapsible.Content>
                    <Box p={4} bgColor="bg.muted" rounded="md">
                      <EntityMarkdownView field={entity.sourceSummary} />
                    </Box>
                  </Collapsible.Content>
                </Collapsible.Root>
              </>
            )}
          </Stack>
          <RichInput
            name="goalHypothesis"
            label="Goal / Hypothesis"
            placeholder="What were you thinking might happen or hypothesis for the change"
            defaultValue={entity?.goalHypothesis || ''}
            errors={errors}
          />
          <RichInput
            name="results"
            label="Results"
            placeholder="What actually happened as a result"
            defaultValue={entity?.results || ''}
            errors={errors}
          />
          <Field label="Type" invalid={!!errors?.entityType} errorText={errors?.entityType?.message}>
            <Input defaultValue={entity?.entityType} name="entityType" placeholder="chore, feature, fix, etc." />
          </Field>
          <Field
            label="URL title"
            invalid={!!errors?.linkTitle}
            errorText={errors?.linkTitle?.message}
            helperText="The title of the link to the demonstration"
          >
            <Input defaultValue={entity?.linkTitle} name="linkTitle" placeholder="Demonstration" />
          </Field>
          <Field
            label="URL"
            helperText="The URL of the link of the demonstration"
            invalid={!!errors?.linkUrl}
            errorText={errors?.linkUrl?.message}
          >
            <Input defaultValue={entity?.linkUrl} name="linkUrl" placeholder="https://" />
          </Field>
          {entity?.classifications?.length > 0 && <ClassificationsInput entity={entity} />}
          <LabelAutocompleteInput name="labels" label="Labels" defaultValue={labels} menuPortalTarget={document.body} />
          <ContributorAutocompleteInput
            name="contributors"
            label="Contributors"
            defaultValue={entity?.entityContributions}
          />
          {entity?.entityAttachmentsCount > 0 ? imagePreview : null}
          {children}
          <Group justifyContent="end">
            {uploadButton}
            <Button onClick={handleCancel} variant="outline">
              Cancel
            </Button>
            <Button type="submit">{entity ? 'Update event' : 'Create event'}</Button>
          </Group>
        </Stack>
      </Form>
    </UploadsContextProvider>
  )
}

export default EntityForm
