import type { MenuItemProps } from '@chakra-ui/react'
import { Box, Input, Group, Link, Stack, StackSeparator, Text, VisuallyHidden } from '@chakra-ui/react'
import type { FC, FormEventHandler, ForwardRefExoticComponent } from 'react'
import { useCallback, useState } from 'react'

import { Button } from '@app/components/ui/button'
import type { ButtonProps } from '@app/components/ui/button'
import {
  DialogBody,
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { Field } from '@app/components/ui/field'
import { FileUploadDropzone, FileUploadRoot } from '@app/components/ui/file-upload'
import { toaster } from '@app/components/ui/toaster'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import track from '@app/lib/analyticsHelper'
import ToggleInput from '@app/next/forms/toggleInput'
import UppyRoot from '@app/next/forms/uppyRoot'
import { useStore } from '@app/store'
import { MetricUploadBatchFile, MetricUploadFile } from '@graphql/documents/metric.graphql'
import type { MetricUploadFileMutationVariables } from '@graphql/queries'
import type { Metric } from '@graphql/types'

const HelperText = () => (
  <Text>
    For information on how to format your CSV, please see our{' '}
    <Link
      display="inline"
      color="link"
      href="https://docs.doubleloop.app/measure/upload-metrics-via-a-csv-file"
      rel="onoopener noreferrer"
      target="_blank"
    >
      help document.
    </Link>
  </Text>
)

// TODO: hardcoding both prop types is dirty. This could be worked around with
//       separate modals, or a bunch of conditionals.
type Props<OProps = ButtonProps | MenuItemProps> = OProps & {
  metric?: Pick<Metric, 'id'> | null
  OpenerComponent?: FC<OProps> | ForwardRefExoticComponent<OProps>
  openerChildren?: React.ReactNode
}

const MetricCSVModal: FC<Props> = ({
  metric = null,
  OpenerComponent = Button,
  openerChildren = null,
  ...openerProps
}) => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [shrineHash, setShrineHash] = useState<string | null>(null)
  const { user } = useStoreCurrentUser()
  const actionMutation = useStore.use.actionMutation()
  const [open, setOpen] = useState(false)

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()

      const formData = new FormData(e.currentTarget)
      const values = Object.fromEntries(formData.entries())
      const { clearExistingData, shrineHash: inputShrineHash } = values

      let input: Partial<MetricUploadFileMutationVariables['input']> = {
        clearExistingData: clearExistingData as string,
        shrineHash: inputShrineHash as string
      }
      let mutation = MetricUploadBatchFile

      if (metric?.id) {
        input = { ...input, metricId: metric.id }
        mutation = MetricUploadFile
      }

      try {
        await actionMutation<typeof mutation>(mutation, input)
        setShrineHash(null)

        setOpen(false)

        toaster.create({
          title: 'CSV Upload Successful!',
          description:
            'We will begin processing the file soon. Check a metric page after a few minutes to see the results.',
          type: 'success'
        })
      } catch (err) {
        setError(err.message)
      }
    },
    [metric]
  )

  if (!user) {
    return null
  }

  return (
    <>
      <OpenerComponent onClick={() => setOpen(true)} {...openerProps}>
        {openerChildren}
        Upload CSV
      </OpenerComponent>

      <DialogRoot onOpenChange={(e) => setOpen(e.open)} open={open} placement="center">
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Upload metric data via CSV</DialogTitle>
          </DialogHeader>
          <DialogCloseTrigger />
          <form id="metric-csv-form" onSubmit={onSubmit}>
            <DialogBody>
              <Stack gap={4} separator={<StackSeparator />}>
                <UppyRoot
                  fileTypes={['text/csv']}
                  onUploadStart={() => {
                    setLoading(true)
                  }}
                  onUploadSuccess={(newShrineHash, file) => {
                    setShrineHash(newShrineHash)
                    setLoading(false)
                    track('csv:succeed', user, { filename: file.name })
                  }}
                  onUploadError={(file) => {
                    setLoading(false)
                    track('csv:fail', user, { filename: file?.name })
                  }}
                >
                  {({ shrineHash: csvShrineHash, onFileAccept }) => (
                    <Field label="CSV" required helperText={<HelperText />} invalid={!!error} errorText={error}>
                      <FileUploadRoot
                        gap={1}
                        onFileAccept={onFileAccept}
                        maxW="xl"
                        alignItems="stretch"
                        maxFiles={1}
                        accept={['text/csv']}
                      >
                        <VisuallyHidden asChild>
                          <Input name="shrineHash" readOnly value={csvShrineHash} />
                        </VisuallyHidden>
                        <FileUploadDropzone
                          label="Drag and drop or click here to upload a CSV"
                          description=".csv up to 50MB"
                        />
                      </FileUploadRoot>
                    </Field>
                  )}
                </UppyRoot>
                <Box css={{ '--field-label-width': '220px' }}>
                  <Field label="Clear existing data points?" orientation="horizontal">
                    <ToggleInput name="clearExistingData" defaultValue={false} />
                  </Field>
                </Box>
              </Stack>
            </DialogBody>

            <DialogFooter>
              <Group>
                <Button
                  onClick={() => {
                    setOpen(false)
                    setShrineHash(null)
                  }}
                  variant="outline"
                >
                  Close
                </Button>
                <Button loading={loading} disabled={!shrineHash} type="submit">
                  Upload
                </Button>
              </Group>
            </DialogFooter>
          </form>
        </DialogContent>
      </DialogRoot>
    </>
  )
}

export default MetricCSVModal
