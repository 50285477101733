import { Box, Stack } from '@chakra-ui/react'
import { useState } from 'react'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import DurationInput from '@app/next/forms/durationInput'
import ToggleInput from '@app/next/forms/toggleInput'
import ApplyOrganizationMetricStatIntervals from '@app/pages/settings/organization/components/applyOrganizationMetricStatIntervals'
import TextDivider from '@app/shared/textDivider'
import type { FormErrors } from '@app/types'
import type { Organization } from '@graphql/types'

interface Props {
  organization?: Pick<Organization, 'metricDefaults'>
  errors?: FormErrors
}

const StatIntervals: FC<Props> = ({ organization = null, errors = null }) => {
  const metricDefaults = organization?.metricDefaults || {}
  const [checked, setChecked] = useState(!!metricDefaults?.period_to_date)

  return (
    <Stack gap={4}>
      <Box css={{ '--field-label-width': '430px' }}>
        <Field label="Month to date (MTD), Quarter to date (QTD), Year to date (YTD)" orientation="horizontal">
          <ToggleInput
            checked={checked}
            name="metricDefaults.period_to_date"
            onChange={() => setChecked(!checked)}
            defaultValue={checked}
          />
        </Field>
      </Box>
      {!checked && (
        <>
          <TextDivider>or</TextDivider>
          <Field
            label="Interval Period 1"
            invalid={!!errors?.['metricDefaults.stat_intervals.0']}
            errorText={errors?.['metricDefaults.stat_intervals.0']?.message}
          >
            <DurationInput
              name="metricDefaults.stat_intervals.0"
              defaultValue={metricDefaults.stat_intervals?.[0] || 'P7D'}
            />
          </Field>
          <Field
            label="Interval Period 2"
            invalid={!!errors?.['metricDefaults.stat_intervals.1']}
            errorText={errors?.['metricDefaults.stat_intervals.1']?.message}
          >
            <DurationInput
              name="metricDefaults.stat_intervals.1"
              defaultValue={metricDefaults.stat_intervals?.[1] || 'P6W'}
            />
          </Field>
          <Field
            label="Interval Period 3"
            invalid={!!errors?.['metricDefaults.stat_intervals.2']}
            errorText={errors?.['metricDefaults.stat_intervals.2']?.message}
          >
            <DurationInput
              name="metricDefaults.stat_intervals.2"
              defaultValue={metricDefaults.stat_intervals?.[2] || 'P12M'}
            />
          </Field>
        </>
      )}
      <ApplyOrganizationMetricStatIntervals />
    </Stack>
  )
}

export default StatIntervals
