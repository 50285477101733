import { HStack, Spacer } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import { CardContributors } from '@app/shared/cards/components'
import Source from '@app/shared/entity/source'
import type { CardSize } from '@app/types'
import type { Entity } from '@graphql/queries'

interface Props {
  entity: Pick<
    Entity,
    | 'confidenceRating'
    | 'foreignState'
    | 'linkUrl'
    | 'entityContributions'
    | 'foreignType'
    | 'sourceName'
    | 'sourceUrl'
    | 'workflowState'
  >
  size?: CardSize
  children?: ReactNode
}

export const shouldRenderCardEntityContributorsAndStatus = (entity) => {
  const { entityContributions, source } = entity

  return !!entityContributions?.length || source
}

const CardEntityContributorsAndStatus: FC<Props> = ({ entity, size = 'normal', children }) => {
  const { entityContributions } = entity

  return (
    <HStack>
      {entityContributions?.length && <CardContributors contributors={entityContributions} size={size} />}
      <Spacer />
      <Source entity={entity} asButton={false} size={size} />
      {children}
    </HStack>
  )
}

export default CardEntityContributorsAndStatus
