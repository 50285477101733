import { useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Button } from '@app/components/ui/button'

const AddWorkItemButton = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const to = location.pathname.endsWith('/add') ? '..' : 'add'

  const cb = useCallback(() => {
    if (to === '..') {
      navigate(to, { relative: 'path' })
    }
  }, [navigate, to])

  useHotkeys('esc', cb)

  return (
    <Button size="xs" variant="outline" asChild>
      <Link to={`${to}${location.search}`}>Add work item</Link>
    </Button>
  )
}

export default AddWorkItemButton
