import { Box, Link as ChakraLink, Text, Tabs } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import type { FC } from 'react'
import { Link, Outlet } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@app/components/ui/menu'
import type { TabType } from '@app/types'

type Props = {
  tab?: string
  tabs: Record<string, TabType>
}

const PageTabs: FC<Props> = ({ tab = '', tabs }) => {
  // split tabs based on whether it has a `collapsed` property set to true
  const uncollapsedTabs = Object.entries(tabs).reduce(
    (acc, [key, tabData]) => {
      if (!tabData.collapsed) {
        acc[key] = tabData
      }
      return acc
    },
    {} as Record<string, TabType>
  )

  const collapsedTabs = Object.entries(tabs).reduce(
    (acc, [key, tabData]) => {
      if (tabData.collapsed) {
        acc[key] = tabData
      }
      return acc
    },
    {} as Record<string, TabType>
  )

  const tabKeys = Object.keys(tabs)
  const tabKeysUncollapsed = Object.keys(uncollapsedTabs)
  const tabKeysCollapsed = Object.keys(collapsedTabs)

  let tabIndex = tabKeys.findIndex((e) => tab.match(e))

  tabIndex = tabIndex === -1 ? 0 : tabIndex

  return (
    <Box>
      <Tabs.Root defaultValue={tabKeys[tabIndex]} fitted lazyMount>
        <Tabs.List>
          {tabKeysUncollapsed.map((tabName) => {
            const tabData = tabs[tabName]
            const { label } = tabData

            return (
              <Tabs.Trigger key={`tabs_${tabName}`} value={tabName} asChild>
                <ChakraLink asChild unstyled>
                  <Link to={tabData.to}>{label || capitalize(tabName)}</Link>
                </ChakraLink>
              </Tabs.Trigger>
            )
          })}
          {tabKeysCollapsed.length > 0 && (
            <MenuRoot lazyMount>
              <MenuTrigger key="tabs_more" asChild>
                <Button variant="plain" value="tabs_more">
                  <Text fontSize="sm">More</Text>
                </Button>
              </MenuTrigger>
              <MenuContent zIndex="popover">
                {tabKeysCollapsed.map((tabName) => {
                  const tabData = tabs[tabName]
                  const { label } = tabData

                  return (
                    <MenuItem value={tabName} key={`tabs_${tabName}`} asChild>
                      <Tabs.Trigger value={tabName} asChild>
                        <Link to={tabData.to}> {label || capitalize(tabName)}</Link>
                      </Tabs.Trigger>
                    </MenuItem>
                  )
                })}
              </MenuContent>
            </MenuRoot>
          )}
        </Tabs.List>
        <Tabs.Content value={tabKeys[tabIndex]}>
          <Outlet />
        </Tabs.Content>
      </Tabs.Root>
    </Box>
  )
}

export default PageTabs
