import { HStack, Spacer, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo } from 'react'
import { BiBuildings, BiGroup, BiCreditCard, BiUser, BiExit, BiSun, BiMoon } from 'react-icons/bi'
import { RiAdminLine } from 'react-icons/ri'

import SidebarMenuItem from './sidebarMenuItem'
import UserButton, { type UserButtonProps } from './userButton'

import { useColorMode } from '@app/components/ui/color-mode'
import { MenuContent, MenuItem, MenuRoot, MenuSeparator, MenuTrigger } from '@app/components/ui/menu'
import { Switch } from '@app/components/ui/switch'
import Can from '@app/shared/authorization/can'

type Props = UserButtonProps

const SidebarMenu: FC<Props> = ({ user }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'

  return (
    <MenuRoot>
      <MenuTrigger>
        <UserButton user={user} />
      </MenuTrigger>
      <MenuContent px="3" py="4" color="muted" shadow="lg" zIndex="popover">
        <Can I="view" an="admin">
          <SidebarMenuItem icon={<RiAdminLine />} href="/admin" title="Admin" isExternal target="_blank" />
        </Can>
        <Can I="update" an="organization">
          <SidebarMenuItem icon={<BiBuildings />} href="/settings/organization/edit" title="Organization" />
        </Can>
        <Can I="update" an="account">
          <SidebarMenuItem icon={<BiGroup />} href="/settings/organization/accounts" title="Accounts & Invites" />
        </Can>
        <Can I="update" a="payment">
          <SidebarMenuItem icon={<BiCreditCard />} href="/settings/billing" title="Billing" />
        </Can>
        <MenuItem value="dark_mode_toggle" closeOnSelect={false} onClick={toggleColorMode} rounded="md">
          <HStack>
            {isDarkMode ? <BiMoon /> : <BiSun />}
            <Text>Dark Mode</Text>
            <Spacer />
            <Switch colorPalette="gray" id="dark-mode" checked={isDarkMode} />
          </HStack>
        </MenuItem>
        <MenuSeparator />
        <SidebarMenuItem icon={<BiUser />} href="/settings/user/edit" title="Profile" />
        <SidebarMenuItem icon={<BiExit />} href="/sign_out" title="Logout" isExternal />
      </MenuContent>
    </MenuRoot>
  )
}

export default memo(SidebarMenu)
