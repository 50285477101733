import type { RouteObject } from 'react-router-dom'
import { Navigate, Outlet } from 'react-router-dom'

import Billing from '@app/pages/settings/billing/billing'
import Plan from '@app/pages/settings/billing/plan'
import AccountsAndInvites from '@app/pages/settings/organization/accountsAndInvites'
import EditOrganization from '@app/pages/settings/organization/edit'
import EditUser from '@app/pages/settings/user/edit'
import integrationRoutes from '@app/routes/integrations'
import { requiresAuthorization } from '@app/utils/auth'
import { loaderQuery } from '@graphql/client'
import { Organization } from '@graphql/documents/organization.graphql'

const loadOrganization = async () => {
  const { data } = await loaderQuery(Organization)

  return { organization: data?.organization }
}

const routes: RouteObject = {
  path: 'settings',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <Navigate to="integrations" replace />
    },
    integrationRoutes,
    {
      path: 'billing',
      loader: () => requiresAuthorization('update', 'payment'),
      element: <Billing />
    },
    {
      path: 'plan',
      loader: () => requiresAuthorization('update', 'payment'),
      element: <Plan />
    },
    {
      path: 'organization',
      loader: () => requiresAuthorization('update', 'organization'),
      children: [
        {
          path: 'edit',
          element: <EditOrganization />,
          loader: loadOrganization
        }
      ]
    },
    {
      path: 'organization/accounts',
      element: <AccountsAndInvites />
    },
    {
      path: 'user/edit',
      element: <EditUser />
    }
  ]
}

export default routes
