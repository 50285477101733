import { HStack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import cardAvatarSize from '@app/shared/cards/cardAvatarSize'
import TooltipAvatar from '@app/shared/tooltipAvatar'
import type { CardSize } from '@app/types'
import profileImage from '@app/utils/profileImage'
import type { User } from '@graphql/queries'

interface Props {
  user?: Pick<User, 'name'> & Partial<Pick<User, 'email'>>
  showName?: boolean
  size?: CardSize
}

const CardAvatar: FC<Props> = ({ user, showName = false, size = 'normal' }) => {
  if (!user) {
    return null
  }

  return (
    <HStack>
      <TooltipAvatar name={user.name} size={cardAvatarSize('xs', size)} src={profileImage(user.email)} />
      {showName && (
        <Text color="fg.muted" fontSize="xs">
          {user?.name}
        </Text>
      )}
    </HStack>
  )
}

export default CardAvatar
