import { Box, HStack, Icon, Text } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import { useMemo } from 'react'

import MetricIntegrationDeleteButton from './metricIntegrationDeleteButton'
import MetricIntegrationEditModal from './metricIntegrationEditModal'

import { Button } from '@app/components/ui/button'
import { EmptyState } from '@app/components/ui/empty-state'
import { MenuContent, MenuRoot, MenuTrigger } from '@app/components/ui/menu'
import useGetObjects from '@app/hooks/useGetObjects'
import { METRIC_SOURCES } from '@app/lib/globals'
import Can from '@app/shared/authorization/can'
import Table from '@app/shared/table'
import type { Column } from '@app/shared/table/types'
import type { CredentialsQuery } from '@graphql/queries'

type Credential = CredentialsQuery['credentials'][0]

const columns: Column<Credential>[] = [
  {
    header: '',
    key: 'name',
    cell: ({ data }) => {
      const { icon, color, display } = METRIC_SOURCES[data.sourceName] || {}

      if (!icon) {
        return data.displayName
      }

      const IconComponent = icon

      return (
        <HStack>
          <Icon boxSize="16px" color={color}>
            <IconComponent />
          </Icon>
          <Text>
            {display}
            {data.label ? <span> &mdash; {data.label}</span> : ''}
          </Text>
        </HStack>
      )
    }
  },
  {
    header: '',
    key: 'actions',
    cell: ({ data }) => (
      <Can I="update" a="credential">
        <Box textAlign="end">
          <MenuRoot>
            <MenuTrigger asChild>
              <Button variant="outline">...</Button>
            </MenuTrigger>
            <MenuContent>
              <MetricIntegrationEditModal credential={data} />
              <MetricIntegrationDeleteButton credential={data} />
            </MenuContent>
          </MenuRoot>
        </Box>
      </Can>
    )
  }
]

const MetricIntegrationsSection = () => {
  const credentials = useGetObjects('credential')

  const creds = useMemo(() => {
    if (!Array.isArray(credentials) || credentials.length === 0) {
      return null
    }

    const sortable = credentials.map((cred) => {
      const { display = '' } = METRIC_SOURCES[cred.sourceName] || {}
      return { ...cred, display }
    })

    return sortBy(sortable, ['display', 'label'])
  }, [credentials])

  if (!creds || creds.length === 0) {
    return <EmptyState title="No metric integrations" description="Add an integration to get started" />
  }

  return <Table<Credential> showHeader={false} columns={columns} data={creds} />
}

export default MetricIntegrationsSection
