import '@xyflow/react/dist/style.css'
import { Box, useToken } from '@chakra-ui/react'
import { ReactFlow, Background, ConnectionMode, type ColorMode } from '@xyflow/react'
import { memo, useCallback, useMemo } from 'react'
import { Outlet, useParams, useNavigate } from 'react-router-dom'

import { defaultEdgeOptions } from './components/edges/helpers'
import nodeTypes from './components/nodes/nodeTypes'

import { useColorMode, useColorModeValue } from '@app/components/ui/color-mode'
import useGetEdges from '@app/hooks/useGetEdges'
import useGetIsSelecting from '@app/hooks/useGetIsSelecting'
import useGetNodes from '@app/hooks/useGetNodes'
import useGetObject from '@app/hooks/useGetObject'
import BackgroundChangeLoader from '@app/pages/maps/components/backgroundChangeLoader'
import Edge from '@app/pages/maps/components/edges/edge'
import MapDebugger from '@app/pages/maps/components/map/mapDebugger'
import MapFiltering from '@app/pages/maps/components/map/mapFiltering'
import MapMenu from '@app/pages/maps/components/map/mapMenu'
import MultiSelectToolbar from '@app/pages/maps/components/multiSelectToolbar'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import useInteraction from '@app/pages/maps/useInteraction'
import { useStore } from '@app/store'
import type { MapDomainEdge, MapDomainNode } from '@app/types'

const Map = () => {
  const { strategyId } = useParams()
  const navigate = useNavigate()
  const { canEdit } = usePermissionsContext()
  const strategy = useGetObject(strategyId, 'strategy')
  const { colorMode } = useColorMode()

  const { onNodeDragStop, onSelectionDragStop, onEdgeConnect, onEdgeDelete, onDragOver, onDrop, getRootProps } =
    useInteraction(strategyId)
  const onBeforeDelete = useStore.use.onBeforeDelete()
  const onNodesChange = useStore.use.onNodesChange()
  const onEdgesChange = useStore.use.onEdgesChange()
  const onNodesDelete = useStore.use.onNodesDelete()
  const highlightPath = useStore.use.highlightPath()
  const setIsSelecting = useStore.use.setIsSelecting()

  const nodes = useGetNodes(strategyId)

  // check if all the nodes are extensible, console logging each check
  // THIS IS CURRENTLY NOT SHOWING TO BE AN ISSUE BUT THAT CANT BE ASSUMED. GETTING UPGRADED
  // AND THEN CONTINUING THE INVESTIGATION
  // nodes.forEach((node) => {
  //   console.log('checking node isExtensible', node.id, Object.isExtensible(node))
  // })

  const edges = useGetEdges(strategyId)

  const edgeTypes = useMemo(() => ({ custom: Edge }), [])

  const [lightDotColor, darkDotColor] = useToken('colors', ['gray.400', 'gray.500'])
  const dotColor = useColorModeValue(lightDotColor, darkDotColor)

  const isSelecting = useGetIsSelecting()
  const boxProps = getRootProps()

  const onEdgeDoubleClick = useCallback(
    (_, edge) => {
      navigate(`/strategy/${strategyId}/map/edges/${edge.id}`)
    },
    [navigate, strategyId]
  )

  const bgColor = useColorModeValue('gray.100', 'gray.700')

  const onBeforeDelete1 = useCallback(
    (nodesAndEdges) => onBeforeDelete(nodesAndEdges, strategyId),
    [onBeforeDelete, strategyId]
  )
  const onNodesChange1 = useCallback((changes) => onNodesChange(changes), [onNodesChange])
  const onNodesDelete1 = useCallback((nds) => onNodesDelete(strategyId, nds), [onNodesDelete, strategyId])
  // TODO: xyflow does not take the NodeType into account for the onSelectionChange param
  const onSelectionChange = useCallback(
    ({ nodes: selectedNodes }) => highlightPath(selectedNodes as MapDomainNode[], strategyId),
    [highlightPath, strategyId]
  )
  const onSelectionStart = useCallback(() => setIsSelecting(true), [setIsSelecting])
  const onSelectionEnd = useCallback(() => setIsSelecting(false), [setIsSelecting])

  // do not crash if the user deletes the currently-viewed strategy
  if (!strategy) {
    return null
  }

  const { backgroundChangeInProgress = true } = strategy || {}

  return (
    <>
      {backgroundChangeInProgress && <BackgroundChangeLoader />}
      <Box {...boxProps} h="100%" bgColor={bgColor}>
        <ReactFlow<MapDomainNode, MapDomainEdge>
          nodes={nodes}
          edges={edges}
          connectionMode={ConnectionMode.Loose}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          defaultEdgeOptions={defaultEdgeOptions}
          onBeforeDelete={onBeforeDelete1}
          onNodesChange={onNodesChange1}
          onEdgesChange={onEdgesChange}
          onConnect={onEdgeConnect}
          onEdgeDoubleClick={canEdit && onEdgeDoubleClick}
          onEdgesDelete={onEdgeDelete}
          onNodesDelete={onNodesDelete1}
          onNodeDragStop={onNodeDragStop}
          onSelectionDragStop={onSelectionDragStop} // covers multi-select and move
          onDragOver={onDragOver}
          onDrop={onDrop}
          onSelectionStart={onSelectionStart}
          onSelectionEnd={onSelectionEnd}
          onSelectionChange={onSelectionChange}
          nodeDragThreshold={1}
          nodesDraggable={canEdit}
          selectNodesOnDrag={false}
          snapToGrid
          fitView
          minZoom={0.15}
          maxZoom={4}
          proOptions={{ account: 'paid-pro', hideAttribution: true }}
          fitViewOptions={{ padding: 0.2 }}
          deleteKeyCode={canEdit ? ['Backspace', 'Delete'] : null}
          connectionRadius={120}
          isValidConnection={(edge) => edge.source !== edge.target}
          elevateNodesOnSelect={false}
          colorMode={colorMode as ColorMode}
        >
          <MapDebugger strategyId={strategyId} />
          <Background gap={20} size={2} color={dotColor} />
          <MapFiltering strategyId={strategyId} />
          <MapMenu strategyId={strategyId} />
          <MultiSelectToolbar strategyId={strategyId} isSelecting={isSelecting} />
          {/* <LoadingToast strategy={strategy} /> */}
        </ReactFlow>
        <Outlet />
      </Box>
    </>
  )
}

Map.displayName = 'Map'

export default memo(Map)
