import { Flex, IconButton, Spacer } from '@chakra-ui/react'
import { PiArrowLeftBold } from 'react-icons/pi'
import { Link } from 'react-router-dom'

import { Tooltip } from '@app/components/ui/tooltip'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'

const ChatDrawerHeader = () => (
  <Flex p={2}>
    <Tooltip content="Go back">
      <IconButton aria-label="Go back" asChild colorPalette="gray" variant="ghost">
        <Link to="..">
          <PiArrowLeftBold />
        </Link>
      </IconButton>
    </Tooltip>
    <Spacer />
    <DrawerCloseButton variant="ghost" />
  </Flex>
)

export default ChatDrawerHeader
