import { Center, Fieldset, Stack, StackSeparator } from '@chakra-ui/react'

import AccountsList from '@app/pages/settings/organization/components/accountsList'
import InvitationForm from '@app/pages/settings/organization/components/invitationForm'
import InvitationsList from '@app/pages/settings/organization/components/invitationsList'
import Can from '@app/shared/authorization/can'
import PageStack from '@app/shared/layout/pageStack'

const AccountsAndInvites = () => (
  <Center>
    <PageStack separator={<StackSeparator />}>
      <Can I="create" an="invitation">
        <InvitationForm />
      </Can>
      <Fieldset.Root>
        <Stack>
          <Fieldset.Legend>Accounts</Fieldset.Legend>
          <Fieldset.HelperText>Manage who can access the information in DoubleLoop</Fieldset.HelperText>
        </Stack>
        <Fieldset.Content>
          <AccountsList />
        </Fieldset.Content>
      </Fieldset.Root>
      <Fieldset.Root>
        <Fieldset.Legend>Invitations</Fieldset.Legend>
        <Fieldset.Content>
          <InvitationsList />
        </Fieldset.Content>
      </Fieldset.Root>
    </PageStack>
  </Center>
)

export default AccountsAndInvites
