import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Tooltip } from '@app/components/ui/tooltip'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import ICON_MAP from '@app/utils/iconMap'

const buttonStyles = {
  borderRadius: 'sm'
}

interface Props {
  path: string
}

const SettingsButton: FC<Props> = ({ path }) => {
  const navigate = useNavigate()

  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  const openDrawer = () => {
    navigate(path)
  }

  return (
    <Tooltip content="Settings">
      <IconButton {...buttonStyles} aria-label="settings" onClick={openDrawer} size="sm" variant="outline">
        <ICON_MAP.Settings />
      </IconButton>
    </Tooltip>
  )
}

export default SettingsButton
