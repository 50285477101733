import { HStack, IconButton, Spacer, Spinner } from '@chakra-ui/react'
import type { FC } from 'react'

import BasicCardDeleteButton from '@app/pages/basicCards/components/basicCardDeleteButton'
import Can from '@app/shared/authorization/can'
import ICON_MAP from '@app/utils/iconMap'
import type { BasicCard } from '@graphql/types'

interface Props {
  data: BasicCard
}

const BasicCardListActions: FC<Props> = ({ data }) => (
  <HStack>
    <Spacer />
    <Can I="update" a="metric">
      <BasicCardDeleteButton basicCardId={data.id}>
        {({ loading, ...rest }) => (
          <IconButton color="error" aria-label="Delete card" variant="ghost" {...rest}>
            {loading ? <Spinner size="xs" /> : <ICON_MAP.Delete />}
          </IconButton>
        )}
      </BasicCardDeleteButton>
    </Can>
  </HStack>
)

export default BasicCardListActions
