import { Box, HStack, Stack, Text, Icon } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiDot } from 'react-icons/pi'

import Entity from '@app/shared/entity'
import type { MapDomainEntity } from '@app/types'

interface Props {
  entities?: MapDomainEntity[]
}

const MinorEntitiesList: FC<Props> = ({ entities = [] }) => (
  <Box>
    <Text py={2} color="gray.600" fontSize="lg">
      Minor updates
    </Text>
    <Stack pb={4}>
      {entities.map((entity) => (
        <HStack key={`minor-entity-${entity?.id}`} gap={0}>
          <Icon color="gray" fontSize="40px">
            <PiDot />
          </Icon>
          <Entity entity={entity} isListView />
        </HStack>
      ))}
    </Stack>
  </Box>
)

export default MinorEntitiesList
