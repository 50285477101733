import type { InputProps } from '@chakra-ui/react'
import { Input, VisuallyHidden } from '@chakra-ui/react'
import type { FC } from 'react'

import isChartUrl from './isChartUrl'

type Props = InputProps & {
  chart: string
}

const ChartInput: FC<Props> = ({ chart, onChange, placeholder, ...rest }) => {
  const chartIsUrl = isChartUrl(chart || '')
  const chartField = chartIsUrl ? 'chart_url' : 'chart_id'
  const chartName = `configuration.${chartField}`
  const otherChartField = chartIsUrl ? 'chart_id' : 'chart_url'
  const otherChartName = `configuration.${otherChartField}`

  return (
    <>
      <Input name={chartName} onChange={onChange} placeholder={placeholder} value={chart || ''} {...rest} />
      <VisuallyHidden asChild>
        <Input defaultValue="" name={otherChartName} required={false} />
      </VisuallyHidden>
    </>
  )
}

export default ChartInput
