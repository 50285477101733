import { Table } from '@chakra-ui/react'
import type { FC } from 'react'

import type { Column, Data } from '../types'

import CellComponent from './cellComponent'

type Props<TCellData extends Data = Data> = {
  column: Column<TCellData>
  row: TCellData
}

const RowColumn = <T extends Data = Data>({ column, row }: Props<T>): ReturnType<FC<Props<T>>> => {
  const cell = column.accessor ? row[column.accessor] : row
  let element

  if (column.cell) {
    element = <CellComponent column={column} cell={cell} />
  } else {
    element = cell
  }

  return (
    <Table.Cell key={`row-column-${column.header}-${row.id}`} p={column?.padding}>
      {element}
    </Table.Cell>
  )
}

export default RowColumn
