import { useReactFlow } from '@xyflow/react'
import { useCallback, useMemo } from 'react'

import { toaster } from '@app/components/ui/toaster'
import useMousePosition from '@app/hooks/useMousePosition'
import { MAX_AWS_FILE_SIZE } from '@app/lib/globals'
import { useStore } from '@app/store'

const useImages = (strategyId: string) => {
  const addNode = useStore.use.addNode()
  const addTemporaryNode = useStore.use.addTemporaryNode()
  const removeTemporaryNode = useStore.use.removeTemporaryNode()
  const { screenToFlowPosition } = useReactFlow()
  const getLatestMousePosition = useMousePosition()
  const mousePosition = getLatestMousePosition()

  const createImageNodes = useCallback(
    async (images: File | File[] = [], dropPosition = mousePosition) => {
      const files = Array.isArray(images) ? images : [images]

      files.forEach((file) => {
        if (!file.type.includes('image') || file.size >= MAX_AWS_FILE_SIZE) {
          toaster.create({
            title: 'File could not be uploaded',
            description: !file.type.includes('image')
              ? 'We support .jpg, .png, or .gif'
              : 'Please upload a smaller image'
          })

          return
        }

        if (file) {
          const position = screenToFlowPosition(dropPosition)
          const url = URL.createObjectURL(file)

          const image = new Image()
          image.src = url
          image.onload = async () => {
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')

            canvas.height = image.height
            canvas.width = image.width
            context.drawImage(image, 0, 0)

            const dataURL = canvas.toDataURL('image/jpeg')

            const tempNodeId = addTemporaryNode({
              strategyId,
              type: 'mapImage',
              selected: false,
              height: image.height,
              width: image.width,
              position,
              data: { loading: true, width: image.width, height: image.height, url: dataURL }
            })

            await addNode({
              strategyId,
              nodeData: {
                position,
                dimensions: {
                  height: image.height,
                  width: image.width
                }
              },
              objectType: 'mapImage',
              objectData: {
                fileDataUri: dataURL
              },
              onCreate: () => {
                removeTemporaryNode(tempNodeId)
              }
            })
          }
        }
      })
    },
    [addNode, addTemporaryNode, mousePosition, removeTemporaryNode, screenToFlowPosition, strategyId, toaster]
  )

  return useMemo(() => ({ createImageNodes }), [createImageNodes])
}

export default useImages
