import type { FC } from 'react'

import MetricIntegrationsSection from '@app/pages/settings/integrations/components/metricIntegrations/metricIntegrationsSection'
import { PageHeader, PageStack } from '@app/shared/layout'
import withAwait from '@app/shared/withAwait'

const List: FC = () => (
  <PageStack>
    <PageHeader title="Credentials" subtitle="Maintain the credentials for your Metric sources." />
    <MetricIntegrationsSection />
  </PageStack>
)

export default withAwait(List, 'credentials')
