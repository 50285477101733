import { HStack, IconButton, Spacer, Stack, Text } from '@chakra-ui/react'
import { formatDistanceToNow } from 'date-fns'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { ProgressBar, ProgressRoot } from '@app/components/ui/progress'
import { progressPercentForTask, progressColorForTask, linkForTask } from '@app/pages/tasks/taskHelpers'
import ICON_MAP from '@app/utils/iconMap'
import type { Task as TaskType } from '@graphql/types'
import { TaskStatusesEnum } from '@graphql/types'

interface Props {
  task: TaskType
  showLink?: boolean
}

const Task: FC<Props> = ({ task, showLink = true }) => {
  const { description, summary, status, updatedAt } = task
  const progressColor = progressColorForTask(task)
  const progressPercent = progressPercentForTask(task)
  const link = linkForTask(task)

  return (
    <Stack gap={4}>
      <Stack gap={0}>
        <HStack alignItems="flex-start">
          <Text fontSize="md" fontWeight="semibold">
            {description}
          </Text>
          <Spacer />
          {!!link && showLink && (
            <IconButton aria-label="Manage task" size="xs" variant="ghost">
              <Link to={link} target="_blank">
                <ICON_MAP.Settings />
              </Link>
            </IconButton>
          )}
        </HStack>
        <Text fontSize="xs" title={updatedAt}>
          {formatDistanceToNow(new Date(updatedAt), { includeSeconds: true })} ago
        </Text>
      </Stack>
      <ProgressRoot
        colorPalette={progressColor}
        size="sm"
        value={status === TaskStatusesEnum.InProgress ? null : progressPercent}
      >
        <ProgressBar />
      </ProgressRoot>
      <Text fontSize="sm">{summary}</Text>
    </Stack>
  )
}

export default Task
