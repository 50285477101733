import { Stack, Table } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'

import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import useGetObject from '@app/hooks/useGetObject'
import ConfidenceRatingPopover from '@app/next/confidenceRatingPopover'
import LabelsInput from '@app/next/forms/inline/labelsInput'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import InlineUpdaterRoot from '@app/next/forms/inlineUpdaterRoot'
import OwnerPopover from '@app/next/ownerPopover'
import DetailsTable from '@app/pages/shared/detailsTable'
import Can from '@app/shared/authorization/can'
import CardEntityStatus from '@app/shared/cards/entity/cardEntityStatus'

const EntityDetails = () => {
  const { nodeId } = useParams()
  const entity = useGetObject(nodeId, 'entity')
  const { workflowState, foreignState, aiGenerated } = entity

  return (
    <Stack gap={4} p={4}>
      <DetailsTable>
        <Table.Body>
          <ViewerFieldWrapper value={entity.owner}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">Owner</Table.Cell>
              <Table.Cell>
                <OwnerPopover object={entity} showName />
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <ViewerFieldWrapper value={entity.labels}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">Labels</Table.Cell>
              <Table.Cell>
                <LabelsInput domainObject={entity} name="labels" />
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <ViewerFieldWrapper value={typeof entity.confidenceRating === 'number'}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">Confidence</Table.Cell>
              <Table.Cell>
                <ConfidenceRatingPopover domainObject={entity} />
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <ViewerFieldWrapper value={foreignState}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">Status</Table.Cell>
              <Table.Cell>
                <CardEntityStatus status={foreignState} workflowState={workflowState} />
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <Can I="view" an="admin">
            <Table.Row>
              <Table.Cell fontWeight="semibold">AI Generated</Table.Cell>
              <Table.Cell>
                <NativeSelectRoot>
                  <InlineUpdaterRoot domainObject={entity}>
                    <NativeSelectField name="aiGenerated" defaultValue={aiGenerated?.toString()} placeholder={null}>
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </NativeSelectField>
                  </InlineUpdaterRoot>
                </NativeSelectRoot>
              </Table.Cell>
            </Table.Row>
          </Can>
        </Table.Body>
      </DetailsTable>
    </Stack>
  )
}

export default EntityDetails
