import type { UppyEventMap, UppyOptions } from '@uppy/core'
import type { FC, ReactNode } from 'react'

import DoubleloopUploader from './doubleloopUploader'
import UploaderModal from './uploaderModal'

import type { FileTicketMeta, RemoteFile } from '@app/shared/uploader/types'

type Props = {
  title?: string
  children?: ReactNode
  uppyOptions?: UppyOptions<FileTicketMeta, RemoteFile>
  onUploadSuccess?: UppyEventMap<FileTicketMeta, RemoteFile>['upload-success']
  onComplete?: UppyEventMap<FileTicketMeta, RemoteFile>['complete']
}

const DoubleloopUploaderModal: FC<Props> = ({
  title = 'Upload Files',
  children = null,
  uppyOptions = null,
  onUploadSuccess = () => {},
  onComplete = () => {}
}) => {
  const uploaderBody = (
    <DoubleloopUploader onUploadSuccess={onUploadSuccess} onComplete={onComplete} uppyOptions={uppyOptions} />
  )

  return (
    <UploaderModal title={title} uploaderBody={uploaderBody}>
      {children}
    </UploaderModal>
  )
}

export default DoubleloopUploaderModal
