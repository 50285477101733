import capitalize from 'lodash/capitalize'
import type { FC } from 'react'

import ConfidenceRatingTag from '@app/next/domainObjects/confidenceRating/confidenceRatingTag'
import { COLOR_MAP } from '@app/utils/iconMap'
import type { ConfidenceRatingEnum } from '@graphql/queries'

const displayName = (type?: ConfidenceRatingEnum) => {
  if (!type) {
    return ''
  }

  return capitalize(type?.replace('_', ' '))
}

interface Props {
  type: ConfidenceRatingEnum
}

const CardConfidenceRating: FC<Props> = ({ type }) => {
  const displayType = displayName(type)
  const fontSize = 'sm'

  if (!type) {
    return null
  }

  const color = COLOR_MAP[`Confidence-${type}`] || 'gray'
  const tagSize = 'sm'

  return (
    <ConfidenceRatingTag
      showChevron={false}
      rating=""
      fontSize={fontSize}
      color={color}
      size={tagSize}
      tooltip={displayType}
    />
  )
}

export default CardConfidenceRating
