import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Comments from '@app/shared/comments/comments'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStore } from '@app/store'
import { useBasicCardCommentsQuery, useEntityCommentsQuery, useMetricCommentsQuery } from '@graphql/queries'

interface Props {
  commentableId?: string
  commentableType: string
}

const CommentsFetcher: FC<Props> = ({ commentableId, commentableType }) => {
  const [commentData, setCommentData] = useState(null)
  const bulkAdd = useStore.use.bulkAdd()
  const { nodeId } = useParams()
  const id = commentableId || nodeId

  let commentsQuery

  if (commentableType === 'entity') {
    commentsQuery = useEntityCommentsQuery
  } else if (commentableType === 'basicCard') {
    commentsQuery = useBasicCardCommentsQuery
  } else if (commentableType === 'metric') {
    commentsQuery = useMetricCommentsQuery
  }

  const [{ data }] = commentsQuery({
    variables: { id },
    pause: !!commentData
  })

  useEffect(() => {
    if (!data) {
      return
    }

    bulkAdd(data[commentableType].comments)
    setCommentData(data)
  }, [bulkAdd, commentableType, data])

  return <Comments commentableId={id} commentableType={commentableType} />
}

export default withSuspenseWrapper(CommentsFetcher)
