import Sidebar from './sidebar'

import { DrawerBackdrop, DrawerContent, DrawerRoot } from '@app/components/ui/drawer'

interface DrawerSidebarProps {
  open: boolean
  onOpenChange(): void
}

const DrawerSidebar = ({ open, onOpenChange }: DrawerSidebarProps) => (
  <DrawerRoot onOpenChange={onOpenChange} open={open} placement="start" trapFocus={false}>
    <DrawerBackdrop />
    <DrawerContent zIndex="skipLink">
      <Sidebar />
    </DrawerContent>
  </DrawerRoot>
)

export default DrawerSidebar
