import { HStack, Link as ChakraLink, Stack, StackSeparator, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import { Field } from '@app/components/ui/field'
import { Radio, RadioGroup } from '@app/components/ui/radio'
import StrategyFormUrl from '@app/pages/maps/components/settings/components/strategyFormUrl'
import type { MapDomainStrategy } from '@app/types'
import { Routes } from '@app/utils/routeHelpers'
import { SharingTypesEnum } from '@graphql/queries'

const HelperText = () => (
  <HStack pt={2}>
    <Text>Configure how stakeholders can see this strategy.</Text>
    <ChakraLink color="link" href={Routes.docsSharing} rel="noopener noreferrer" target="_blank">
      Learn more.
    </ChakraLink>
  </HStack>
)

type Props = {
  strategy: MapDomainStrategy
}

const StrategyFormAccess: FC<Props> = ({ strategy }) => {
  const [sharing, setSharing] = useState(strategy?.sharing || SharingTypesEnum.PrivateAccess)

  return (
    <Stack gap={4} separator={<StackSeparator />}>
      <Field label="Sharing" helperText={<HelperText />}>
        <RadioGroup
          name="sharing"
          value={sharing || SharingTypesEnum.PrivateAccess}
          onValueChange={(e) => setSharing(e.value as SharingTypesEnum)}
        >
          <Stack direction={['column', 'column', 'row']} gap={3}>
            <Radio value={SharingTypesEnum.PrivateAccess}>Private</Radio>
            <Radio value={SharingTypesEnum.TokenAccess}>Private w/token access</Radio>
            <Radio value={SharingTypesEnum.PublicAccess}>Public</Radio>
          </Stack>
        </RadioGroup>
      </Field>
      <StrategyFormUrl strategy={strategy} sharing={sharing} />
    </Stack>
  )
}

export default StrategyFormAccess
