import { Link as ChakraLink, Box, HStack, Spacer, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { Button } from '@app/components/ui/button'
import WordmarkColor from '@app/images/wordmarkColor'

interface Props {
  showPlaybooksCta?: boolean
}

const Header: FC<Props> = ({ showPlaybooksCta = true }) => (
  <HStack>
    <Box hideBelow="lg">
      <ChakraLink href="https://doubleloop.app">
        <WordmarkColor w="200px" h="auto" color="fg.default" />
      </ChakraLink>
    </Box>
    <Spacer />
    {showPlaybooksCta && (
      <>
        <Text fontSize="sm">What are we missing?</Text>
        <Button asChild>
          <ChakraLink
            href="https://doubleloop.canny.io/playbook-suggestions"
            rel="onoopener noreferrer"
            target="_blank"
          >
            Request a playbook.
          </ChakraLink>
        </Button>
      </>
    )}
  </HStack>
)

export default Header
