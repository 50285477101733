import { Link, Text } from '@chakra-ui/react'

const LimitedUseDisclosure = ({ ...rest }) => (
  <Text color="subtle" fontSize="xs" {...rest}>
    DoubleLoop does not share your data with any third party services, and its use of information received from Google
    APIs will adhere to{' '}
    <Link
      color="link"
      href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
      rel="onoopener noreferrer"
      target="_blank"
    >
      Google API Services User Data Policy
    </Link>
    , including the Limited Use requirements.
  </Text>
)

export default LimitedUseDisclosure
