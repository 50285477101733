import { Navigate, Outlet } from 'react-router-dom'
import type { LoaderFunction, RouteObject } from 'react-router-dom'

import RecurringCreate from '@app/pages/reports/recurring/create'
import RecurringEdit from '@app/pages/reports/recurring/edit'
import { loaderQuery } from '@graphql/client'
import { RecurringReportConfiguration } from '@graphql/documents/recurring_report_configuration.graphql'

const loadRecurringReportConfiguration: LoaderFunction = async ({ params }) => {
  const { recurringReportConfigurationId: id } = params
  const variables = { id }

  const resp = await loaderQuery(RecurringReportConfiguration, variables)

  if (resp.error) {
    throw resp
  }

  return { recurringReportConfiguration: resp?.data?.recurringReportConfiguration }
}

const routes: RouteObject = {
  path: 'recurring',
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <Navigate to="create" replace />
    },
    {
      path: 'create',
      element: <RecurringCreate />
    },
    {
      path: ':recurringReportConfigurationId',
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <Navigate to="edit" replace />
        },
        {
          path: 'edit',
          loader: loadRecurringReportConfiguration,
          element: <RecurringEdit />
        }
      ]
    }
  ]
}

export default routes
