import { Link as ChakraLink, Stack, Text } from '@chakra-ui/react'
import truncate from 'lodash/truncate'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import ListLabels from '@app/pages/shared/listLabels'
import MapsCell from '@app/pages/shared/mapsCell'
import type { Metric } from '@graphql/queries'

interface Props {
  data: Metric
}

const MetricName: FC<Props> = ({ data: metric }) => {
  const { id, name, ownerId } = metric
  const owner = useGetObject(ownerId, 'user')

  return (
    <Stack>
      <ChakraLink
        asChild
        data-cy="metric-name"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Link to={id}>
          <Stack>
            <Text minW="xs" fontSize="md" whiteSpace="normal" wordBreak="break-all" title={name}>
              {truncate(name, { length: 50 })}
            </Text>
          </Stack>
        </Link>
      </ChakraLink>
      {owner?.name && <Text color="muted">Owner: {owner.name}</Text>}
      <ListLabels labels={metric?.labels} />
      <MapsCell data={metric} />
    </Stack>
  )
}

export default MetricName
