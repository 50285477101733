import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import ImageWithOverlay from './imageWithOverlay'

import UsePlaybookButton from '@app/pages/playbooks/components/usePlaybookButton'
import type { Playbook } from '@graphql/queries'

interface Props {
  playbooks: Array<Playbook>
}

const HeroPlaybooks: FC<Props> = ({ playbooks }) => {
  const [hero, special1, special2, ...rest] = playbooks

  return (
    <Stack>
      <Text fontSize="lg" fontWeight="semibold">
        Featured
      </Text>
      <Stack
        align="stretch"
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: '6', md: '10' }}
        h={{ md: '400px' }}
      >
        <ImageWithOverlay
          flex="1"
          objectPosition="top center"
          title={hero.name}
          description={hero.shortDescription}
          src={hero.hero}
          alt={hero.name}
          url={hero.friendlyId}
          cta={<UsePlaybookButton playbook={hero} key="use" aria-label="Use this playbook" />}
        />
        {special1 && (
          <Stack gap={{ base: '6', md: '10' }} maxW={{ md: '400px' }}>
            <ImageWithOverlay
              key="special-1"
              spacing="4"
              title={special1.name}
              description={special1.shortDescription}
              src={special1.hero}
              alt={special1.name}
              url={special1.friendlyId}
              cta={<UsePlaybookButton playbook={special1} aria-label="Use this playbook" />}
            />
            {special2 && (
              <ImageWithOverlay
                key="special-2"
                spacing="4"
                title={special2.name}
                description={special2.shortDescription}
                src={special2.hero}
                alt={special2.name}
                url={special1.friendlyId}
                cta={<UsePlaybookButton playbook={special2} aria-label="Use this playbook" />}
              />
            )}
          </Stack>
        )}
        {!!rest.length &&
          rest.map((playbook) => (
            <ImageWithOverlay
              key={`playbook-list-${playbook.id}`}
              spacing="4"
              maxW="30%"
              title={playbook.name}
              src={playbook.hero}
              alt={playbook.name}
              url={playbook.friendlyId}
            />
          ))}
      </Stack>
    </Stack>
  )
}

export default HeroPlaybooks
