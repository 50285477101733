import MetricForm from './components/metricForm'

import { Card, PageHeader, PageStack } from '@app/shared/layout'

const Create = () => (
  <PageStack>
    <PageHeader title="Create a metric" />
    <Card>
      <MetricForm />
    </Card>
  </PageStack>
)

export default Create
