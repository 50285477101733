import type { FC, ReactNode } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import { Button } from '@app/components/ui/button'
import ConfirmPopover from '@app/shared/confirmPopover'
import { useStore } from '@app/store'
import { CardTypeDelete } from '@graphql/documents/card_types.graphql'
import type { CardTypeDeleteMutation } from '@graphql/queries'

interface Props {
  cardTypeId?: string
  onDelete?: () => void
  children?: ReactNode
}

const defaultChildren = (
  <Button color="error" aria-label="Delete metric" variant="outline">
    <FiTrash2 />
    Delete
  </Button>
)

const CardTypeDeleteButton: FC<Props> = ({ cardTypeId = null, onDelete = () => {}, children = defaultChildren }) => {
  const actionMutation = useStore.use.actionMutation()

  const deleteCardType = () => {
    onDelete()
    return actionMutation<CardTypeDeleteMutation>(CardTypeDelete, { cardTypeId })
  }

  return (
    <ConfirmPopover
      onConfirm={deleteCardType}
      header="Delete card type"
      body="Are you sure you want to delete this card type? You won’t be able to undo this and cards with this type will have their type removed."
      confirmButtonText="Delete card type"
    >
      {children}
    </ConfirmPopover>
  )
}

export default CardTypeDeleteButton
