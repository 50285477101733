import { Box } from '@chakra-ui/react'
import type { FC } from 'react'

import FilterCardFacetNameOption from './filterCardFacetNameOption'
import FilterCardLabels from './filterCardLabels'
import FilterNodeName from './filterNodeName'
import { useMapCardFiltersModalContext } from './mapCardFiltersModalContext'

import type { NodeFiltersInput } from '@graphql/types'

type Props = {
  filters: NodeFiltersInput
}

const Builder: FC<Props> = ({ filters }) => {
  const { activeFacetName } = useMapCardFiltersModalContext()

  switch (activeFacetName) {
    case 'name':
      return <FilterNodeName defaultValue={filters.name} />
    case 'labels':
      return <FilterCardLabels defaultValue={filters.labels} />
    default:
      return (
        <>
          <FilterCardFacetNameOption name="name" />
          <FilterCardFacetNameOption name="labels" displayName="label" />
        </>
      )
  }
}

const CardFilters: FC<Props> = ({ filters }) => (
  <Box overflowX="hidden" overflowY="auto" maxH="330px">
    <Builder filters={filters} />
  </Box>
)

export default CardFilters
