import { Container } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiArrowLeft } from 'react-icons/fi'

import LayoutRow from '@app/next/changelog/components/layoutRow'
import useChangelogEntity from '@app/next/changelog/useChangelogEntity'
import NavigationButton from '@app/pages/changelogs/components/navigationButton'
import Entity from '@app/shared/entity'
import HeadMetaData from '@app/shared/headMetaData'
import { PageStack } from '@app/shared/layout'
import SuspenseLoader from '@app/shared/loaders/suspenseLoader'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { pathWithSearchParams } from '@app/utils/routeHelpers'

const Show: FC = () => {
  const entity = useChangelogEntity('strategyChangelogEntity')

  if (!entity) {
    return null
  }

  const entityPath = pathWithSearchParams(`events/${entity.id}`)
  const backButtonPath = pathWithSearchParams('..')

  return (
    <PageStack>
      <Container>
        <HeadMetaData
          url={entityPath}
          title={entity.name}
          description={entity.summaryShort}
          imageUrl={entity.thumbnail?.url}
        />
        <LayoutRow>
          <Entity entity={entity} />
          <NavigationButton url={backButtonPath} label="Back to overview" leftIcon={<FiArrowLeft />} mt={6} />
        </LayoutRow>
      </Container>
    </PageStack>
  )
}

export default withSuspenseWrapper(Show, <SuspenseLoader />)
