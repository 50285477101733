import groupBy from 'lodash/groupBy'

import { useStore } from '@app/store'
import client from '@graphql/client'
import { ContributorsAutocomplete } from '@graphql/documents/contributor.graphql'

export const contributorToPickerItem = (contributor) => {
  const contrib = contributor?.contributor ? contributor.contributor : contributor
  const users = useStore.getState().user
  let displayName = contrib.name
  const intId = parseInt(contrib.id, 10)

  const { sourceName, id, searchableContributorId } = contrib

  // Things with classType are automatically denormalized via `handleGraphqlResult` which means user
  // contributions will not have any data including name which will instead live on the user object
  // in the zustand store. If we stop excluding SourceIdentity from the denormalization process, we
  // will need to update this to handle that case as well. The other option is removing the classType
  // from the loader/graphql query but that seems even more hacky and fragile.
  if (contrib?.classType === 'user' && users[intId]) {
    displayName = users[intId].name
  }

  return { value: id, label: displayName, sourceName, searchableId: searchableContributorId }
}

export const fetchContributors = async (inputValue) => {
  const { data } = await client.query(ContributorsAutocomplete, { query: inputValue }).toPromise()

  const formattedGroups = []

  if (data?.contributorsAutocomplete) {
    const groups = groupBy(data?.contributorsAutocomplete, 'sourceName')

    Object.keys(groups).forEach((key) => {
      const label = key === 'doubleloop' ? 'members' : key
      const options = groups[key].map(contributorToPickerItem).sort((a, b) => {
        const labelA = a.label.toLowerCase()
        const labelB = b.label.toLowerCase()

        if (labelA < labelB) {
          return -1
        }

        if (labelA > labelB) {
          return 1
        }

        return 0
      })

      formattedGroups.push({ label, options })
    })
  }

  return formattedGroups
}
