import { Stack } from '@chakra-ui/react'
import type { FC, ReactElement } from 'react'
import { cloneElement, useState } from 'react'

import ColorOptionLabel from './colorOptionLabel'

import { PopoverRoot, PopoverContent, PopoverBody, PopoverArrow, PopoverTrigger } from '@app/components/ui/popover'

interface Props {
  value: string
  onClick?: (arg0: string) => void
  children: ReactElement
}

const ColorPickerPopover: FC<Props> = ({ value, onClick, children }) => {
  const [open, setOpen] = useState(false)
  const onToggle = () => setOpen(!open)
  const trigger = cloneElement(children, { onClick: onToggle })

  const handleClick = (e: string) => {
    if (onClick) {
      onClick(e)
    }

    setOpen(!open)
  }

  return (
    <PopoverRoot onOpenChange={(e) => setOpen(e.open)} open={open}>
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody my={2}>
          <Stack gap={2}>
            <ColorOptionLabel color="gray" label="none" value="" onClick={handleClick} isActive={value === ''} />
            <ColorOptionLabel color="red" onClick={handleClick} isActive={value === 'red'} />
            <ColorOptionLabel color="orange" onClick={handleClick} isActive={value === 'orange'} />
            <ColorOptionLabel color="yellow" onClick={handleClick} isActive={value === 'yellow'} />
            <ColorOptionLabel color="green" onClick={handleClick} isActive={value === 'green'} />
            <ColorOptionLabel color="teal" onClick={handleClick} isActive={value === 'teal'} />
            <ColorOptionLabel color="blue" onClick={handleClick} isActive={value === 'blue'} />
            <ColorOptionLabel color="cyan" onClick={handleClick} isActive={value === 'cyan'} />
            <ColorOptionLabel color="purple" onClick={handleClick} isActive={value === 'purple'} />
            <ColorOptionLabel color="pink" onClick={handleClick} isActive={value === 'pink'} />
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default ColorPickerPopover
