import { Group, Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import type { ChangeEvent, FC, FormEventHandler, ReactNode } from 'react'
import { useState } from 'react'
import type { FormProps } from 'react-router-dom'
import { useNavigate, Form } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { NativeSelectRoot, NativeSelectField } from '@app/components/ui/native-select'
import FormAlert from '@app/next/forms/formAlert'
import useForm from '@app/next/forms/useForm'
import type { Props as UseFormProps } from '@app/next/forms/useForm'
import YearSelectInput from '@app/pages/metrics/components/goals/yearSelectInput'
import { useStore } from '@app/store'
import type { Goal, Metric } from '@graphql/queries'
import { useGoalCreateMutation, PeriodUnitEnum } from '@graphql/queries'

type Props = FormProps & {
  metric: Pick<Metric, 'id'>
  goal: Goal | null
  onSuccess?: UseFormProps['onSuccess']
}

const NUMBERIZED_FIELDS = ['startValue', 'targetValue', 'periodYear', 'periodMonth', 'periodNumber']

const numberizeFields = (input) => {
  const output = { ...input }

  NUMBERIZED_FIELDS.forEach((field) => {
    if (input[field]) {
      output[field] = parseFloat(input[field])
    }
  })

  return output
}

const GoalForm: FC<Props> = ({ metric, goal, onSuccess = () => {}, ...rest }) => {
  const [periodUnit, setPeriodUnit] = useState<PeriodUnitEnum>(goal?.periodUnit || PeriodUnitEnum.All)
  const { errors } = useForm({ onSuccess })
  const navigate = useNavigate()
  const updateObject = useStore.use.updateObject()
  const [, createGoal] = useGoalCreateMutation()

  const onPeriodChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setPeriodUnit(event.target.value as PeriodUnitEnum)
  }

  const onCancel = () => {
    navigate(-1)
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const input = numberizeFields(Object.fromEntries(formData.entries()))

    if (goal) {
      await updateObject({ goal: { id: goal.id, ...input } })
    } else {
      await createGoal({ input })
    }

    navigate(-1)
  }

  const yearSelect = (
    <Field label="Year" required>
      <YearSelectInput
        name="periodYear"
        defaultValue={goal?.periodYear || new Date().getFullYear()}
        endYear={new Date().getFullYear() + 3}
        placeholder={null}
      />
    </Field>
  )

  const periodSection = (): ReactNode => {
    switch (periodUnit) {
      case PeriodUnitEnum.All:
        return null
      case PeriodUnitEnum.Year:
        return yearSelect
      case PeriodUnitEnum.Quarter:
        return (
          <>
            <Field label="Quarter" required>
              <NativeSelectRoot>
                <NativeSelectField name="periodNumber" defaultValue={goal?.periodNumber || 1}>
                  <option value={1}>Q1</option>
                  <option value={2}>Q2</option>
                  <option value={3}>Q3</option>
                  <option value={4}>Q4</option>
                </NativeSelectField>
              </NativeSelectRoot>
            </Field>
            {yearSelect}
          </>
        )
      case PeriodUnitEnum.Month:
        return (
          <>
            <Field label="Month" required>
              <NativeSelectRoot>
                <NativeSelectField name="periodNumber" defaultValue={goal?.periodNumber || 1}>
                  <option value={1}>January</option>
                  <option value={2}>February</option>
                  <option value={3}>March</option>
                  <option value={4}>April</option>
                  <option value={5}>May</option>
                  <option value={6}>June</option>
                  <option value={7}>July</option>
                  <option value={8}>August</option>
                  <option value={9}>September</option>
                  <option value={10}>October</option>
                  <option value={11}>November</option>
                  <option value={12}>December</option>
                </NativeSelectField>
              </NativeSelectRoot>
            </Field>
            {yearSelect}
          </>
        )
      case PeriodUnitEnum.Custom:
        return (
          <>
            <Field label="Start date" invalid={!!errors?.startDate} errorText={errors?.startDate?.message}>
              <Input defaultValue={goal?.startDate} name="startDate" placeholder="Start (optional)" type="date" />
            </Field>
            <Field label="End date" invalid={!!errors?.endDate} errorText={errors?.endDate?.message} required>
              <Input defaultValue={goal?.endDate} name="endDate" placeholder="End" type="date" />
            </Field>
          </>
        )
      default:
        return null
    }
  }

  return (
    <Form onSubmit={onSubmit} {...rest}>
      <Stack gap={5}>
        {!goal && (
          <VisuallyHidden asChild>
            <Input defaultValue={metric.id} name="metricId" />
          </VisuallyHidden>
        )}

        <FormAlert
          description={errors?.global?.message as string}
          title={`Failed to ${goal ? 'update' : 'create'} the goal!`}
        />
        <Field label="Time Unit" required>
          <NativeSelectRoot>
            <NativeSelectField name="periodUnit" defaultValue={periodUnit} onChange={onPeriodChange} bg="bg">
              <option value={PeriodUnitEnum.All}>All Time</option>
              <option value={PeriodUnitEnum.Year}>Year</option>
              <option value={PeriodUnitEnum.Quarter}>Quarter</option>
              <option value={PeriodUnitEnum.Month}>Month</option>
              <option value={PeriodUnitEnum.Custom}>Custom date range</option>
            </NativeSelectField>
          </NativeSelectRoot>
        </Field>
        {periodSection()}
        <Field label="Starting value" required>
          <Input bg="bg" defaultValue={goal?.startValue || 0} name="startValue" type="number" />
        </Field>
        <Field label="Target value" required>
          <Input bg="bg" defaultValue={goal?.targetValue || 0} name="targetValue" type="number" />
        </Field>
        <Group justifyContent="end">
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>

          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </Form>
  )
}

export default GoalForm
