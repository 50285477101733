import { Group, Input, Stack, StackSeparator, Textarea } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { Form, useLocation } from 'react-router-dom'
import type { FormProps } from 'react-router-dom'
import { RRule } from 'rrule'

import ChannelInput from '../../components/channelInput'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import RRuleInput from '@app/next/forms/rruleInput'
import useForm from '@app/next/forms/useForm'
import ServiceSelect from '@app/pages/reports/recurring/components/serviceSelect'
import type { MapDomainStrategy } from '@app/types'
import type { RecurringReportConfigurationQuery } from '@graphql/queries'
import { RecurringReportConfigurationServiceEnum } from '@graphql/types'

type Props = FormProps & {
  strategy?: MapDomainStrategy
  recurringReportConfiguration?: RecurringReportConfigurationQuery['recurringReportConfiguration']
  onCancel?: () => void
}

const RecurringReportConfigurationForm: FC<Props> = ({
  strategy,
  recurringReportConfiguration,
  onCancel,
  ...formProps
}) => {
  const { state } = useLocation()
  const { errors } = useForm({})
  const [service, setService] = useState<RecurringReportConfigurationServiceEnum | null>(null)
  // const serviceRef = useRef<HTMLSelectElement>(null)
  let defaultNameValue = recurringReportConfiguration?.name
  let defaultServiceSelectValue = recurringReportConfiguration?.service

  if (state && state.reportType) {
    defaultNameValue = `${strategy?.name} - ${state.reportType === RecurringReportConfigurationServiceEnum.SendGoals ? 'Key Results' : 'Changelog'}`
    defaultServiceSelectValue = state.reportType
  }

  const handleFormChange = (e) => {
    if (e.target.name === 'service') {
      setService(e.target.value)
    }
  }

  return (
    <Form {...formProps} onChange={(e) => handleFormChange(e)}>
      <Stack gap={4} pt={4} separator={<StackSeparator />}>
        <Field label="Report title" required invalid={!!errors?.name} errorText={errors?.name?.message}>
          <Input autoComplete="off" defaultValue={defaultNameValue} name="name" placeholder="Untitled" />
        </Field>
        <RRuleInput
          defaultValue={recurringReportConfiguration?.schedule}
          name="schedule"
          availableFrequencies={[RRule.DAILY, RRule.WEEKLY, RRule.MONTHLY]}
        />
        <Field label="Action" required>
          <ServiceSelect defaultValue={defaultServiceSelectValue} />
        </Field>
        {[null, RecurringReportConfigurationServiceEnum.EmitReport].includes(service) && (
          <Field required label="AI Prompt" invalid={!!errors?.aiPrompt} errorText={errors?.aiPrompt?.message}>
            <Textarea
              autoComplete="off"
              defaultValue={recurringReportConfiguration?.aiPrompt}
              name="aiPrompt"
              placeholder="Summarize the work and metrics that are on the map."
            />
          </Field>
        )}
        <ChannelInput report={recurringReportConfiguration} />
        <Group justifyContent="end">
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </Group>
      </Stack>
    </Form>
  )
}

export default RecurringReportConfigurationForm
