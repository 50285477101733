import type { FC, ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import type { ButtonProps } from '@app/components/ui/button'
import { Button } from '@app/components/ui/button'
import { Tooltip } from '@app/components/ui/tooltip'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

interface Props extends ButtonProps {
  text?: string
  leftIcon?: ReactElement
}

const MetricCreateButton: FC<Props> = ({ text = 'New Data Point', ...rest }) => {
  const { leftIcon } = rest
  const { user } = useStoreCurrentUser()
  const navigate = useNavigate()

  if (!user) {
    return null
  }

  const onClick = () => {
    navigate('new')
  }

  return (
    <Tooltip content="Create data point">
      <Button onClick={onClick} {...rest}>
        {leftIcon} {text}
      </Button>
    </Tooltip>
  )
}

export default MetricCreateButton
