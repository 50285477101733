import { applyEdgeChanges, applyNodeChanges, getConnectedEdges, getIncomers, getOutgoers } from '@xyflow/react'
import { produce } from 'immer'
import { type StateCreator } from 'zustand'

import { NON_SELECTED_DIMMING_OPACITY } from '@app/lib/globals'
import {
  detachNodeFromParent,
  getAllIncomers,
  getAllOutgoers,
  getNodeChildren,
  queueChildrenNodesForDeletion
} from '@app/pages/maps/components/nodes/helpers'
import { mutationNodes, toNode } from '@app/pages/maps/dataHelpers'
import {
  getEdgesSelector,
  getNodesSelector,
  getNodeWithDataSelector,
  getObjectById,
  getObjectsByProperties
} from '@app/store/selectors'
import type { ApplicationState, MapSliceState, StoreDomainObjectKeys } from '@app/store/types'
import type { MapDomainEdge, MapDomainNode } from '@app/types'
import { actionMutation } from '@graphql/client'
import { PlaybookApplyToStrategy } from '@graphql/documents/playbook.graphql'
import { ReportSend } from '@graphql/documents/report.graphql'
import {
  EdgeCreate,
  EdgeDelete,
  MapCreateFromExisting,
  NodeCreate,
  NodesDuplicate,
  StrategyNodeDelete,
  StrategyNodeUpdate
} from '@graphql/documents/strategy.graphql'
import type {
  NodeCreateMutation,
  NodesDuplicateMutation,
  PlaybookApplyToStrategyMutation,
  PlaybookApplyToStrategyMutationVariables
} from '@graphql/queries'
import type { Edge, NodeCreateInput, NodeObjectInput } from '@graphql/types'

export const initialMapSliceState = {
  node: {},
  edge: {},
  report: {},
  recurringReportConfiguration: {},
  isSelecting: false
}

export const mapsSlice: StateCreator<
  // ApplicationState is required for the get().addObject method
  MapSliceState & ApplicationState,
  [['zustand/devtools', never]],
  [],
  MapSliceState
> = (set, get) => ({
  ...initialMapSliceState,
  setIsSelecting(isSelecting) {
    set({ isSelecting })
  },
  updateNodesToServer: async (strategyId, nodes) => {
    const input = { strategyId, nodes: mutationNodes(nodes) }

    const resp = await actionMutation(StrategyNodeUpdate, input)

    const { data: { strategyNodeUpdate = undefined } = {} } = resp || {}
    const { nodes: responseNodes } = strategyNodeUpdate || {}

    if (responseNodes) {
      get().updateNodes(strategyId, responseNodes, { skipMutation: true })
    }
  },
  onNodesChange: (changes) => {
    const filteredChanges = changes.filter((change) => change.type !== 'remove')

    set(
      produce((draft) => {
        const updatedNodes = applyNodeChanges(filteredChanges, Object.values(draft.node))

        draft.node = updatedNodes.reduce((acc, change) => {
          acc[change.id] = change
          return acc
        }, {})
      })
    )
  },
  onEdgesChange: (changes) => {
    const filteredChanges = changes.filter((change) => change.type !== 'remove')

    set(
      produce((draft) => {
        const updatedEdges = applyEdgeChanges(filteredChanges, Object.values(draft.edge))

        draft.edge = updatedEdges.reduce((acc, change) => {
          acc[change.id] = change
          return acc
        }, {})
      })
    )
  },
  onBeforeDelete: ({ nodes, edges }, strategyId) => {
    const allNodes = getObjectsByProperties(get(), 'node', { strategyId })
    const allEdges = getEdgesSelector(get(), strategyId)
    const childNodeIds = []

    // Remove any nodes that have a parent (are in a section) from the delete list and unset the parentNode
    let nodesToDelete = nodes.filter((node) => {
      // If the node is selected, we still want to delete it. We just want to avoid deleting nodes just because they have a parent
      if (!node.parentId || node.selected) {
        return true
      }

      const parentNode = get().node[node.parentId]
      const childNode = detachNodeFromParent(node, parentNode)

      get().updateNodes(strategyId, [childNode])

      childNodeIds.push(node.id)

      return false
    })

    // Before deleting, look for any collapsed incomers and add them to the delete list
    nodesToDelete.forEach((node) => {
      nodesToDelete = queueChildrenNodesForDeletion(node, allNodes, allEdges, nodesToDelete)
    })

    // If the edge was connecting a child node and the parent node was deleted, don't destroy the connection.
    const edgesToDelete = edges.filter(
      (edge) => !(childNodeIds.includes(edge.source) || childNodeIds.includes(edge.target))
    )

    return Promise.resolve({ nodes: nodesToDelete, edges: edgesToDelete })
  },
  onNodesDelete: (strategyId, nodes, options = { skipMutation: false }) => {
    const { skipMutation } = options
    const ids = nodes.map((node) => node.id)

    set(
      produce((draft) => {
        ids.forEach((id) => {
          delete draft.node[id]
        })

        const deletedIds = new Set(ids)

        draft.filteredNodeIds = draft.filteredNodeIds.filter((id) => !deletedIds.has(id))
      })
    )

    if (!skipMutation) {
      actionMutation(StrategyNodeDelete, { strategyId, nodes: nodes.map((node) => node.nodeId) })
    }
  },
  addNode: async ({ strategyId, nodeData, objectType, objectData, onCreate = () => {} }) => {
    const { position, selected, dimensions } = nodeData

    const input: NodeCreateInput = {
      strategyId,
      object: {
        [objectType]: objectData
      } as NodeObjectInput,
      position
    }

    if (dimensions) {
      input.dimensions = dimensions
    }

    const { data } = await actionMutation<NodeCreateMutation>(NodeCreate, input)
    const { nodeCreate } = data || {}
    const { nodeObject, node: newNode } = nodeCreate || {}

    // This might have to come first so when the selector for a Node is called, it has the object
    const { addObject, setFilteredNodeIds } = get()
    addObject(nodeObject)

    // This will put it on the users page immediately, regardless of filters.
    setFilteredNodeIds((currentIds) => {
      if (!currentIds.includes(newNode.id)) {
        return [...currentIds, newNode.id]
      }

      return currentIds
    })

    const node = toNode(newNode, {
      position: newNode.position,
      selected,
      nodeId: newNode?.nodeId
    })

    get().addObject(node)

    onCreate()
  },
  addTemporaryNode: (node) => {
    const temporaryId = `${node.type}-${Date.now()}`

    set(
      produce((draft) => {
        draft.node[temporaryId] = { ...node, id: temporaryId }
      })
    )

    return temporaryId
  },
  removeTemporaryNode: (temporaryId) => {
    // This looks like it could be migrated to removeObject()
    set(
      produce((draft) => {
        delete draft.node[temporaryId]
      })
    )
  },
  getNodeById: (id) => {
    const node = getObjectById(get(), 'node', id)

    if (!node) {
      return null
    }

    const data = getObjectsByProperties(get(), node.type as StoreDomainObjectKeys, { rfId: node.id })?.[0]

    return getNodeWithDataSelector(get(), node, data)
  },
  updateNode: (strategyId, id, updates, options = { skipMutation: false }) => {
    const { skipMutation } = options

    set(
      produce((draft) => {
        draft.node[id] = { ...(draft.node[id] || {}), ...updates }
      })
    )

    if (!skipMutation) {
      const updatedNode = get().node[id]
      get().updateNodesToServer(strategyId, [updatedNode])
    }
  },
  updateNodes: async (strategyId, updates, options = { skipMutation: false }) => {
    const { skipMutation } = options

    set(
      produce((draft) => {
        updates.forEach((update) => {
          const node = draft.node[update.id]

          if (node) {
            draft.node[update.id] = { ...node, ...update }
          }
        })
      })
    )

    const updatedNodes = updates.map((update) => get().node[update.id]).filter((n) => n)

    if (!skipMutation) {
      get().updateNodesToServer(strategyId, updatedNodes)
    }
  },
  moveNodesToNewMap: async (strategyId, nodes, edges, position) => {
    const nodeIds = nodes.map((node) => node.nodeId)
    const edgeIds = edges.map((edge) => edge.edgeId)

    set(
      produce((draft) => {
        nodes.forEach(({ id }) => {
          delete draft.node[id]
        })
        edges.forEach(({ id }) => {
          delete draft.edge[id]
        })
      })
    )

    const result = await actionMutation(MapCreateFromExisting, {
      strategyId,
      nodeIds,
      edgeIds,
      position
    })

    const { data } = result || {}
    const { mapCreateFromExisting } = data || {}
    const { map, node } = mapCreateFromExisting || {}

    get().bulkAdd([map, node])
  },
  collapseNode: (strategyId, node) => {
    const store = get()
    const edges = getEdgesSelector(store, strategyId)
    const nodes = getObjectsByProperties(store, 'node', { strategyId })

    const directOutgoers = getOutgoers(node, nodes, edges)

    // if no direct Outgoers, return early so it does not make a node in a un-uncollapsed state
    if (directOutgoers.length === 0) {
      return
    }

    const incomers = getAllIncomers(node, nodes, edges)
    // Get any section children so we can hide those nodes and edges too
    const nodeChildren = getNodeChildren(node, nodes)

    const updatedNodes = [...incomers, ...nodeChildren, node].map((n) => ({
      ...n,
      hidden: true,
      selected: false,
      metadata: { ...n.metadata, collapsed: true }
    }))

    get().updateNodes(strategyId, updatedNodes)

    // Hide all connected edges -- live syncing does not update the edges
    const connectedEdges = getConnectedEdges([...incomers, ...nodeChildren, node], edges) as MapDomainEdge[]
    connectedEdges.forEach((edge) => {
      get().updateEdge({ ...edge, data: { syncedStyles: { opacity: 0 } } })
    })
  },
  collapseNodes: (strategyId, nodes) => {
    nodes.forEach((node) => get().collapseNode(strategyId, node))
  },
  duplicateNodes: async (nodeIds, strategyId, options = {}) => {
    const result = await actionMutation<NodesDuplicateMutation>(NodesDuplicate, {
      nodeIds,
      strategyId,
      ...(options.variables || {})
    })
    const currentNodes = getNodesSelector(get(), strategyId)
    const newNodes: MapDomainNode[] = (result?.data?.nodesDuplicate?.nodes || []).map((node) => ({
      ...node,
      selected: true
    }))

    if (newNodes.length > 0) {
      // unselect all current nodes
      const { strategyId: currentStrategyId } = newNodes[0]
      await get().updateNodes(
        currentStrategyId,
        currentNodes.map((n) => ({ ...n, selected: false })),
        { skipMutation: true }
      )

      get().bulkAdd(newNodes)
    }

    return newNodes
  },
  expandNode: (strategyId, node) => {
    const nodes = getObjectsByProperties(get(), 'node', { strategyId })
    const nodeChildren = getNodeChildren(node, nodes)

    const updatedNodes = [...nodeChildren, node].map((n) => ({
      ...n,
      hidden: false,
      metadata: { ...n.metadata, collapsed: null }
    }))

    get().updateNodes(strategyId, updatedNodes)

    const strategyEdges = getEdgesSelector(get(), strategyId)
    const connectedEdges = getConnectedEdges([...nodeChildren, node], strategyEdges)

    connectedEdges.forEach((edge: Edge) => {
      let connectedNode

      if (edge.source === node.id) {
        connectedNode = get().getNodeById(edge.target)
      } else {
        connectedNode = get().getNodeById(edge.source)
      }

      if (!connectedNode.hidden) {
        const updatedEdge = { ...edge, data: { syncedStyles: { opacity: undefined } } }

        get().updateEdge(updatedEdge)
      }
    })
  },
  expandNodes: (strategyId, nodes) => {
    nodes.forEach((node) => get().expandNode(strategyId, node))
  },
  getIncomers: (node) => getIncomers(node, Object.values(get().node), Object.values(get().edge)) as MapDomainNode[], // TODO: This only seems to work coincidentally
  highlightPath: (nodes, strategyId) => {
    const highlightColor = '#6bc5f7'
    const allNodes = getObjectsByProperties(get(), 'node', { strategyId })
    const edges = getEdgesSelector(get(), strategyId)

    let nodesToHighlight: MapDomainNode[] = []
    let edgesToHighlight: Edge[] = []

    nodes.forEach((node) => {
      const incomers = getAllIncomers(node, allNodes, edges)
      const incomerIds = incomers.map((i) => i.id)

      const outgoers = getAllOutgoers(node, allNodes, edges)
      const outgoerIds = outgoers.map((o) => o.id)

      const allOutgoerEdges = getConnectedEdges([...outgoers, node], edges) as Edge[]
      const allIncomerEdges = getConnectedEdges([...incomers, node], edges) as Edge[]

      nodesToHighlight = [...nodesToHighlight, ...incomers, ...outgoers]
      edgesToHighlight = [
        ...edgesToHighlight,
        ...allOutgoerEdges.filter((oe) => outgoerIds.includes(oe.source)),
        ...allIncomerEdges.filter((oe) => incomerIds.includes(oe.target)),
        ...(getConnectedEdges([node], edges) as Edge[])
      ]
    })

    const pathEdgeIds = edgesToHighlight.map((edge) => edge.id)
    const pathNodeIds = nodesToHighlight.map((node) => node.id)

    set(
      produce((draft) => {
        Object.values(draft.edge).forEach((e: MapDomainEdge) => {
          if (pathEdgeIds.includes(e.id)) {
            draft.edge[e.id] = { ...e, style: { strokeWidth: 5, strokeDasharray: 5, stroke: highlightColor } }
          } else if (nodesToHighlight.length > 0) {
            draft.edge[e.id] = { ...e, style: { opacity: NON_SELECTED_DIMMING_OPACITY } }
          } else {
            draft.edge[e.id] = { ...e, style: {} }
          }
        })
        Object.values(draft.node).forEach((n: MapDomainNode) => {
          if (pathNodeIds.includes(n.id)) {
            draft.node[n.id] = {
              ...n,
              metadata: {
                ...n.metadata,
                highlight: true
              }
            }
          } else {
            draft.node[n.id] = {
              ...n,
              metadata: {
                ...n.metadata,
                highlight: null
              }
            }
          }
        })
      })
    )
  },
  addEdge: async (strategyId, edge) => {
    const { addObject, updateEdge } = get()

    const existingEdge = get().edge[edge.id]

    if (!existingEdge) {
      // Optimistic creation
      addObject(edge)

      const { sourceId, sourceType, sourceHandle, targetId, targetType, targetHandle } = edge

      const resp = await actionMutation(EdgeCreate, {
        strategyId,
        sourceId,
        sourceType,
        sourceHandle,
        targetId,
        targetType,
        targetHandle
      })

      const newEdge = resp?.data?.edgeCreate?.edge

      // Ensure we have the DB ID for deletion in the same session
      updateEdge({ ...edge, ...newEdge })
    }
  },
  updateEdge: (edge) => {
    set(
      produce((draft) => {
        draft.edge[edge.id] = { ...(draft.edge[edge.id] || {}), ...edge }
      })
    )
  },
  removeEdge: (id) => {
    const edge = get().edge[id]

    if (edge) {
      set(
        produce((draft) => {
          delete draft.edge[id]
        })
      )

      actionMutation(EdgeDelete, { edgeId: edge.edgeId })
    }
  },
  sendReport: async (reportId) => {
    const { data } = await actionMutation(ReportSend, { reportId })
    const { reportSend } = data || {}
    const { report } = reportSend || {}

    set(
      produce((draft) => {
        draft.report[reportId] = { ...(draft.report[reportId] || {}), ...report }
      })
    )
  },
  applyPlaybookToStrategy: async (input) => {
    const { actionMutation: storeActionMutation, setFilteredNodeIds } = get()

    return storeActionMutation<PlaybookApplyToStrategyMutation, PlaybookApplyToStrategyMutationVariables>(
      PlaybookApplyToStrategy,
      input
    ).then((result) => {
      // populate filtered nodes with results.
      const nodeIds = result.data?.playbookApplyToStrategy?.strategy?.nodes.map((node) => node.id) || []
      setFilteredNodeIds((current) => [...current, ...nodeIds])

      return result
    })
  }
})
