import { IconButton, Spinner } from '@chakra-ui/react'
import type { FC } from 'react'

import useDuplicateNodes from '@app/hooks/useDuplicateNodes'
import { usePermissionsContext } from '@app/pages/maps/permissionsContext'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  nodes: Parameters<typeof useDuplicateNodes>[0]
}

const BulkDuplicateButton: FC<Props> = ({ nodes }) => {
  const { duplicate, loading } = useDuplicateNodes(nodes)
  const { canEdit } = usePermissionsContext()

  if (!canEdit) {
    return null
  }

  const handleClick = () => {
    duplicate()
  }

  return (
    <IconButton borderRadius="none" aria-label="Duplicate cards" onClick={handleClick} size="sm" variant="outline">
      {loading ? <Spinner size="xs" /> : <ICON_MAP.Copy />}
    </IconButton>
  )
}

export default BulkDuplicateButton
