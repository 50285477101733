import type { FC, MutableRefObject, PropsWithChildren } from 'react'

import { Button } from '@app/components/ui/button'
import { DialogActionTrigger } from '@app/components/ui/dialog'

export type Props = {
  loading: boolean
  onConfirm: () => Promise<any> // eslint-disable-line @typescript-eslint/no-explicit-any
  onClose: () => void
  cancelRef: MutableRefObject<null>
}

const ConfirmPopoverFooter2Button: FC<PropsWithChildren<Props>> = ({
  children,
  loading,
  onConfirm,
  onClose,
  cancelRef
}) => {
  const confirm = () => onConfirm().then(onClose)

  return (
    <>
      <DialogActionTrigger asChild>
        <Button ref={cancelRef} disabled={loading} variant="outline">
          Cancel
        </Button>
      </DialogActionTrigger>
      <Button colorPalette="red" loading={loading} onClick={confirm}>
        {children}
      </Button>
    </>
  )
}

export default ConfirmPopoverFooter2Button
