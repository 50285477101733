import type { FC, MutableRefObject } from 'react'
import { useImperativeHandle, useState } from 'react'

import EventNamesInput from './eventNamesInput'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import ChartInput from '@app/pages/metrics/components/metricSource/amplitude/chartInput'
import type { MetricSourceFormApi } from '@app/types'
import type { MetricSource } from '@graphql/types'

export type Props = {
  metricSource: Pick<MetricSource, 'configuration'> | null
  credentialId: string
  disabled: boolean
  apiRef: MutableRefObject<MetricSourceFormApi>
}

enum DataSourceEnum {
  Event = 'event',
  Chart = 'chart'
}

const Amplitude: FC<Props> = ({ apiRef, disabled, credentialId, metricSource }) => {
  const defaultDataSource = metricSource?.configuration?.data_source || DataSourceEnum.Chart
  const [queryType, setQueryType] = useState<DataSourceEnum>(defaultDataSource)
  const defaultChart = metricSource?.configuration?.chart_url || metricSource?.configuration?.chart_id
  const [chart, setChart] = useState<string>(defaultChart)

  useImperativeHandle(
    apiRef,
    () => ({
      reset: () => {
        setQueryType(defaultDataSource)
        setChart(defaultChart)
      },
      clear: () => {
        setQueryType(DataSourceEnum.Chart)
        setChart('')
      }
    }),
    [defaultChart, defaultDataSource]
  )

  return (
    <>
      <Field label="Query type" disabled={disabled}>
        <NativeSelectRoot>
          <NativeSelectField
            name="configuration.data_source"
            data-testid="amplitude-configuration-data-source"
            placeholder={null}
            defaultValue={queryType}
            onChange={(e) => setQueryType(e.target.value as DataSourceEnum)}
          >
            <option value={DataSourceEnum.Event}>Event</option>
            <option value={DataSourceEnum.Chart}>Chart</option>
          </NativeSelectField>
        </NativeSelectRoot>
      </Field>
      {queryType === DataSourceEnum.Event && (
        <Field label="Event name" required disabled={disabled}>
          <EventNamesInput
            name="configuration.event"
            placeholder=""
            defaultValue={metricSource?.configuration?.event}
            credentialId={credentialId}
          />
        </Field>
      )}
      {queryType === DataSourceEnum.Chart && (
        <Field label="Chart URL" disabled={disabled} required>
          <ChartInput chart={chart} placeholder="Amplitude chart URL" onChange={(e) => setChart(e.target.value)} />
        </Field>
      )}
    </>
  )
}

export default Amplitude
