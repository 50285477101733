import { useShallow } from 'zustand/react/shallow'

import { useStore, getSelectedNodesSelector } from '@app/store'

type UseAreNodesSelected = (strategyId: string) => boolean

const useAreNodesSelected: UseAreNodesSelected = (strategyId) =>
  useStore(useShallow((state) => getSelectedNodesSelector(state, strategyId).length > 0))

export default useAreNodesSelected
