import capitalize from 'lodash/capitalize'
import { colors, animals, uniqueNamesGenerator } from 'unique-names-generator'

const generateRandomName = () =>
  capitalize(
    uniqueNamesGenerator({
      dictionaries: [colors, animals],
      separator: ' ',
      length: 2
    })
  )

export default generateRandomName
