import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'configuration'> | null
  disabled: boolean
}

enum StripeCalculation {
  MRR = 'mrr',
  ARR = 'arr',
  PAYING_CUSTOMERS = 'paying_customers',
  CANCELED_SUBSCRIPTIONS = 'canceled_subscriptions'
}

const Stripe: FC<Props> = ({ metricSource, disabled }) => (
  <Field label="Calculation" disabled={disabled}>
    <NativeSelectRoot>
      <NativeSelectField
        name="configuration.calculation"
        placeholder={null}
        defaultValue={metricSource?.configuration?.calculation}
      >
        <option value={StripeCalculation.MRR}>MRR</option>
        <option value={StripeCalculation.ARR}>ARR</option>
        <option value={StripeCalculation.PAYING_CUSTOMERS}>Paying Customers</option>
        <option value={StripeCalculation.CANCELED_SUBSCRIPTIONS}>Canceled Subscriptions</option>
      </NativeSelectField>
    </NativeSelectRoot>
  </Field>
)

export default Stripe
