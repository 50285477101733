import { StiggProvider } from '@stigg/react-sdk'
import { Suspense, useEffect } from 'react'
import { CloudimageProvider } from 'react-cloudimage-responsive'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import * as CookieConsent from 'vanilla-cookieconsent'

import VersionCheck from '@app/lib/versionCheck'
import CASLProvider from '@app/shared/authorization/caslProvider'
import StiggTheme from '@app/shared/billing/stiggTheme'
import { RegisterSentryUser } from '@app/shared/errorPage'
import Head from '@app/shared/layout/head'
import { initializeSentry, Sentry } from '@app/utils/sentry'
import GraphqlProvider from '@graphql/graphqlProvider'

import 'vanilla-cookieconsent/dist/cookieconsent.css'

const cloudimageConfig = {
  token: import.meta.env.PUBLIC_CLOUDIMAGE_TOKEN
}

const stiggApiKey = import.meta.env.PUBLIC_STIGG_CLIENT_API_KEY

initializeSentry()

interface CloudImageProviderProps {
  children: React.ReactNode
}

const CloudimageProviderFiltered = ({ children }: CloudImageProviderProps) => (
  <CloudimageProvider {...({ children } as Element)} config={cloudimageConfig} />
)

const Root = () => {
  useEffect(() => {
    CookieConsent.run({
      revision: 1, // increment this number to reset the cookie consent modal
      manageScriptTags: false,
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true // this category cannot be disabled
        },
        analytics: {}
      },

      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'We use cookies',
              description:
                'This website collects cookies to deliver better user experience. We will never sell your data.',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage Individual preferences'
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'Somebody said ... cookies?',
                  description: 'I want one!'
                },
                {
                  title: 'Strictly Necessary cookies',
                  description:
                    'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                  // this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: 'necessary'
                },
                // {
                //   title: 'Performance and Analytics',
                //   description:
                //     'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                //   linkedCategory: 'analytics'
                // },
                {
                  title: 'More information',
                  description:
                    'For any queries in relation to my policy on cookies and your choices, please <a href="mailto:privacy@doubleloop.app">contact us</a>'
                }
              ]
            }
          }
        }
      }
    })
  }, [])

  return (
    <GraphqlProvider>
      <StiggProvider
        apiKey={stiggApiKey}
        theme={StiggTheme() as unknown}
        customerId={window?.dlOrganizationId?.toString() || null}
      >
        <Suspense fallback={null}>
          <CloudimageProviderFiltered>
            <RegisterSentryUser />
            <Head />
            <CASLProvider>
              <Outlet />
              <VersionCheck />
            </CASLProvider>
          </CloudimageProviderFiltered>

          <ScrollRestoration />
        </Suspense>
      </StiggProvider>
    </GraphqlProvider>
  )
}

export default Sentry.withProfiler(Root)
