import type { NativeSelect } from '@chakra-ui/react'
import type { FC, Ref } from 'react'

import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import { RecurringReportConfigurationServiceEnum } from '@graphql/types'

type Props = Partial<NativeSelect.FieldProps> & { ref?: Ref<HTMLSelectElement> }

const ServiceSelect: FC<Props> = (props) => (
  <NativeSelectRoot>
    <NativeSelectField name="service" placeholder={null} {...props}>
      <option value={RecurringReportConfigurationServiceEnum.EmitReport}>Send an AI report</option>
      <option value={RecurringReportConfigurationServiceEnum.SendChangelog}>Send a changelog</option>
      <option value={RecurringReportConfigurationServiceEnum.SendGoals}>Send key results</option>
    </NativeSelectField>
  </NativeSelectRoot>
)

export default ServiceSelect
