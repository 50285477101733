import { HStack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import CardConfidenceRating from '@app/next/domainObjects/confidenceRating/cardConfidenceRating'
import cardFontSize from '@app/shared/cards/cardFontSize'
import type { CardSize, MapDomainStrategy } from '@app/types'

type Props = {
  strategy: MapDomainStrategy
  size?: CardSize
}

const CollapsedStrategy: FC<Props> = ({ strategy, size = 'normal' }) => {
  const { name, confidenceRating } = strategy
  const fontSize = cardFontSize('md', size)

  return (
    <HStack>
      {confidenceRating && <CardConfidenceRating type={confidenceRating} />}
      <Text fontSize={fontSize} fontWeight="semibold">
        {name}
      </Text>
    </HStack>
  )
}

export default CollapsedStrategy
