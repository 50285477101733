import type { Dispatch, FC, PropsWithChildren } from 'react'
import { createContext, useContext, useMemo, useReducer } from 'react'

import type { BulkLabelableObject } from './bulkLabelsPopover'

type BulkLabelsContextCheckboxState = 'checked' | 'unchecked' | 'indeterminate'

type BulkLabelsContextState = {
  labels: Record<string, BulkLabelsContextCheckboxState>
}

type BulkLabelsContextValue = BulkLabelsContextState & {
  dispatch: Dispatch<BulkLabelsContextAction>
}

const BulkLabelsContext = createContext<BulkLabelsContextValue>({
  labels: {},
  dispatch: () => {}
})

type BulkLabelsContextAction = { type: 'check' | 'uncheck'; payload: string }
const reducer = (state: BulkLabelsContextState, action: BulkLabelsContextAction): BulkLabelsContextState => {
  if (action.type === 'check') {
    return {
      ...state,
      labels: {
        ...state.labels,
        [action.payload]: 'checked'
      }
    }
  }

  if (action.type === 'uncheck') {
    return {
      ...state,
      labels: {
        ...state.labels,
        [action.payload]: 'unchecked'
      }
    }
  }

  return state
}

type Props = {
  domainObjects: BulkLabelableObject[]
}
const BulkLabelsContextProvider: FC<PropsWithChildren<Props>> = ({ children, domainObjects }) => {
  const init = () => {
    const categorized = domainObjects.reduce(
      (acc, object) => {
        object.labels.forEach((label) => {
          acc[label.name] ||= []

          acc[label.name].push(object)
        })

        return acc
      },
      {} as Record<string, BulkLabelableObject[]>
    )

    const labels = Object.entries(categorized).reduce(
      (acc, [key, objects]) => {
        const defaultChecked = objects.length === domainObjects.length
        const isIndeterminate = !defaultChecked && objects.length > 0
        if (isIndeterminate) {
          acc[key] = 'indeterminate'
        } else if (defaultChecked) {
          acc[key] = 'checked'
        } else {
          acc[key] = 'unchecked'
        }

        return acc
      },
      {} as Record<string, BulkLabelsContextCheckboxState>
    )

    return {
      labels
    }
  }

  const [state, dispatch] = useReducer<typeof reducer, BulkLabelsContextState>(reducer, null, init)
  const value = useMemo(
    () => ({
      ...state,
      dispatch
    }),
    [dispatch, state]
  )

  return <BulkLabelsContext.Provider value={value}>{children}</BulkLabelsContext.Provider>
}

const useBulkLabelsContext = () => {
  const context = useContext(BulkLabelsContext)

  if (context === undefined) {
    throw new Error('useBulkLabelContext must be used within BulkLabelsContext.Provider')
  }

  return context
}

export { BulkLabelsContextProvider, useBulkLabelsContext }
