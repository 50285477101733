import { getObjectById, useStore } from '@app/store'
import type { Strategy } from '@graphql/types'

type UseIsAnonymousUser = (strategyId?: string) => boolean
const useIsAnonymousUser: UseIsAnonymousUser = (strategyId) => {
  const { user } = useStore.use.currentUser()
  const strategy = useStore((state) => (strategyId ? getObjectById(state, 'strategy', strategyId) : null)) as Strategy

  // If we aren't looking at a map but there's a user, we're not anonymous
  if (user && !strategy) {
    return false
  }

  // If we don't have a user, have already failed the first check, and don't have a strategy, we're anonymous
  if (!user || !strategyId || !strategy) {
    return true
  }

  // If we have a user and a strategy, but they're from different organizations, we're anonymous
  return user.organization?.id !== strategy?.organization?.id
}

export default useIsAnonymousUser
