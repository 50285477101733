import type { RouteObject } from 'react-router-dom'

import Tasks from '@app/pages/tasks'

const routes: RouteObject = {
  path: 'tasks',
  element: <Tasks />
}

export default routes
