import { Separator } from '@chakra-ui/react'
import type { FC } from 'react'
import { SiGoogleanalytics } from 'react-icons/si'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import GoogleIntegrationButton from '@app/pages/settings/integrations/components/google/googleIntegrationButton'
import LimitedUseDisclosure from '@app/pages/settings/integrations/components/google/limitedUseDisclosure'
import PropertyImports from '@app/pages/settings/integrations/components/google/propertyImports'
import IntegrationConnection from '@app/pages/settings/integrations/components/integrationConnection'
import PageHeader from '@app/shared/layout/pageHeader'
import PageStack from '@app/shared/layout/pageStack'
import withAwait from '@app/shared/withAwait'
import { Routes } from '@app/utils/routeHelpers'
import type { GoogleIntegration } from '@graphql/types'
import { IntegrationSourceNamesEnum } from '@graphql/types'

const Google: FC = () => {
  const googleIntegration = useGetObjectsByProperties('integration', {
    sourceName: IntegrationSourceNamesEnum.Google
  })[0] as GoogleIntegration

  return (
    <PageStack>
      <PageHeader
        title="Google Analytics"
        subtitle="Get daily users, sessions, session duration, new visitors, and bounce rate from Google Analytics"
      />
      <IntegrationConnection
        name="Google"
        icon={<SiGoogleanalytics />}
        items="Analytics"
        integration={googleIntegration}
        connectUrl={Routes.googleAuthentication}
        customConnectButton={GoogleIntegrationButton}
      >
        <>
          <Separator pt={4} />
          <LimitedUseDisclosure p={4} />
        </>
      </IntegrationConnection>
      <PropertyImports integration={googleIntegration} />
    </PageStack>
  )
}

export default withAwait(Google, 'integration')
