import { Icon, IconButton, Input } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useRef, memo } from 'react'
import { FiXCircle, FiSearch } from 'react-icons/fi'

import type { InputGroupProps } from '@app/components/ui/input-group'
import { InputGroup } from '@app/components/ui/input-group'
import { useListFilterContext } from '@app/pages/shared/listFilterContext'

interface Props extends Omit<InputGroupProps, 'children'> {
  field: string
  placeholder?: string
}

const FieldSearchInput: FC<Props> = ({ ...rest }) => {
  const { listFilter: defaultValue, setListFilter } = useListFilterContext()
  const ref = useRef<HTMLInputElement>()

  useEffect(() => {
    if (ref?.current) {
      ref.current.value = defaultValue
    }
  }, [defaultValue])

  const onChange = ({ target: { value } }) => {
    setListFilter(value)
  }

  const clearSearch = () => {
    if (ref?.current) {
      ref.current.value = ''
    }

    setListFilter(null)
  }

  const { placeholder, ...inputGroupProps } = rest

  return (
    <InputGroup
      {...inputGroupProps}
      startElement={
        <Icon>
          <FiSearch />
        </Icon>
      }
      endElement={
        <IconButton aria-label="Clear search" onClick={clearSearch} variant="plain">
          <Icon color="gray">
            <FiXCircle />
          </Icon>
        </IconButton>
      }
    >
      <Input
        ref={ref}
        autoFocus
        data-cy="field-search-input"
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder={placeholder}
      />
    </InputGroup>
  )
}

export default memo(FieldSearchInput)
