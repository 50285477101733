import type { MapCardFilterType } from './types'

import { CARD_FILTER_SIGIL } from '@app/lib/globals'
import type { NodeFiltersInput } from '@graphql/types'

export const isArrayFilter = (key: keyof NodeFiltersInput): boolean => {
  switch (key) {
    case 'labels':
      return true
    case 'name':
      return false
    default: {
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
      const exhaustiveCheck: never = key
      return exhaustiveCheck
    }
  }
}

export const paramsToInput = (searchParams: URLSearchParams): NodeFiltersInput => ({
  name: searchParams.get(`${CARD_FILTER_SIGIL}name`),
  labels: searchParams.getAll(`${CARD_FILTER_SIGIL}labels`)
})

export const filtersToInput = (filters: MapCardFilterType[]): NodeFiltersInput => ({
  name: filters.find((filter) => filter.name === 'name')?.value,
  labels: filters.reduce<string[]>((acc, filter) => {
    if (filter.name === 'labels') {
      acc.push(filter.value)
    }

    return acc
  }, [])
})

export const inputToFilters = (input: NodeFiltersInput) =>
  Object.entries(input).flatMap<MapCardFilterType>(([key, value]) => {
    const tKey = key as keyof NodeFiltersInput

    if (Array.isArray(value)) {
      return value.map((item) => ({
        name: tKey,
        value: item
      }))
    }

    return [{ name: tKey, value }]
  })
