import { useNavigate, useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import MetricForm from '@app/pages/metrics/components/metricForm'

const MetricFormTab = () => {
  const navigate = useNavigate()
  const { nodeId } = useParams()

  const metric = useGetObject(nodeId, 'metric')

  if (!metric) {
    return null
  }

  return (
    <MetricForm
      metric={metric}
      onSuccess={() => navigate('..')}
      onCancel={() => navigate('..')}
      onDelete={() => navigate('../..')}
    />
  )
}

export default MetricFormTab
