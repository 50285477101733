import { format, parse } from 'date-fns'
import { RRule } from 'rrule'

// Rails uses 0-6 where 0 is Sunday, 1 is Monday, etc.
// The Rails beginning of week is setup is Monday, but it still does 0-6
export const railsDayToJSDay = (day) => {
  const intDay = parseInt(day, 10)

  if (intDay === 0) {
    return '7'
  }

  return intDay.toString()
}

// All manipulations are based on Monday = 1
export const dayTimeRailsToJS = (day, time) => {
  const date = new Date()
  const wday = railsDayToJSDay(day)

  const parsedDate = parse(`${wday} ${time} +00`, 'i H:m x', date)
  // The parsed weekday could be before the passed in day due to timezones.
  // It also needs to be run back through railsDayToJSDay as getDay returns 0-6
  // and format requires 1-7
  const parsedWday = railsDayToJSDay(parsedDate.getDay())

  return {
    wday: parsedWday,
    day: format(parse(parsedWday, 'i', date), 'EEEE'),
    hour: format(parsedDate, 'h'),
    minute: format(parsedDate, 'mm'),
    ampm: format(parsedDate, 'aaa')
  }
}

// All manipulations are based on Monday = 1 and UTC
export const dayParamsToRails = (day, hour, minute, ampm) => {
  if (!day || !hour || !minute || !ampm) {
    return {
      wday: null,
      day: null,
      hour: null,
      minute: null,
      time: null
    }
  }

  const date = new Date()
  const wday = railsDayToJSDay(day) // Because we're calling date-fns parse, it needs to be 1-7, not 0-6

  const parsedDate = parse(`${wday} ${hour}:${minute} ${ampm}`, 'i h:m aaa', date)
  const hours = parsedDate.getUTCHours().toString()
  const minutes = parsedDate.getUTCMinutes().toString()

  return {
    wday,
    day: parsedDate.getUTCDay(), // Returns 0-6, the way Rails wants
    hour: hours,
    minute: minutes,
    time: `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
  }
}

export const getWeekDays = () => {
  const baseDate = new Date(2017, 0, 2) // Any given Monday
  const weekDays = {}

  for (let i = 1; i <= 7; i += 1) {
    weekDays[i] = baseDate.toLocaleDateString(undefined, { weekday: 'long' })
    baseDate.setDate(baseDate.getDate() + 1)
  }

  return weekDays
}

export const defaultIcalSchedule = (dateTimeInUserTimeZone) => {
  const dtstart = dateTimeInUserTimeZone(new Date())
  dtstart.setHours(9, 0, 0, 0) // 9am in user's timezone

  const rrule = new RRule({
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.FR],
    dtstart
  })

  return rrule.toString()
}

export const convert12To24 = (hours, ap) => {
  const parsed = parseInt(hours, 10)
  let h = parsed === 12 ? 0 : parsed

  if (ap === 'pm') {
    h += 12
  }

  return h
}

export const formatPeriodDate = (date) => format(new Date(`${date}T12:00:00`), 'eee, MM/dd/yy')
