import type { Dispatch } from 'react'
import { createContext, useContext } from 'react'

import type { MapCardFilterType } from './types'

import type { NodeFiltersInput } from '@graphql/types'

type ActiveFacetName = keyof NodeFiltersInput

export type MapCardFiltersModalContextType = {
  cardFilters: MapCardFilterType[]
  open: boolean
  onOpen: () => void
  onClose: () => void
  activeFacetName: ActiveFacetName | null
  setActiveFacetName: Dispatch<ActiveFacetName>
  onFilter: (field: keyof NodeFiltersInput, value: string | string[]) => void
}

const MapCardFiltersModalContext = createContext<MapCardFiltersModalContextType>({
  cardFilters: [],
  open: false,
  onOpen: () => {},
  onClose: () => {},
  activeFacetName: null,
  setActiveFacetName: () => {},
  onFilter: () => {}
})

const useMapCardFiltersModalContext = () => {
  const context = useContext(MapCardFiltersModalContext)

  if (context === undefined) {
    throw new Error('useMapCardFiltersModalContext must be used within MapCardFiltersModalContext.Provider')
  }

  return context
}

export { useMapCardFiltersModalContext }

export default MapCardFiltersModalContext
