import { HStack } from '@chakra-ui/react'
import queryString from 'query-string'
import type { FC } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { fetchLabels, labelToPickerItem } from './labelsAutocompleteShared'

import { Tag } from '@app/components/ui/tag'
import { useParameters } from '@app/hooks/useURLParams'
import Can from '@app/shared/authorization/can'
import { ChakraAsyncCreatableSelect } from '@app/shared/autocomplete/chakraAutocompletes'
import type { MapDomainEntity } from '@app/types'
import { Paths } from '@app/utils/routeHelpers'
import { useLabelDeleteMutation, useLabelCreateMutation } from '@graphql/queries'

export interface Props {
  entity: Pick<MapDomainEntity, 'id' | 'labels'>
  onChange?: (value?: unknown) => void
  onItemClick?: (value?: unknown) => void
  initialValues?: { value: string | number; label: string }[]
}

const EntityLabelsAutocomplete: FC<Props> = ({
  entity = { labels: [] },
  onChange = null,
  onItemClick = undefined,
  initialValues = null
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = queryString.parse(location.search)

  const [, createLabel] = useLabelCreateMutation()
  const [, deleteLabel] = useLabelDeleteMutation()

  const { setQueryParameter } = useParameters()

  const symmetricArrayDiff = (array1, array2) => [
    ...array1.filter((x) => !array2.includes(x)),
    ...array2.filter((x) => !array1.includes(x))
  ]

  const handleChange = (updatedLabels) => {
    const currentLabelNames = entity.labels.map((label) => label.name)
    const newLabelNames = updatedLabels.map((label) => label.label)
    const currentLabelIds = entity.labels.map((label) => label.id)
    const newLabelIds = updatedLabels.map((label) => label.value)

    const changedName = symmetricArrayDiff(currentLabelNames, newLabelNames)[0]
    const changedId = symmetricArrayDiff(currentLabelIds, newLabelIds)[0]

    if (newLabelNames.length > currentLabelNames.length) {
      createLabel({ input: { domainObject: { entityId: entity.id }, name: changedName } })
    } else if (newLabelNames.length < currentLabelNames.length) {
      deleteLabel({ input: { domainObject: { entityId: entity.id }, labelId: changedId } })
    }
  }

  let clickHandler: ((label) => void) | null = (label) => {
    const onEntitysPage = matchPath(Paths.entitiesPath(), location.pathname)

    if (!onEntitysPage) {
      navigate(`${Paths.entitiesPath()}?labels=${label}`)
    } else if (!searchParams.labels || !searchParams.labels.includes(label)) {
      setQueryParameter('labels', label)
    }
  }

  if (onItemClick === null) {
    clickHandler = null
  } else if (onItemClick) {
    clickHandler = onItemClick
  }

  return (
    <Can I="update" an="event" passThrough>
      {(allowed) => {
        if (allowed && clickHandler) {
          return (
            <ChakraAsyncCreatableSelect
              aria-label="entity-labels-autocomplete"
              placeholder="Labels..."
              onChange={onChange || handleChange}
              onItemClick={clickHandler}
              asyncFetch={(inputValue) => fetchLabels(inputValue)}
              value={entity?.labels?.map(labelToPickerItem)}
              initialValues={entity?.labels?.map(labelToPickerItem) || initialValues}
            />
          )
        }
        return <HStack wrap="wrap">{entity?.labels?.map((label) => <Tag key={label.id}>{label.name}</Tag>)}</HStack>
      }}
    </Can>
  )
}

export default EntityLabelsAutocomplete
