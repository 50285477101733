import isNull from 'lodash/isNull'
import omitBy from 'lodash/omitBy'
import type { RouteObject } from 'react-router-dom'
import { useParams, Navigate, Outlet, defer } from 'react-router-dom'

import List from '@app/pages/basicCards/list'
import Show from '@app/pages/basicCards/show'
import BasicCardDetails from '@app/pages/basicCards/tabs/basicCardDetails'
import Tabs from '@app/pages/basicCards/tabs/index'
import ImpactGraphs from '@app/pages/shared/impactGraphs/impactGraphs'
import { SharedEventRoutes } from '@app/routes/events'
import CommentsFetcher from '@app/shared/comments/commentsFetcher'
import extractOrderings from '@app/shared/utils/extractOrderings'
import { useStore } from '@app/store'
import { BasicCard, BasicCards } from '@graphql/documents/basic_card.graphql'
import type { BasicCardQuery, BasicCardsQuery, BasicCardsQueryVariables } from '@graphql/queries'

const loadBasicCards = async ({ request }) => {
  const url = new URL(request.url)
  const page = parseInt(url.searchParams.get('page'), 10) || 1
  const limit = parseInt(url.searchParams.get('limit'), 10) || null
  const filter = url.searchParams.get('filter')
  const strategyId = url.searchParams.get('strategyId')
  const order = extractOrderings(url.searchParams, ['cardTypeName', 'name'])
  const { loaderQuery } = useStore.getState()

  const variables: BasicCardsQueryVariables = omitBy({ page, limit, filter, order, strategyId }, isNull)

  const result = await loaderQuery<BasicCardsQuery, BasicCardsQueryVariables>(BasicCards, variables)
  const { addObjectPage } = useStore.getState()

  const { collection = [], metadata } = result.data.basicCards
  addObjectPage('basicCard', collection, metadata)

  return defer(result.data)
}

const loadBasicCard = async ({ params }) => {
  const { strategyId, nodeId } = params
  const { loaderQuery } = useStore.getState()

  const { data } = await loaderQuery<BasicCardQuery>(BasicCard, {
    id: nodeId,
    strategyId
  })

  return data
}

const basicCardShowTabRoutes = (namespace = null): RouteObject[] => [
  {
    path: ':nodeId/*',
    id: namespace ? `${namespace}-basicCard` : 'basicCard',
    element: <Show />,
    loader: loadBasicCard,
    children: [
      {
        index: true,
        element: <Navigate to="details" replace />
      },
      {
        element: <Tabs />,
        children: [
          {
            path: 'details',
            element: <BasicCardDetails />
          },
          {
            path: 'events',
            children: SharedEventRoutes('basicCard')
          },
          {
            path: 'impact',
            children: [
              {
                index: true,
                element: <ImpactGraphs nodeType="basicCard" />
              }
            ]
          },
          {
            path: 'comments',
            element: <CommentsFetcher commentableType="basicCard" />
          }
        ]
      }
    ]
  }
]

const Redirector = () => {
  const params = useParams()
  return <Navigate to={`/cards/${params['*']}`} replace />
}

const routes: RouteObject[] = [
  {
    path: 'cards',
    element: <Outlet />,
    children: [
      {
        index: true,
        loader: loadBasicCards,
        element: <List />
      },
      ...basicCardShowTabRoutes()
    ]
  },
  {
    path: 'basicCards/*',
    element: <Redirector />
  }
]

export { basicCardShowTabRoutes }

export default routes
