import { Group, Stack } from '@chakra-ui/react'
import type { ButtonProps, IconButtonProps, ConditionalValue } from '@chakra-ui/react'
import { type FC } from 'react'

import { Button } from '@app/components/ui/button'
import type { Color } from '@app/types'

interface Props extends Omit<ButtonProps, 'children'> {
  selectedColor: Color
  onColorSelect: (color: Color) => void
  open: boolean
  onOpen?: () => void
  onClose?: () => void
  children: FC<ButtonProps | IconButtonProps>
}

const ColorButton: FC<Props> = ({
  selectedColor,
  onColorSelect,
  open,
  onOpen = () => {},
  onClose = () => {},
  children,
  ...buttonProps
}) => {
  const buttonParams = (color: Color) => ({
    colorPalette: color || 'gray',
    variant: (color === selectedColor ? 'solid' : 'surface') as ConditionalValue<
      'outline' | 'solid' | 'subtle' | 'surface' | 'ghost' | 'plain'
    >,
    size: '2xs' as ConditionalValue<'sm' | 'md' | 'lg' | 'xl' | '2xl' | '2xs' | 'xs'>,
    onClick: () => (selectedColor === color ? onClose() : onColorSelect(color))
  })

  const Child = children

  return (
    <>
      {open && (
        <Stack pos="absolute" top="-150px" right="40px" p={3} bg="bg" borderRadius="sm">
          <Group>
            <Button {...buttonParams('red')}>Red</Button>
            <Button {...buttonParams('orange')}>Orange</Button>
            <Button {...buttonParams('yellow')}>Yellow</Button>
          </Group>
          <Group>
            <Button {...buttonParams('green')}>Green</Button>
            <Button {...buttonParams('teal')}>Teal</Button>
            <Button {...buttonParams('blue')}>Blue</Button>
          </Group>
          <Group>
            <Button {...buttonParams('cyan')}>Cyan</Button>
            <Button {...buttonParams('purple')}>Purple</Button>
            <Button {...buttonParams('pink')}>Pink</Button>
          </Group>
          <Button {...buttonParams('')}>None</Button>
        </Stack>
      )}
      <Child
        aria-label="Change color"
        variant={open ? 'solid' : 'surface'}
        onClick={() => (open ? onClose() : onOpen())}
        {...buttonProps}
      />
    </>
  )
}

export default ColorButton
