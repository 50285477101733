import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import type { FC, ReactNode } from 'react'

import { Avatar } from '@app/components/ui/avatar'
import useGetObject from '@app/hooks/useGetObject'
import type { MapDomainMessage } from '@app/types'
import profileImage from '@app/utils/profileImage'

type Props = Pick<MapDomainMessage, 'role'> & {
  messages: [string, MapDomainMessage, ReactNode][]
}

const MessageGroup: FC<Props> = ({ role, messages }) => {
  const messageData = messages[0][1]
  const propUser = useGetObject(messageData.userId, 'user')
  const user = messageData.user || propUser

  const timestamp = messages[messages.length - 1][1].updatedAt
  const dateTime = timestamp ? format(timestamp, 'MMMM do, yyy h:mm aaa') : null

  return (
    <HStack align="flex-start" gap="5">
      <Box pt="1">
        {role === 'assistant' ? (
          <Avatar bg="blue.500" name="Artificial Intelligence" size="sm" />
        ) : (
          <Avatar name={user?.name} size="sm" src={profileImage(user?.email)} />
        )}
      </Box>

      <Stack gap="1">
        <Text fontWeight="medium">
          {role === 'assistant' ? messageData.assistant?.name || 'Assistant' : user?.name}
        </Text>
        <Stack gap="2">
          {messages.map(([id, , node]) => (
            <Box key={id} color="fg.muted" lineHeight="tall">
              {node}
            </Box>
          ))}
        </Stack>
        {dateTime && (
          <Text color="fg.subtle" fontSize="xs">
            {dateTime}
          </Text>
        )}
      </Stack>
    </HStack>
  )
}

export default MessageGroup
