import type { FC } from 'react'
import { FcGoogle } from 'react-icons/fc'

import ProviderForm from './providerForm'

import Slack from '@app/images/slack'
import { Paths } from '@app/utils/routeHelpers'

const buttonTitle = (text, provider) => `${text} ${provider}`

interface Props {
  inviteCode?: string
  text?: string
}

const AuthProviders: FC<Props> = ({ inviteCode = null, text = 'Sign in with' }) => {
  const searchParams = inviteCode ? { search: `/?inviteCode=${inviteCode}` } : {}

  return (
    <>
      <ProviderForm
        key="google-provider"
        url={Paths.authPath({ provider: 'google_oauth2' }, searchParams)}
        buttonTitle={buttonTitle(text, 'Google')}
        buttonIcon={FcGoogle}
      />
      <ProviderForm
        key="slack-provider"
        url={Paths.authPath({ provider: 'slack' }, searchParams)}
        buttonTitle={buttonTitle(text, 'Slack')}
        buttonIcon={Slack}
      />
    </>
  )
}

export default AuthProviders
