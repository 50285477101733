import { Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams, Form } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { PasswordInput } from '@app/components/ui/password-input'
import FormAlert from '@app/next/forms/formAlert'
import useForm from '@app/next/forms/useForm'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'

const ResetPassword: FC = () => {
  const { passwordResetToken } = useParams()
  const { errors } = useForm({})

  return (
    <Stack gap="8" w="full" maxW="md" px={{ base: '4', md: '8' }} py={{ base: '12', md: '48' }}>
      <SessionHeader
        title="Reset your password"
        subtitle="Remembered your password?"
        linkText="Sign in"
        linkUrl="/sign_in"
      />

      <Stack gap="6">
        <Form id="reset-password-form" method="post">
          <VisuallyHidden asChild>
            <Input defaultValue={passwordResetToken} name="passwordResetToken" />
          </VisuallyHidden>
          <FormAlert description={errors?.global?.message} />
          <Stack gap="5">
            <Field label="New Password" invalid={!!errors?.password} required errorText={errors?.password?.message}>
              <PasswordInput name="password" placeholder="Enter new password" autoComplete="new-password" />
            </Field>
          </Stack>
        </Form>
        <Stack gap={4}>
          <Button form="reset-password-form" size="md" type="submit">
            Set new password
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ResetPassword
