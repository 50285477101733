import { Stack, Text, Spacer, Group, IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'

import ReportContent from './components/reportContent'

import useGetObject from '@app/hooks/useGetObject'
import DrawerHeader from '@app/pages/reports/components/drawerHeader'
import SendReportButton from '@app/pages/reports/components/shared/sendReportButton'
import Can from '@app/shared/authorization/can'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'

const Show: FC = () => {
  const { reportId } = useParams()

  const report = useGetObject(reportId, 'report')

  if (!report) {
    return null
  }

  const { updatedAt } = report
  const updated = new Date(updatedAt)

  return (
    <Stack gap={4} w="3xl" maxW="100%" mx="auto">
      <DrawerHeader px={4}>
        <IconButton aria-label="go back" asChild variant="ghost">
          <Link to="..">
            <FiArrowLeft />
          </Link>
        </IconButton>
        <Text color="subtle" fontSize="sm">
          Last updated on{' '}
          {updated.toLocaleDateString(undefined, {
            weekday: 'short',
            month: 'numeric',
            day: 'numeric',
            year: 'numeric'
          })}{' '}
          at {updated.toLocaleTimeString()}
        </Text>
        <Spacer />
        <Can I="update" an="strategy">
          <Group>
            <SendReportButton report={report} />
          </Group>
        </Can>
        <DrawerCloseButton variant="ghost" />
      </DrawerHeader>
      <ReportContent report={report} />
    </Stack>
  )
}

export default Show
