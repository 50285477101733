import { Stack, Text } from '@chakra-ui/react'

import { ClipboardIconButton, ClipboardInput, ClipboardLabel, ClipboardRoot } from '@app/components/ui/clipboard'
import { InputGroup } from '@app/components/ui/input-group'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { Paths } from '@app/utils/routeHelpers'

const JiraWebhook = () => {
  const { user } = useStoreCurrentUser()
  const webhookUrl = Paths.jiraWebhookUrlPath({ apiToken: user.organization.apiToken })

  return (
    <Stack gap="4">
      <Text color="error" fontSize="sm" fontWeight="bold">
        Important: you must add the webhook for your new data to be recorded in DoubleLoop.
      </Text>
      <Text fontSize="sm">To install the webhook copy this webhook:</Text>
      <ClipboardRoot value={webhookUrl}>
        <ClipboardLabel>Webhook URL</ClipboardLabel>
        <InputGroup width="full" endElement={<ClipboardIconButton me="-2" />}>
          <ClipboardInput readOnly value={webhookUrl} />
        </InputGroup>
      </ClipboardRoot>
    </Stack>
  )
}

export default JiraWebhook
