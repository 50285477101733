import Cookies from 'js-cookie'
import { useCallback, useMemo } from 'react'

type SetCookie = (value: string, options?: Cookies.CookieAttributes) => void
type RemoveCookie = (options?: Cookies.CookieAttributes) => void
type UseCookie = (name: string) => [string | undefined, SetCookie, RemoveCookie]

const useCookie: UseCookie = (name) => {
  const cookie = Cookies.get(name)

  const setCookie: SetCookie = useCallback((value, options = undefined) => Cookies.set(name, value, options), [name])

  const removeCookie: RemoveCookie = useCallback((options = undefined) => Cookies.remove(name, options), [name])

  return useMemo(() => [cookie, setCookie, removeCookie], [cookie, setCookie, removeCookie])
}

export default useCookie
