import { Icon, Link, useToken } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { BiUnlink, BiSync } from 'react-icons/bi'
import { SiGooglesheets } from 'react-icons/si'
import { useLocation } from 'react-router-dom'

import { MenuItem, MenuItemGroup } from '@app/components/ui/menu'
import { toaster } from '@app/components/ui/toaster'
import GoogleSheetsConnectModal from '@app/pages/metrics/components/googleSheetsConnectModal'
import Can from '@app/shared/authorization/can'
import { useGoogleSheetImportMutation, GoogleSheetStatusEnum, useGoogleSheetDeleteMutation } from '@graphql/queries'
import type { GoogleSheet } from '@graphql/types'

type Props = {
  googleSheet: GoogleSheet | null
}

const calculateButtonText = (status: GoogleSheetStatusEnum) => {
  switch (status) {
    case GoogleSheetStatusEnum.Importing:
      return 'Importing latest data...'
    case GoogleSheetStatusEnum.Exporting:
      return 'Exporting latest data...'
    case GoogleSheetStatusEnum.Queued:
      return 'Queued...'
    default:
      return 'Import latest data'
  }
}

const GoogleSheetsMenuGroup: FC<Props> = ({ googleSheet }) => {
  const [, googleSheetImport] = useGoogleSheetImportMutation()
  const [, googleSheetDelete] = useGoogleSheetDeleteMutation()
  const [loading, setLoading] = useState(false)

  const green = useToken('colors', 'green.500')
  const [open, setOpen] = useState(false)
  const location = useLocation()

  if (!googleSheet) {
    const oauthState = {
      redirect_to: location.pathname,
      type: 'organization' as const
    }

    return (
      <Can I="create" a="googleSheet">
        <GoogleSheetsConnectModal open={open} onOpenChange={(e) => setOpen(e.open)} state={oauthState} />
        <MenuItem value="connect_to_google_sheets" onClick={() => setOpen(true)}>
          <Icon color={green}>
            <SiGooglesheets />
          </Icon>
          Connect to Google Sheets
        </MenuItem>
      </Can>
    )
  }

  const { status } = googleSheet

  const disabled = [
    GoogleSheetStatusEnum.Importing,
    GoogleSheetStatusEnum.Exporting,
    GoogleSheetStatusEnum.Queued
  ].includes(status)

  const deleteSheet = () => {
    setLoading(true)

    googleSheetDelete({
      input: {
        googleSheetId: googleSheet.id
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const importSheet = () => {
    setLoading(true)

    googleSheetImport({
      input: {
        googleSheetId: googleSheet.id
      }
    }).finally(() => {
      setLoading(false)
    })

    toaster.create({
      title: 'Importing started',
      description: 'We are importing your data from Google Sheets. This may take a few minutes.'
    })
  }

  const buttonText = calculateButtonText(status)

  return (
    <MenuItemGroup title="Google Sheets">
      <MenuItem disabled={loading} value="open_linked_google_sheet">
        <SiGooglesheets />
        <Link href={googleSheet.spreadsheetUrl} target="_blank">
          Open Linked Google Sheet
        </Link>
      </MenuItem>
      <Can I="update" a="metric">
        <MenuItem value={buttonText} disabled={disabled} onClick={importSheet}>
          <BiSync />
          {buttonText}
        </MenuItem>
      </Can>
      <Can I="delete" a="googleSheet">
        <MenuItem value="Unlink" disabled={disabled} onClick={deleteSheet}>
          <BiUnlink />
          Unlink Google Sheet
        </MenuItem>
      </Can>
    </MenuItemGroup>
  )
}

export default GoogleSheetsMenuGroup
