import { Group, IconButton } from '@chakra-ui/react'
import type { FC } from 'react'

import type { MapCardFilterType } from './types'

import { Button } from '@app/components/ui/button'
import ICON_MAP from '@app/utils/iconMap'

export interface Props {
  filter: MapCardFilterType
  onRemoveClick: (filter: MapCardFilterType) => void
  canEdit: boolean
}

const MapCardFilter: FC<Props> = ({ filter, onRemoveClick, canEdit = false, ...rest }) => {
  const { name, value } = filter

  const title = `${name || '(none)'}: ${value || '(none)'}`

  return (
    <Group attached {...rest}>
      {canEdit && (
        <IconButton
          aria-label="Remove filter"
          onClick={() => {
            onRemoveClick(filter)
          }}
          size="2xs"
        >
          <ICON_MAP.Close />
        </IconButton>
      )}
      <Button userSelect="auto" pointerEvents="none" title={title} size="2xs">
        {title}
      </Button>
    </Group>
  )
}

export default MapCardFilter
