import { HStack, IconButton, Spacer } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiEdit2, FiTrash2 } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { Tooltip } from '@app/components/ui/tooltip'
import GoalDeleteButton from '@app/pages/metrics/components/goals/goalDeleteButton'
import Can from '@app/shared/authorization/can'
import type { Goal } from '@graphql/queries'

type Props = {
  goal: Pick<Goal, 'id'>
}

const GoalActions: FC<Props> = ({ goal }) => (
  <HStack data-testid="goal-actions">
    <Spacer />
    <Can I="update" a="goal">
      <Tooltip content="Edit key result">
        <IconButton aria-label="Edit key result" asChild variant="ghost">
          <Link to={`${goal.id}/edit`}>
            <FiEdit2 />
          </Link>
        </IconButton>
      </Tooltip>
    </Can>
    <Can I="delete" a="goal">
      <GoalDeleteButton goalId={goal.id}>
        <Tooltip content="Delete key result">
          <IconButton aria-label="Delete key result" variant="ghost">
            <FiTrash2 />
          </IconButton>
        </Tooltip>
      </GoalDeleteButton>
    </Can>
  </HStack>
)

export default GoalActions
