import { StrategyTypeEnum, ConfidenceRatingEnum } from '@graphql/queries'
import { TeamTypeEnum } from '@graphql/types'

export const strategyTypeOptionsFor = (type) => {
  if (type === 'metric') {
    return (
      <>
        <option value={StrategyTypeEnum.Kpi}>KPI</option>
        <option value={StrategyTypeEnum.NorthStar}>North Star</option>
        <option value={StrategyTypeEnum.Input}>Input</option>
        <option value={StrategyTypeEnum.Diagnostic}>Diagnostic</option>
      </>
    )
  }

  return (
    <>
      <option value={TeamTypeEnum.Product}>Product</option>
      <option value={TeamTypeEnum.Marketing}>Marketing</option>
      <option value={TeamTypeEnum.Sales}>Sales</option>
      <option value={TeamTypeEnum.Engineering}>Engineering</option>
      <option value={TeamTypeEnum.Support}>Support</option>
    </>
  )
}

export const domainObjectConfidenceRatingOptionsFor = () => (
  <>
    <option value={ConfidenceRatingEnum.OnTrack}>On Track</option>
    <option value={ConfidenceRatingEnum.AtRisk}>At Risk</option>
    <option value={ConfidenceRatingEnum.OffTrack}>Off Track</option>
  </>
)

export default strategyTypeOptionsFor
