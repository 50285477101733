import { useShallow } from 'zustand/react/shallow'

import { useStore, getSelectedNodesSelector } from '@app/store'
import type { MapDomainNode } from '@app/types'

type UseGetSelectedNodes = (strategyId: string) => MapDomainNode[]

const useGetSelectedNodes: UseGetSelectedNodes = (strategyId) =>
  useStore(useShallow((state) => getSelectedNodesSelector(state, strategyId)))

export default useGetSelectedNodes
