import { useShallow } from 'zustand/react/shallow'

import { useStore, getObjectsByProperties } from '@app/store'
import type { StoreDomainObjectKeys } from '@app/store/types'

const useGetObjectsByProperties = <T extends StoreDomainObjectKeys>(
  type: T,
  properties: Parameters<typeof getObjectsByProperties<T>>[2]
): ReturnType<typeof getObjectsByProperties<T>> =>
  useStore(useShallow((state) => getObjectsByProperties<T>(state, type, properties)))

export default useGetObjectsByProperties
