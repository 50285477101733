import { useShallow } from 'zustand/react/shallow'

import { useStore, getObjectById } from '@app/store'
import type { BoundedState } from '@app/store/types'

const useGetObject = <T extends keyof BoundedState>(
  id: string,
  type: T
): ReturnType<typeof getObjectById<T>> | undefined => useStore(useShallow((state) => getObjectById<T>(state, type, id)))

export default useGetObject
