import type { TextProps } from '@chakra-ui/react'
import { HStack, Icon, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import { PiCaretDown } from 'react-icons/pi'

import { PopoverRoot, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow } from '@app/components/ui/popover'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import { useStore } from '@app/store'
import { basicCardDisplayName } from '@app/utils/basicCardHelpers'
import type { BasicCard } from '@graphql/types'
import { TeamTypeEnum } from '@graphql/types'

interface Props extends TextProps {
  basicCard: Pick<BasicCard, 'id' | 'teamType'>
}

const BasicCardTeamPopover: FC<Props> = ({ basicCard, ...rest }) => {
  const { user } = useStoreCurrentUser()
  const initialFocusRef = useRef()
  const [open, setOpen] = useState(false)
  const updateObject = useStore.use.updateObject()
  const { id, teamType } = basicCard

  const displayType = basicCardDisplayName(teamType)

  if (!['admin', 'editor'].includes(user?.role)) {
    return <Text {...rest}>{displayType}</Text>
  }

  const handleItemClick = (value: string) => {
    updateObject({ basicCard: { id, teamType: value as TeamTypeEnum } })

    setOpen(!open)
  }

  return (
    <PopoverRoot initialFocusEl={initialFocusRef.current} lazyMount onOpenChange={(e) => setOpen(e.open)} open={open}>
      <PopoverTrigger>
        <HStack cursor="pointer" onClick={() => setOpen(!open)}>
          <Text {...rest}>{displayType}</Text>
          <Icon>
            <PiCaretDown />
          </Icon>
        </HStack>
      </PopoverTrigger>
      <PopoverContent maxW={40}>
        <PopoverArrow />
        <PopoverBody m={0} p={0}>
          <SearchList
            searchField="team"
            currentValue={teamType}
            initialFocusRef={initialFocusRef}
            onChange={handleItemClick}
          >
            <SearchListItem text="Product" value={TeamTypeEnum.Product} />
            <SearchListItem text="Marketing" value={TeamTypeEnum.Marketing} />
            <SearchListItem text="Sales" value={TeamTypeEnum.Sales} />
            <SearchListItem text="Engineering" value={TeamTypeEnum.Engineering} />
            <SearchListItem text="Support" value={TeamTypeEnum.Support} />
          </SearchList>
        </PopoverBody>
      </PopoverContent>
    </PopoverRoot>
  )
}

export default BasicCardTeamPopover
