import { type FC, useRef, useState } from 'react'
import { useRevalidator } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { useMetricDeleteAllMetricDataPointsMutation } from '@graphql/queries'

interface Props {
  metric: {
    id: string
  }
}

const MetricDataPointsClearButton: FC<Props> = ({ metric, ...buttonProps }) => {
  const { user } = useStoreCurrentUser()
  const revalidator = useRevalidator()
  const [open, setOpen] = useState(false)
  const cancelRef = useRef()
  const [, deleteDataPoints] = useMetricDeleteAllMetricDataPointsMutation()

  const { id } = metric

  if (!user) {
    return null
  }

  const onSubmit = () =>
    deleteDataPoints({ input: { metricId: id } }).then(() => {
      setOpen(false)
      revalidator.revalidate()
    })

  return (
    <>
      <Button aria-label="Clear data points" onClick={() => setOpen(true)} {...buttonProps} variant="plain">
        Clear data points
      </Button>

      <DialogRoot
        initialFocusEl={cancelRef?.current}
        onOpenChange={(e) => setOpen(e.open)}
        open={open}
        placement="center"
        role="alertdialog"
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Clear data points</DialogTitle>
          </DialogHeader>

          <DialogBody>
            Are you sure you want to delete all the data for this metric? This can&apos;t be undone.
          </DialogBody>

          <DialogFooter>
            <DialogActionTrigger asChild>
              <Button ref={cancelRef} variant="outline">
                Cancel
              </Button>
            </DialogActionTrigger>
            <Button ml={3} bgColor="error" onClick={onSubmit}>
              OK
            </Button>
          </DialogFooter>
          <DialogCloseTrigger />
        </DialogContent>
      </DialogRoot>
    </>
  )
}

export default MetricDataPointsClearButton
