import { IconButton } from '@chakra-ui/react'
import { type FC } from 'react'
import { useState } from 'react'

import useGetSelectedNodes from '@app/hooks/useGetSelectedNodes'
import ColorButton from '@app/pages/shared/colorButton'
import { useStore } from '@app/store'
import type { Color } from '@app/types'
import ICON_MAP from '@app/utils/iconMap'

type Props = {
  strategyId: string
}

const BulkColorButton: FC<Props> = ({ strategyId }) => {
  const updateNodes = useStore.use.updateNodes()
  const nodes = useGetSelectedNodes(strategyId)
  const [open, setOpen] = useState(false)

  const changeColor = async (color: Color) => {
    await updateNodes(
      strategyId,
      nodes.map((node) => ({
        ...node,
        metadata: {
          ...node.metadata,
          color
        }
      }))
    )
    setOpen(false)
  }

  return (
    <ColorButton
      selectedColor=""
      onColorSelect={changeColor}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      {(props) => (
        <IconButton aria-label="color-button" {...props} borderRadius="none" size="sm" variant="outline">
          <ICON_MAP.ColorAction />
        </IconButton>
      )}
    </ColorButton>
  )
}

export default BulkColorButton
