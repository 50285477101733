import { Link } from '@chakra-ui/react'
import type { FC, MutableRefObject } from 'react'
import { useImperativeHandle, useState } from 'react'
import { PiArrowSquareOut } from 'react-icons/pi'
import { SiGooglesheets } from 'react-icons/si'
import { useLocation } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import useGetObject from '@app/hooks/useGetObject'
import GoogleSheetsConnectModal from '@app/pages/metrics/components/googleSheetsConnectModal'
import type { MetricSourceFormApi } from '@app/types'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'id' | 'configuration' | 'googleSheet'> | null
  disabled: boolean
  apiRef: MutableRefObject<MetricSourceFormApi>
  metricId: string
}

const GoogleSheets: FC<Props> = ({ apiRef, metricSource, disabled, metricId }) => {
  const location = useLocation()

  const [open, setOpen] = useState(false)

  const googleSheetId = metricSource?.googleSheet?.id
  const googleSheet = useGetObject(googleSheetId, 'googleSheet')

  useImperativeHandle(
    apiRef,
    () => ({
      isValid: () => !!googleSheet?.id
    }),
    [googleSheet?.id]
  )

  const modalState = {
    redirect_to: location.pathname,
    metric_id: metricId,
    type: 'source' as const
  }

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(true)
  }

  return (
    <>
      <GoogleSheetsConnectModal open={open} onOpenChange={(e) => setOpen(e.open)} state={modalState} />
      {googleSheet && (
        <Button>
          <Link href={googleSheet?.spreadsheetUrl} rel="onoopener noreferrer" target="_blank">
            Open Google Sheet <PiArrowSquareOut />
          </Link>
        </Button>
      )}
      {!googleSheet && (
        <Button disabled={disabled} onClick={onClick} type="button">
          <SiGooglesheets />
          Connect
        </Button>
      )}
    </>
  )
}

export default GoogleSheets
