import type { useCheckboxGroup } from '@chakra-ui/react'
import { Center, Table } from '@chakra-ui/react'
import isEqual from 'lodash/isEqual'
import type { FC, MouseEventHandler } from 'react'
import { useRef } from 'react'

import type { CheckboxProps } from '@app/components/ui/checkbox'
import { Checkbox } from '@app/components/ui/checkbox'
import useGetObjectPage from '@app/hooks/useGetObjectPage'

type SelectAllCheckboxHeaderProps = {
  collectionType: Parameters<typeof useGetObjectPage>[0]
  selected: ReturnType<typeof useCheckboxGroup>['value']
  setSelected: ReturnType<typeof useCheckboxGroup>['setValue']
}

const SelectAllCheckboxHeader: FC<SelectAllCheckboxHeaderProps> = ({ collectionType, selected, setSelected }) => {
  const { collection } = useGetObjectPage(collectionType)
  const ref = useRef(null)

  const checked = isEqual(selected.sort(), collection.map((mdo) => mdo.id).sort())
  const onCheckedChange: CheckboxProps['onCheckedChange'] = (_details) => {
    if (ref.current.checked) {
      setSelected([])
    } else {
      setSelected(collection.map((mdo) => mdo.id))
    }
  }

  const headerOnClick: MouseEventHandler = (e) => {
    e.stopPropagation()
    onCheckedChange(null)
  }

  return (
    <Table.ColumnHeader onClick={headerOnClick}>
      <Center>
        <Checkbox ref={ref} checked={checked} onCheckedChange={onCheckedChange} />
      </Center>
    </Table.ColumnHeader>
  )
}

export default SelectAllCheckboxHeader
