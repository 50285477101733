import { Text } from '@chakra-ui/react'
import type { FC } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { RoleEnum } from '@graphql/queries'
import type { Account } from '@graphql/types'

interface Props {
  data: Account
}

const RoleCell: FC<Props> = ({ data: account }) => {
  const { user } = useStoreCurrentUser()
  const { role, disabledAt, organizationId } = account

  let roleText = 'Viewer'
  let roleColor = 'fg'

  if (role === RoleEnum.Editor) {
    roleText = 'Editor'
  } else if (role === RoleEnum.Admin) {
    roleText = 'Admin'
  }

  if (disabledAt) {
    roleColor = 'fg.subtle'
  } else if (user?.organization?.id === organizationId) {
    roleColor = 'colorPalette.fg'
  }

  return (
    <Text color={roleColor} fontSize="sm" fontWeight={user?.organization?.id === organizationId ? 'semibold' : ''}>
      {roleText}
    </Text>
  )
}

export default RoleCell
