import type { FC } from 'react'

import ConfirmPopover from '@app/shared/confirmPopover'
import { useAccountUpdateMutation } from '@graphql/queries'
import type { Account } from '@graphql/types'

interface Props {
  account: Account
  children?: React.ReactNode
}

const AccountAccessToggleButton: FC<Props> = ({ account, children = null }) => {
  const [, updateAccount] = useAccountUpdateMutation()

  const toggleAccess = async (disabled: boolean) => {
    await updateAccount({
      input: {
        accountId: account.id,
        disabled
      }
    })
  }

  return (
    <ConfirmPopover
      onConfirm={() => toggleAccess(!account.disabledAt)}
      header="Disable account"
      body={`Are you sure you'd like to ${account.disabledAt ? 'enable' : 'disable'} this account?`}
      confirmButtonText="Confirm"
    >
      {children}
    </ConfirmPopover>
  )
}

export default AccountAccessToggleButton
