import delay from 'lodash/delay'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'

import MapSegmentFiltersModalContext from './mapSegmentFiltersModalContext'
import SegmentFilters from './segmentFilters'

import { DialogBody, DialogContent, DialogRoot } from '@app/components/ui/dialog'
import type { SegmentFilter, SegmentName, SegmentValue } from '@graphql/types'

interface Props {
  strategyId: string
  segmentFilters?: (Pick<SegmentFilter, 'id'> & {
    segmentName: Pick<SegmentName, 'id' | 'name'>
    segmentValue: Pick<SegmentValue, 'id' | 'value'>
  })[]
  open?: boolean
  onOpen?: () => void
  onOpenChange?: (_e) => void
  onFilter?: (segmentFilter: { id: string; value: string; active: boolean }) => void
}

const SegmentFiltersModal: FC<Props> = ({
  strategyId,
  segmentFilters = [],
  open,
  onOpen = () => {},
  onOpenChange = () => {},
  onFilter = () => {}
}) => {
  const [activeSegmentName, setActiveSegmentName] = useState(null)

  const handleClose = useCallback(
    (e) => {
      onOpenChange(e)
      delay(() => {
        setActiveSegmentName(null)
      }, 500)
    },
    [onOpenChange]
  )

  const providerValue = useMemo(
    () => ({
      segmentFilters,
      open,
      onOpen,
      onClose: handleClose,
      activeSegmentName,
      setActiveSegmentName,
      onFilter
    }),
    [segmentFilters, open, onOpen, handleClose, activeSegmentName, onFilter]
  )

  return (
    <MapSegmentFiltersModalContext.Provider value={providerValue}>
      <DialogRoot onOpenChange={handleClose} open={open} closeOnInteractOutside closeOnEscape>
        <DialogContent shadow="dark-lg">
          <DialogBody p={0}>
            <SegmentFilters strategyId={strategyId} />
          </DialogBody>
        </DialogContent>
      </DialogRoot>
    </MapSegmentFiltersModalContext.Provider>
  )
}

export default SegmentFiltersModal
