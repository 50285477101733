import type { TextProps } from '@chakra-ui/react'

import type { CardSize } from '@app/types'

type CardFontSize = (defaultFontSize: TextProps['fontSize'], cardSize: CardSize) => TextProps['fontSize']
const cardFontSize: CardFontSize = (defaultFontSize, cardSize) => {
  if (cardSize === 'normal') {
    return defaultFontSize
  }

  if (cardSize === 'small') {
    return 'sm'
  }

  switch (defaultFontSize) {
    case 'xs':
      return 'lg'
    case 'sm':
      return 'xl'
    case 'md':
      return '2xl'
    case 'lg':
      return '3xl'
    case 'xl':
      return '4xl'
    default:
      if (typeof defaultFontSize === 'number') {
        return defaultFontSize * 2
      }

      return defaultFontSize
  }
}

export default cardFontSize
