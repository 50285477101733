import { HStack, Icon, Input, Link, Stack, Table, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams } from 'react-router-dom'

import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import useGetObject from '@app/hooks/useGetObject'
import BasicCardTeamTypePopover from '@app/next/basicCardTeamTypePopover'
import ConfidenceRatingPopover from '@app/next/confidenceRatingPopover'
import DomainObjectStatusPopover from '@app/next/domainObjectStatusPopover'
import LabelsInput from '@app/next/forms/inline/labelsInput'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import InlineUpdaterRoot from '@app/next/forms/inlineUpdaterRoot'
import UrlInput from '@app/next/forms/urlInput'
import OwnerPopover from '@app/next/ownerPopover'
import DetailsTable from '@app/pages/shared/detailsTable'
import Can from '@app/shared/authorization/can'
import ICON_MAP from '@app/utils/iconMap'

interface Props {}

const BasicCardDetails: FC<Props> = () => {
  const { nodeId } = useParams()
  const basicCard = useGetObject(nodeId, 'basicCard')
  const { externalUrl, aiGenerated, ownerId } = basicCard
  const owner = useGetObject(ownerId, 'user')

  return (
    <Stack gap={4} p={4}>
      <DetailsTable>
        <Table.Body>
          <ViewerFieldWrapper value={!!externalUrl}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">
                {externalUrl ? (
                  <Link
                    color="link"
                    fontSize="sm"
                    whiteSpace="pre-wrap"
                    wordBreak="break-all"
                    href={externalUrl}
                    rel="onoopener noreferrer"
                    target="_blank"
                  >
                    External URL
                  </Link>
                ) : (
                  <Text>External URL</Text>
                )}
              </Table.Cell>
              <Table.Cell>
                <InlineUpdaterRoot domainObject={basicCard}>
                  <UrlInput bg="bg" defaultValue={externalUrl || ''} name="externalUrl" placeholder="External url..." />
                </InlineUpdaterRoot>
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <ViewerFieldWrapper value={basicCard.externalUrlTitle}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">External URL title</Table.Cell>
              <Table.Cell>
                <InlineUpdaterRoot domainObject={basicCard}>
                  <Input
                    defaultValue={basicCard.externalUrlTitle || ''}
                    name="externalUrlTitle"
                    placeholder="See more..."
                  />
                </InlineUpdaterRoot>
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <ViewerFieldWrapper value={basicCard.dueDate}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">Due date</Table.Cell>
              <Table.Cell>
                <InlineUpdaterRoot domainObject={basicCard}>
                  <Input defaultValue={basicCard.dueDate || ''} name="dueDate" type="date" />
                </InlineUpdaterRoot>
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <ViewerFieldWrapper value={owner}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">Owner</Table.Cell>
              <Table.Cell>
                <OwnerPopover object={basicCard} showName />
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <ViewerFieldWrapper value={basicCard.teamType}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">Team</Table.Cell>
              <Table.Cell>
                <HStack>
                  <Icon color="fg.muted">
                    <ICON_MAP.Team />
                  </Icon>
                  <BasicCardTeamTypePopover basicCard={basicCard} />
                </HStack>
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <ViewerFieldWrapper value={basicCard.labels}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">Labels</Table.Cell>
              <Table.Cell>
                <LabelsInput domainObject={basicCard} name="labels" />
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <ViewerFieldWrapper value={typeof basicCard.confidenceRating === 'number'}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">Confidence</Table.Cell>
              <Table.Cell>
                <ConfidenceRatingPopover domainObject={basicCard} />
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <ViewerFieldWrapper value={basicCard.status}>
            <Table.Row>
              <Table.Cell fontWeight="semibold">Status</Table.Cell>
              <Table.Cell>
                <DomainObjectStatusPopover domainObject={basicCard} />
              </Table.Cell>
            </Table.Row>
          </ViewerFieldWrapper>
          <Can I="view" an="admin">
            <Table.Row>
              <Table.Cell fontWeight="semibold">AI Generated</Table.Cell>
              <Table.Cell>
                <NativeSelectRoot>
                  <InlineUpdaterRoot domainObject={basicCard}>
                    <NativeSelectField defaultValue={aiGenerated?.toString()} name="aiGenerated" placeholder={null}>
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </NativeSelectField>
                  </InlineUpdaterRoot>
                </NativeSelectRoot>
              </Table.Cell>
            </Table.Row>
          </Can>
        </Table.Body>
      </DetailsTable>
    </Stack>
  )
}

export default BasicCardDetails
