import { Icon, Input, Link, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiHelpCircle } from 'react-icons/fi'

import { Field } from '@app/components/ui/field'
import type { Credential } from '@graphql/types'

type Props = {
  credential?: Pick<Credential, 'apiKey'>
}

const StripeAuth: FC<Props> = ({ credential = {} }) => (
  <Stack>
    <Field
      required
      label={
        <>
          <Text display="inline">API key </Text>
          <Link
            verticalAlign="middle"
            color="link"
            href="https://stripe.com/docs/keys"
            rel="onoopener noreferrer"
            target="_blank"
          >
            <Icon boxSize="4">
              <FiHelpCircle />
            </Icon>
          </Link>
        </>
      }
    >
      <Input
        autoComplete="off"
        data-1p-ignore
        defaultValue={credential?.apiKey}
        name="apiKey"
        placeholder="Stripe API Key"
      />
    </Field>
  </Stack>
)

export default StripeAuth
