import { Group, Stack } from '@chakra-ui/react'
import type { FC, FormEventHandler } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-router-dom'

import { useMapCardFiltersModalContext } from './mapCardFiltersModalContext'

import { Button } from '@app/components/ui/button'
import type { Props as LabelAutocompleteInputProps } from '@app/next/forms/autocomplete/labelAutocompleteInput'
import LabelAutocompleteInput from '@app/next/forms/autocomplete/labelAutocompleteInput'

type Props = {
  defaultValue: string[]
}

const FilterCardLabels: FC<Props> = ({ defaultValue }) => {
  const { onFilter, setActiveFacetName, onClose } = useMapCardFiltersModalContext()
  const [labels, setLabels] = useState(defaultValue)

  const onCancel = useCallback(() => {
    setActiveFacetName(null)
  }, [setActiveFacetName])

  const onChange: LabelAutocompleteInputProps['onChange'] = useCallback((selectedLabels) => {
    setLabels(selectedLabels.map((label) => label.label))
  }, [])

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()
      onFilter('labels', labels)
      onClose()
    },
    [labels, onFilter, onClose]
  )

  const defaultObjects = useMemo(() => defaultValue.map((label) => ({ id: label, name: label })), [defaultValue])

  return (
    <Form onSubmit={onSubmit}>
      <Stack px={4} py={2}>
        <LabelAutocompleteInput
          name="labels"
          label="Labels"
          defaultValue={defaultObjects}
          onChange={onChange}
          menuPortalTarget={document.body}
        />
        <Group justifyContent="end">
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </Group>
      </Stack>
    </Form>
  )
}

export default FilterCardLabels
