import { Link as ChakraLink, Stack, Text } from '@chakra-ui/react'

import { ClipboardIconButton, ClipboardInput, ClipboardLabel, ClipboardRoot } from '@app/components/ui/clipboard'
import { InputGroup } from '@app/components/ui/input-group'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { Paths } from '@app/utils/routeHelpers'

const ShortcutWebhook = () => {
  const { user } = useStoreCurrentUser()
  const webhookUrl = Paths.shortcutWebhookUrlPath({ apiToken: user.organization.apiToken })

  return (
    <Stack gap="4">
      <Text fontSize="sm">To install the webhook copy this webhook:</Text>
      <ClipboardRoot value={webhookUrl}>
        <ClipboardLabel>Share URL</ClipboardLabel>
        <InputGroup width="full" endElement={<ClipboardIconButton me="-2" />}>
          <ClipboardInput readOnly value={webhookUrl} />
        </InputGroup>
      </ClipboardRoot>
      <Text fontSize="sm">
        Once you have the webhook copied, go back to Shortcut add it here:{' '}
        <ChakraLink color="link" href="https://app.shortcut.com/settings/integrations/outgoing-webhook" target="_blank">
          Shortcut Outgoing Webhooks
        </ChakraLink>
      </Text>
    </Stack>
  )
}

export default ShortcutWebhook
