import { HStack, Icon, Link, Spacer, Stack, Text } from '@chakra-ui/react'
import { intlFormat } from 'date-fns'
import delay from 'lodash/delay'
import type { FC, ReactElement } from 'react'
import { useState } from 'react'
import NewWindow from 'react-new-window'
import { useRevalidator } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import IntegrationDeleteButton from '@app/pages/settings/integrations/components/integrationDeleteButton'
import Card from '@app/shared/layout/card'
import type { MapDomainIntegration } from '@app/types'
import type { IntegrationInterface } from '@graphql/queries'

interface ConnectedEntitiesCountProps {
  integration?: Pick<MapDomainIntegration, 'sourceName' | 'entitiesCount' | 'activitiesCount'>
}

const ConnectedEntitiesCount: FC<ConnectedEntitiesCountProps> = ({ integration = null }) => {
  const connectedCount = integration?.sourceName === 'slack' ? integration?.entitiesCount : integration?.activitiesCount
  const connectedType = integration?.sourceName === 'slack' ? 'Channels' : 'Events'

  if (!integration || connectedCount === 0) {
    return null
  }

  return (
    <Text color="subtle">
      Added {integration.entitiesCount} {connectedType}
    </Text>
  )
}

interface ConnectionDetailsProps {
  integration?: Pick<MapDomainIntegration, 'creator' | 'createdAt'>
}

const ConnectionDetails: FC<ConnectionDetailsProps> = ({ integration = null }) => {
  if (!integration) {
    return null
  }

  return (
    <Text color="subtle">
      Connected by {integration?.creator?.name} on{' '}
      {intlFormat(new Date(integration?.createdAt), { month: 'short', day: 'numeric', year: 'numeric' })}
    </Text>
  )
}

interface ConnectionButtonProps {
  integration?: Pick<IntegrationInterface, 'id'>
  connectUrl?: string
  customConnectButton?: FC
  reexecuteQuery?: (obj: object) => void
}

interface CustomConnectionButtonProps {
  disabled: boolean
  onClick?: () => void
}

const ConnectionButton: FC<ConnectionButtonProps> = ({
  integration = null,
  connectUrl = null,
  customConnectButton = null,
  reexecuteQuery = null
}) => {
  const [opened, setOpened] = useState(false)
  const revalidator = useRevalidator()

  if (integration) {
    return <IntegrationDeleteButton integration={integration} />
  }

  if (!connectUrl) {
    return null
  }

  const CustomConnectionButton: FC<CustomConnectionButtonProps> = customConnectButton

  return (
    <>
      {!customConnectButton && (
        <Button colorPalette="green" disabled={opened} onClick={() => setOpened(true)}>
          Connect
        </Button>
      )}
      {customConnectButton && <CustomConnectionButton disabled={opened} onClick={() => setOpened(true)} />}
      {opened && (
        <NewWindow
          url={connectUrl}
          onUnload={() => {
            // this is still required due to the google analytics / metric source flow, revalidator handles the rest
            if (reexecuteQuery) {
              delay(() => {
                reexecuteQuery({ requestPolicy: 'network-only' })
              }, 1000)
            }
            revalidator.revalidate()
            setOpened(false)
          }}
          /* eslint-disable-next-line no-console */
          onBlock={() => console.log('need to handle this with an alert or something')}
          name="doubleloop_auth"
          title="Connect to DoubleLoop"
        />
      )}
    </>
  )
}

interface IntegrationConnectionProps {
  name: string
  icon: ReactElement
  items: string
  integration?: Pick<
    MapDomainIntegration,
    'id' | 'activitiesCount' | 'entitiesCount' | 'sourceName' | 'creator' | 'createdAt'
  >
  connectUrl?: string
  customConnectButton?: FC
  configureUrl?: string
  reexecuteQuery?: () => void
  children?: React.ReactNode
}

const IntegrationConnection: FC<IntegrationConnectionProps> = ({
  name,
  icon,
  items,
  integration = null,
  connectUrl = undefined,
  customConnectButton = null,
  configureUrl = null,
  reexecuteQuery = () => {},
  children = null
}) => (
  <Card minH={1}>
    <Stack>
      <HStack>
        <Icon>{icon}</Icon>
        <Text color="emphasized">
          {name} {items}
        </Text>
        <Spacer />
        <Stack>
          {integration && configureUrl && (
            <Button asChild>
              <Link href={configureUrl} rel="onoopener noreferrer" target="_blank">
                Configure
              </Link>
            </Button>
          )}
          <ConnectionButton
            integration={integration}
            connectUrl={connectUrl}
            reexecuteQuery={reexecuteQuery}
            customConnectButton={customConnectButton}
          />
        </Stack>
      </HStack>
      <ConnectedEntitiesCount integration={integration} />
      <ConnectionDetails integration={integration} />
      {children}
    </Stack>
  </Card>
)

export default IntegrationConnection
