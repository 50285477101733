import type { FC } from 'react'

import SubmitForReviewButton from './submitForReviewButton'
import UsePlaybookButton from './usePlaybookButton'

import type { ButtonProps } from '@app/components/ui/button'
import type { Playbook } from '@graphql/queries'

interface Props extends ButtonProps {
  playbook: Playbook
}

const PlaybookButtons: FC<Props> = ({ playbook, ...rest }) => (
  <>
    <UsePlaybookButton playbook={playbook} aria-label="Use this playbook" {...rest} />

    <SubmitForReviewButton playbook={playbook} aria-label="Submit for review" {...rest}>
      Publish
    </SubmitForReviewButton>
  </>
)

export default PlaybookButtons
