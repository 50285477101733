import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import type { FC } from 'react'
import { Outlet } from 'react-router-dom'

import { useDrawerContext } from '@app/pages/maps/drawerContext'
import DrawerSidebar from '@app/shared/layout/shells/components/drawerSidebar'
import GraphqlSubscriptionShell from '@app/shared/layout/shells/graphqlSubscriptionShell'

interface Props extends FlexProps {
  strategyId: string
  children?: React.ReactNode
  variant?: string
  colorPalette?: string
  size?: string
}

const MapShell: FC<Props> = ({ strategyId, children = null, ...rest }) => {
  const { isDrawerOpen, onDrawerToggle } = useDrawerContext()

  return (
    <GraphqlSubscriptionShell strategyId={strategyId}>
      <Flex direction="column" display="flex" overflow="hidden" h="100vh" {...rest}>
        <Flex flex="1" overflow="hidden" maxW="100%">
          <Flex direction="column" flex="1" overflowX="hidden" overflowY="auto">
            <DrawerSidebar open={isDrawerOpen} onOpenChange={onDrawerToggle} />
            {children || <Outlet />}
          </Flex>
        </Flex>
      </Flex>
    </GraphqlSubscriptionShell>
  )
}

export default MapShell
