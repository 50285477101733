import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef, useState } from 'react'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { toaster } from '@app/components/ui/toaster'
import { Tooltip } from '@app/components/ui/tooltip'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'

interface Props {
  strategyId?: string
}

const StrategyDeleteButton: FC<Props> = ({ strategyId = null }) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const cancelRef = useRef()
  const deleteObject = useStore.use.deleteObject()

  const deleteStrategy = async () => {
    if (!strategyId) {
      return false
    }

    setLoading(true)
    const result = await deleteObject({ id: strategyId, classType: 'strategy' }, { updatePageCounts: true })
    setLoading(false)

    setOpen(false)
    toaster.create({ title: 'Deleted strategy' })

    return result
  }

  return (
    <>
      <Tooltip content="Delete map" showArrow>
        <IconButton
          color="error"
          aria-label="Delete map"
          data-cy="strategy-delete-button"
          onClick={() => setOpen(true)}
          variant="ghost"
        >
          <ICON_MAP.Delete />
        </IconButton>
      </Tooltip>

      <DialogRoot
        initialFocusEl={cancelRef?.current}
        onOpenChange={(e) => setOpen(e.open)}
        open={open}
        placement="center"
        role="alertdialog"
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete strategy</DialogTitle>
          </DialogHeader>

          <DialogBody>Are you sure? You can&#39;t undo this action afterwards.</DialogBody>

          <DialogFooter>
            <DialogActionTrigger asChild>
              <Button ref={cancelRef} variant="outline">
                Cancel
              </Button>
            </DialogActionTrigger>
            <Button ml={3} bgColor="error" loading={loading} onClick={deleteStrategy}>
              OK
            </Button>
          </DialogFooter>
          <DialogCloseTrigger />
        </DialogContent>
      </DialogRoot>
    </>
  )
}

export default StrategyDeleteButton
