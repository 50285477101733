import type { IconType } from 'react-icons'
import {
  PiAirplane,
  PiAnchor,
  PiArrowsClockwise,
  PiAtom,
  PiBag,
  PiBank,
  PiBell,
  PiBinoculars,
  PiBook,
  PiBookmark,
  PiBriefcase,
  PiChartBar,
  PiChartLine,
  PiChartPie,
  PiCheck,
  PiClock,
  PiCoins,
  PiCompass,
  PiCrosshair,
  PiDownload,
  PiEnvelope,
  PiEye,
  PiFactory,
  PiFire,
  PiFirstAid,
  PiGauge,
  PiGavel,
  PiGear,
  PiGearFine,
  PiGlobe,
  PiHeart,
  PiHouse,
  PiInfinity,
  PiInfo,
  PiKey,
  PiKnife,
  PiLightbulb,
  PiLink,
  PiLock,
  PiMapPin,
  PiMapPinDuotone,
  PiMegaphone,
  PiMoney,
  PiPaperPlane,
  PiPaperclip,
  PiPlanet,
  PiPokerChip,
  PiPushPin,
  PiPuzzlePiece,
  PiRobot,
  PiRocket,
  PiShootingStar,
  PiSkull,
  PiSpiral,
  PiStar,
  PiSun,
  PiSword,
  PiTargetDuotone,
  PiThermometer,
  PiTicket,
  PiTimer,
  PiTreeStructureDuotone,
  PiWallet
} from 'react-icons/pi'

const iconMap: Record<string, IconType> = {}

;[
  PiAirplane,
  PiAnchor,
  PiArrowsClockwise,
  PiAtom,
  PiBag,
  PiBank,
  PiBell,
  PiBinoculars,
  PiBook,
  PiBookmark,
  PiBriefcase,
  PiChartBar,
  PiChartLine,
  PiChartPie,
  PiCheck,
  PiClock,
  PiCoins,
  PiCompass,
  PiCrosshair,
  PiDownload,
  PiEnvelope,
  PiEye,
  PiFactory,
  PiFire,
  PiFirstAid,
  PiGauge,
  PiGavel,
  PiGear,
  PiGearFine,
  PiGlobe,
  PiHeart,
  PiHouse,
  PiInfinity,
  PiInfo,
  PiKey,
  PiKnife,
  PiLightbulb,
  PiLink,
  PiLock,
  PiMapPin,
  PiMapPinDuotone,
  PiMegaphone,
  PiMoney,
  PiPaperPlane,
  PiPaperclip,
  PiPlanet,
  PiPokerChip,
  PiPushPin,
  PiPuzzlePiece,
  PiRobot,
  PiRocket,
  PiShootingStar,
  PiSkull,
  PiSpiral,
  PiStar,
  PiSun,
  PiSword,
  PiTargetDuotone,
  PiThermometer,
  PiTicket,
  PiTimer,
  PiTreeStructureDuotone,
  PiWallet
].forEach((icon) => {
  iconMap[icon.name] = icon
})

const iconArray: [string, IconType][] = Object.entries(iconMap)

export { iconMap, iconArray }
