import { Box, Text } from '@chakra-ui/react'
import type { EdgeProps } from '@xyflow/react'
import { EdgeLabelRenderer } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'

interface Props
  extends Pick<
    EdgeProps,
    'labelStyle' | 'labelShowBg' | 'labelBgStyle' | 'labelBgPadding' | 'labelBgBorderRadius' | 'data' | 'style'
  > {
  x: number
  y: number
  insights?: string
  color?: string
}

const InsightLabel: FC<Props> = ({ x, y, insights = '', color = 'gray', labelBgBorderRadius }) => {
  if (!insights) {
    return null
  }

  return (
    <EdgeLabelRenderer>
      <Box
        className="nodrag nopan"
        pos="absolute"
        zIndex={2}
        px={4}
        py={2}
        color="white"
        borderRadius={labelBgBorderRadius}
        transform={`translate(-50%, -50%) translate(${x}px,${y}px)`}
        bgColor={color}
      >
        <Text fontSize="lg" fontWeight="700">
          {insights}
        </Text>
      </Box>
    </EdgeLabelRenderer>
  )
}

export default memo(InsightLabel)
