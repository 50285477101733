import { HStack, Kbd, Text } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props {
  title: string
  shortcut: string
  modifier?: string
}

const KeyboardShortcutsTooltip: FC<Props> = ({ title, shortcut, modifier }) => (
  <HStack pt={1}>
    <Text>{title}</Text>
    <span>
      {modifier ? (
        <Kbd pt={1} color="black">
          {modifier}
        </Kbd>
      ) : (
        ''
      )}
      {modifier ? (
        <Text display="inline" px={1} color="white">
          +
        </Text>
      ) : (
        ''
      )}
      <Kbd pt={1} color="black">
        {shortcut}
      </Kbd>
    </span>
  </HStack>
)

export default KeyboardShortcutsTooltip
