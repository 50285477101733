import { Box } from '@chakra-ui/react'
import type { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import EntityForm from '@app/pages/shared/entities/components/entityForm'
import { type Entity } from '@graphql/types'

const EntityFormTab: FC = () => {
  const navigate = useNavigate()
  const { nodeId } = useParams()

  const entity = useGetObject(nodeId, 'entity') as Entity

  return (
    <Box p={4} data-testid="entity-form-tab">
      <EntityForm
        entity={entity}
        onCancel={() => navigate('..')}
        onDelete={() => navigate('..')}
        onSuccess={() => navigate('..')}
      />
    </Box>
  )
}

export default EntityFormTab
