import isEmpty from 'lodash/isEmpty'
import type { FC } from 'react'
import { memo, useRef } from 'react'

import Node from './components/node'
import MetricToolbar from './components/toolbar/metricToolbar'

import useGetNode from '@app/hooks/useGetNode'
import MetricCard from '@app/shared/cards/metric/metricCard'
import type { MapDomainNode } from '@app/types'
import type { Metric } from '@graphql/types'

const MetricNode: FC<MapDomainNode> = (rfNode) => {
  const { id } = rfNode

  const node = useGetNode(id)
  const ref = useRef() // Can we get rid of the ref?

  const metric = node?.data
  const strategyId = node?.strategyId

  // The isEmpty check is a byproduct of how we're loading an empty `data` hash when the data should just come straight
  // from the store instead. This is a temporary fix until we can refactor the way we're loading data from the store.
  if (!metric || isEmpty(metric)) {
    return null
  }

  return (
    <Node
      topHandle={{ type: 'source' }}
      leftHandle={{ type: 'source' }}
      rightHandle={{ type: 'source' }}
      bottomHandle={{ type: 'source' }}
      nodeRef={ref}
      node={node}
    >
      <MetricToolbar node={node} />
      <MetricCard strategyId={strategyId} metric={metric as Metric} node={node} />
    </Node>
  )
}

export default memo(MetricNode)
