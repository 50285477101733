import { Input, Stack, VisuallyHidden } from '@chakra-ui/react'
import type { FC } from 'react'
import { useParams, useAsyncValue, useNavigation, Form } from 'react-router-dom'

// import AuthProviders from '@app/pages/sessions/components/authProviders'
import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { PasswordInput } from '@app/components/ui/password-input'
import FormAlert from '@app/next/forms/formAlert'
import useForm from '@app/next/forms/useForm'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'
import TermsAndConditionsToggle from '@app/shared/termsAndConditionsToggle'
import withAwait from '@app/shared/withAwait'
import type { Invitation } from '@graphql/queries'

type ResolvedInvitation = {
  invitation?: Invitation
}

interface InvitationPromise {
  data?: ResolvedInvitation
}

const SignUp: FC = () => {
  const {
    data: { invitation }
  }: InvitationPromise = useAsyncValue()
  const navigation = useNavigation()
  const { inviteCode } = useParams()
  const pageTitle = inviteCode ? 'Join your Organization' : 'Create your account'
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const { errors } = useForm({})

  return (
    <Stack gap="8">
      <SessionHeader title={pageTitle} subtitle="Already have an account?" linkText="Sign in" linkUrl="/sign_in" />

      <Stack gap="6">
        <Form method="post" id="sign-up-form">
          <VisuallyHidden asChild>
            <Input defaultValue={inviteCode} name="inviteCode" />
          </VisuallyHidden>
          <VisuallyHidden asChild>
            <Input defaultValue={timeZone} name="timeZone" />
          </VisuallyHidden>

          <Stack gap="5">
            <FormAlert description={errors?.global?.message} />

            <Field label="Name" id="name" invalid={!!errors?.name} required errorText={errors?.name?.message}>
              <Input autoComplete="name" id="name" name="name" placeholder="Your name" />
            </Field>

            <Field label="Email" id="email" invalid={!!errors?.email} required errorText={errors?.email?.message}>
              <Input
                autoComplete="email"
                defaultValue={invitation?.email}
                id="email"
                name="email"
                placeholder="Your work email"
                type="email"
              />
            </Field>

            <Field label="Password" invalid={!!errors?.password} required errorText={errors?.password?.message}>
              <PasswordInput name="password" placeholder="Password" autoComplete="new-password" minLength={8} />
            </Field>
            <Field
              label="Password Confirmation"
              invalid={!!errors?.passwordConfirmation}
              errorText={errors?.passwordConfirmation?.message}
              required
            >
              <PasswordInput
                name="passwordConfirmation"
                placeholder="Password confirmation"
                autoComplete="new-password"
                required
                minLength={8}
              />
            </Field>
            <Stack gap={4}>
              <TermsAndConditionsToggle name="termsAndConditions" />
            </Stack>
          </Stack>
        </Form>
        <Stack gap={4}>
          <Button
            form="sign-up-form"
            loading={navigation.state === 'submitting' || navigation.state === 'loading'}
            size="md"
            type="submit"
          >
            Sign up
          </Button>
          {/* <AuthProviders inviteCode={inviteCode} text="Sign up with" /> */}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default withAwait(SignUp, 'invitation')
