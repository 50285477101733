import type { TextareaProps } from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/react'
import type { FC } from 'react'

interface Props extends TextareaProps {
  onSubmit?: (event) => void
}

const CommentTextArea: FC<Props> = ({ onInput, onSubmit, ...rest }) => (
  <Textarea
    w="full"
    maxH="200px"
    pe="9"
    resize="none"
    name="message"
    placeholder="Add a comment..."
    rows={2}
    {...rest}
    _placeholder={{ color: 'fg.subtle' }}
    onInput={(event) => {
      const textarea = event.currentTarget
      textarea.style.height = 'auto'
      const borderHeight = textarea.offsetHeight - textarea.clientHeight
      textarea.style.height = `${textarea.scrollHeight + borderHeight}px`
      onInput?.(event)
    }}
    onKeyDown={(event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        onSubmit?.(event)
      }
    }}
  />
)

export default CommentTextArea
