import { Group, Input, Stack } from '@chakra-ui/react'
import type { ChangeEventHandler, FC, FormEventHandler } from 'react'
import { useState } from 'react'
import { Form } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { useStore } from '@app/store'
import type { JiraIntegration } from '@graphql/types'

type Props = {
  integration: Pick<JiraIntegration, 'id' | 'restrictedProjects'>
}

const JiraRestrictedProjectsForm: FC<Props> = ({ integration }) => {
  const [restrictedProjects, setRestrictedProjects] = useState(() => (integration?.restrictedProjects || []).join(', '))
  const [loading, setLoading] = useState(false)

  const updateIntegration = useStore.use.updateIntegration()

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target
    setRestrictedProjects(value)
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    setLoading(true)

    return updateIntegration(integration.id, {
      restrictedProjects
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Form onSubmit={onSubmit}>
      <Stack gap={4}>
        <Field helperText="Project keys to not import into DoubleLoop">
          <Input bg="bg" onChange={onChange} placeholder="DOU, TST" type="text" value={restrictedProjects} />
        </Field>
        <Group justifyContent="end">
          <Button loading={loading} type="submit">
            Save
          </Button>
        </Group>
      </Stack>
    </Form>
  )
}

export default JiraRestrictedProjectsForm
