import type { NativeSelect } from '@chakra-ui/react'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import type { WORK_SOURCES_TO_SHOW } from '@app/pages/maps/components/addCards/helpers'
import { showSourceNameInput } from '@app/pages/maps/components/addCards/helpers'

interface Props extends Pick<NativeSelect.FieldProps, 'value' | 'onChange'> {
  sources?: { value: string; display: string }[]
  typeValue: 'metric' | 'entity' | (typeof WORK_SOURCES_TO_SHOW)[number]
}

const SourceNameInput: FC<Props> = ({ value, typeValue, sources = [], onChange = () => {} }) => {
  if (!showSourceNameInput(typeValue)) {
    return null
  }

  return (
    <Field>
      <NativeSelectRoot>
        <NativeSelectField
          value={value}
          name="scope.source_name"
          placeholder=""
          onChange={onChange}
          data-testid="show-sources-input"
        >
          <option value="">All sources</option>
          {sources.map((source) => (
            <option key={source.value} value={source.value}>
              {source.display}
            </option>
          ))}
        </NativeSelectField>
      </NativeSelectRoot>
    </Field>
  )
}

export default SourceNameInput
