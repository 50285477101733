import { Box, IconButton, Input } from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import type { FC } from 'react'
import { useState } from 'react'
import { FiMail } from 'react-icons/fi'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { InputGroup } from '@app/components/ui/input-group'
import type { Contributor } from '@graphql/queries'
import { useInvitationCreateMutation } from '@graphql/queries'
import { RoleEnum } from '@graphql/types'

interface InviteFormProps {
  contributor: Contributor
  setInvited?: (invited: boolean) => void
}

const InviteForm: FC<InviteFormProps> = ({ contributor, setInvited = () => {} }) => {
  const [error, setError] = useState(null)
  const [, createInvitation] = useInvitationCreateMutation()

  const onSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const values = Object.fromEntries(formData.entries())

    try {
      const input = {
        email: values.email as string,
        role: RoleEnum.Viewer
      }

      await createInvitation({ input })

      ahoy.track('user:sent_invitation', {
        email: input.email
      })

      setInvited(true)
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Field errorText={error} invalid={!!error} required>
        <InputGroup
          endElement={
            <IconButton aria-label="Send Invite" type="submit">
              <FiMail />
            </IconButton>
          }
        >
          <Input
            defaultValue={contributor.email || ''}
            id="email"
            name="email"
            placeholder="Email address"
            type="email"
          />
        </InputGroup>
      </Field>
    </form>
  )
}

interface InviteButtonProps {
  setInviting?: (invited: boolean) => void
}

const InviteButton: FC<InviteButtonProps> = ({ setInviting = () => {} }) => (
  <Button
    w="full"
    _hover={{
      transform: 'translateY(-2px)',
      boxShadow: 'lg'
    }}
    onClick={() => setInviting(true)}
    rounded="md"
  >
    Invite
  </Button>
)

interface ContributorInviteButtonProps {
  contributor: Contributor
}

const ContributorInviteButton: FC<ContributorInviteButtonProps> = ({ contributor }) => {
  const [inviting, setInviting] = useState(false)
  const [invited, setInvited] = useState(false)
  let buttonComponent

  if (contributor.sourceName === 'doubleloop') {
    return null
  }

  if (invited) {
    buttonComponent = (
      <Button w="full" disabled rounded="md">
        Invite Sent!
      </Button>
    )
  } else if (inviting) {
    buttonComponent = <InviteForm contributor={contributor} setInvited={setInvited} />
  } else {
    buttonComponent = <InviteButton setInviting={setInviting} />
  }

  return <Box mt={8}>{buttonComponent}</Box>
}

export default ContributorInviteButton
