import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import ShortcutIcon from '@app/images/shortcut'
import IntegrationConnection from '@app/pages/settings/integrations/components/integrationConnection'
import IntegrationInstallation from '@app/pages/settings/integrations/components/integrationInstallation'
import ShortcutTokenForm from '@app/pages/settings/integrations/components/shortcutTokenForm'
import ShortcutWebhook from '@app/pages/settings/integrations/components/shortcutWebhook'
import Card from '@app/shared/layout/card'
import CardHeader from '@app/shared/layout/cardHeader'
import PageHeader from '@app/shared/layout/pageHeader'
import PageStack from '@app/shared/layout/pageStack'
import withAwait from '@app/shared/withAwait'
import type { StepType } from '@app/types'
import { IntegrationSourceNamesEnum } from '@graphql/types'

const Shortcut = () => {
  const integration = useGetObjectsByProperties('integration', { sourceName: IntegrationSourceNamesEnum.Shortcut })[0]

  const installationSteps: StepType[] = [
    {
      title: 'Enable API Access',
      description: <ShortcutTokenForm integration={integration} />,
      completed: (shortcutIntegration) => !!shortcutIntegration.sourceId
    },
    {
      title: 'Setup Webhook',
      description: <ShortcutWebhook />,
      completed: (shortcutIntegration) => shortcutIntegration.activitiesCount > 0
    }
    // {
    //   title: 'Test it out',
    //   description: (
    //     <Text fontSize="sm">
    //       Once that is complete, make any change to a story in Shortcut to confirm it is working. You will see activity
    //       appear on your{' '}
    //       <ChakraLink as={Link} color="link" to={Paths.entitiesPath()}>
    //         all events page
    //       </ChakraLink>
    //       .
    //     </Text>
    //   )
    // }
  ]

  return (
    <PageStack>
      <PageHeader title="Shortcut" subtitle="Send your Shortcut data to DoubleLoop." />
      <IntegrationConnection
        name="Shortcut"
        icon={<ShortcutIcon />}
        items="milestones, epics, and stories"
        integration={integration}
      />
      <Card>
        <CardHeader title="Installation instructions" />
        <IntegrationInstallation integration={integration} steps={installationSteps} skipSpinner />
      </Card>
    </PageStack>
  )
}

export default withAwait(Shortcut, 'integration')
