import type { IconProps } from '@chakra-ui/react'
import type { FC } from 'react'
import type { IconType } from 'react-icons'
import { SiJirasoftware } from 'react-icons/si'

import ICON_MAP from '@app/utils/iconMap'

const workIntegrationBuilder = ({ display, icon }: { display: string; icon: IconType | FC<IconProps> }) => ({
  display,
  icon
})

// eslint-disable-next-line import/prefer-default-export
export const INTEGRATIONS = {
  asana: workIntegrationBuilder({ display: 'Asana', icon: ICON_MAP.Asana }),
  jira: workIntegrationBuilder({ display: 'JIRA', icon: SiJirasoftware }),
  linear: workIntegrationBuilder({ display: 'Linear', icon: ICON_MAP.Linear }),
  productboard: workIntegrationBuilder({ display: 'Productboard', icon: ICON_MAP.Productboard }),
  shortcut: workIntegrationBuilder({ display: 'Shortcut', icon: ICON_MAP.Shortcut })
}
