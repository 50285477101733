import { defer } from 'react-router-dom'

import { useStore } from '@app/store'
import { Credentials } from '@graphql/documents/credential.graphql'
import type { CredentialsQuery } from '@graphql/queries'

const loadCredentials = async () => {
  const { loaderQuery: storeLoaderQuery } = useStore.getState()
  const { data } = await storeLoaderQuery<CredentialsQuery>(Credentials)

  return defer({ credentials: data?.credentials })
}

export default loadCredentials
