import { Box, Stack, Table as ChakraTable } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useState } from 'react'

import { Field } from '@app/components/ui/field'
import AccountsFetcher from '@app/fetchers/accountsFetcher'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import ToggleInput from '@app/next/forms/toggleInput'
import NameCell from '@app/pages/settings/organization/components/columns/nameCell'
import RoleCell from '@app/pages/settings/organization/components/columns/roleCell'
import UserActionsCell from '@app/pages/settings/organization/components/columns/userActionsCell'
import Table from '@app/shared/table'
import type { Column, ColumnCellProps, Data } from '@app/shared/table/types'

const columns: Column<Data>[] = [
  {
    header: 'Name',
    cell: NameCell as unknown as FC<ColumnCellProps<Data>>
  },
  {
    header: 'Role',
    cell: RoleCell
  },
  {
    header: '',
    cell: UserActionsCell as unknown as FC<ColumnCellProps<Data>>
  }
]

interface Props {}

const AccountsList: FC<Props> = () => {
  const { user } = useStoreCurrentUser()
  const [showInactive, setShowInactive] = useState(false)
  const organizationAccounts = useGetObjectsByProperties('account', {
    organizationId: user.organization?.id
  })

  let sortedAccounts = sortBy(organizationAccounts || [], ['disabledAt', 'name']).reverse()
  if (showInactive === false) {
    sortedAccounts = sortedAccounts.filter((account) => !account.disabledAt)
  }

  const customHeader = (
    <ChakraTable.Header>
      <ChakraTable.Row>
        <ChakraTable.ColumnHeader colSpan={3}>
          <Box css={{ '--field-label-width': '140px' }}>
            <Field label="Show inactive users" orientation="horizontal">
              <ToggleInput
                checked={showInactive}
                onCheckedChange={() => {
                  setShowInactive(!showInactive)
                }}
              />
            </Field>
          </Box>
        </ChakraTable.ColumnHeader>
      </ChakraTable.Row>
    </ChakraTable.Header>
  )

  return (
    <Stack gap={4}>
      <AccountsFetcher showInactive={!showInactive} />
      <Box overflow="auto">
        <Table columns={columns} data={sortedAccounts as Data[]} customHeader={customHeader} />
      </Box>
    </Stack>
  )
}

export default AccountsList
