import { Group } from '@chakra-ui/react'
import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import CardFiltersModal from './cardFiltersModal'
import type { Props as MapNodeFilterProps } from './mapCardFilter'
import MapCardFilter from './mapCardFilter'
import type { MapCardFiltersModalContextType } from './mapCardFiltersModalContext'
import type { MapCardFilterType } from './types'

import { Button } from '@app/components/ui/button'
import { CARD_FILTER_SIGIL } from '@app/lib/globals'
import ICON_MAP from '@app/utils/iconMap'
import type { NodeFiltersInput } from '@graphql/types'

interface Props {
  canEdit?: boolean
}

const MapCardFilters: FC<Props> = ({ canEdit = false }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [open, setopen] = useState(false)

  const onNewFacetClick = useCallback(() => {
    setopen(true)
  }, [setopen])

  const onClose = useCallback(() => {
    setopen(false)
  }, [setopen])

  const onFilter: MapCardFiltersModalContextType['onFilter'] = useCallback(
    (field, value) => {
      searchParams.delete(`${CARD_FILTER_SIGIL}${field}`)

      const params = Array.isArray(value) ? value : [value]

      params.forEach((v) => {
        const val = v.trim()

        if (val) {
          searchParams.append(`${CARD_FILTER_SIGIL}${field}`, val)
        }
      })

      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const onRemoveClick: MapNodeFilterProps['onRemoveClick'] = useCallback(
    (nodeFilter) => {
      searchParams.delete(`${CARD_FILTER_SIGIL}${nodeFilter.name}`, nodeFilter.value)
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const filters = useMemo(
    () =>
      Array.from(searchParams.entries())
        .filter(([key]) => key.startsWith(CARD_FILTER_SIGIL))
        .map<MapCardFilterType>(([param, value]) => ({ name: param.slice(2) as keyof NodeFiltersInput, value })),
    [searchParams]
  )

  if (!canEdit) {
    return null
  }

  return (
    <Group>
      <>
        <Button size="2xs" aria-label="Filter Cards" colorPalette="gray" onClick={onNewFacetClick} variant="ghost">
          <ICON_MAP.Filter />
          Filter Cards
        </Button>
        <CardFiltersModal open={open} onOpenChange={onClose} onFilter={onFilter} cardFilters={filters} />
      </>

      {filters.map((filter) => (
        <MapCardFilter
          key={`${filter.name}:${filter.value}`}
          canEdit={canEdit}
          filter={filter}
          onRemoveClick={onRemoveClick}
        />
      ))}
    </Group>
  )
}

export default MapCardFilters
