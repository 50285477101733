import { Center, HStack, Text } from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

const MultiAccountBanner: FC<BoxProps> = ({ ...rest }) => {
  const { user } = useStoreCurrentUser()

  if (!user || user.accountsCount < 2) {
    return null
  }

  return (
    <Center
      p={2}
      bgGradient="to-r"
      _dark={{ gradientFrom: 'purple.700', gradientTo: 'pink.700' }}
      gradientFrom="cyan.500"
      gradientTo="teal.500"
      {...rest}
    >
      <HStack>
        <Text color="white" fontSize="sm" fontWeight="semibold">
          {user?.organization?.name}
        </Text>
        <Button color="white" colorPalette="whiteAlpha" size="2xs" asChild>
          <Link to="/settings/user/edit">Switch accounts</Link>
        </Button>
      </HStack>
    </Center>
  )
}

export default MultiAccountBanner
