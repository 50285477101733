import type { IconProps } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import type { FC } from 'react'

const Mixpanel: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 24 24">
      <g>
        <g>
          <g transform="translate(1.034343, 123.086869)">
            <ellipse fill="currentColor" cx="3.5" cy="-103.77" rx="2.53" ry="2.53" />
            <ellipse fill="currentColor" cx="12.63" cy="-103.74" rx="1.79" ry="1.79" />
            <ellipse fill="currentColor" cx="20.15" cy="-103.74" rx="0.81" ry="0.81" />
          </g>
          <g>
            <path
              fill="currentColor"
              d="M20.72,14.51c-0.74,0-1.28-0.57-1.28-1.28V7.79c0-1.88-0.9-3.32-2.91-3.32s-3.24,1.55-3.24,3.35
					v5.42c0,0.71-0.49,1.28-1.28,1.28c-0.76,0-1.28-0.57-1.28-1.28V7.79c0-1.88-0.98-3.32-2.99-3.32c-2.07,0-3.16,1.55-3.16,3.35
					v5.42c0,0.71-0.52,1.28-1.28,1.28c-0.74,0-1.28-0.57-1.28-1.28V3.57c0-0.76,0.49-1.31,1.28-1.31c0.87,0,1.28,0.6,1.28,1.31v0.87
					c0.74-1.44,2.23-2.29,3.84-2.29c1.96,0,3.43,0.98,4.17,2.42c1.06-1.63,2.61-2.42,4.55-2.42c3.29,0,4.87,2.26,4.87,5.45v5.64
					C22,13.94,21.48,14.51,20.72,14.51L20.72,14.51z"
            />
          </g>
        </g>
      </g>
    </svg>
  </Icon>
)

export default Mixpanel
