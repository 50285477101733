import { Container } from '@chakra-ui/react'

import EntityForm from './components/entityForm'

const Create = () => (
  <Container py={8}>
    <EntityForm />
  </Container>
)

export default Create
