import { Link as ChakraLink, HStack, Input, Spacer, Stack, VisuallyHidden } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link, useLocation, useNavigation, Form } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import { PasswordInput } from '@app/components/ui/password-input'
import FormAlert from '@app/next/forms/formAlert'
import useForm from '@app/next/forms/useForm'
import AuthProviders from '@app/pages/sessions/components/authProviders'
import SessionHeader from '@app/pages/sessions/components/sessionHeader'

const SignIn: FC = () => {
  const location = useLocation()
  const navigation = useNavigation()
  const from = location.state?.from?.pathname || '/'
  const { errors } = useForm({ globalError: 'Invalid username or password.' }) as {
    errors: { [key: string]: { message: string } }
  }

  return (
    <Stack gap="8">
      <SessionHeader
        title="Sign in to your account"
        // subtitle="Don't have an account?"
        // linkText="Sign up"
        // linkUrl="/sign_up"
      />

      <Stack gap="6">
        <FormAlert description={errors?.global?.message} />
        <Form id="sign-in-form" method="post">
          <VisuallyHidden asChild>
            <Input defaultValue={from} name="from" />
          </VisuallyHidden>
          <Stack gap="5">
            <Field label="Email" id="email" invalid={!!errors?.email} required errorText={errors?.email?.message}>
              <Input autoComplete="email" id="email" name="email" placeholder="Enter your email" type="email" />
            </Field>
            <Field
              label="Password"
              id="password"
              invalid={!!errors?.password}
              required
              errorText={errors?.password?.message}
            >
              <PasswordInput name="password" placeholder="" autoComplete="current-password" />
            </Field>
          </Stack>
        </Form>
        <HStack textStyle="sm" justify="space-between" fontWeight="medium">
          <Spacer />
          <ChakraLink asChild variant="plain">
            <Link to="/forgot_password">Forgot your password?</Link>
          </ChakraLink>
        </HStack>
        <Stack gap="4">
          <Button form="sign-in-form" loading={navigation.state === 'submitting'} size="md" type="submit">
            Sign in
          </Button>
          <AuthProviders text="Sign in with" />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SignIn
