import { HStack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import { FcGoogle } from 'react-icons/fc'

import {
  DialogBody,
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { InputGroup } from '@app/components/ui/input-group'
import { Radio, RadioGroup } from '@app/components/ui/radio'
import ProviderForm from '@app/pages/sessions/components/providerForm'
import LimitedUseDisclosure from '@app/pages/settings/integrations/components/google/limitedUseDisclosure'
import { Paths } from '@app/utils/routeHelpers'

// used in GoogleSheetsController#callback
type GoogleOauthState = {
  redirect_to?: string
  metric_id?: string
  type: 'source' | 'organization'
}

interface Props {
  open: boolean
  onOpenChange: (_e) => void
  state?: GoogleOauthState
}

const GoogleSheetsConnectModal: FC<Props> = ({ open, onOpenChange, state = null }) => {
  const [segmented, setSegmented] = useState(false)
  const url = Paths.authPath(
    { provider: 'google_sheets' },
    state ? { search: `?state=${btoa(JSON.stringify({ ...state, segmented }))}` } : {}
  )

  const onChange = (e) => setSegmented(!!e.value)

  return (
    <DialogRoot onOpenChange={onOpenChange} open={open} placement="center">
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Connect to Google Sheets</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger />
        <DialogBody>
          <Text pb={4}>
            After authorizing DoubleLoop to read and write data to Google Sheets, we’ll automatically create a Google
            Sheet that you can use to update and import metrics into DoubleLoop. No other spreadsheets will be affected.
          </Text>
          {state?.type === 'organization' && (
            <InputGroup pb={4}>
              <>
                <Text>Select template</Text>
                <RadioGroup defaultValue="" onValueChange={onChange}>
                  <HStack>
                    <Radio value="">Simple</Radio>
                    <Radio value="segmented">Segmented</Radio>
                  </HStack>
                </RadioGroup>
              </>
            </InputGroup>
          )}
          <ProviderForm url={url} buttonTitle="Continue with Google" buttonIcon={FcGoogle} />
          <LimitedUseDisclosure py={4} />
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  )
}

export default GoogleSheetsConnectModal
