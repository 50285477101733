import { Center, Spinner, type SpinnerProps } from '@chakra-ui/react'
import type { FC } from 'react'

const SuspenseLoader: FC<SpinnerProps> = ({ ...rest }) => (
  <Center h="full">
    <Spinner
      w={24}
      h={24}
      color="blue.500"
      borderWidth="8px"
      animationDuration="0.65s"
      css={{ '--spinner-track-color': 'colors.gray.200' }}
      {...rest}
    />
  </Center>
)

export default SuspenseLoader
