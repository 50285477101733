import { createMongoAbility } from '@casl/ability'
import { type FC, useMemo } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { AbilityContext } from '@app/shared/authorization/can'
import type { User } from '@graphql/types'

interface Props {
  children: React.ReactNode
}

const CASLProvider: FC<Props> = ({ children }) => {
  const { user = null } = useStoreCurrentUser()

  const ability = useMemo(() => createMongoAbility(user?.abilities || {}), [user?.abilities])

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}

export const abilitiesFor = (user: Pick<User, 'abilities'> = null) => createMongoAbility(user?.abilities || {})

export default CASLProvider
