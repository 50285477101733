import { Alert } from '@app/components/ui/alert'

const WhatIsAPlaybook = () => (
  <Alert title="What is a DoubleLoop playbook?" status="info">
    Playbooks in DoubleLoop are more than flowchart templates. With DoubleLoop, you can connect each node to your live
    data sources and workflow tools. The result is a revolutionary way for everyone on your team to understand the
    business impact of their work, stay aligned, and test their assumptions with real data.
  </Alert>
)

export default WhatIsAPlaybook
