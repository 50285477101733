import Paywall from '@app/pages/settings/billing/components/paywall'
import { Card, PageHeader, PageStack } from '@app/shared/layout'

const Plan = () => (
  <PageStack>
    <PageHeader title="Plan" />
    <Card>
      <Paywall />
    </Card>
  </PageStack>
)

export default Plan
