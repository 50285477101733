import { useEffect, useState } from 'react'

import { Alert } from '@app/components/ui/alert'
import { Button } from '@app/components/ui/button'
import {
  DialogBody,
  DialogBackdrop,
  DialogContent,
  DialogRoot,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@app/components/ui/dialog'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'

const VersionCheck = () => {
  const { user } = useStoreCurrentUser()
  const [outOfDate, setOutOfDate] = useState<boolean>(false)

  useEffect(() => {
    const handleVersionCheck = (_event: CustomEvent) => {
      if (user) {
        setOutOfDate(true)
      }
    }

    window.addEventListener('outdatedClient', handleVersionCheck)

    return () => {
      window.removeEventListener('outdatedClient', handleVersionCheck)
    }
  }, [user])

  if (!outOfDate) {
    return null
  }

  return (
    <DialogRoot onOpenChange={() => {}} open placement="center">
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New version available!</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Alert
            title="We've made some updates to the app. Please refresh the page to get the latest version."
            status="info"
          />
        </DialogBody>
        <DialogFooter>
          <Button onClick={() => window.location.reload()}>Refresh</Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  )
}

export default VersionCheck
