import type { StackProps } from '@chakra-ui/react'
import { Box, Stack, StackSeparator } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import { useColorModeValue } from '@app/components/ui/color-mode'

export interface Props extends StackProps {
  isStack?: boolean
  children?: ReactNode
  minH?: number
}

const Card: FC<Props> = ({ isStack = false, children, ...rest }) => {
  const baseProps = {
    p: { base: '4', md: '6' },
    bg: 'bg.subtle',
    borderRadius: 'lg',
    shadow: useColorModeValue('sm', 'sm-dark')
  }

  if (isStack) {
    return (
      <Stack gap={{ base: 4, sm: 6 }} separator={<StackSeparator />} {...baseProps} {...rest}>
        {children}
      </Stack>
    )
  }

  return (
    <Box {...baseProps} {...rest}>
      {children}
    </Box>
  )
}

export default Card
