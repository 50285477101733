import { Input, VisuallyHidden } from '@chakra-ui/react'
import type { FC } from 'react'
import { useCallback, useState } from 'react'

import CredentialSelect from './credentialSelect'
import type { Props as CredentialSelectProps } from './credentialSelect'

import { Field } from '@app/components/ui/field'

type Props = CredentialSelectProps & {
  disabled: boolean
}

const CredentialInput: FC<Props> = ({
  disabled,
  credential: credentialProp,
  onSelect: propOnSelect,
  ...selectProps
}) => {
  const { defaultSourceName } = selectProps
  const [credential, setCredential] = useState(credentialProp)
  const [sourceName, setSourceName] = useState(credential?.sourceName || defaultSourceName || '')

  const onSelect = useCallback<Props['onSelect']>(
    (newSourceName, newCredential) => {
      setSourceName(newSourceName)
      setCredential(newCredential)
      propOnSelect?.(newSourceName, newCredential)
    },
    [propOnSelect]
  )

  return (
    <Field label="Source" disabled={disabled}>
      <CredentialSelect disabled={disabled} credential={credential} onSelect={onSelect} {...selectProps} />
      <VisuallyHidden asChild>
        <Input name="sourceName" readOnly value={sourceName || ''} />
      </VisuallyHidden>
      <VisuallyHidden asChild>
        <Input name="credentialId" readOnly value={credential?.id || ''} />
      </VisuallyHidden>
    </Field>
  )
}

export default CredentialInput
