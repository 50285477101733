import { Group, IconButton, Spinner } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'
import type { OperationResult } from 'urql'

import { Button } from '@app/components/ui/button'
import ICON_MAP from '@app/utils/iconMap'

type Filter = {
  id: string
  name: string
  value: string
}

interface Props {
  filter: Filter
  onRemoveClick: (filter: Filter) => Promise<OperationResult>
  canEdit: boolean
  isApplying?: boolean
}

const MapSegmentFilter: FC<Props> = ({ filter, onRemoveClick, canEdit = false, isApplying = false, ...rest }) => {
  const [loading, setLoading] = useState(false)
  const { name, value } = filter

  const title = `${name || '(none)'}: ${value || '(none)'}`

  return (
    <Group attached colorPalette="blue" {...rest}>
      {canEdit && (
        <IconButton
          aria-label="Remove filter"
          onClick={() => {
            setLoading(true)
            return onRemoveClick(filter).finally(() => setLoading(false))
          }}
          size="2xs"
        >
          {isApplying || loading ? <Spinner size="xs" /> : <ICON_MAP.Close />}
        </IconButton>
      )}
      <Button userSelect="auto" pointerEvents="none" disabled={isApplying || loading} title={title} size="2xs">
        {title}
      </Button>
    </Group>
  )
}

export default MapSegmentFilter
