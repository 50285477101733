import type { FC } from 'react'

import CardTypeTable from './basicTypeModal/cardTypeTable'
import CreateBasicCardTypeButton from './basicTypeModal/createBasicCardTypeButton'

import {
  DialogBody,
  DialogBackdrop,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
  DialogTitle,
  DialogFooter
} from '@app/components/ui/dialog'
import type { CardType } from '@graphql/types'

interface Props {
  cardTypes: CardType[]
  open: boolean
  onOpenChange: (e) => void
}

const BasicTypeModal: FC<Props> = ({ cardTypes, open, onOpenChange }) => (
  <DialogRoot onOpenChange={onOpenChange} open={open} placement="center" size="lg">
    <DialogBackdrop />
    <DialogContent maxH="80%">
      <DialogHeader>
        <DialogTitle>Manage card types</DialogTitle>
      </DialogHeader>

      <DialogBody pb={4} overflowY="auto">
        <CardTypeTable cardTypes={cardTypes} />
      </DialogBody>
      <DialogFooter alignItems="normal" justifyContent="normal" display="block">
        <CreateBasicCardTypeButton />
      </DialogFooter>
      <DialogCloseTrigger />
    </DialogContent>
  </DialogRoot>
)

export default BasicTypeModal
