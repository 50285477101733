import { Box, Heading, Link as ChakraLink, HStack, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import WordmarkColor from '@app/images/wordmarkColor'

interface Props {
  title: string
  subtitle?: string
  linkText?: string
  linkUrl?: string
}

const SessionHeader: FC<Props> = ({ title, subtitle, linkText, linkUrl }) => (
  <Stack gap={{ base: '2', md: '3' }} textAlign="center">
    <Stack gap={{ base: '2', md: '3' }} textAlign="center">
      <Box hideFrom="lg">
        <ChakraLink href="https://www.doubleloop.app">
          <WordmarkColor w="200px" h="auto" />
        </ChakraLink>
      </Box>
      <Heading size={{ base: '2xl', md: '3xl' }}>{title}</Heading>
      <HStack justify="center" gap="1">
        {subtitle && <Text color="muted">{subtitle}</Text>}
        {linkText && linkUrl && (
          <ChakraLink asChild>
            <Link to={linkUrl}>{linkText}</Link>
          </ChakraLink>
        )}
      </HStack>
    </Stack>
  </Stack>
)

export default SessionHeader
