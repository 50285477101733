import { useShallow } from 'zustand/react/shallow'

import { useStore, getObjectsByIds } from '@app/store'
import type { BoundedState } from '@app/store/types'

const useGetObjectsByIds = <T extends keyof BoundedState>(
  type: T,
  ids: string[]
): ReturnType<typeof getObjectsByIds<T>> => useStore(useShallow((state) => getObjectsByIds(state, type, ids)))

export default useGetObjectsByIds
