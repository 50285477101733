import { HStack } from '@chakra-ui/react'

import { Tag } from '@app/components/ui/tag'

const ListLabels = ({ labels }) => {
  if (!labels || labels.length === 0) {
    return null
  }

  return <HStack wrap="wrap">{labels?.map((label) => <Tag key={`laben-name-${label.name}`}>{label.name}</Tag>)}</HStack>
}

export default ListLabels
