import { Input, Stack } from '@chakra-ui/react'
import type { FC } from 'react'
import { Form } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import { Field } from '@app/components/ui/field'
import FormAlert from '@app/next/forms/formAlert'
import useForm from '@app/next/forms/useForm'
import { CardHeader } from '@app/shared/layout'
import TermsAndConditionsToggle from '@app/shared/termsAndConditionsToggle'

const UserForm: FC = () => {
  const { errors } = useForm({}) as { errors: { global?: { message?: string }; email?: string } }

  return (
    <Stack gap="6">
      <CardHeader
        title="Almost there!"
        subtitle="Add your work email address and job title to finish setting up your account."
      />
      <Form id="user-form" method="post">
        <Stack gap="5">
          <FormAlert description={errors?.global?.message} title="Failed to setup your account!" />

          <Field label="Email" id="email" invalid={!!errors?.email} required>
            <Input autoComplete="email" id="email" name="email" placeholder="Enter your email" />
          </Field>
          <Field label="Job title" id="jobTitle">
            <Input id="jobTitle" name="jobTitle" placeholder="Enter your job title" />
          </Field>
          <TermsAndConditionsToggle name="termsAndConditions" />
        </Stack>
      </Form>
      <Stack gap={4}>
        <Button form="user-form" size="md" type="submit">
          Continue
        </Button>
      </Stack>
    </Stack>
  )
}

export default UserForm
