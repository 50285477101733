import { Box, HStack, Icon, Spinner, Text } from '@chakra-ui/react'
import type { UppyFile } from '@uppy/core'
import truncate from 'lodash/truncate'
import type { FC } from 'react'
import { PiFile, PiFileDoc, PiFileHtml, PiFileJs, PiFileMd, PiFilePdf, PiFilePy } from 'react-icons/pi'

import type { FileTicketMeta, RemoteFile } from '@app/shared/uploader/types'

interface Props {
  file: UppyFile<FileTicketMeta, RemoteFile>
}

const File: FC<Props> = ({ file }) => {
  let IconEl = PiFile
  const { percentage } = file.progress

  if (file.extension === 'pdf') {
    IconEl = PiFilePdf
  } else if (file.extension === 'doc' || file.extension === 'docx') {
    IconEl = PiFileDoc
  } else if (file.extension === 'html') {
    IconEl = PiFileHtml
  } else if (file.extension === 'js' || file.extension === 'json' || file.extension === 'ts') {
    IconEl = PiFileJs
  } else if (file.extension === 'md') {
    IconEl = PiFileMd
  } else if (file.extension === 'py') {
    IconEl = PiFilePy
  }

  return (
    <HStack key={file.toString()} gap={0} border="border" borderWidth={1} borderRadius="sm" bgColor="bg">
      <Box
        h="100%"
        px={2}
        py={1}
        textAlign="center"
        borderRadius="sm"
        borderRightRadius={0}
        bgColor="colorPalette.solid"
      >
        {percentage > 0 && percentage < 100 ? (
          <Spinner boxSize={4} color="white" />
        ) : (
          <Icon boxSize={4} color="white">
            <IconEl />
          </Icon>
        )}
      </Box>
      <Text w="100%" px={2} color="fg.subtle" fontSize="xs" title={file.name}>
        {truncate(file.name, { length: 20 })}
      </Text>
    </HStack>
  )
}

export default File
