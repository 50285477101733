import type { FC, ReactNode } from 'react'
import { useEffect } from 'react'
import type { UseQueryArgs, UseQueryResponse } from 'urql'

import { usePage } from '@app/shared/pagination'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStore } from '@app/store'
import type { EntityEntitiesQueryVariables, StrategyEntityEntitiesQueryVariables } from '@graphql/queries'
import { useStrategyEntityEntitiesQuery, useEntityEntitiesQuery } from '@graphql/queries'
import type { CollectionMetadata, Entity, EntityCollection } from '@graphql/types'

type Variables = StrategyEntityEntitiesQueryVariables | EntityEntitiesQueryVariables

type Props = Variables & {
  id: string
  strategyId: string | null
  children: (collection: Entity[], metadata: CollectionMetadata | Record<string, never>) => ReactNode
}

const EntityEntitiesFetcher: FC<Props> = ({ id, children, strategyId, ...rest }) => {
  const page = usePage()
  const updateObject = useStore.use.updateObject()

  let query: (options: Omit<UseQueryArgs, 'query'>) => UseQueryResponse
  let variables: Variables

  if (strategyId) {
    query = useStrategyEntityEntitiesQuery
    variables = { id: strategyId, entityId: id, page, ...rest }
  } else {
    query = useEntityEntitiesQuery
    variables = { id, page, ...rest }
  }

  const [{ data }] = query({ variables })

  const { collection = [], metadata = {} } = (data?.strategy?.entityEntities ||
    data?.entityEntities ||
    {}) as EntityCollection

  useEffect(() => {
    // @ts-expect-error containee_ids is not a valid field for updateObject, do use without skipMutation
    updateObject({ entity: { id, containeeIds: collection?.map((containment) => containment.id) } }, true)
  }, [])

  return children(collection, metadata)
}

export default withSuspenseWrapper(EntityEntitiesFetcher)
