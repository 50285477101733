import { HStack, Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { Button } from '@app/components/ui/button'
import { toaster } from '@app/components/ui/toaster'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { useConfirmEmailMutation } from '@graphql/queries'

const EmailForm: FC = () => {
  const { user } = useStoreCurrentUser()
  const [, sendConfirmationEmail] = useConfirmEmailMutation()

  const sendEmailConfirmation = () => {
    sendConfirmationEmail({ input: {} }).then(() => {
      toaster.create({ title: 'Resent confirmation email' })
    })
  }

  return (
    <Stack gap="6">
      <Stack textAlign="center">
        <Text>We sent a confirmation email to:</Text>
        <Text fontWeight="bold">{user.email}</Text>
        <Text>After receiving the email, follow the link inside to finish setting up your account.</Text>
      </Stack>
      <HStack justify="center">
        <Text color="muted" fontSize="sm">
          Didn&apos;t receive an email from us?
        </Text>
        <Button color="link" onClick={sendEmailConfirmation}>
          Resend email
        </Button>
      </HStack>
    </Stack>
  )
}

export default EmailForm
