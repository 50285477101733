export const getProgressColor = (
  percentageComplete: number,
  startDate: Date,
  currentDate: Date,
  endDate: Date,
  oldestMetricDate: Date,
  newestMetricDate: Date
) => {
  const initialDate = startDate ?? oldestMetricDate
  const lastDate = endDate ?? newestMetricDate

  const now = new Date(currentDate)
  const start = new Date(initialDate)
  const end = new Date(lastDate)

  const timeFromBeginning = now.getTime() - start.getTime()

  const totalDays = end.getTime() - start.getTime()

  if (totalDays === 0) {
    return 'yellow'
  }

  const pseudoPercentageComplete = (timeFromBeginning / totalDays) * 100

  if (percentageComplete >= pseudoPercentageComplete) {
    return 'green'
  }

  return 'yellow'
}

export default { getProgressColor }
