import type { FC, ReactNode } from 'react'
import { cloneElement, useState } from 'react'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'

const modalTrigger = (children, onOpen) => {
  if (children) {
    return cloneElement(children, { onClick: onOpen })
  }

  return <Button onClick={onOpen}>Trigger modal</Button>
}

type Props = {
  title?: string
  children?: ReactNode
  uploaderBody: ReactNode
}

const UploaderModal: FC<Props> = ({ uploaderBody, title = 'Upload Photos', children = null }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {modalTrigger(children, () => setOpen(true))}

      <DialogRoot onOpenChange={(e) => setOpen(e.open)} open={open} placement="center" size="lg">
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          <DialogCloseTrigger />
          <DialogBody>{uploaderBody}</DialogBody>
          <DialogFooter>
            <DialogActionTrigger asChild>
              <Button>Close</Button>
            </DialogActionTrigger>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  )
}

export default UploaderModal
