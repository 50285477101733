import { HStack, Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import { Field } from '@app/components/ui/field'
import { NativeSelectField, NativeSelectRoot } from '@app/components/ui/native-select'
import ToggleInput from '@app/next/forms/toggleInput'
import type { FormErrors, MapDomainMetric } from '@app/types'

const OPTIONS = [...Array(21)].map((_, i) => (
  // eslint-disable-next-line react/no-array-index-key
  <option key={i} value={i}>
    {i}
  </option>
))

interface Props {
  metric?: MapDomainMetric
  errors?: FormErrors
}

const MetricDisplay: FC<Props> = ({ metric = null, errors = null }) => (
  <Stack>
    <Field label="Make number compact?" orientation="vertical">
      <ToggleInput name="compactDisplay" defaultValue={metric?.compactDisplay} />
    </Field>
    <HStack>
      <Field label="Min. decimal places" id="minimumDisplayPrecision" invalid={!!errors?.minimumDisplayPrecision}>
        <NativeSelectRoot>
          <NativeSelectField defaultValue={metric?.minimumDisplayPrecision} name="minimumDisplayPrecision">
            {OPTIONS}
          </NativeSelectField>
        </NativeSelectRoot>
      </Field>
      <Field label="Max. decimal places" id="maximumDisplayPrecision" invalid={!!errors?.maximumDisplayPrecision}>
        <NativeSelectRoot>
          <NativeSelectField
            defaultValue={metric?.maximumDisplayPrecision ? metric.maximumDisplayPrecision : 2}
            name="maximumDisplayPrecision"
          >
            {OPTIONS}
          </NativeSelectField>
        </NativeSelectRoot>
      </Field>
    </HStack>
  </Stack>
)

export default MetricDisplay
