import type { IconProps } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
import type { FC } from 'react'

const Logo: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg viewBox="0 0 24 24">
      <g fill="currentColor">
        <path
          d="M15 7.5C15 6.67157 15.6716 6 16.5 6C17.3284 6 18 6.67157 18 7.5C18 8.32843 17.3284 9 16.5 9V11C18.433 11 20 9.433 20 7.5C20 5.567 18.433 4 16.5 4C14.567 4 13 5.567 13 7.5V16.5C13 19.5376 10.5376 22 7.5 22C4.46243 22 2 19.5376 2 16.5C2 13.4624 4.46243 11 7.5 11V9C3.35786 9 0 12.3579 0 16.5C0 20.6421 3.35786 24 7.5 24C11.6421 24 15 20.6421 15 16.5V7.5Z"
          fill="currentColor"
        />
        <path
          d="M7.5 13C5.567 13 4 14.567 4 16.5C4 18.433 5.567 20 7.5 20C9.433 20 11 18.433 11 16.5V7.5C11 4.46243 13.4624 2 16.5 2C19.5376 2 22 4.46243 22 7.5C22 10.5376 19.5376 13 16.5 13V15C20.6421 15 24 11.6421 24 7.5C24 3.35786 20.6421 0 16.5 0C12.3579 0 9 3.35786 9 7.5V16.5C9 17.3284 8.32843 18 7.5 18C6.67157 18 6 17.3284 6 16.5C6 15.6716 6.67157 15 7.5 15V13Z"
          fill="currentColor"
        />
      </g>
    </svg>
  </Icon>
)

export default Logo
