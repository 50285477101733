import { Box } from '@chakra-ui/react'
import type { FC } from 'react'

import AggregatedEntities, { type Props as AggregatedEntitiesProps } from './aggregatedEntities'

import Pagination from '@app/shared/pagination'
import type { Nodes } from '@app/types'
import type { Entity } from '@graphql/queries'

interface Props {
  collection: Entity[]
  metadata: object
  nodeType: Nodes
  layoutRowProps?: AggregatedEntitiesProps['layoutRowProps']
}

const Entities: FC<Props> = ({ collection, metadata, nodeType, layoutRowProps, ...rest }) => (
  <Box id="lazyload-container" {...rest}>
    <AggregatedEntities entities={collection} nodeType={nodeType} layoutRowProps={layoutRowProps} />
    <Pagination pt={8} pb={4} {...{ ...metadata }} />
  </Box>
)

export default Entities
