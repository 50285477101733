import { Group } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useCallback } from 'react'

import { Button } from '@app/components/ui/button'
import type { FooterProps as BaseFooterProps } from '@app/shared/confirmPopover'
import ConfirmPopover from '@app/shared/confirmPopover'

export type Props = {
  onYes: () => void
  onNo: () => void
  children?: ReactNode
}

type FooterProps = BaseFooterProps & {
  onYes: () => void
  onNo: () => void
  onClose: () => void
}

const Footer: FC<FooterProps> = ({ onYes, onNo, onClose, cancelRef }) => {
  const yes = () => {
    onYes()
    onClose()
  }

  const no = () => {
    onNo()
    onClose()
  }

  return (
    <Group>
      <Button onClick={yes}>Yes</Button>
      <Button onClick={no}>No</Button>
      <Button variant="outline" ref={cancelRef} onClick={onClose}>
        Cancel
      </Button>
    </Group>
  )
}

const ClearDataPointsPopover: FC<Props> = ({ onYes, onNo, children }) => {
  const ThreeButtonFooter: FC<FooterProps> = useCallback(
    (props) => <Footer {...props} onYes={onYes} onNo={onNo} />,
    [onYes, onNo]
  )

  return (
    <ConfirmPopover
      header="Clear data points first?"
      body="Would you like us to clear all existing data points before we update the data?"
      Footer={ThreeButtonFooter}
    >
      {children}
    </ConfirmPopover>
  )
}

export default ClearDataPointsPopover
