import { Icon, IconButton, type IconButtonProps } from '@chakra-ui/react'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { Tooltip } from '@app/components/ui/tooltip'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import Slack from '@app/images/slack'
import type { Strategy } from '@graphql/types'

interface Props extends IconButtonProps {
  strategy?: Strategy
}

const StrategySlackDisplay: FC<Props> = ({ strategy = null, ...rest }) => {
  const { user } = useStoreCurrentUser()
  const { organization } = user

  if (!organization.slackable) {
    return (
      <Tooltip content="Connect live updates to Slack">
        <IconButton fontSize="lg" aria-label="Slack integration" variant="ghost" {...rest}>
          <Link to="/settings/integrations/slack">
            <Icon>
              <Slack />
            </Icon>
          </Link>
        </IconButton>
      </Tooltip>
    )
  }

  if (strategy?.slackChannelId) {
    return (
      <Tooltip content="Slack settings">
        <IconButton fontSize="lg" aria-label="Slack settings" variant="ghost" {...rest} asChild>
          <Link to="map/settings">
            <Icon>
              <Slack />
            </Icon>
          </Link>
        </IconButton>
      </Tooltip>
    )
  }

  return null
}

export default StrategySlackDisplay
