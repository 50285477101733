import { HStack, Icon } from '@chakra-ui/react'
import type { FC } from 'react'
import { PiCaretDown } from 'react-icons/pi'

import type { TagProps } from '@app/components/ui/tag'
import { Tag } from '@app/components/ui/tag'
import { Tooltip } from '@app/components/ui/tooltip'

interface Props extends TagProps {
  rating: string
  color?: string
  showChevron?: boolean
  tooltip?: string
}

const ConfidenceRatingTag: FC<Props> = ({
  rating,
  fontSize = 'sm',
  size = 'sm',
  color = 'gray',
  showChevron = false,
  tooltip = '',
  ...rest
}) => (
  <Tooltip content={tooltip} showArrow>
    <Tag
      fontSize={fontSize}
      whiteSpace="nowrap"
      colorPalette={color}
      size={size}
      variant="solid"
      {...rest}
      _hover={{ cursor: 'pointer' }}
    >
      <HStack>
        <span>{rating}</span>
        {showChevron && (
          <Icon>
            <PiCaretDown />
          </Icon>
        )}
      </HStack>
    </Tag>
  </Tooltip>
)

export default ConfidenceRatingTag
