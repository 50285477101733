import type { GroupProps } from '@chakra-ui/react'
import { Group, IconButton, Link } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiDownload, FiTrash2 } from 'react-icons/fi'

import { useUploadsContext } from '../entities/uploadsContext'

import type { Upload } from '@app/types'

type Props = GroupProps & {
  attachment: Upload
}

const NewEntityActionBar: FC<Props> = ({ attachment, ...rest }) => {
  const { dispatch } = useUploadsContext()
  const onClick = () => {
    dispatch({ type: 'remove', payload: attachment })
  }

  return (
    <Group {...rest}>
      <IconButton aria-label="Download image" asChild size="xs">
        <Link href={attachment.url} rel="onoopener noreferrer" target="_blank">
          <FiDownload />
        </Link>
      </IconButton>
      <IconButton aria-label="Delete Attachment" onClick={onClick} size="xs">
        <FiTrash2 />
      </IconButton>
    </Group>
  )
}

export default NewEntityActionBar
