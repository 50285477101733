import type { EdgeProps } from '@xyflow/react'
import { EdgeText } from '@xyflow/react'
import type { FC } from 'react'

interface Props
  extends Pick<
    EdgeProps,
    'labelStyle' | 'labelShowBg' | 'labelBgStyle' | 'labelBgPadding' | 'labelBgBorderRadius' | 'data' | 'style'
  > {
  x: number
  y: number
  color: string
  correlationScore?: number | string
  sourceId?: string
  targetId?: string
}

const CorrelationLabel: FC<Props> = ({
  x,
  y,
  labelBgStyle,
  labelStyle,
  labelShowBg,
  labelBgPadding,
  labelBgBorderRadius,
  style,
  correlationScore = null,
  sourceId = '',
  targetId = '',
  color
}) => {
  let onClickLabelStyle = labelStyle
  let onClickLabelBgStyle = labelBgStyle
  let rawLabelBgStyle = labelBgStyle

  if (!correlationScore || !sourceId || !targetId) {
    return null
  }

  if (correlationScore || typeof correlationScore === 'number') {
    onClickLabelStyle = { ...labelStyle, cursor: 'pointer' }
    rawLabelBgStyle = {
      fill: color,
      ...style
    }
    onClickLabelBgStyle = { ...rawLabelBgStyle, cursor: 'pointer' }
  }

  return (
    <EdgeText
      x={x}
      y={y}
      label={correlationScore}
      labelStyle={onClickLabelStyle}
      labelShowBg={labelShowBg}
      labelBgStyle={onClickLabelBgStyle}
      labelBgPadding={labelBgPadding}
      labelBgBorderRadius={labelBgBorderRadius}
    />
  )
}

export default CorrelationLabel
