import { Box, HStack, IconButton, Input, Separator } from '@chakra-ui/react'
import Fuse from 'fuse.js'
import sortBy from 'lodash/sortBy'
import type { ChangeEvent, FC, MouseEvent } from 'react'
import { useContext, useState } from 'react'
import { FiXCircle, FiSearch } from 'react-icons/fi'
import { PiCaretLeft } from 'react-icons/pi'
import { FixedSizeList as List } from 'react-window'

import FilterSegmentValueOption from './filterSegmentValueOption'

import { InputGroup } from '@app/components/ui/input-group'
import MapSegmentFiltersModalContext from '@app/pages/maps/components/map/filters/segmentFilters/mapSegmentFiltersModalContext'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStrategyMetricDataPointSegmentValuesQuery } from '@graphql/queries'
import type { SegmentName } from '@graphql/types'

const optionBuilder = ({ data, index, style }) => {
  const option = data[index]
  const { id } = option

  return (
    <Box h="45px" css={style}>
      <FilterSegmentValueOption key={id} option={option} />
    </Box>
  )
}

interface Props {
  strategyId: string
  segmentName: Pick<SegmentName, 'id'>
}

const FilterSegmentValueOptions: FC<Props> = ({ segmentName, strategyId }) => {
  const { setActiveSegmentName } = useContext(MapSegmentFiltersModalContext)
  const [filter, setFilter] = useState('')
  const [inputValue, setInputValue] = useState('')
  const { id } = segmentName

  const [{ data }] = useStrategyMetricDataPointSegmentValuesQuery({
    variables: {
      id: strategyId,
      segmentNameId: id
    }
  })

  const fuse = new Fuse(data?.strategy?.metricDataPointSegmentValues || [], {
    keys: ['value']
  })

  const results = filter
    ? fuse.search(filter).map((result) => result.item)
    : data?.strategy?.metricDataPointSegmentValues || []

  const options = sortBy(results, (o) => o.value.toLowerCase())

  const builder = () => {
    // this is a parameterized field, so getting this data through zustand can lead to unexpected outcomes sometimes
    const listHeight = options.length < 7 ? options.length * 45 : 280

    return (
      <>
        <Box p={2}>
          <HStack>
            <IconButton
              aria-label="Back to segments"
              colorPalette="gray"
              onClick={() => setActiveSegmentName(null)}
              size="md"
              variant="outline"
            >
              <PiCaretLeft />
            </IconButton>
            <InputGroup
              w="full"
              endElement={
                inputValue ? (
                  <IconButton
                    aria-label="Clear search"
                    onClick={(_e: MouseEvent<HTMLButtonElement>) => {
                      setInputValue('')
                      setFilter('')
                    }}
                    variant="ghost"
                  >
                    <FiXCircle />
                  </IconButton>
                ) : (
                  <IconButton cursor="default" aria-label="search" variant="ghost">
                    <FiSearch />
                  </IconButton>
                )
              }
            >
              <Input
                w="full"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInputValue(e.target.value)
                  setFilter(e.target.value)
                }}
                placeholder="Search segments..."
                type="text"
                value={inputValue}
              />
            </InputGroup>
          </HStack>
        </Box>
        <Separator />
        <List height={listHeight} itemCount={options.length} itemData={options} itemSize={45} width="100%">
          {optionBuilder}
        </List>
        {options.length === 0 && filter && <Box p={2}>No segments match your search</Box>}
      </>
    )
  }

  return <Box>{builder()}</Box>
}

export default withSuspenseWrapper(FilterSegmentValueOptions)
