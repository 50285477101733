import { IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { Button } from '@app/components/ui/button'
import {
  DialogActionTrigger,
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle
} from '@app/components/ui/dialog'
import { useStore } from '@app/store'
import { Metric } from '@graphql/documents/metric.graphql'
import type { MetricDataPoint } from '@graphql/types'

interface Props {
  metricDataPoint?: Pick<MetricDataPoint, 'id' | 'metricId'>
  onDelete?: () => void
}

const MetricDataPointDeleteButton: FC<Props> = ({ metricDataPoint = null, onDelete = () => {} }) => {
  const { strategyId } = useParams()
  const [open, setOpen] = useState(false)
  const cancelRef = useRef()
  const metricDataPointDelete = useStore.use.deleteMetricDataPoint()
  const { loaderQuery: storeLoaderQuery } = useStore.getState()
  const { id } = metricDataPoint || {}

  const deleteMetricDataPoint = () => {
    if (!id) {
      return false
    }

    metricDataPointDelete(id).then(() => {
      // TODO: This is a stupid way to do this, but it works until we move the stats onto the node
      if (strategyId) {
        storeLoaderQuery(Metric, { id: metricDataPoint.metricId, strategyId })
      }

      onDelete()
    })

    setOpen(false)

    return true
  }

  return (
    <>
      <IconButton color="error" aria-label="Delete data point" onClick={() => setOpen(true)} variant="ghost">
        <FiTrash2 />
      </IconButton>

      <DialogRoot
        initialFocusEl={cancelRef?.current}
        onOpenChange={(e) => setOpen(e.open)}
        open={open}
        placement="center"
        role="alertdialog"
      >
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete metric data point</DialogTitle>
          </DialogHeader>

          <DialogBody>Are you sure? You can&#39;t undo this action afterwards.</DialogBody>

          <DialogFooter>
            <DialogActionTrigger asChild>
              <Button ref={cancelRef} variant="outline">
                Cancel
              </Button>
            </DialogActionTrigger>
            <Button ml={3} bgColor="error" onClick={deleteMetricDataPoint}>
              Delete
            </Button>
          </DialogFooter>
          <DialogCloseTrigger />
        </DialogContent>
      </DialogRoot>
    </>
  )
}

export default MetricDataPointDeleteButton
